import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-clock',
  templateUrl: './clock.icon.html',
})
export class ClockIconComponent extends AbstractIconComponent {
  static icon = 'clock'
}
