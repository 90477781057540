<div class="dialog">
  <h1 mat-dialog-title id="dialog-title" i18n>
    Unable to Authorize
  </h1>
  <div id="dialog-content" mat-dialog-content>
    <p *ngIf="!isRapUser" i18n>
      We are having trouble authorizing your membership for road service, please try again. If the problem persists, please contact us:
    </p>
    <p class="font-size-large" *ngIf="isRapUser" i18n>
      To continue your request for Roadside Assistance, please contact us at
    </p>
  </div>
  <div mat-dialog-actions>
    <app-call-aaa-button
      [clickEventPage]="pageType"
      (buttonClick)="onCallAAAClick()"
      [ariaLabel]="'Contact us'">
    </app-call-aaa-button>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message" i18n-aria-label (click)="onClose()">
    <icon-close></icon-close>
  </button>
</div>
