<app-header-redesign>
  <app-back-button slot="start" (aaaClick)="navigateBack()" />
  <ion-title>
    <app-logo />
  </ion-title>
  <app-closable-header slot="end" />

  <app-stepper-redesign />
</app-header-redesign>

<ion-content>
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-margin">
      <ion-col>
        <aaa-text weight="semibold" color="dark" size="title3" i18n>Issue Selection</aaa-text>
        <aaa-text color="dark" size="subheadline" i18n>How can we help you?</aaa-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ng-container>
        <app-issue-tooltip (selectIssue)="problemSelected($event)"></app-issue-tooltip>
        <app-issue-selection (selectIssue)="problemSelected($event)"></app-issue-selection>
          <app-situation-editor
            [template]="currentTemplate$ | async"
            [isOpen]="isIssueSelected"
            (close)="handleCloseSituation()"
          ></app-situation-editor>
      </ng-container>
    </ion-row>
  </ion-grid>
</ion-content>
