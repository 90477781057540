<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 18 18"
  id="icon-edit"
>
  <g fill="none" fill-rule="evenodd">
    <path
      fill="currentColor"
      d="M17.857 5.582c.19-.19.19-.5 0-.69L13.718.753a.488.488 0 0 0-.69 0L1.99 11.791l-.345.345L.61 18l5.864-1.035.345-.345L17.857 5.582z"
    />
    <path
      stroke="#F5F5F5"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M14.585 8.854L9.756 4.025"
    />
  </g>
</svg>
