import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-engine',
  templateUrl: './engine.icon.html',
})
export class EngineIconComponent extends AbstractIconComponent {
  static icon = 'engine'
}
