<span
  class="form-error text-error small-text"
  role="alert"
  aria-atomic="true"
  [attr.aria-labelledby]="name"
  [attr.name]="name"
  [attr.aria-label]="content?.innerText"
  (click)="onControlClick($event, name)"
  *ngIf="
    control?.errors[type]
    && control.isTouched
  "
>
  <span class="material-icons-sharp icon">close</span>
  <div #content>
    <ng-content></ng-content>
  </div>
</span>