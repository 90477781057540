<app-header-redesign>
  <app-menu-button slot="start" />
  @if ((locationCard$ | async).display) {
    <ion-title>
      <app-logo />
    </ion-title>
  } @else {
    <app-title i18n>Breakdown Location</app-title>
  }
  <app-closable-header slot="end" />
</app-header-redesign>

<ion-content>
  <div class="map-container">
    <ng-container *ngIf="(locationMap$ | async) as locationMap">
      <app-map
        [centerMarker]="locationMap.centerMarker"
        [showGpsLocation]="true"
        [preventTouch]="false"
        [userLocation]="userCoords$ | async"
        [static]="locationMap.static"
        [displayZoomAction]="!locationMap.static"
        [center]="breakdownCoordinates$ | async"
        [displayLocateMeAction]="!(hasDeniedGpsAccess$ | async)"
        (mapDrag)="handleMapDrag($event)"
        [zoomLevel]="zoomLevel$ | async"
      >
        <div topSlot>
          <app-location-search #locationSearch
            i18n-placeholder
            placeholder="Search"
            [selectedAddress]="selectedAddress"
            [location]="breakdownLocation"
            [gpsAvailable]="!(hasDeniedGpsAccess$ | async)"
            showUseCurrentLocation="true"
            (locationSelected)="handleLocationSelected($event)"
            (useCurrentLocation)="useCurrentLocation()"
            [lastSearchLocation]="lastSearchLocation$ | async"
            (inputClear)="inputCleared()"
          ></app-location-search>
        </div>
        <div bottomSlot>
          <ng-container *ngIf="(locationCard$ | async) as locationCard">
            <app-location-card
              *ngIf="locationCard.display || !selectedAddress; else adjustLocationCard"
              (onCurrentLocationClick)="useCurrentLocation()"
              (onHomeLocationClick)="useHomeLocation(locationCard.member)"
              [gpsDisabled]="hasDeniedGpsAccess$ | async"
              [userDefaultCoordsLoading]="locationCard.userDefaultCoordsLoading"
              [isHomeAddressVisible]="locationCard.displayHomeItem"
              [isLoading]="locationCard.loading"></app-location-card>
          </ng-container>
          <ng-template #adjustLocationCard>
            <app-adjust-location-card
              [loading]="isServicingClubConfigLoading$ | async"
              [address]="selectAdjustLocationAddress$ | async"
              [adjustRequired]="selectAdjustLocation$ | async"
              (onLocationConfirm)="handleLocationConfirm(true)"
            ></app-adjust-location-card>
          </ng-template>
        </div>
      </app-map>
  </ng-container>

  <app-lets-start-sheet
    [isLoading]="isLoading"
    [isOpen]="showFullMap$ | async"
    [isRapUser]="isRapUser$ | async"
    [hasDeniedGpsAccess]="hasDeniedGpsAccess$ | async"
    (enterYourAddressClick)="handleEnterYourAddressClick()"
    (findMyLocationClick)="useCurrentLocation({ isFindMyLocationClick: true })"
    (onDismiss)="turnOffLetsStartSheet()"
  ></app-lets-start-sheet>

  <location-details-sheet
    [(isOpen)]="isLocationDetailsDisplay"
    [details]="details$ | async"
    (detailsChange)="handleOptionalDetailsChanged($event)"
    (adjustLocationClick)="handleAdjustLocationClick()"
    (nextClick)="handleNext()"
    (clearClick)="handleLocationSelected($event)"
    [address]="selectAdjustLocationAddress$ | async"
  ></location-details-sheet>

  </div>
</ion-content>

