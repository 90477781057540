<div class="dialog">
  <h1 mat-dialog-title id="dialog-title">{{ data.title }}</h1>
  <app-safehtml
    id="dialog-content"
    mat-dialog-content
    [content]="data.content"
  ></app-safehtml>
  <div mat-dialog-actions>
    <button
      i18n-aria-label
      i18n-title
      name="dialogCustomDismissSubmit"
      class="btn primary btn-sm custom-message-close"
      (click)="onClose()"
      aria-label="dismiss this message"
      title="dismiss this message"
    >
      {{data.closeLabel || closeDefault()}}
    </button>
  </div>
  <button i18n-aria-label mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
