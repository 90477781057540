import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-tire',
  templateUrl: './tire.icon.html',
})
export class TireIconComponent extends AbstractIconComponent {
  static icon = 'tire'
}
