<img
  id="icon-aaa"
  src="../../../../assets/drrweb-lib/images/icons/img-club-brand-copy.png"
  srcset="
    ../../../../assets/drrweb-lib/images/icons/img-club-brand-copy@2x.png 2x,
    assets/drrweb-lib/images/icons/img-club-brand-copy@3x.png 3x
  "
  class="img_club_brand-copy"
  alt="AAA"
  i18n-alt
/>
