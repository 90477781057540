import { Component, Input, OnInit } from '@angular/core'
import { SafeHtml, DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-safehtml',
  templateUrl: './safehtml.component.html',
})
export class SafehtmlComponent implements OnInit {
  @Input() content: string

  contentParsed: SafeHtml

  constructor(private _sanitizer: DomSanitizer) {}

  ngOnInit() {
    const contentsParsed = this.content.trim().replace(/\n/g, '<br />')
    // This parses the string and sanitize unwanted html
    this.contentParsed = this._sanitizer.bypassSecurityTrustHtml(contentsParsed)
  }
}
