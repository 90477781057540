import { createAsyncConstants } from '../../../shared/utils'
import { createAction, props } from '@ngrx/store'
import { AARData, AARRequestParams } from './aar.types'
import { DestinationType, IndexedCollection } from '../../../shared/types'
import { AAR_SORTING_ORDER, AAR_SORTING_TYPES, MapState } from '../location.types'

export const AAR_LOAD = createAsyncConstants('AAR_LOAD')
export const AAR_LOAD_OPEN_AARS = 'AAR_LOAD_OPEN_AARS'
export const AAR_RESET = 'AAR_RESET'
export const SEARCH_AREA = createAsyncConstants('SEARCH_AREA')
export const RESET_TOW_LOCATION_SEARCH = 'RESET_TOW_LOCATION_SEARCH'
export const AAR_PREVIEW = 'AAR_PREVIEW'
export const AAR_DETAIL = createAsyncConstants('AAR_DETAIL')
export const INCREMENT_AARS_SHOWN = 'INCREMENT_AARS_SHOWN'
export const SHOW_OPEN_AARS_ONLY = 'SHOW_OPEN_AARS_ONLY'
export const SHOW_ALL_AARS = 'SHOW_ALL_AARS'
export const NO_MAKE_FACILITIES = 'NO_MAKE_FACILITIES'
export const SET_DESTINATION_TYPE = 'SET_DESTINATION_TYPE'

export const SET_SORTING_TYPE = 'SET_SORTING_TYPE'
export const SET_HAS_RATING_SUMMARY = 'SET_HAS_RATING_SUMMARY'
export const SET_SUGGESTED_SHOP = 'SET_SUGGESTED_SHOP'
export const SET_HOME_CENTER = createAsyncConstants('SET_HOME_CENTER')
export const SET_BREAKDOWN_LOCATION_CENTER = 'SET_BREAKDOWN_LOCATION_CENTER'

export const requestMinimumAars = createAction(AAR_LOAD_OPEN_AARS)

export const requestAars = createAction(
  AAR_LOAD.REQUEST,
  props<{ payload: AARRequestParams }>()
)

export const completeAarLoad = createAction(
  AAR_LOAD.SUCCESS,
  props<{
    payload: {
      [id: string]: IndexedCollection<AARData>
    }
  }>()
)

export const notifyAarLoadFailure = createAction(AAR_LOAD.FAILURE)

export const requestAarDetail = createAction(
  AAR_DETAIL.REQUEST,
  props<{ payload: number | string }>()
)

export const completeAarDetail = createAction(
  AAR_DETAIL.SUCCESS,
  props<{ payload: AARData }>()
)

export const notifyAarDetailFailure = createAction(AAR_DETAIL.FAILURE)

export const resetAar = createAction(AAR_RESET)

export const requestSearchArea = createAction(
  SEARCH_AREA.REQUEST,
  props<{ payload: MapState }>()
)

export const resetTowLocationSearch = createAction(RESET_TOW_LOCATION_SEARCH)

export const incrementAarsShown = createAction(INCREMENT_AARS_SHOWN)

export const showOpenAARsOnly = createAction(SHOW_OPEN_AARS_ONLY)

export const showAllAARs = createAction(SHOW_ALL_AARS)

export const setNoMakesFacilities = createAction(
  NO_MAKE_FACILITIES,
  props<{ payload: { active: boolean } }>()
)

export const setDestinationType = createAction(
  SET_DESTINATION_TYPE,
  props<{ payload: DestinationType }>()
)

export const setSortingType = createAction(
  SET_SORTING_TYPE,
  props<{ payload: { type: AAR_SORTING_TYPES, order: AAR_SORTING_ORDER }}>()
)

export const setHasRatingSummary = createAction(
  SET_HAS_RATING_SUMMARY,
  props<{ payload: boolean }>()
)

export const setSuggestedShop = createAction(
  SET_SUGGESTED_SHOP,
  props<{ payload: AARData }>()
)

export const setAarPreview = createAction(
  AAR_PREVIEW,
  props<{ payload: { id: number }}>()
)

export const setHomeCenter = createAction(SET_HOME_CENTER.REQUEST)

export const notifyHomeCenterFailure = createAction(SET_HOME_CENTER.FAILURE)

export const setBreakdownLocationCenter = createAction(SET_BREAKDOWN_LOCATION_CENTER)
