interface CoordinateLongitudeLatitude {
  longitude: number
  latitude: number
}

interface CoordinateLonLat {
  lon: number
  lat: number
}

interface CoordinateLatLng {
  lat: number
  lng: number
}

type LatLonTuple = [number, number]

interface GeoJSON {
  geometry: {
    coordinates: number[] // matches Point type in types/geojson.
  }
}

export type Coordinate =
  | CoordinateLongitudeLatitude
  | CoordinateLonLat
  | CoordinateLatLng
  | LatLonTuple
  | GeoJSON

export interface Options {
  /** Unit of measurement applied to result. Default: "km". */
  unit?: 'km' | 'mile' | 'meter' | 'nmi' | undefined
  /**
   * If passed, will result in library returning boolean value of whether or not the start and end points are within that supplied threshold.
   */
  threshold?: number | null | undefined
  /** Format of coordinate arguments. */
  format?:
    | '[lat,lon]'
    | '[lon,lat]'
    | '{lon,lat}'
    | '{lat,lng}'
    | 'geojson'
    | undefined
}

// The input & output types of haversine() both depend on the Options object.
type ParamType<T extends Options | undefined> = T extends undefined
  ? CoordinateLongitudeLatitude
  : T extends { format: '[lat,lon]' | '[lon,lat]' }
    ? [number, number]
    : T extends { format: '{lat,lon}' }
      ? CoordinateLonLat
      : T extends { format: '{lat,lng}' }
        ? CoordinateLatLng
        : T extends { format: 'geojson' }
          ? GeoJSON
          : Coordinate

type Return<T extends Options | undefined> = T extends { threshold: number }
  ? boolean
  : number

export function haversine<OptionsT extends Options | undefined = undefined>(
  startCoordinates: ParamType<OptionsT>,
  endCoordinates: ParamType<OptionsT>,
  options?: OptionsT
): Return<OptionsT> {
  options = options ?? ({} as OptionsT)

  const R = options.unit in RADII ? RADII[options.unit] : RADII.km

  const start = convertCoordinates(options.format, startCoordinates)
  const end = convertCoordinates(options.format, endCoordinates)

  const dLat = toRad(end.latitude - start.latitude)
  const dLon = toRad(end.longitude - start.longitude)
  const lat1 = toRad(start.latitude)
  const lat2 = toRad(end.latitude)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  if (options.threshold) {
    return (options.threshold > R * c) as Return<OptionsT>
  }

  return (R * c) as Return<OptionsT>
}

const RADII = {
  km: 6371,
  mile: 3960,
  meter: 6371000,
  nmi: 3440,
}

function toRad(num: number): number {
  return (num * Math.PI) / 180
}

function convertCoordinates(format: string, coordinates) {
  switch (format) {
    case '[lat,lon]':
      return { latitude: coordinates[0], longitude: coordinates[1] }
    case '[lon,lat]':
      return { latitude: coordinates[1], longitude: coordinates[0] }
    case '{lon,lat}':
      return { latitude: coordinates.lat, longitude: coordinates.lon }
    case '{lat,lng}':
      return { latitude: coordinates.lat, longitude: coordinates.lng }
    case 'geojson':
      return {
        latitude: coordinates.geometry.coordinates[1],
        longitude: coordinates.geometry.coordinates[0],
      }
    default:
      return coordinates
  }
}
