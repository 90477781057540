<div class="location-container">
  <ng-container *ngIf="(showFullMap$ | async); else defaultSection">
    <section class="full-map-bd-location">
    </section>
  </ng-container>
  <ng-template #defaultSection>
    <div class="select-location_primary-container"
      [ngClass]="{
            'select-location_invalid': !(isBreakdownLocationValid$ | async)}">
      <div class="select-location_map">
        <app-map [breakdownMarker]="breakdownMarker$ | async"
          [center]="initialCoords"
          [userLocation]="userCoords$ | async"
          view="BREAKDOWN_LOCATION"
          [tabIndexPosition]="250"
          [static]="true"
          [isLoading]="isLoading"
          [hasDeniedGpsAccess]="hasDeniedGpsAccess$ | async"
          (adjustLocationClick)="adjustLocation()"
          [showActionButtons]="true"
          (useCurrentLocation)="useCurrentLocation({ isFindMyLocationClick: true })"
          [isValidBreakdownLocation]="isBreakdownLocationValid$ | async">
        </app-map>
      </div>
      <div class="location-help"
        [ngClass]="{shake: isShaking}"
        *ngIf="(hasDeniedGpsAccess$ | async) && !(isBreakdownLocationValid$ | async)">
        <div class="help-text" i18n>
          We could not determine your precise location.
        </div>
        <div class="help-action">
          <button mat-flat-button i18n
            (click)="help()"
            color="tertiary">
            HELP
          </button>
        </div>
      </div>
      <p i18n id="find-your-location"
        role="heading"
        class="lets-start-label"
        aria-level="2"
        *ngIf="!(isBreakdownLocationValid$ | async)">Let's start by finding you and your vehicle:
      </p>
      <div class="select-location_content">
        <div class="select-location_typeahead"
          *ngIf="!(isBreakdownLocationValid$ | async)">
          <ul class="select-location_options">
            <li class="divisor"
            *ngIf="isHomeAddressComplete && !((authMethod$ | async) === 'MEMBER_NAME')">
            </li>
            <li id="select-location_current">
              <app-button inverted="true"
                noBorder="true"
                class="select-location_current extra-padding-button"
                classNames="small-pad"
                [disabled]="isLoading"
                (buttonClick)="useCurrentLocation()"
                [tabIndex]="204">
                <div class="data-group no-padding">
                  <app-gps-status-icon class="data-group_icon"></app-gps-status-icon>
                  <span i18n>USE CURRENT LOCATION</span>
                </div>
              </app-button>
            </li>
          </ul>

          <ng-container *ngIf="(highWayExit$ | async) as highWayExit">
            <div class="highway-exit">
              {{ highWayExit }}
            </div>
          </ng-container>
        </div>
        <div class="divider-top"
          *ngIf="!(isBreakdownLocationValid$ | async)"></div>
        <div>
          <app-button *ngIf="isValid" i18n
            class="select-location_next"
            [loading]="(isServicingClubConfigLoading$ | async)"
            [inverted]="true"
            (buttonClick)="checkBreakdownLocationDistance()"
            classNames="base-btn-size"
            [tabIndex]="212">
            Next
          </app-button>
          <div class="text-error small-text" i18n
            [ngClass]="{ hidden: (isBreakdownLocationValid$ | async) || isPristine }">
            Location is required.
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
