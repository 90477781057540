export enum SUPPORTED_LOCALES {
  EN_US = 'en-US',
  EN_CA = 'en-CA',
  FR_CA = 'fr-CA',
}

export enum DISTANCE_UNIT {
  MILES = 'MILES',
  KILOMETERS = 'KILOMETERS',
}

export interface I18nLocale {
  locale: SUPPORTED_LOCALES;
  label: string;
  country_code: string;
  abbreviation: string;
  defaultDistanceUnit: DISTANCE_UNIT;
}

export interface I18nDistanceUnit {
  unit: DISTANCE_UNIT;
  label: string;
  abbreviation: string;
}

export const I18nLocales = () => [
  {
    locale: SUPPORTED_LOCALES.EN_US,
    country_code: 'us',
    label: $localize`United Stated (English)`,
    abbreviation: $localize`EN`,
    defaultDistanceUnit: DISTANCE_UNIT.MILES,
  },
  {
    locale: SUPPORTED_LOCALES.EN_CA,
    country_code: 'ca',
    label: $localize`Canada (English)`,
    abbreviation: $localize`EN`,
    defaultDistanceUnit: DISTANCE_UNIT.KILOMETERS,
  },
  {
    locale: SUPPORTED_LOCALES.FR_CA,
    country_code: 'ca',
    label: $localize`Canada (Français)`,
    abbreviation: $localize`FR`,
    defaultDistanceUnit: DISTANCE_UNIT.KILOMETERS,
  }
] as I18nLocale[];

export const I18nDistanceUnits = () => [
  {
    unit: DISTANCE_UNIT.MILES,
    label: $localize`miles`,
    abbreviation: $localize`mi`,
  },
  {
    unit: DISTANCE_UNIT.KILOMETERS,
    label: $localize`kilometers`,
    abbreviation: $localize`km`,
  }
] as I18nDistanceUnit[];
