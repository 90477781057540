<app-notes-input
  *ngIf="details"
  [(inputValue)]="details.notes"
  fieldId="detail-entry-notes"
  placeholder="Add notes (Optional)..."
>
  <label for="detail-entry-notes">
    Anything else we need to know?
  </label>
</app-notes-input>
