import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-key-locked',
  templateUrl: './key-locked.icon.html',
})
export class KeyLockedIconComponent extends AbstractIconComponent {
  static icon = 'key-locked'
}
