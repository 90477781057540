import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogData } from '../../../ui.types'
import { AbstractComponent } from '../../../../../shared/abstract.component'
import { wait } from '../../../../../shared/utils/wait'
import {
  I18nLocale,
  I18nLocales,
  I18nDistanceUnit,
  I18nDistanceUnits,
  DISTANCE_UNIT
} from '../../../../../shared/i18n/i18n.types'
import { setDistanceUnit } from '../../../ui.actions'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../../../store/root-reducer'
import { selectDistanceUnit } from '../../../ui.selectors'
import { WindowObject } from '../../../../../shared/utils/window-object'
import { getCurrentLocale } from '../../../../../shared/i18n/i18n.utils'

@Component({
  selector: 'app-i18n-switcher',
  templateUrl: './i18n-switcher.component.html',
  styleUrls: [
    '../../shared-dialog.component.scss',
    './i18n-switcher.component.scss',
  ],
})
export class I18nSwitcherComponent extends AbstractComponent implements OnInit {
  locale = localStorage.getItem('locale')
  distanceUnit = DISTANCE_UNIT.MILES
  supportedLocales = I18nLocales()
  supportedUnits = I18nDistanceUnits()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private windowObject: WindowObject,
    private store$: Store<AAAStore>
  ) {
    super()
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store$.pipe(select(selectDistanceUnit)).subscribe((distanceUnit) => {
        this.distanceUnit = distanceUnit !== null ? distanceUnit : getCurrentLocale().defaultDistanceUnit
      })
    )
  }

  handleSwitchLocale(i18n: I18nLocale) {
    this.locale = i18n.locale
    this.distanceUnit = i18n.defaultDistanceUnit
  }

  handleSwitchUnitSystem(distanceUnit: I18nDistanceUnit) {
    this.distanceUnit = distanceUnit.unit
  }

  async handleConfirm() {
    // close dialog
    this.onClose()
    await wait(100)
    // apply unit system
    this.store$.dispatch(setDistanceUnit({ payload: this.distanceUnit }))
    // apply locale
    localStorage.setItem('locale', this.locale)
    this.windowObject.reload()
  }

  onClose() {
    return this.data.close()
  }
}



