<ng-container *ngIf="vehicle">
  <div class="vehicle-tile" (click)="selectVehicle()">
    <div class="vehicle-container">
      <div class="vehicle-image">
        <app-vehicle-image
                [make]="vehicle.make"
                [imageUrl]="vehicle.imageUrl || vehicle.images?.front?.full"
                [thumbnailUrl]="vehicle.images?.front?.thumbnail"
                [maxWidthImage]="'81px'"
                [maxWidthMake]="'51px'"
                [maxHeight]="'51px'"
        >
        </app-vehicle-image>
      </div>
      <app-vehicle-colors
              [color]="vehicle.color"
              [height]="50">
      </app-vehicle-colors>
      <div class="vehicle-details">
        <div class="year-make">{{vehicle.year}} {{vehicle.make}}</div>
        <div class="model">{{vehicle.model}}</div>
      </div>
    </div>
    <icon-arrow-right></icon-arrow-right>
  </div>
</ng-container>
