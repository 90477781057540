<label
  class="{{
    'radio-square_label radio-square_' + active + ' radio-square_' + focus
  }}"
>
  <input
    type="checkbox"
    class="radio-square"
    ngModel
    [name]="name"
    [checked]="checked"
    [required]="required"
    [value]="value"
    (change)="handleChange()"
    (focus)="handleFocus()"
    (blur)="handleBlur()"
    [tabIndex]="tabIndex"
    [ngClass]="{
      'first-focusable-element': first
    }"
  />
  <div class="radio-square_selection-indicator"></div>
  <ng-content></ng-content>
</label>
