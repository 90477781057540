import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-rating-v2',
  templateUrl: './rating-v2.component.html',
  styleUrls: ['./rating-v2.component.scss']
})
export class RatingV2Component {
  @Input() rate: number
  @Input() color = 'var(--aaa-color-dark)'
  starArray: number[]

  ngOnChanges() {
    this.starArray = this.calculateStarFill(this.rate)
  }

  calculateStarFill(rate: number): number[] {
    const stars = []
    for (let i = 1; i <= 5 ;i++) {
      if (rate >= i) {
        stars.push(100)
      } else if (rate < i && rate > i - 1) {
        stars.push((rate - (i - 1)) * 100)
      } else {
        stars.push(0)
      }
    }
    return stars
  }
}
