import { Component, EventEmitter, Input, Output } from '@angular/core'
import { LocationAddress } from '../components/breakdown-location.types'

@Component({
  selector: 'app-adjust-location-card',
  templateUrl: './adjust-location-card.component.html',
  styleUrls: ['./adjust-location-card.component.scss'],
})
export class AdjustLocationCardComponent {
  @Input() address: LocationAddress
  @Input() adjustRequired: boolean
  @Input() loading: boolean
  @Output() onLocationConfirm = new EventEmitter<void>()
  @Output() onClearAddress = new EventEmitter<{}>()

  handleClearAddress() {
    this.onClearAddress.emit({})
  }

  handleConfirm() {
    this.onLocationConfirm.emit()
  }
}
