<svg
  xmlns="http://www.w3.org/2000/svg"
  width="32"
  height="20"
  viewBox="0 0 32 20"
  id="icon-comment"
>
  <g fill="none" fill-rule="evenodd">
    <rect width="31.538" height="20" fill="#1A1A1A" rx="4" />
    <g fill="#FFF">
      <path
        d="M3.846 4.615h23.846v.77H3.846zM3.846 7.692h23.846v.77H3.846zM3.846 10.77h23.846v.768H3.846zM3.846 13.846h15.385v.77H3.846z"
      />
    </g>
  </g>
</svg>
