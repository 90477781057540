import { ConnectorTypes } from '../aar/aar.types';

export const evStationDetailsFixture = {
  chargeLevel: { dcFast : 3, level2: 39, level1: 12 },
  connectorTypes: [
    ConnectorTypes.J1772COMBO,
    ConnectorTypes.CHADEMO,
    ConnectorTypes.J1772,
    ConnectorTypes.NEMA1450,
    ConnectorTypes.NEMA520,
    ConnectorTypes.TESLA,
  ],
  stationNotes: 'Test station notes deserunt reprehenderit id anim tempor cillum duis sint consequat eiusmod.',
  lastUpdated: new Date()
}
