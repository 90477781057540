interface IndexedEntity {
  id: string
}

export const findById = <T extends IndexedEntity>(list: Array<T>, id: string) =>
  list.find((item) => item.id === id)

export const generateMockId = <T extends IndexedEntity>(
  list: Array<T>,
  item: T,
  attributes?: Array<keyof T>
): string => {
  let parts = Object.values(item)
  let i = 1

  if (attributes) {
    parts = attributes.map((attribute) => item[attribute])
  }

  const id = parts.join('-')

  while (findById(list, `${id}-${i}`) !== undefined) {
    i++
  }
  return `${id}-${i}`
}
