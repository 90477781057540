<div class="dialog">
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message" i18n-aria-label>
    <icon-close></icon-close>
  </button>
  <div class="details-container tall" mat-dialog-content>
    <!--<app-img
      classNames="logo"
      src="assets/drrweb-lib/images/AAA Approved.svg"
      alt="AAA Approved"
    ></app-img>-->
    <ng-container *ngIf="details$ | async as details; else loading">
      <div class="name-title">{{ details.name }}</div>
      <div class="location-group">
        <div class="normal-text color-darkgrey" *ngIf="details.address as address">
          <div>{{ address.addressLine }}</div>
          <div>
            {{ address.cityName }},&nbsp;{{ address.stateProv.code }}
            {{ address.postalCode }}
          </div>
        </div>
      </div>
      <!-- Phone and distance row -->
      <div class="phone-container" *ngIf="details.phone.value">
        <a class="contact show-mobile"
          href="tel:{{ details.phone.value | phone }}"
          target="_blank">
          <i class="call"></i> {{ details.phone.value }}
        </a>
        <p class="contact show-desktop bold">{{ details.phone.value }}</p>
        <p class="distance color-darkgrey" *ngIf="distanceTo"><strong><app-distance-display [distance]="distanceTo"></app-distance-display></strong></p>
      </div>

      <!-- Electric Vehicle Charging -->
      <ng-container *ngIf="evStationDetails$ | async">
        <app-electric-vehicle [evStationDetails]="evStationDetails$ | async"></app-electric-vehicle>
      </ng-container>

      <!-- Tow to this location button -->
      <div class="button-wrapper">
        <app-button id="aar-details-tow-to-button" (buttonClick)="setLocation(details)" i18n>
          TOW TO THIS LOCATION
        </app-button>
        <ng-container *ngIf="isRapUser$ | async">
          <div class="warn-distance-message">
            <div class="small-text" *ngIf="distanceTo > maxTowMileLimit && distanceTo <= callCenterTowMileage">
              <hr />
              {{flexTowLimitMessage}}
            </div>
            <div class="small-text" *ngIf="distanceTo > callCenterTowMileage" i18n>
              <hr />
              {{strictTowLimitMessage}}
              Please contact us at <a href="tel:{{contactPhone$ | async}}">{{contactPhone$ | async}}</a> so that we may assist you.
            </div>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="details.discount && !(isRapUser$ | async) && !(isEvStation$ | async)">
        <!-- Special offers -->
        <div class="offers-hours bg-lightgrey bg-lightgrey-discounts">
          <div class="offers">
            <app-img classNames="logo-discounts" i18n-alt
              src="assets/drrweb-lib/images/discount-awards.svg"
              alt="AAA Members discount awards"></app-img>
            <h3 class="large-text offer-title" i18n>This Facility Offers AAA Members:</h3>
            <p class="offer-description">{{ details.discount.offerDescription }}</p>
          </div>
        </div>

        <!-- Offer Restriction -->
        <div class="offers-hours bg-lightgrey bg-lightgrey-discounts">
          <div class="offers">
            <p class="offer-restriction">{{ details.discount.offerRestriction }}</p>
          </div>
        </div>
      </ng-container>

      <!-- Repair services -->
      <div class="services-details bg-lightgrey bg-lightgrey-repair-services" *ngIf="details.serviceOffers && details.serviceOffers.length">
        <div class="repair-services">
          <h3 class="large-text" i18n>Repair Services</h3>
          <ul class="repair-services-list small-text"
            data-columns="2">
            <li class="repair-service-item"
              *ngFor="let offer of details.serviceOffers">
              {{ offer | titlecase }}
            </li>
          </ul>
        </div>
      </div>
      <div class="services-details bg-lightgrey bg-lightgrey-vehicles-serviced" *ngIf="details.vehicles && details.vehicles.length">
        <div class="vehicles-serviced">
          <h3 class="large-text" i18n>Vehicles Serviced</h3>
          <ul class="vehicles-list">
            <li class="vehicle-list-item small-text"
              *ngFor="let vehicle of details.vehicles">
              {{ vehicle.value | titlecase }}
            </li>
          </ul>
        </div>
      </div>
      <ng-container *ngIf="!(evStationDetails$ | async) && details.operatingDays && details.operatingDays.length">
        <div class="offers-hours bg-lightgrey bg-lightgrey-operating-days">
          <div class="hours">
            <h3 class="large-text hours-title" i18n>Hours</h3>
            <ng-container *ngIf="details.operatingDays && details.operatingDays.length">
              <ul>
                <li class="small-text day-hours" *ngFor="let day of details.compressedHours">
                  <span class="day">{{ day.days | titlecase }}</span>
                  <span class="hours">{{ day.hours }}</span>
                </li>
              </ul>
            </ng-container>
          </div>
        </div>
      </ng-container>

    </ng-container>
    <ng-template #loading>
      <div class="loading">
        <app-loading [width]="64" [height]="64"></app-loading>
      </div>
    </ng-template>
  </div>
</div>
