@if (rapService.isRapUser()) {
  <app-img
    *ngIf="showLogo"
    src="assets/drrweb-lib/images/{{appIdLogo.fileName}}"
    classNames="{{appIdLogo.className}}"
    alt="{{appIdLogo.alt}}"
    id="{{appIdLogo.className}}"
    width="{{appIdLogo.width[size]}}"
    height="{{appIdLogo.height}}"
  ></app-img>
} @else {
  <icon-aaa-logo id="aaa-logo" [class]="size"></icon-aaa-logo>
}
