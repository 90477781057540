import { Component, OnInit, Inject } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../../store/root-reducer'
import { combineLatest, Observable } from 'rxjs'
import { AARData, AARDetail, EvStationDetails } from '../../../location/aar/aar.types'
import { map } from 'rxjs/operators'
import {
  selectFacility,
  selectEvStationDetails,
  selectIsEVstation,
} from '../../../location/aar/aar.selectors'
import { DialogData } from '../../ui.types'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { setAARAddress } from '../../../location/tow-location/tow-location.actions'
import { areStringsEquivalent } from '../../../../shared/utils/areStringsEquivalent'
import { aarAppendLocationMarker, LocationUtils } from '../../../location/location.utils'
import { selectBreakdownLocationCoordinates } from '../../../location/location.selectors'
import { GenericCoordinates } from '../../../location/location.types'
import {
  selectIsRapUser,
  selectMaxTowMileLimit,
  selectModeConfiguration,
} from '../../../auth/auth.selectors'
import { VendorConfigurationMode } from '../../../auth/mode-configuration.types'
import { requestAarDetail } from '../../../location/aar/aar.actions'
import { selectRoutesDistance } from '../../../route-distance/route-distance.selector'
import { RouteDistance } from '../../../route-distance/route-distance.reducer'
import { RAP_EV_TOW_LIMITATION_FLEX, RAP_EV_TOW_LIMITATION_STRICT, RAP_TOW_LIMITATION_FLEX, RAP_TOW_LIMITATION_STRICT } from '../../../../shared/utils/messages'
import {
  CLOSED_LABEL,
  DEFAULT_DISCOUNT_DESCRIPTION,
  DEFAULT_DISCOUNT_RESTRICTION
} from '../../../constants/shared.constants';
import { selectDefaultPhoneNumber } from '../../../servicing-club/servicing-club.selectors';

@Component({
  selector: 'app-aar-details',
  templateUrl: './aar-details.component.html',
  styleUrls: ['./aar-details.component.scss'],
})
export class AarDetailsComponent implements OnInit {
  details$: Observable<AARDetail> = combineLatest([
    this.store$.pipe(select(selectFacility)),
    this.store$.pipe(select(selectRoutesDistance)),
  ]).pipe(
    map(([selector, routesDistance]: [any, RouteDistance[]]) => {
      const result = selector(this.id)
      if (result) {
        this.distanceTo = this.locationUtils.getDistance(
          routesDistance,
          this.breakdownCoordinates,
          result
        )
        result.compressedHours = this.calculateHours(result)
        if (!result.discount?.offerDescription) {
          result.discount = {
            ...result.discount,
            offerDescription: DEFAULT_DISCOUNT_DESCRIPTION(),
          }
        }
        if (!result.discount?.offerRestriction) {
          result.discount = {
            ...result.discount,
            offerRestriction: DEFAULT_DISCOUNT_RESTRICTION(),
          }
        }
      }
      return result
    })
  )

  configData$: Observable<VendorConfigurationMode> = this.store$.pipe(
    select(selectModeConfiguration)
  )

  breakdownCoordinates$: Observable<GenericCoordinates> = this.store$.pipe(
    select(selectBreakdownLocationCoordinates)
  )

  isRapUser$: Observable<boolean> = this.store$.pipe(select(selectIsRapUser))

  callCenterTowMileage
  distanceTo
  breakdownCoordinates
  maxTowMileLimit
  id: number | string
  flexTowLimitMessage: string
  strictTowLimitMessage: string

  isEvStation$: Observable<boolean> = this.store$.pipe(select(selectIsEVstation))
  evStationDetails$: Observable<EvStationDetails> = this.store$.pipe(select(selectEvStationDetails))
  contactPhone$ = this.store$.pipe(select(selectDefaultPhoneNumber))

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>,
    private locationUtils: LocationUtils,
  ) {
    this.id = data.params.id
  }

  ngOnInit() {
    this.store$.dispatch(requestAarDetail({ payload: this.id }))
    this.breakdownCoordinates$.subscribe((breakdownCoordinates) => {
      this.breakdownCoordinates = breakdownCoordinates
    })
    this.isEvStation$.subscribe((isEvStation) => {
      this.flexTowLimitMessage = isEvStation ? RAP_EV_TOW_LIMITATION_FLEX() : RAP_TOW_LIMITATION_FLEX()
      this.strictTowLimitMessage = isEvStation ? RAP_EV_TOW_LIMITATION_STRICT() : RAP_TOW_LIMITATION_STRICT()
    })
    this.store$
      .pipe(select(selectMaxTowMileLimit))
      .subscribe((maxTowMileLimit) => (this.maxTowMileLimit = maxTowMileLimit))

    this.configData$.subscribe((configData) => {
      this.callCenterTowMileage = configData?.callCenterTowMileage
    })
  }

  setLocation(aar: AARData) {
    this.data.close()
    const shop = aarAppendLocationMarker(aar)
    if (shop) {
      this.store$.dispatch(
        setAARAddress({
          payload: shop,
        })
      )
    }
  }

  calculateHours(details: AARData) {
    const days = [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
      'SUNDAY',
    ]
    let lastOpen = ''
    let lastClose = ''
    return days.reduce((output, day) => {
      const currentDay = details.operatingDays.find((operatingDay) =>
        areStringsEquivalent(operatingDay.day, day)
      )

      if (!currentDay) {
        lastOpen = ''
        lastClose = ''
        output.push({
          days: day,
          hours: CLOSED_LABEL(),
        })
        // note: below lines were commented to match with wireframes not grouping week days, to be confirmed
        // } else if (areStringsEquivalent(lastOpen, currentDay.open) && areStringsEquivalent(lastClose, currentDay.close)) {
        //   output[output.length - 1].days = `${firstDay} - ${day}`
      } else {
        lastOpen = currentDay.open
        lastClose = currentDay.close
        output.push({
          days: day,
          hours: `${lastOpen} - ${lastClose}`,
        })
      }

      return output
    }, [])
  }
}
