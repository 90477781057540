import { Component } from '@angular/core'
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { CallStatus, CallStatusMessage } from '../../dashboard/calls-statuses/call-status.types';
import { selectCallStatusMessageHistory } from '../../dashboard/calls-statuses/call-status.selectors';
import { AAAStore } from '../../../store/root-reducer';

@Component({
  selector: 'app-call-status-history',
  templateUrl: './call-status-history.component.html',
  styleUrls: ['./call-status-history.component.scss'],
})
export class CallStatusHistoryComponent {

  callStatusMessageHistory$: Observable<CallStatusMessage[]> = this.store$.pipe(select(selectCallStatusMessageHistory))

  constructor(
    private store$: Store<AAAStore>,
  ) {
  }

  getIcon(callStatus: CallStatus): string {
    switch (callStatus) {
      case CallStatus.COMPLETED:
        return 'drr-circle-check-filled'
      case CallStatus.NOT_STARTED:
        return 'circle-dashed'
      case CallStatus.PROGRESS:
        return 'progress-check'
    }
  }

}
