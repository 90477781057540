import { Component, Input, OnInit } from '@angular/core'
import { RapService } from '../../../../modules/rap/rap.service'
import { OEMBranding } from '../../../../modules/auth/auth.types'
import { OEMLogoData } from '../../../utils/branding'
import { AaaLogoIconComponent } from '../../../icons/aaa-logo.icon'
import { ImageComponent } from '../../../controls/image/image.component'
import { NgIf } from '@angular/common'
import { AbstractComponent } from '../../../abstract.component'


@Component({
  selector: 'app-logo',
  standalone: true,
  imports: [AaaLogoIconComponent, ImageComponent, NgIf],
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss',
})
export class LogoComponent extends AbstractComponent implements OnInit {
  @Input()
  size: 'xsmall' | 'small' | 'medium' | 'large' = 'small'

  showLogo = false
  appIdLogo

  fileName: string

  constructor(protected rapService: RapService) {
    super()
  }

  ngOnInit(): void {
    if (this.rapService.isRapUser()) {
      const rapId = this.rapService.getRapAppId() as OEMBranding
      this.appIdLogo = {
        ...OEMLogoData[rapId],
        fileName: OEMLogoData[rapId].fileName[this.size] || OEMLogoData[rapId].fileName['default']
      }
    }
    this.showLogo = true;
  }
}
