import { AbstractIconComponent } from '../abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-ev-connector-nema-1450',
  templateUrl: './ev-connector-nema1450.icon.html',
})
export class EvConnectorNema1450IconComponent extends AbstractIconComponent {
  static icon = 'ev-connector-nema-1450'
}
