import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AARData, EvStationDetails } from '../aar/aar.types'
import { OperatingDay, Phone } from '../../../shared/types'

@Component({
  selector: 'app-shop-card',
  templateUrl: './shop-card.component.html',
  styleUrls: ['./shop-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopCardComponent implements OnChanges {
  @Input() destination: AARData
  @Input() size: 'default' | 'reduced' = 'default'
  @Input() borderShadow = true
  @Input() rap = false
  @Input() showSelectButton = false

  @Output() onCardClick = new EventEmitter<void>()
  @Output() close = new EventEmitter<void>();

  addressLine1: string
  addressLine2: string
  status: string
  phone: Phone
  operatingDays: OperatingDay[]
  evStationDetails: EvStationDetails

  ngOnChanges(): void {
    this.addressLine1 = this.destination.address.addressLine
    this.addressLine2 = `${this.destination.address.cityName}, ${this.destination.address.stateProv.code}, ${this.destination.address.postalCode}`
    this.status = this.destination.priority ? $localize`Immediately Available` : null
    this.phone = this.destination.phone
    this.operatingDays = this.destination.operatingDays
    this.evStationDetails = this.destination.evStationDetails
  }

  handleCardClick() {
    if (this.onCardClick) {
      this.onCardClick.emit()
    }
  }

  onClose() {
    this.close.emit()
  }

  isReducedSize() {
    return this.size === 'reduced'
  }

}
