import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import { BreakdownLocationDetails } from '../../../location/location.types'
import { BREAKDOWN_LOCATION_OPTIONS } from '../../../location/breakdown-location/breakdown-location.constants'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { LocationAddress } from '../breakdown-location.types'
import { AdobeEventService } from '../../../tagging/adobe/event-adobe.service';
import { AdobeEventTypes } from '../../../tagging/tagging.types';
import events from '../../../tagging/events'

@Component({
  selector: 'location-details-sheet',
  templateUrl: './location-details-sheet.component.html',
  styleUrls: ['./location-details-sheet.component.scss'],
})
export class LocationDetailsSheetComponent implements OnDestroy {
  private _isOpen = false

  @Input() details: BreakdownLocationDetails
  @Input() address: LocationAddress

  @Input() get isOpen(): boolean {
    return this._isOpen
  }
  set isOpen(value: boolean) {
    this._isOpen = value
    this.isOpenChange.emit(this._isOpen)
  }

  @Output() detailsChange: EventEmitter<any> = new EventEmitter<any>()
  @Output() adjustLocationClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() nextClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() clearClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() isOpenChange = new EventEmitter<boolean>()


  optionTemplates = [
    { name: BREAKDOWN_LOCATION_OPTIONS().AT_HOME_IN_DRIVEWAY, checked: false },
    { name: BREAKDOWN_LOCATION_OPTIONS().IN_PARKING_GARAGE, checked: false },
    { name: BREAKDOWN_LOCATION_OPTIONS().IN_PARKING_LOT, checked: false },
    { name: BREAKDOWN_LOCATION_OPTIONS().ON_SIDE_ROAD, checked: false },
    { name: BREAKDOWN_LOCATION_OPTIONS().ON_INTERSTATE_HIGHWAY, checked: false },
  ]

  private detailsChangeSubject = new Subject<any>()

  constructor(private adobeEventService: AdobeEventService) {
    const RIPPLE_DURATION = 225
    this.detailsChangeSubject
      .pipe(debounceTime(RIPPLE_DURATION))
      .subscribe((value) => {
        this.detailsChange.emit(value)
      })
  }

  ngOnDestroy() {
    this.detailsChangeSubject.complete();
  }

  dismissModal() {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.location.LOCATION_DETAILS_DISMISS
    })
    this.closeModal()
  }

  closeModal() {
    this.isOpen = false
  }

  handleChange(item) {
    this.optionTemplates = this.optionTemplates.map((option) => {
      if (option.name !== item.name) {
        option.checked = false
      }
      return option
    })

    const newDetails = { ...this.details }
    newDetails.options = [{ name: item.name }]

    /*
     * Updating the state immediately was causing the component to be rendered again and freezing the ripple effect.
     * So, we are using a debounced subject to update the state after the ripple effect is done.
     */
    this.detailsChangeSubject.next(newDetails)
  }

  adjustLocation() {
    this.adjustLocationClick.emit()
    this.closeModal()
  }

  next() {
    this.nextClick.emit()
  }

  clear() {
    this.clearClick.emit({})
    this.isOpen = false
  }
}
