import { SharedConfig } from '../../shared/shared.config'
import { SET_SHARED_CONFIG } from './config.actions'
import { PayloadedAction } from '../../shared/types'

export interface ConfigState {
  sharedConfig: SharedConfig
}

export const initialState: ConfigState = {
  sharedConfig: null,
}

export function reducer(
  state: ConfigState | undefined = initialState,
  action: PayloadedAction
): ConfigState {
  switch (action.type) {
    case SET_SHARED_CONFIG:
      return {
        ...state,
        sharedConfig: action.payload,
      }
    default:
      return state
  }
}
