import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'icon-add',
  templateUrl: './add.icon.html',
})
export class AddIconComponent extends AbstractIconComponent {
  static icon = 'add'
  @Input() color = '#003893'
}
