<svg width="35" height="23" viewBox="0 0 35 23" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2185_7105)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 16.4426C9 15.6849 9.31607 14.9581 9.87868 14.4223C10.4413 13.8865 11.2044 13.5855 12 13.5855C12.7956 13.5855 13.5587 13.8865 14.1213 14.4223C14.6839 14.9581 15 15.6849 15 16.4426C15 17.2004 14.6839 17.9271 14.1213 18.4629C13.5587 18.9987 12.7956 19.2998 12 19.2998C11.2044 19.2998 10.4413 18.9987 9.87868 18.4629C9.31607 17.9271 9 17.2004 9 16.4426Z" stroke="#F21D1D" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15 16.4426H18.75V12.1569C18.75 11.778 18.592 11.4147 18.3107 11.1467C18.0294 10.8788 17.6478 10.7283 17.25 10.7283L3.75 10.7298V5.01404M3.75 16.4426H9M3.75 6.79975H10.5" stroke="#F21D1D" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5 10.7283V4.29976L16.5 0.728333V2.87119C16.5 4.29976 14.625 4.6569 14.625 6.08548C14.625 7.1569 15.375 7.87119 16.5 7.87119C17.625 7.87119 18.375 7.1569 18.375 6.08548" stroke="#F21D1D" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <path d="M35 14.264C35 13.5414 34.5312 12.9164 33.8672 12.6625C34.1602 12.0375 34.043 11.2562 33.5352 10.7289C33.0078 10.2211 32.2266 10.1039 31.6016 10.3969C31.3477 9.73279 30.7227 9.26404 30 9.26404C29.2578 9.26404 28.6328 9.73279 28.3789 10.3969C27.7539 10.1039 26.9727 10.2211 26.4648 10.7289C25.9375 11.2562 25.8203 12.0375 26.1133 12.6625C25.4492 12.9164 25 13.5414 25 14.264C25 15.0062 25.4492 15.6312 26.1133 15.8851C25.8203 16.5101 25.9375 17.2914 26.4648 17.7992C26.9727 18.3265 27.7539 18.4437 28.3789 18.1508C28.6328 18.8148 29.2578 19.264 30 19.264C30.7227 19.264 31.3477 18.8148 31.6016 18.1508C32.2461 18.4437 33.0078 18.3265 33.5352 17.7992C34.043 17.2914 34.1602 16.5101 33.8672 15.8851C34.5312 15.6312 35 15.0062 35 14.264ZM30.4688 16.1195V16.4515C30.4688 16.6273 30.3125 16.764 30.1562 16.764H29.8438C29.668 16.764 29.5312 16.6273 29.5312 16.4515V16.1195C29.2969 16.0804 29.082 16.0219 28.9062 15.8851C28.7891 15.807 28.7695 15.6312 28.8672 15.5336L29.2188 15.182C29.2773 15.1234 29.3945 15.1039 29.4922 15.1429C29.5508 15.182 29.6094 15.2015 29.6875 15.2015H30.332C30.4297 15.2015 30.4883 15.1234 30.4883 15.0258C30.4883 14.9476 30.4492 14.889 30.3711 14.8695L29.3945 14.5961C28.9648 14.4594 28.6133 14.1078 28.5547 13.6586C28.4766 13.0336 28.9258 12.5062 29.5312 12.4281V12.0765C29.5312 11.9203 29.668 11.764 29.8438 11.764H30.1562C30.3125 11.764 30.4688 11.9203 30.4688 12.0765V12.4281C30.6836 12.4672 30.8984 12.5258 31.0742 12.6625C31.2109 12.7406 31.2109 12.9164 31.1133 13.014L30.7617 13.3656C30.7031 13.4242 30.5859 13.4437 30.4883 13.4047C30.4297 13.3656 30.3711 13.3461 30.293 13.3461H29.6484C29.5703 13.3461 29.4922 13.4242 29.4922 13.5219C29.4922 13.6 29.5312 13.6586 29.6094 13.6781L30.5859 13.9515C31.0156 14.0883 31.3672 14.4398 31.4258 14.889C31.5039 15.514 31.0547 16.0414 30.4688 16.1195Z" fill="#419553"/>
  <defs>
    <clipPath id="clip0_2185_7105">
      <rect width="24" height="20" fill="white" transform="translate(0 0.0140381)"/>
    </clipPath>
  </defs>
</svg>
