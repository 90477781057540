<div
  class="letters-section"
  [hidden]="isHideAlphabet || dividers.length === 0"
  (wheel)="$event.preventDefault()"
  (touchmove)="$event.preventDefault()"
>
  <ul class="letter-sidebar" [ngClass]="{'sticky': shouldStick}" #bar>
    <li *ngFor="let letter of letters" (click)="scrollToLetter(letter)" tappable>
      <a>{{ letter }}</a>
    </li>
  </ul>
</div>

<ion-list #itemsList mode="ios">
  <ng-container *ngFor="let divider of dividers">
    <ion-item-group>
      <ion-item-divider [id]="'letter-divider-' + divider.key" class="letter-divider" sticky>
        <aaa-text weight="semibold">{{ divider.key }}</aaa-text>
      </ion-item-divider>
      <ion-list>
        <ion-radio-group mode="ios" [value]="selected">
          <ion-item *ngFor="let item of divider.items" (click)="selectItem(item)" button>
            <ion-radio
              color="drr-primary"
              justify="start"
              [value]="item"
              [disabled]="isLoading"
            >
              <ion-row class="ion-align-items-center">
                <ion-col>
                  <aaa-text color="dark" size="subheadline">{{ item }}</aaa-text>
                </ion-col>
                <ion-col class="ion-margin-start" [hidden]="!loading(item)">
                  <ion-spinner></ion-spinner>
                </ion-col>
              </ion-row>
            </ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </ion-item-group>
  </ng-container>
</ion-list>

@if (isLoading && dividers.length === 0) {
  @for (loading of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]; track loading) {
    <div class="loading"></div>
  }
}
