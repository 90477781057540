import { createAsyncConstants } from '../../shared/utils'
import { createAction, props } from '@ngrx/store'
import { ServicesConfiguration } from './servicing-club.types'

export const SET_SERVICING_CLUB_CONFIGS = createAsyncConstants(
  'SET_SERVICING_CLUB_CONFIGS'
)

export const setServicingClubConfigsRequest = createAction(
  SET_SERVICING_CLUB_CONFIGS.REQUEST,
  props<{ payload: { club: string } }>()
)

export const setServicingClubConfigsSuccess = createAction(
  SET_SERVICING_CLUB_CONFIGS.SUCCESS,
  props<{ payload: { club: string; config: ServicesConfiguration } }>()
)

export const setServicingClubConfigsFailure = createAction(
  SET_SERVICING_CLUB_CONFIGS.FAILURE
)
