import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core'
import { TitleCasePipe, UpperCasePipe } from '@angular/common'

import {
  IonButton,
  IonCol,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
} from '@ionic/angular/standalone'

import { IconComponent, InputComponent, TextComponent } from '@aaa-mobile/shared-components'
import { VehicleColorCircleComponent } from '../vehicle-color-circle'
import { OTHER_COLOR_NAME, VEHICLE_COLORS } from '../vehicle.contants'
import { CHARACTER_LIMIT_VEHICLE_COLOR } from '../../location/location.constants'
import { toTitleCase } from '../../../shared/utils/titlecase'

@Component({
  selector: 'aaa-vehicle-color-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './vehicle-color-list.component.html',
  styleUrl: './vehicle-color-list.component.scss',
  imports: [
    VehicleColorCircleComponent,
    TextComponent,
    IonLabel,
    IonRadioGroup,
    IonRadio,
    IonItemDivider,
    IonList,
    IonListHeader,
    IonItemGroup,
    TitleCasePipe,
    IonItem,
    IonRow,
    IonCol,
    IonSpinner,
    IonButton,
    InputComponent,
    IconComponent,
    UpperCasePipe,
  ],
})
export class VehicleColorListComponent {
  @Input() color: string
  @Input() isLoading: boolean
  @Output() selectedColor = new EventEmitter<string>()
  neutralColorList = VEHICLE_COLORS.slice(0, 3)
  colorList = VEHICLE_COLORS.slice(3)
  showOtherColorInput = false
  otherColor = ''
  maxChars = CHARACTER_LIMIT_VEHICLE_COLOR

  ngOnChanges(changes: SimpleChanges) {
    if(changes.color.currentValue && !VEHICLE_COLORS.find((color) =>
      color.name.toLowerCase() === changes.color.currentValue.toLowerCase())) {
      this.otherColor = changes.color.currentValue
    } else if (!this.showOtherColorInput) {
      this.otherColor = OTHER_COLOR_NAME
    }
  }

  selectColor(color: string) {
    if (color === OTHER_COLOR_NAME) {
      this.showOtherColorInput = true
      this.otherColor = ''
    } else {
      this.selectedColor.emit(color)
    }
  }

  selectOtherColor() {
    this.selectedColor.emit(toTitleCase(this.otherColor))
  }

  setOtherColor(event: CustomEvent) {
    this.otherColor = event.detail.value
  }

  getValue(color: string) {
    return color === OTHER_COLOR_NAME && this.otherColor !== OTHER_COLOR_NAME ? this.color : color
  }

  handleOtherColorClick(event: any) {
    event.preventDefault()
    event.stopPropagation()
    if (this.otherColor !== OTHER_COLOR_NAME) {
      this.selectOtherColor()
    }
  }

}
