import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UIService {
  isBrowser = false

  private viewportHeightSubject = new BehaviorSubject<number>(window.innerHeight);
  viewportHeight$ = this.viewportHeightSubject.asObservable();
  viewportHeight: number

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngZone: NgZone
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId)

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', this.updateViewportHeight.bind(this));
      window.visualViewport.addEventListener('scroll', this.updateViewportHeight.bind(this));
    } else {
      window.addEventListener('resize', this.updateViewportHeight.bind(this));
    }
  }

  private updateViewportHeight() {
    this.ngZone.run(() => {
      this.viewportHeightSubject.next(window.visualViewport ? window.visualViewport.height : window.innerHeight);
    });
  }
}
