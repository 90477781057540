<div class="review-list">
  <h1 class="location-name" role="heading" aria-level="1">{{ locationName }}</h1>
  <app-reviews-count [numberReviews]="ratingSummary.reviewCount" class="number-reviews"></app-reviews-count>

  <app-loading *ngIf="isLoading$ | async; else reviewsList" width="25" height="25"></app-loading>

  <ng-template #reviewsList>
    <div class="reviews">
      <ng-container *ngFor="let review of reviews$ | async">
        <app-review-card [review]="review"></app-review-card>
      </ng-container>
    </div>
  </ng-template>
</div>
