import { IndexedCollection, PayloadedAction } from '../../shared/types'
import {
  SET_VEHICLE_STEP,
  VEHICLE_YEARS,
  VEHICLE_MAKES,
  VEHICLE_MODELS,
  SET_VEHICLE_YEAR,
  SET_VEHICLE_MAKE,
  SET_VEHICLE_DETAILS,
  EDIT_VEHICLE,
  SET_EDIT_METADATA,
  CLEAR_EDIT_METADATA,
  CREATE_NEW_VEHICLE,
  VEHICLE_MODEL_TYPES,
  SET_VEHICLE_MODEL_AND_TYPE,
  VEHICLE_BY_PLATE,
  RESET_VEHICLE_BY_PLATE,
  SET_VEHICLE_BY_PLATE_COLOR,
  VEHICLE_IMAGES,
  SET_IS_VEHICLE_EDITING,
} from './vehicle.actions'
import { VehicleData } from '../member/member.types'
import { ModelType, Vehicle, VehicleEditMetadata } from './vehicle.types'
import { VEHICLE_ADD, VEHICLE_UPDATE } from '../member/member.actions'
import { CANCEL_EDITING_REQUEST } from '../../shared/actions/shared.actions'

export interface VehicleEditState {
  id?: string
  color?: string
  imageUrl?: string | null
  driveType?: string
  make?: string
  model?: string
  notes?: string
  vin?: string
  wheelDrive?: string
  year?: number
}

export interface VehicleState {
  step: string
  selectedVehicle?: VehicleData
  years: string[]
  makes: string[]
  models: string[]
  types: IndexedCollection<ModelType[]>
  colors: string[]
  workingVehicle: VehicleEditState
  isVehicleEditing: boolean
  editMetadata: VehicleEditMetadata
  vehiclesByPlate: Vehicle[]
}

export const initialState: VehicleState = {
  step: '',
  years: [],
  makes: [],
  models: [],
  types: {},
  colors: [],
  workingVehicle: {},
  isVehicleEditing:  false,
  editMetadata: {},
  vehiclesByPlate: null,
}

export function reducer(
  state: VehicleState | undefined = initialState,
  action: PayloadedAction
): VehicleState {
  switch (action.type) {
    case SET_VEHICLE_STEP:
      return {
        ...state,
        step: action.payload.step,
      }
    case VEHICLE_YEARS.SUCCESS: {
      return {
        ...state,
        years: [...(action.payload.years || [])],
      }
    }
    case VEHICLE_MAKES.SUCCESS: {
      return {
        ...state,
        makes: [...(action.payload.makes || [])],
      }
    }
    case VEHICLE_MODELS.REQUEST: {
      // Clear models, since they change depending on make.  If not done,
      // you can briefly see the wrong models after re-selecting a make.
      const result = { ...state }
      result.models = []
      return result
    }
    case VEHICLE_MODELS.SUCCESS: {
      return {
        ...state,
        models: [...(action.payload.models || [])],
      }
    }

    case VEHICLE_MODEL_TYPES.SUCCESS: {
      return {
        ...state,
        types: {
          ...state.types,
          ...action.payload,
        },
      }
    }

    case EDIT_VEHICLE: {
      return {
        ...state,
        workingVehicle: { ...action.payload.vehicle },
      }
    }
    case SET_IS_VEHICLE_EDITING: {
      return {
        ...state,
        isVehicleEditing: action.payload
      }
    }
    case SET_EDIT_METADATA: {
      return {
        ...state,
        editMetadata: { ...action.payload },
      }
    }
    case CLEAR_EDIT_METADATA: {
      // Active (mid-edit) records shouldn't stomp the working vehicle!
      if (state.editMetadata.isActive) {
        return {
          ...state,
          editMetadata: {},
        }
      } else {
        return {
          ...state,
          editMetadata: {},
          workingVehicle: {},
        }
      }
    }
    case CREATE_NEW_VEHICLE: {
      const workingVehicle = {
        ...action.payload,
      }

      return {
        ...state,
        workingVehicle,
      }
    }
    case SET_VEHICLE_YEAR: {
      const workingVehicle = {
        ...state.workingVehicle,
        year: action.payload,
      }

      return {
        ...state,
        workingVehicle,
      }
    }
    case SET_VEHICLE_MAKE: {
      const workingVehicle = {
        ...state.workingVehicle,
        make: action.payload,
        model: '',
      }

      return {
        ...state,
        workingVehicle,
      }
    }

    case SET_VEHICLE_MODEL_AND_TYPE: {
      const workingVehicle = {
        ...state.workingVehicle,
        model: action.payload.model,
        driveType: action.payload.driveType,
      }

      return {
        ...state,
        workingVehicle,
      }
    }

    case VEHICLE_ADD.SUCCESS:
    case VEHICLE_UPDATE.SUCCESS:
    case SET_VEHICLE_DETAILS: {
      const workingVehicle = {
        ...state.workingVehicle,
        ...action.payload,
      }

      return {
        ...state,
        workingVehicle,
        editMetadata: {
          ...state.editMetadata,
          completionUrl: null,
        },
      }
    }
    case CANCEL_EDITING_REQUEST:
      return {
        ...initialState,
        years: state.years,
        makes: state.makes,
        colors: state.colors,
      }
    case RESET_VEHICLE_BY_PLATE: {
      return {
        ...state,
        vehiclesByPlate: null,
      }
    }
    case VEHICLE_BY_PLATE.SUCCESS: {
      return {
        ...state,
        vehiclesByPlate: action.payload.vehicles,
      }
    }
    case SET_VEHICLE_BY_PLATE_COLOR: {
      return {
        ...state,
        vehiclesByPlate: state.vehiclesByPlate?.map((vehicle) => ({
          ...vehicle,
          color: action.payload.color,
        })),
      }
    }
    case VEHICLE_IMAGES.SUCCESS: {
      return {
        ...state,
        vehiclesByPlate: state.vehiclesByPlate.map((v1) => ({
          ...v1,
          images: action.payload.find((v2) => v1.vin === v2.vin)?.images,
        })),
      }
    }
    default:
      return state
  }
}
