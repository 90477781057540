import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-vehicle-color',
  templateUrl: './vehicle-color.icon.html',
})
export class VehicleColorIconComponent extends AbstractIconComponent {
  static icon = 'vehicle-color'
}
