<div class="color_container" [ngClass]="{'error-border': error }">
  <icon-vehicle-color class="color_icon"
    [hidden]="selectedColor"
    color="#FA1223">
  </icon-vehicle-color>
  <ng-select
    i18n-placeholder
    placeholder="Color"
    #ngSelect
    addTagText="Add Other Color:"
    bindLabel="label"
    [addTag]="true"
    [inputAttrs]="{ maxLength: maxCharacters }"
    [(ngModel)]="selectedColor"
    [clearable]="false"
    [searchable]="false"
    (change)="handleChange()">
    <ng-option *ngFor="let color of availableColors"
      bindLabel="color"
      [value]="color">
      <div class="color_container-option">
        <div class="color_circle">
          <app-vehicle-colors [color]="color.code"
            [height]="25"
            [width]="25"></app-vehicle-colors>
        </div>
        <span class="color_text">{{ color.label | titlecase }}</span>
      </div>
    </ng-option>
    <ng-template ng-label-tmp>
      <div class="color_container-option">
        <div class="color_circle">
          <ng-container *ngIf="!isColorAvailable(); else colorIsAvailable">
            <div class="color_circle-other"></div>
          </ng-container>
          <ng-template #colorIsAvailable>
            <app-vehicle-colors [color]="getColor().code"
              [height]="25"
              [width]="25"></app-vehicle-colors>
          </ng-template>
        </div>
        <span class="color_text">{{ (getColor()?.label || selectedColor) | titlecase }}</span>
      </div>
    </ng-template>
  </ng-select>
</div>
