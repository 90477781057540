import { Component } from '@angular/core'
import { TowLocationComponent } from '../../location/tow-location/tow-location.component';
import { combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { select } from '@ngrx/store';
import { selectBreakdownCoordinates } from '../../location/location.selectors';
import {
  AAR_FILTER_TYPES,
  AAR_SORTING_ORDER,
  AARSorting,
  GenericCoordinates,
  MapBoundsPadding,
  MapState,
} from '../../location/location.types'
import {
  selectAarPreviewLocation,
  selectIsLoadingSearchArea,
  selectMapBoundsPadding,
  selectShopPreferences
} from '../../location/aar/aar.selectors'
import { CustomMarkerInfo } from '../../location/map/map.component';
import { MarkerTypes, MessageDialogTypes, Option, StepTypes, SubmitSections, TowSections } from '../../ui/ui.types';
import {
  requestMinimumAars,
  requestSearchArea,
  setAarPreview,
  setSortingType,
  showAllAARs,
  showOpenAARsOnly
} from '../../location/aar/aar.actions'
import { AARData, FacilitiesDisplay } from '../../location/aar/aar.types';
import { DestinationType } from '../../../shared/types';
import { aarAppendLocationMarker } from '../../location/location.utils'
import { cancelStepsAhead, openMessageDialog } from '../../ui/ui.actions'
import {
  confirmTowSelection,
  resetTowDestination,
  setAARAddress,
  setPassengers
} from '../../location/tow-location/tow-location.actions'
import events from '../../tagging/events'
import { currentEditStepLocation } from '../../wizard/wizard.selectors';
import { AdobeEventTypes } from '../../tagging/tagging.types';
import { addPartialCallRequest } from '../../dashboard/calls.actions';
import { selectIsMapStep, selectIsTowSelectionConfirmed } from '../../location/tow-location/tow-location.selectors';
import { getMarkerType } from '../../location/tow-location/tow-location.utils';

const PASSENGER_TITLE_TRUCK_LIMIT = () => $localize`Truck capacity limited`
const PASSENGER_CONTENT_TRUCK_LIMIT = () => $localize`Trucks only have 2 seats available. In order to safely transport all of your passengers, please find other means of transportation.`

@Component({
  selector: 'app-tow-location-step',
  templateUrl: './tow-location-step.component.html',
  styleUrls: ['./tow-location-step.component.scss'],
})
export class TowLocationStepComponent extends TowLocationComponent {

  center$ = this.store$.pipe(select(selectBreakdownCoordinates))
  totalDistance = 0
  totalMarkers = 0
  mapLocation: MapState
  displaySearchArea = false
  facilityId: number
  isModalVisible = false

  markers$: Observable<CustomMarkerInfo[]> = combineLatest([
    this.facilitiesDisplay$,
    this.breakdownCoordinates$,
    this.destinationType$,
    this.aarPreview$,
    this.store$.select(selectIsTowSelectionConfirmed),
  ]).pipe(
    map((
      [facilitiesDisplay, breakdownCoordinates, destinationType, aarPreview, towSelectionConfirmed]:
      [FacilitiesDisplay, GenericCoordinates, DestinationType, number, boolean]
    ) => {
      if (!facilitiesDisplay?.markers) {
        return []
      }
      this.totalMarkers = facilitiesDisplay.markers.length
      this.totalDistance = facilitiesDisplay.markers
        .map((shop) => this.locationUtils.haversineMiles(this.lastSearchLocation, shop.marker))
        .reduce((a, b) => a + b, 0)
      const markers: CustomMarkerInfo[] = facilitiesDisplay.markers.map(marker => ({
        location: {
          lat: marker.marker.lat,
          lng: marker.marker.lng,
        },
        markerDetails: {
          type: getMarkerType(marker.location, destinationType),
          active: this.shopPreviewId === marker.location.id,
        },
        tabIndex: this.shopPreviewId === marker.location.id ? 100 : 0,
        id: marker.marker.aarId.toString(),
        click: () => {
          this.selectDestination(marker.marker.aarId)
        },
      }))
      markers.push({
        location: {
          lat: Number(breakdownCoordinates.latitude),
          lng: Number(breakdownCoordinates.longitude),
        },
        markerDetails: {
          type: MarkerTypes.CAR,
        },
        tabIndex: 99,
        id: 'breakdown-pin',
      })
      if (this.towLocation && !this.towLocation.aarId) {
        markers.push({
          location: this.towLocation,
          markerDetails: {
            type: MarkerTypes.CUSTOM,
            active: this.isCustomTowDestination(),
          },
          tabIndex: 100,
          id: 'custom-pin',
          click: () => {
            this.store$.dispatch(setAarPreview({ payload: { id: null } }))
          },
        })
      }
      if (towSelectionConfirmed) {
        return markers.filter(marker => marker.markerDetails.type === MarkerTypes.CAR || marker.markerDetails.active)
      }
      return markers
    })
  )

  isLoadingSearchArea$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoadingSearchArea)
  )

  aarPreviewLocation$: Observable<AARData | null> = this.store$.pipe(
    select(selectAarPreviewLocation)
  )

  mapBoundsPadding$: Observable<MapBoundsPadding> = this.store$.pipe(
    select(selectMapBoundsPadding)
  )

  shopPreferences$ = this.store$.pipe(
    select(selectShopPreferences)
  )

  currentEditStep$: Observable<any> = this.store$.pipe(
    select(currentEditStepLocation)
  )

  isMapStep$: Observable<boolean> = this.store$.pipe(
    select(selectIsMapStep),
    /*
     * This call makes Safari show the searchbar to prevent issues with ionic modal sheet on map page
     * Smooth scrolling behavior seems to not work
     * This can be moved to a global effect after the redesign if it's needed in other places
     */
    tap((isMapStep) => isMapStep && window.scrollTo(0, 0))
  )

  isListStep$: Observable<boolean> = this.isMapStep$.pipe(map((isMapStep) => !isMapStep))

  showMap(show: boolean) {
    const section = show ? TowSections.TOW_MAP : undefined

    this.showMapModal = show
    this.sendPageLoadEvent()

    this.editService.doEdit(StepTypes.TOWING, section, false)
  }

  isCustomTowDestination() {
    return this.shopPreviewId ? !this.shopPreviewId : !!this.towLocation
  }

  handleMapDrag(mapState: MapState) {
    if (this.isCustomTowDestination()) {
      this.displaySearchArea = false
      return
    }
    this.mapLocation = mapState
    if (!this.totalMarkers) {
      this.displaySearchArea = true
    } else {
      const distance = this.locationUtils.haversineMiles(this.lastSearchLocation, this.mapLocation.center)
      const avgDistance = this.totalDistance / this.totalMarkers
      this.displaySearchArea = distance > avgDistance
    }
  }

  selectDestination(id: number) {
    this.store$.dispatch(setAarPreview({ payload: { id } }))
  }

  handleSearchArea() {
    this.store$.dispatch(requestSearchArea({
      payload: this.mapLocation
    }))
    this.displaySearchArea = false
  }

  handleDestinationCardClick(aarData: AARData, suggested = false) {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: suggested ? events.towTo.DESTINATION_SUGGESTED_SHOP_CLICK : events.towTo.DESTINATION_CARD_SELECT,
    }, null, {
      location_name: aarData.name,
      location_code: aarData.id
    })
    this.showMap(true)
    this.selectDestination(aarData.id)
    this.store$.dispatch(confirmTowSelection({
      payload: false
    }))
  }

  handleCustomAddressSelected(address) {
    this.handleAddressSelected(address)
    this.showMap(true)
  }

  handleClearSelectedShop() {
    this.store$.dispatch(resetTowDestination())
    this.store$.dispatch(confirmTowSelection({
      payload: false
    }))
    this.store$.dispatch(setAarPreview({ payload: { id: null } }))
    this.store$.dispatch(cancelStepsAhead())
  }

  handleNext() {
    this.store$.dispatch(confirmTowSelection({
      payload: true
    }))
    super.handleNext()
  }

  handleSelectDestination(destination: AARData) {
    const shop = aarAppendLocationMarker(destination)
    if (shop) {
      this.store$.dispatch(
        setAARAddress({
          payload: shop,
        })
      )
      this.handleNext()
    }
  }

  handleDetails(aarData: AARData) {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.towTo.PAGE_NAME_TOW_TO_DETAIL
    }, null, {
      location_name: aarData.name,
      location_code: aarData.id
    })
    this.facilityId = aarData.id
    this.isModalVisible = true
  }

  handleCloseModal() {
    this.isModalVisible = false
  }

  handleFilter(type: AAR_FILTER_TYPES) {
    switch (type) {
      case AAR_FILTER_TYPES.OPEN:
        this.store$.dispatch(showOpenAARsOnly())
        this.store$.dispatch(requestMinimumAars())
        break
      case AAR_FILTER_TYPES.ALL:
        this.store$.dispatch(showAllAARs())
        break
    }
  }

  handleSorting({ type, order }: AARSorting) {
    switch (order) {
      case AAR_SORTING_ORDER.ASCENDING:
        this.store$.dispatch(setSortingType({ payload: { type, order: AAR_SORTING_ORDER.DESCENDING } }))
        this.adobeEventService.sendEvent({
          eventName: AdobeEventTypes.CTA,
          eventValue: `${events.towTo.DESTINATION_FACILITIES_SORTING} ${type} - Ascending`
        })
        break

      default:
        this.store$.dispatch(setSortingType({ payload: { type, order: AAR_SORTING_ORDER.ASCENDING } }))
        this.adobeEventService.sendEvent({
          eventName: AdobeEventTypes.CTA,
          eventValue: `${events.towTo.DESTINATION_FACILITIES_SORTING} ${type} - Descending`
        })
    }
  }

  selectPassengers(option: Option) {
    // More than two passengers should trigger the message
    const count = parseInt(option.value.replace(/\D/g, ''), 10)
    if (count >= 3) {
      this.store$.dispatch(
        openMessageDialog({
          payload: {
            type: MessageDialogTypes.CUSTOM,
            title: PASSENGER_TITLE_TRUCK_LIMIT(),
            content: PASSENGER_CONTENT_TRUCK_LIMIT(),
          },
        })
      )
    }

    const _action = `${events.towTo.PASSENGERS_COUNT_SELECT}: ${option.value || 1}`
    this.tagging.setClickEvent(_action, events.towTo.PASSENGERS_PAGE_TYPE)
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: _action,
    })

    if (count <= 2) {
      this.store$.dispatch(setPassengers({
        payload: option
      }))
      this.passengersNextStep()
    }
  }

  closePassengers() {
    this.editService.doEdit(StepTypes.TOWING, TowSections.TOW_MAP, false);
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.passengers.DISMISS
    })
  }

  passengersNextStep() {
    this.tagging.setClickEvent(
      events.towTo.PASSENGERS_NEXT_CLICK,
      events.towTo.PASSENGERS_PAGE_TYPE
    )

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.towTo.PASSENGERS_NEXT_CLICK
    })

    // TODO: move these calls to "next" click effects
    this.currentEditStep$.pipe(take(1))
      .subscribe((url) => {
        if (!url) {
          this.store$.dispatch(addPartialCallRequest())

          return this.router.navigate([], {
            queryParams: {
              step: StepTypes.SUBMIT,
              section: SubmitSections.SUMMARY,
            },
          })
        }

        this.wizardService.backToEditUrl(url, true)
      })
  }

  trackFacilitiesByFn(_: number, item: AARData): number {
    return item.id;
  }

  navigateBack() {
    this.isMapStep$.pipe(take(1)).subscribe((isMapStep) => {
      if (isMapStep) {
        this.showMap(false)
      } else {
        this.editService.navigateBack();
      }
    })
  }
}
