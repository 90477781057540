<button *ngIf="problemType"
  appCtaClickEvent="Issue Selected"
  [issueType]="problemType.name"
  class="problem-button"
  [ngClass]="{
    'first-focusable-element': first,
    'disabled': !overrideProblem.enabled
  }"
  (click)="problemClick($event)"
  [title]="problemType.label | uppercase"
  [attr.aria-title]="problemType.label"
  [tabindex]="tabIndex">
  <icon-info class="info-icon"
    appCtaClickEvent="Tooltip Displayed"
    [issueType]="problemType.name"
    [ngClass]="{
        'info-icon_active': (problemTooltip$ | async)?.name === problemType.name
      }"
    (click)="showProblemTooltip($event)"
    [attr.aria-expanded]="problemTooltip$ | async"></icon-info>
  <app-img classNames="problem-button_icon"
    src="{{ problemType.icon }}"
    alt="{{ problemType.label }}"></app-img>
  <div class="problem-button_label">
    {{ problemType.label | uppercase }}
  </div>

</button>
