<button
  [type]="type"
  class="app-button {{ classNames }} {{ color }} {{ size }}"
  [disabled]="disabled"
  [title]="content.innerText | uppercase"
  (click)="onBtnClick($event)"
  [ngClass]="{
    'loading': loading,
    'color-inverted': inverted,
    'active': active,
    'no-border': noBorder,
    'has-shadow': hasShadow
  }"
  [tabIndex]="tabIndex"
  aria-live="polite"
  [attr.aria-busy]="loading"
>

  <ng-container *ngIf="loading">
    <app-loading
      width="24"
      height="24"
      [inverted]="!inverted"
      class="typeahead_icon-begin"
    ></app-loading>
  </ng-container>
  <span #content>
    <ng-content></ng-content>
  </span>
</button>
