import { Component } from '@angular/core'

@Component({
  selector: 'app-panel',
  template: '<ng-content></ng-content>',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent {
  constructor() {}
}
