<div class="call-aaa-button">
  <app-link-button
    href="{{ (useDefault ? (default$ | async) : (roadServicePhoneNumber$ | async)) | phoneConverter: 'href' }}"
    class="show-mobile mobile-phone-button"
    tabIndex="0"
    target="_blank"
    title="{{ (callText$ | async) }}"
    (clickLink)="onCallAAAClick($event)"
    ariaLabel="{{ (callText$ | async) }}"
    size="condensed"
  >
    <span class="phone-number numeric-mobile">
      {{ (roadServicePhoneNumber$ | async) | phoneConverter: 'numeric' }}
    </span>
  </app-link-button>

  <div class="show-desktop text-center">
    <p class="phone-number numeric-desktop">
      {{ (roadServicePhoneNumber$ | async) | phoneConverter: 'numeric' }}
    </p>
  </div>
</div>
