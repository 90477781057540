import { array, boolean, number, object, string } from 'yup'
import {
  CallResponse,
  ClubSupport,
  ServiceDeliveryCallStatus,
  ServiceDeliveryCallStatusHistory,
  ServiceDeliveryPhone,
} from './calls.types'

export const CallResponseSchema = object<CallResponse>().shape({
  callId: string(),
  callDate: string(),
  servicingClub: string(),
  callStatus: string(),
  callToken: string(),
  updateToken: string(),
  trackToken: string(),
  pta: string(),
  ptaInLocalTime: string(),
})

export const ClubSupportSchema = object<ClubSupport>().shape({
  supported: boolean().required(),
})

export const PhoneSchema = object<ServiceDeliveryPhone>().shape({
  number: string().required(),
  primary: boolean().required(),
  sms: boolean().required(),
})

export const CallStatusHistorySchema =
  object<ServiceDeliveryCallStatusHistory>().shape({
    timestamp: string().required(),
    stat: string().required(),
    updateStat: string().required(),
    reason: string().required(),
    facility: string().nullable(),
  })

export const CallStatusSchema = object<ServiceDeliveryCallStatus>()
  .shape({
    callDate: string().required(),
    callId: number().required(),
    pta: string().nullable(),
    phone: array().of(PhoneSchema).nullable(),
    callStatusHist: array().of(CallStatusHistorySchema).nullable(),
  })
  .nullable()
