<div class="promo">
  <ng-container *ngIf="!(isEVstation$ | async); else evContent">
    <div class="image" *ngIf="!(isRapUser)">
      <app-img i18n-alt
        src="assets/drrweb-lib/images/logo.png"
        classNames="aaa-logo"
        elementId="aaa-logo-promo"
        alt="Approved Auto Repair"
      ></app-img>
      <span class="title">Approved Auto Repair</span>
    </div>
    <div class="description" [ngClass]="{ 'description-aligned-center': isRapUser }">
      <ng-container *ngIf="!(isRapUser)">
        <span>AAA Members receive a minimum</span>
        <span class="highlight">
          <span>10% off</span>
          <span class="highlight__extended">Labor</span>
        </span>
      </ng-container>
      <span>Select an approved facility below</span>
    </div>
    <div class="light-divider"></div>
  </ng-container>

  <ng-template #evContent>

    <div class="description description-aligned-center">
      <span>Select an EV charging location</span>
    </div>
    <div class="light-divider"></div>

  </ng-template>
</div>
