import { Component } from '@angular/core'

import { AbstractIconComponent } from './abstract.icon'

@Component({
  selector: 'icon-advisory',
  templateUrl: './advisory.icon.html',
})
export class AdvisoryIconComponent extends AbstractIconComponent {
  static icon = 'advisory'
}
