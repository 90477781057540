import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AAAStore } from '../../../store/root-reducer';
import events from '../../tagging/events';
import { TaggingService } from '../../tagging/tagging.service';
import { setProblemTooltip } from '../../ui/ui.actions';
import { selectProblemTooltip } from '../../ui/ui.selectors';
import { PaceSetterSituation } from '../issue.types';

@Component({
  selector: 'app-issue-tooltip',
  templateUrl: './issue-tooltip.component.html',
  styleUrls: ['./issue-tooltip.component.scss'],
  animations: [
    trigger('slideInAnimation', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ 'max-height':0, opacity: 0 }),
        animate(500, style({ 'max-height':100, opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ 'max-height':0 }))
      ])
    ])
  ],
})
export class IssueTooltipComponent {
  @Output() selectIssue: EventEmitter<PaceSetterSituation> = new EventEmitter<PaceSetterSituation>()

  problemTooltip$: Observable<PaceSetterSituation | null> = this.store$.pipe(
    select(selectProblemTooltip)
  )

  constructor(
    private store$: Store<AAAStore>,
    private eRef: ElementRef,
    private taggingService: TaggingService,
  ) {
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.clearTooltip()
    }
  }

  select(problemType: PaceSetterSituation) {
    this.selectIssue.emit(problemType)
    const action = problemType.name + events.issue.ISSUE_PAGE_NEXT_TOOLTIP_CLICK
    this.taggingService.setClickEvent(action, events.issue.ISSUE_PAGE_TYPE)
    this.clearTooltip()
  }

  clearTooltip() {
    this.store$.dispatch(setProblemTooltip({ payload: null }))
  }

}
