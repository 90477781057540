<svg
  xmlns="http://www.w3.org/2000/svg"
  width="17"
  height="18"
  viewBox="0 0 17 18"
  id="icon-diesel"
>
  <path
    [attr.fill]="color"
    fill-rule="evenodd"
    d="M15.77 4.23l.01-.01L12.06.5 11 1.56l2.11 2.11c-.94.36-1.61 1.26-1.61 2.33A2.5 2.5 0 0 0 14 8.5c.36 0 .69-.08 1-.21v7.21c0 .55-.45 1-1 1s-1-.45-1-1V11c0-1.1-.9-2-2-2h-1V2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2v16h10v-7.5h1.5v5a2.5 2.5 0 0 0 5 0V6c0-.69-.28-1.32-.73-1.77zM8 7H2V2h6v5zm6 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-6.22 6.108c0 .424-.07.813-.212 1.168-.141.355-.34.66-.596.916a2.666 2.666 0 0 1-.924.596c-.36.141-.76.212-1.2.212H2.644v-5.784h2.204c.44 0 .84.071 1.2.214.36.143.668.341.924.596.256.255.455.56.596.914.141.355.212.744.212 1.168zm-1.104 0c0-.317-.042-.602-.126-.854a1.77 1.77 0 0 0-.364-.64 1.588 1.588 0 0 0-.576-.402 1.975 1.975 0 0 0-.762-.14H3.724v4.072h1.124c.283 0 .537-.047.762-.14.225-.093.417-.227.576-.402a1.77 1.77 0 0 0 .364-.64c.084-.252.126-.537.126-.854z"
  />
</svg>
