<svg
  xmlns="http://www.w3.org/2000/svg"
  width="29"
  height="30"
  viewBox="0 0 29 30"
  id="icon-air-meter"
>
  <g fill="none" fill-rule="evenodd">
    <ellipse cx="18.065" cy="15.373" [attr.fill]="color" rx="2.71" ry="2.713" />
    <path
      [attr.fill]="color"
      d="M20.218 9.8a.903.903 0 0 1 1.235-.333c.433.25.585.795.33 1.237l-2.259 3.911a.903.903 0 0 1-1.236.334.899.899 0 0 1-.328-1.237l2.258-3.911zM17.161.904h1.806v4.522h-1.806z"
    />
    <path
      [attr.fill]="color"
      d="M18.97.002c.999 0 1.807.802 1.807 1.807 0 .997-.8 1.806-1.806 1.806h-1.813a1.802 1.802 0 0 1-1.806-1.806c0-.998.8-1.807 1.806-1.807h1.813z"
    />
    <ellipse
      cx="18.065"
      cy="15.373"
      [attr.stroke]="color"
      stroke-width="1.96"
      rx="9.935"
      ry="9.947"
    />
    <path
      [attr.stroke]="color"
      stroke-width="1.96"
      d="M18.684 24.642C16.105 33.678 6.412 23.65.168 22.608"
    />
  </g>
</svg>
