import { AbstractIconComponent } from '../abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-ev-connector-unknown',
  templateUrl: './ev-connector-unknown.icon.html',
})
export class EvConnectorUnknownComponent extends AbstractIconComponent {
  static icon = 'ev-connector-unknown'
}
