<ng-container *ngIf="(step$ | async) === 'passengers'; else tow">
</ng-container>

<ng-template #tow>

  <div class="tow-location-container">
    <!-- Selected address - Map and Action holders -->
    <ng-container *ngIf="!isMobile || isAddressValid">
      <div class="tow-location-map">
        <div class="map-container">
          <ng-container *ngIf="!isMobile && !isAddressValid; else mapTowSelected">
          </ng-container>
          <ng-template #mapTowSelected>
            <ng-container *ngIf="!selectedShop && !isRapUser && !isEV">
              <ng-container *ngIf="!isRapUser && !isEV && (facilitiesDisplay$ | async).aarData.length > 0">
                <div class="pin-legend__container">
                  <div class="pin-legend__item">
                    <icon-aar-destination-pin></icon-aar-destination-pin>
                    <span i18n>AAA Approved Facility</span>
                  </div>
                  <div class="pin-legend__item">
                    <icon-aar-destination-pin [isCOR]="true"></icon-aar-destination-pin>
                    <span i18n>AAA Owned Facility</span>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <app-map #map
              view='TOWING_DESTINATION'
              [static]="true"
              [preventTouch]="false"
              [breakdownMarker]="breakdownMarker$ | async"
              [facilitiesDisplay]="facilitiesDisplayToMap$ | async"
              [center]="breakdownMarker$ | async"
              [(towDestinationMarker)]="towLocation"
              (addressSelected)="handleAddressSelected($event)"
              [enableTowDestination]="true"
              [tabIndexPosition]="401"
              [mapBoundsPadding]="{ top: 130, bottom: 50 }"
              [isLoading]="isLoading$ | async"
              (onShopDetailsClose)="sendPageLoadEvent(true)"
            >
            </app-map>
          </ng-template>
        </div>

        <div class="tow-location-map-options" [ngClass]="{'desktop-margin': !isMobile}" *ngIf="isMobile || isAddressValid && !selectedShop && !(aarPreview$ | async)">
          <ng-container *ngIf="!selectedShop">
            <div class="tow-location-header_search">
              <ng-template [ngTemplateOutlet]="searchAutoComplete"></ng-template>
            </div>
          </ng-container>

          <ng-container *ngIf="!!selectedShop">
            <div class="tow-location-selected">
            </div>
          </ng-container>

          <ng-container *ngIf="isAddressValid">
            <app-button
              i18n
              id="tow-location-next-btn"
              class="tow-location-next-btn"
              (buttonClick)="handleNext()"
              [color]="'primary-yellow'"
              [hasShadow]="true"
              [disabled]="isLoading$ | async"
              type="button">TOW TO THIS LOCATION</app-button>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isAddressValid || !isMobile">
      <div class="tow-location-header"
        [ngClass]="{ 'remove-bg-color': isAddressValid }"
        #towLocationHeader>
        <ng-container *ngIf="!isMobile && (aarPreview$ | async); else searchLocation">
          <app-button
            i18n
            id="tow-location-next-btn"
            class="tow-location-next-btn"
            (buttonClick)="handleNext()"
            [color]="'primary-yellow'"
            [hasShadow]="true"
            [disabled]="isLoading$ | async"
            type="button">TOW TO THIS LOCATION</app-button>
        </ng-container>
        <ng-template #searchLocation>
          <h1 *ngIf="!isAddressValid"
            role="heading"
            aria-level="1"
            class="tow-location-header_title"
            [innerHtml]="getHeadingTitle()">
          </h1>
          <ng-container *ngIf="!isEV && !isAddressValid && !isRapUser">
            <div class="check-list__container">
              <ul class="check-list">
                <li *ngFor="let item of getShopItems()">
                  <icon-check></icon-check>
                  <span>{{ item }}</span>
                </li>
              </ul>
              <app-img i18n-alt
                src="assets/drrweb-lib/images/tow-truck_x2.png"
                class="battery-quotes-banner__icon"
                alt="Battery Quotes"></app-img>
            </div>
          </ng-container>
        </ng-template>
      </div>

      <div [ngClass]="{ 'spacer': isAddressValid }"></div>

      <ng-container *ngIf="!selectedShop && isMobile || !isAddressValid">
        <section class="tow-location-body"
          [ngClass]="{'desktop-margin-list-items': !isMobile}"
          #towLocationBody>
          <div class="map-btn-container"
            *ngIf="isMobile && ((shouldDisplayMapBtn$ | async) || !isAddressValid)">
            <app-button (buttonClick)="showMap(true)"
              id="tow-location-map-btn"
              [color]="'secondary-no-uppercase'"
              [hasShadow]="true">
              <i i18n-alt class="fa-solid fa-map left-icon-button" alt="Map View"></i>
              <ng-container i18n>Map</ng-container>
            </app-button>
          </div>

          <!-- No Facilities / Loading -->
          <ng-container *ngIf="isLoading$ | async">
            <div class="tow-location-body_no-results">
              <app-location-skeleton-item [items]="5"></app-location-skeleton-item>
            </div>
          </ng-container>

          <!-- No Shops / Custom message -->
          <ng-container *ngIf="(facilitiesDisplay$ | async)?.aarData.length && (aarsCustomMessage$ | async)">
            <div i18n class="tow-location-body_no-results">
              Please verify the selected shop services your vehicle
            </div>
          </ng-container>

          <!-- Facilities -->
          <ng-container *ngIf="(facilitiesDisplay$ | async)?.aarData.length &&  !(isLoading$ | async)">
            <!-- List -->
            <ng-container *ngFor="let aar of (facilitiesDisplay$ | async).aarData; let aarIndex = index">
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!(facilitiesDisplay$ | async)?.aarData.length &&  !(isLoading$ | async)">
            <p i18n>No Nearby Facilities</p>
          </ng-container>

        </section>
        <ng-container *ngIf="allowCustomTowDestination">
          <section class="tow-location-footer">
            <h2 *ngIf="!isAddressValid"
              role="heading"
              aria-level="2"
              class="tow-location-header_subtitle"
              [innerHtml]="getHeadingSubtitle()">
            </h2>
            <ng-container *ngIf="!isAddressValid && allowCustomTowDestination">
              <div class="tow-location-header_search">
                <ng-template [ngTemplateOutlet]="searchAutoComplete"></ng-template>
              </div>
            </ng-container>
          </section>
        </ng-container>
      </ng-container>
    </ng-container>

    </div>

  <!-- Map Modal -->
  <ng-container>
  </ng-container>

  <!-- Reusable autocomplete search template -->
  <ng-template #searchAutoComplete>
  </ng-template>

</ng-template>

<ng-template #suggestedShop>
  <div class="suggested-shop-template">
    <p i18n class="promotion-text small-text">AAA Shop near to you:</p>
    <div class="promotion-see-more" (click)="handleSeeMore($event)">
      <p i18n>See more</p>
    </div>
  </div>
</ng-template>
