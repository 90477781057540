<svg
  width="50"
  height="42"
  viewBox="0 0 50 42"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M50 17C50 30.8071 38.8071 42 25 42C11.1929 42 0 30.8071 0 17C0 3.19288 11.1929 0 25 0C38.8071 0 50 3.19288 50 17ZM44 12.5C44 16.0899 41.0899 19 37.5 19C33.9101 19 31 16.0899 31 12.5C31 8.91015 33.9101 6 37.5 6C41.0899 6 44 8.91015 44 12.5ZM12.5 18C16.0899 18 19 15.0899 19 11.5C19 7.91015 16.0899 5 12.5 5C8.91015 5 6 7.91015 6 11.5C6 15.0899 8.91015 18 12.5 18ZM28 31C28 32.6569 26.6569 34 25 34C23.3431 34 22 32.6569 22 31C22 29.3431 23.3431 28 25 28C26.6569 28 28 29.3431 28 31ZM15 33C16.1046 33 17 32.1046 17 31C17 29.8954 16.1046 29 15 29C13.8954 29 13 29.8954 13 31C13 32.1046 13.8954 33 15 33ZM37 31C37 32.1046 36.1046 33 35 33C33.8954 33 33 32.1046 33 31C33 29.8954 33.8954 29 35 29C36.1046 29 37 29.8954 37 31ZM37.5 15C38.8807 15 40 13.8807 40 12.5C40 11.1193 38.8807 10 37.5 10C36.1193 10 35 11.1193 35 12.5C35 13.8807 36.1193 15 37.5 15ZM15 11.5C15 12.8807 13.8807 14 12.5 14C11.1193 14 10 12.8807 10 11.5C10 10.1193 11.1193 9 12.5 9C13.8807 9 15 10.1193 15 11.5ZM22 12C22 17.5228 17.5228 22 12 22C9.76697 22 7.70493 21.2681 6.04063 20.0311C5.67296 19.7579 5.11901 20.0302 5.17935 20.4843C5.69164 24.3399 7.27684 27.8514 9.62096 30.7011C9.80448 30.9242 10.1388 30.9363 10.3495 30.7386C14.133 27.1874 19.3014 25 25 25C30.6985 25 35.8669 27.1874 39.6504 30.7386C39.8611 30.9363 40.1954 30.9242 40.3789 30.7011C42.7231 27.8514 44.3083 24.3399 44.8206 20.4843C44.8809 20.0302 44.3269 19.7579 43.9593 20.0311C42.295 21.2681 40.2329 22 38 22C32.4771 22 28 17.5228 28 12C28 9.35302 29.0284 6.94625 30.7075 5.15746C31.0036 4.84197 30.8358 4.29682 30.4053 4.25228C28.686 4.07433 26.873 4 25 4C23.1269 4 21.3139 4.07433 19.5946 4.25228C19.1641 4.29682 18.9963 4.84197 19.2924 5.15746C20.9715 6.94625 22 9.35302 22 12Z"
    fill="#525252"
  />
</svg>
