<ng-container>
  <app-menu />
  <div class="redesigned-wizard" [ngClass]="(bgContrast$ | async) ? 'bg-contrast' : 'bg-default'">
    <div class="ion-page" [ngSwitch]="(editingStep$ | async)?.url">
      <ng-container *ngSwitchCase="'location'">
        <app-breakdown-location-step class="step"></app-breakdown-location-step>
      </ng-container>
      <ng-container *ngSwitchCase="'issue'">
        <app-issue-step class="step"></app-issue-step>
      </ng-container>
      <ng-container *ngSwitchCase="'vehicle'">
        <app-vehicle-step class="step"></app-vehicle-step>
      </ng-container>
      <ng-container *ngSwitchCase="'submit'">
        <app-review-and-submit class="step"></app-review-and-submit>
      </ng-container>
      <ng-container *ngSwitchCase="'tow-location'">
        <app-tow-location-step class="step"></app-tow-location-step>
      </ng-container>
    </div>
  </div>
</ng-container>
