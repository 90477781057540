<a
  [href]="href"
  target="_blank"
  [tabIndex]="tabIndex"
  class="app-link-button btn link primary {{ size }}"
  [ngStyle]="{'font-size': fontSize}"
  (click)="handleClick($event)"
  [title]="content.innerText"
  triggerable-element
  [ngClass]="{
    'inverted': inverted
  }"
>
  <span #content>
    <ng-content></ng-content>
  </span>
</a>
