import { Injectable } from '@angular/core'
import { Adapter } from '../types'
import { ConnectorTypes } from '../../modules/location/aar/aar.types';

@Injectable({
  providedIn: 'root',
})
export class ConnectorTypeAdapter implements Adapter<ConnectorTypes> {

  constructor() {}

  adapt(connectorType: string): ConnectorTypes {
    return ConnectorTypes[connectorType] ? ConnectorTypes[connectorType] : ConnectorTypes.UNKNOWN
  }
}
