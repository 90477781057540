<ion-row>
  <ion-col class="ion-margin-bottom">
    <aaa-input
      variant="secondary"
      [label]="mileageLabel$ | async"
      [ngrxFormControlState]="controls.mileage"
      type="number"
      autocomplete="on"
    >
      <app-form-helper
        helper
        i18n
        [control]="controls?.mileage"
        name='mileage'
        type='required'
      >
        Mileage is required.
      </app-form-helper>
      <app-form-helper
        helper
        i18n
        [control]="controls?.mileage"
        name='mileage'
        type='greaterThan'
      >
        Mileage must be greater than 0.
      </app-form-helper>
      <app-form-helper
        helper
        i18n
        [control]="controls?.mileage"
        name='mileage'
        type='lessThan'
      >
        Mileage number is too long.
      </app-form-helper>
    </aaa-input>
  </ion-col>
</ion-row>
