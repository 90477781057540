import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'icon-position',
  templateUrl: './position.icon.html',
})
export class PositionIconComponent extends AbstractIconComponent {
  @Input() hasDeniedGpsAccess = false
  static icon = 'position'
}
