import { PACE_SETTER_SITUATION_CODES, PACE_SETTER_SITUATION_TYPES } from '../issue/issue.types'

export enum ServicingClubConfig {
  HIGHWAY_PRIORITY_CODE_NAME = 'highwayPriorityCode',
  ACCIDENT_PRIORITY_CODE = 'accidentPriorityCode',
  LOCKOUT_MEDS_PRIORITY_CODE = 'lockoutMedsPriorityCode',
  LOCKOUT_LOVE_PRIORITY_CODE = 'lockoutLovePriorityCode',
  ROAD_SERVICE_PHONE_NUMBER = 'roadServicePhoneNumber',
  TOW_BENEFITS = 'towBenefits',
  TOW_ALERTS = 'towAlerts',
  ROAD_SERVICE_BATTERY_QUOTE = 'roadServiceBatteryQuote',
  PACE_SETTER_SETTINGS = 'pacesetterSettings',
  DRR = 'drr',
}

export interface ServiceConfigurationSetting {
  name: string
  value: string
  description?: string
}

export interface ServiceConfigurationGroup extends ServiceConfigurationSetting {
  enabled: boolean
  default: boolean
  type?: string
}

export interface ClubSettingsGroup {
  [key: string]: ServicesConfiguration
}

export interface ClubActiveCode {
  [key: string]: {
    overrideOptions: OverrideCode
  }
}

export interface OverrideCode {
  paceSetterCode?: string
  requiresTowing?: boolean
}

export type PacesetterOverrideCode = {
  [key in PACE_SETTER_KEYS]: OverrideCode
}

export interface ClubMembershipTowing {
  distance?: string | number
  message?: string
}

export interface ServicesConfigurationResponse {
  services: {
    configuration: ServicesConfiguration[]
  }
}

export interface ServicesConfiguration {
  configurationGroup: {
    configurationItem: ServiceConfigurationGroup[]
  }
  settings: {
    setting: ServiceConfigurationSetting[]
    association: string
    club: string
    office: string
  }
}

export interface OverrideOptions {
  requiresTowing: boolean
  paceSetterCode: string
  overrideType?: string
}

export interface PacesetterOverrideOptions {
  [key: string]: {
    towIndicator: string
    parentCode: string
  }
}

export enum PACE_SETTER_KEYS {
  ACCIDENT = 'PS_Accident_Code',
  STUCK = 'PS_Stuck_Code',
  STUCK_NO_TOW = 'PS_ExtricateOnly_Code',
  BATTERY_ISSUE = 'PS_Battery_Code',
  TIRE_SPARE = 'PS_TireWithSpare_Code',
  TIRE_NO_SPARE = 'PS_TireNoSpare_Code',
  LOCK_KEY_INSIDE = 'PS_Lock_KeyInside_Code',
  LOCK_KEY_LOST = 'PS_Lock_KeyLost_Code',
  LOCK_KEY_NO_TURN = 'PS_Lock_KeyNoTurn_Code',
  LOCK_KEY_NO_WORK = 'PS_Lock_KeyNoWork_Code',
  LOCK_UNSURE = 'PS_Lock_Unsure_Code',
  FUEL_GAS = 'PS_Fuel_Gas_Code',
  FUEL_DIESEL = 'PS_Fuel_Diesel_Code',
  FUEL_ELECTRIC = 'PS_Fuel_Electric_Code',
  FUEL_HYDROGEN_ALT = 'PS_Fuel_HydrogenAlt_Code',
  NEED_TOW = 'PS_Need_Tow_Code',
  WONT_START = 'PS_WontStart_Code',
  WONT_START_CLICK = 'PS_WontStart_Click_Code',
  WONT_START_DIED = 'PS_WontStart_Died_Code',
  WONT_START_BATT = 'PS_WontStart_Batt_Code',
  WONT_START_CRANK = 'PS_WontStart_Crank_Code',
}

export const PACE_SETTER_KEY_TO_TYPE: { [key in PACE_SETTER_KEYS]: PACE_SETTER_SITUATION_TYPES } = {
  [PACE_SETTER_KEYS.ACCIDENT]: PACE_SETTER_SITUATION_TYPES.ACCIDENT,
  [PACE_SETTER_KEYS.STUCK]: PACE_SETTER_SITUATION_TYPES.STUCK,
  [PACE_SETTER_KEYS.STUCK_NO_TOW]: PACE_SETTER_SITUATION_TYPES.STUCK,
  [PACE_SETTER_KEYS.BATTERY_ISSUE]: PACE_SETTER_SITUATION_TYPES.BATTERY_ISSUE,
  [PACE_SETTER_KEYS.TIRE_SPARE]: PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
  [PACE_SETTER_KEYS.TIRE_NO_SPARE]: PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
  [PACE_SETTER_KEYS.LOCK_KEY_INSIDE]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_KEYS.LOCK_KEY_LOST]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_KEYS.LOCK_KEY_NO_TURN]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_KEYS.LOCK_KEY_NO_WORK]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_KEYS.LOCK_UNSURE]: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
  [PACE_SETTER_KEYS.FUEL_GAS]: PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_KEYS.FUEL_DIESEL]: PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_KEYS.FUEL_ELECTRIC]: PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_KEYS.FUEL_HYDROGEN_ALT]: PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
  [PACE_SETTER_KEYS.NEED_TOW]: PACE_SETTER_SITUATION_TYPES.NEED_A_TOW,
  [PACE_SETTER_KEYS.WONT_START]: PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_KEYS.WONT_START_CLICK]: PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_KEYS.WONT_START_DIED]: PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_KEYS.WONT_START_BATT]: PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
  [PACE_SETTER_KEYS.WONT_START_CRANK]: PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
}

export enum PACE_SETTER_OVERRIDE_TOW_INDICATOR {
  ACCIDENT = 'PS_Accident_TowInd',
  STUCK = 'PS_Stuck_TowInd',
  STUCK_NO_TOW = 'PS_ExtricateOnly_TowInd',
  BATTERY_ISSUE = 'PS_Battery_TowInd',
  TIRE_SPARE = 'PS_TireWithSpare_TowInd',
  TIRE_NO_SPARE = 'PS_TireNoSpare_TowInd',
  LOCK_KEY_INSIDE = 'PS_Lock_KeyInside_TowInd',
  LOCK_KEY_LOST = 'PS_Lock_KeyLost_TowInd',
  LOCK_KEY_NO_TURN = 'PS_Lock_KeyNoTurn_TowInd',
  LOCK_KEY_NO_WORK = 'PS_Lock_KeyNoWork_TowInd',
  LOCK_UNSURE = 'PS_Lock_Unsure_TowInd',
  FUEL_GAS = 'PS_Fuel_Gas_TowInd',
  FUEL_DIESEL = 'PS_Fuel_Diesel_TowInd',
  FUEL_ELECTRIC = 'PS_Fuel_Electric_TowInd',
  NEED_TOW = 'PS_Need_Tow_TowInd',
  WONT_START = 'PS_WontStart_TowInd',
  WONT_START_CLICK = 'PS_WontStart_Click_TowInd',
  WONT_START_DIED = 'PS_WontStart_Died_TowInd',
  WONT_START_BATT = 'PS_WontStart_Batt_TowInd',
  WONT_START_CRANK = 'PS_WontStart_Crank_TowInd',
}

export const PACE_SETTER_OVERRIDE_OPTIONS: PacesetterOverrideOptions = {
  [PACE_SETTER_KEYS.ACCIDENT]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.ACCIDENT,
    parentCode: PACE_SETTER_SITUATION_CODES.T906,
  },
  [PACE_SETTER_KEYS.STUCK]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.STUCK,
    parentCode: PACE_SETTER_SITUATION_CODES.T932,
  },
  [PACE_SETTER_KEYS.STUCK_NO_TOW]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.STUCK_NO_TOW,
    parentCode: PACE_SETTER_SITUATION_CODES.T931,
  },
  [PACE_SETTER_KEYS.BATTERY_ISSUE]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.BATTERY_ISSUE,
    parentCode: PACE_SETTER_SITUATION_CODES.L302,
  },
  [PACE_SETTER_KEYS.TIRE_SPARE]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.TIRE_SPARE,
    parentCode: PACE_SETTER_SITUATION_CODES.L101,
  },
  [PACE_SETTER_KEYS.TIRE_NO_SPARE]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.TIRE_NO_SPARE,
    parentCode: PACE_SETTER_SITUATION_CODES.T180,
  },
  [PACE_SETTER_KEYS.LOCK_KEY_INSIDE]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.LOCK_KEY_INSIDE,
    parentCode: PACE_SETTER_SITUATION_CODES.L701,
  },
  [PACE_SETTER_KEYS.LOCK_KEY_LOST]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.LOCK_KEY_LOST,
    parentCode: PACE_SETTER_SITUATION_CODES.L890,
  },
  [PACE_SETTER_KEYS.LOCK_KEY_NO_TURN]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.LOCK_KEY_NO_TURN,
    parentCode: PACE_SETTER_SITUATION_CODES.L801,
  },
  [PACE_SETTER_KEYS.LOCK_KEY_NO_WORK]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.LOCK_KEY_NO_WORK,
    parentCode: PACE_SETTER_SITUATION_CODES.L890,
  },
  [PACE_SETTER_KEYS.LOCK_UNSURE]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.LOCK_UNSURE,
    parentCode: PACE_SETTER_SITUATION_CODES.L701,
  },
  [PACE_SETTER_KEYS.FUEL_GAS]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.FUEL_GAS,
    parentCode: PACE_SETTER_SITUATION_CODES.L402,
  },
  [PACE_SETTER_KEYS.FUEL_DIESEL]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.FUEL_DIESEL,
    parentCode: PACE_SETTER_SITUATION_CODES.L403,
  },
  [PACE_SETTER_KEYS.FUEL_ELECTRIC]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.FUEL_ELECTRIC,
    parentCode: PACE_SETTER_SITUATION_CODES.T483,
  },
  [PACE_SETTER_KEYS.NEED_TOW]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.NEED_TOW,
    parentCode: PACE_SETTER_SITUATION_CODES.T480,
  },
  [PACE_SETTER_KEYS.WONT_START]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.WONT_START,
    parentCode: PACE_SETTER_SITUATION_CODES.L302,
  },
  [PACE_SETTER_KEYS.WONT_START_CLICK]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.WONT_START_CLICK,
    parentCode: PACE_SETTER_SITUATION_CODES.L302,
  },
  [PACE_SETTER_KEYS.WONT_START_DIED]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.WONT_START_DIED,
    parentCode: PACE_SETTER_SITUATION_CODES.T680,
  },
  [PACE_SETTER_KEYS.WONT_START_BATT]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.WONT_START_BATT,
    parentCode: PACE_SETTER_SITUATION_CODES.L301,
  },
  [PACE_SETTER_KEYS.WONT_START_CRANK]: {
    towIndicator: PACE_SETTER_OVERRIDE_TOW_INDICATOR.WONT_START_CRANK,
    parentCode: PACE_SETTER_SITUATION_CODES.T480,
  },
}
