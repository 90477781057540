import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-air-meter',
  templateUrl: './air-meter.icon.html',
})
export class AirMeterIconComponent extends AbstractIconComponent {
  static icon = 'air-meter'
}
