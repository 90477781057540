import { Component, Input } from '@angular/core'

@Component({
  selector: 'icon-timeline-bullet',
  templateUrl: './timeline-bullet.icon.html',
  styleUrls: ['timeline-bullet.icon.scss']
})
export class TimelineBulletIconComponent {
  @Input() opacity: number
  @Input() disabled = false
}
