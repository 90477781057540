import { Component, Input } from '@angular/core'


@Component({
  selector: 'app-location-skeleton-item',
  templateUrl: './tow-location-skeleton-item.component.html',
  styleUrls: ['../tow-location-list-item.component.scss', './tow-location-skeleton-item.component.scss'],
})
export class TowLocationListSkeletonComponent {

  @Input() items = 5
  @Input() tabIndex = 0


  constructor() {
  }

  generateItems(): number[] {
    return Array.from({ length: this.items }, (_, index) => index);
  }

}
