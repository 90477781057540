<div class="dialog">
  <h1 i18n mat-dialog-title>Your Member ID</h1>

  <div mat-dialog-content>
    <p i18n>
      We are sorry to hear you do not have your AAA Membership number. As a
      reminder, your member number can be located on the front of your AAA
      Membership Card.
    </p>
    <p i18n>To continue your request for service, please call the number below.</p>
  </div>
  <div mat-dialog-actions>
    <app-call-aaa-button i18n-ariaLabel
      [clickEventPage]="pageType"
      (buttonClick)="onClose()"
      ariaLabel="Contact us">
    </app-call-aaa-button>
  </div>
  <button i18n-aria-label mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
