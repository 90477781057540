import { pipe } from 'rxjs'
import { Vehicle, VehicleData } from '../../modules/member/member.types'
import { concatWith } from './concatWith'
import { DestinationType } from '../types';

const DEFAULT_PREFIX = '-'

export const generateVehicleSlug = (
  vehicle: Vehicle | VehicleData,
  postalCode?: string,
  destinationType?: DestinationType,
) =>
  pipe(
    concatWith(vehicle?.year as string, DEFAULT_PREFIX),
    concatWith(vehicle?.make || '', DEFAULT_PREFIX),
    concatWith(vehicle?.model || '', DEFAULT_PREFIX),
    concatWith(postalCode || '', DEFAULT_PREFIX),
    concatWith(destinationType?.toString() || '', DEFAULT_PREFIX),
  )('')
