import { Injectable } from '@angular/core'
import { ofType, Actions, createEffect } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { PayloadedAction } from '../../shared/types'
import { AAAStore } from '../../store/root-reducer'
import { selectActiveCallStatus } from '../dashboard/calls-statuses/call-status.selectors'
import { openMessageDialog } from '../ui/ui.actions'
import { MessageDialogTypes } from '../ui/ui.types'
import {
  ADVISORIES,
  advisoriesRequest,
  advisoriesSuccess,
  notifyAdvisoriesFailure,
  setAdvisoryClubSeen,
} from './advisories.actions'
import { selectHasSeenClubAdvisory } from './advisories.selectors'
import { AdvisoriesService } from './advisories.service'
import { Advisory } from './advisories.types'
import { from, of } from 'rxjs'
import {
  map,
  catchError,
  withLatestFrom,
  mergeMap,
  filter,
  switchMap,
} from 'rxjs/operators'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { RapService } from '../rap/rap.service'

@Injectable()
export class AdvisoriesEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private errorReportingService: ErrorReportingService,
    private _service: AdvisoriesService,
    private rapService: RapService
  ) {}

  getAdvisories$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof advisoriesRequest>>(ADVISORIES.REQUEST),
      withLatestFrom(this.store$.pipe(select(selectActiveCallStatus))),
      filter(
        ([action, activeCall]) =>
          activeCall?.servicingClub !== action.payload.club
      ),
      switchMap(([action]) => {
        const club = action.payload.club

        return from(
          this._service.getAdvisories(action.payload.association, club, action.payload.zipcode)
        ).pipe(
          map((advisories: Advisory[]) =>
            advisoriesSuccess({
              payload: { advisories, club },
            })
          ),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              notifyAdvisoriesFailure,
              { callId: selectActiveCallStatus }
            )
          )
        )
      }),
      catchError((error) =>
        this.errorReportingService.notifyErrorObservable(
          error,
          notifyAdvisoriesFailure,
          { callId: selectActiveCallStatus }
        )
      )
    )
  )

  displayAdvisories$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PayloadedAction>(ADVISORIES.SUCCESS),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.pipe(select(selectHasSeenClubAdvisory))),
          filter(
            ([mappedAction, hasSeenAdvisories]) =>
              mappedAction.payload.advisories.length > 0 && !hasSeenAdvisories
          ),
          mergeMap(() => {
            if (this.rapService.isRapUser()) {
              return []
            }

            return [
              openMessageDialog({
                payload: {
                  type: MessageDialogTypes.ADVISORY,
                },
              }),
              setAdvisoryClubSeen(),
            ]
          }),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              notifyAdvisoriesFailure
            )
          )
        )
      )
    )
  )
}
