import { createAction, props } from '@ngrx/store'

import {
  MemberBasicInfo,
  MemberEligibility,
  MemberInfo,
  MemberVehicles,
  SearchMemberResult,
  SearchMembersMobileParams,
  SearchMembersParams,
  ServiceItem,
  Vehicle,
  VehicleData,
} from './member.types'
import { createAsyncConstants } from '../../shared/utils/createAsyncConstants'

export const VEHICLE_CLEAR = 'VEHICLE_CLEAR'
export const VEHICLE_DETAILS = 'VEHICLE_DETAILS'
export const SET_VEHICLE = 'SET_VEHICLE'
export const SET_ELIGIBILITY_VEHICLE = 'SET_ELIGIBILITY_VEHICLE'
export const CLEAR_ACTIVE_VEHICLE = 'CLEAR_ACTIVE_VEHICLE'
export const ASSIGN_EXISTING_VEHICLE = 'ASSIGN_EXISTING_VEHICLE'
export const RAP_INFO = createAsyncConstants('RAP_INFO')
export const OEM_INFO = createAsyncConstants('OEM_INFO')
export const MEMBER_INFO = createAsyncConstants('MEMBER_INFO')
export const VEHICLE_LOAD = createAsyncConstants('VEHICLE_LOAD')
export const VEHICLE_ADD = createAsyncConstants('VEHICLE_ADD')
export const VEHICLE_UPDATE = createAsyncConstants('VEHICLE_UPDATE')
export const VEHICLE_DELETE = createAsyncConstants('VEHICLE_DELETE')
export const MEMBER_BASIC_INFO = createAsyncConstants('MEMBER_BASIC_INFO')
export const MEMBERS_SEARCH = createAsyncConstants('MEMBERS_SEARCH')
export const MEMBERS_SEARCH_RESET = 'MEMBERS_SEARCH_RESET'
export const REMOVE_VEHICLE = 'REMOVE_VEHICLE'
export const EDIT_VEHICLE_INFO = 'EDIT_VEHICLE_INFO'
export const MEMBER_ELIGIBILITY_INFO = createAsyncConstants(
  'MEMBER_ELIGIBILITY_INFO'
)
export const MEMBER_SERVICE_HISTORY = createAsyncConstants(
  'MEMBER_SERVICE_HISTORY'
)
export const MEMBER_ELIGIBILITY_RESULT = 'MEMBER_ELIGIBILITY_RESULT'
export const ASSIGN_ELIGIBILITY_VEHICLE = 'ASSIGN_ELIGIBILITY_VEHICLE'
export const ASSIGN_DEFAULT_VEHICLE = 'ASSIGN_DEFAULT_VEHICLE'

export const memberEligibilityResult = createAction(
  MEMBER_ELIGIBILITY_RESULT,
  props<{ payload: MemberInfo }>()
)

export const setVehicle = createAction(
  SET_VEHICLE,
  props<{ payload: Vehicle | VehicleData }>()
)

export const setEligibilityVehicle = createAction(
  SET_ELIGIBILITY_VEHICLE,
  props<{ payload: Vehicle | VehicleData }>()
)

export const assignEligibilityVehicle = createAction(ASSIGN_ELIGIBILITY_VEHICLE)

export const assignDefaultVehicle = createAction(ASSIGN_DEFAULT_VEHICLE)

export const assignExistingVehicle = createAction(
  ASSIGN_EXISTING_VEHICLE,
  props<{ payload: Vehicle | VehicleData }>()
)

export const clearActiveVehicle = createAction(CLEAR_ACTIVE_VEHICLE)

export const memberInfoRequest = createAction(MEMBER_INFO.REQUEST)

export const oemInfoRequest = createAction(OEM_INFO.REQUEST)

export const memberInfoSuccess = createAction(
  MEMBER_INFO.SUCCESS,
  props<{ payload: MemberInfo }>()
)

export const memberInfoFailure = createAction(MEMBER_INFO.FAILURE)


export const rapInfoRequest = createAction(RAP_INFO.REQUEST)
export const rapInfoSuccess = createAction(RAP_INFO.SUCCESS)
export const rapInfoFailure = createAction(RAP_INFO.FAILURE)


export const memberBasicInfoRequest = createAction(MEMBER_BASIC_INFO.REQUEST)

export const memberBasicInfoSuccess = createAction(
  MEMBER_BASIC_INFO.SUCCESS,
  props<{ payload: MemberBasicInfo }>()
)

export const memberBasicInfoFailure = createAction(MEMBER_BASIC_INFO.FAILURE)

export const requestVehicleLoad = createAction(VEHICLE_LOAD.REQUEST)

export const vehicleLoadSuccess = createAction(
  VEHICLE_LOAD.SUCCESS,
  props<{ payload: MemberVehicles }>()
)

export const notifyVehicleLoadFailure = createAction(VEHICLE_LOAD.FAILURE)

export const requestVehicleAdd = createAction(
  VEHICLE_ADD.REQUEST,
  props<{ payload: VehicleData }>()
)

export const vehicleAddSuccess = createAction(
  VEHICLE_ADD.SUCCESS,
  props<{ payload: Vehicle }>()
)

export const notifyVehicleAddFailure = createAction(VEHICLE_ADD.FAILURE)

export const requestVehicleUpdate = createAction(
  VEHICLE_UPDATE.REQUEST,
  props<{ payload: Vehicle }>()
)

export const vehicleUpdateSuccess = createAction(
  VEHICLE_UPDATE.SUCCESS,
  props<{ payload: Vehicle }>()
)

export const notifyVehicleUpdateFailure = createAction(VEHICLE_UPDATE.FAILURE)

export const requestVehicleDelete = createAction(
  VEHICLE_DELETE.REQUEST,
  props<{ payload: string }>()
)

export const vehicleDeleteSuccess = createAction(
  VEHICLE_DELETE.SUCCESS,
  props<{ payload: string }>()
)

export const notifyVehicleDeleteFailure = createAction(VEHICLE_DELETE.FAILURE)

export const searchMembersRequest = createAction(
  MEMBERS_SEARCH.REQUEST,
  props<{ payload: SearchMembersParams | SearchMembersMobileParams }>()
)

export const searchMembersSuccess = createAction(
  MEMBERS_SEARCH.SUCCESS,
  props<{ payload: SearchMemberResult }>()
)

export const searchMembersFailure = createAction(MEMBERS_SEARCH.FAILURE)

export const searchMembersReset = createAction(MEMBERS_SEARCH_RESET)

// start ARR:POC - Actions for committing the Tow usage and service history to state
export const memberServiceHistorySuccess = createAction(
  MEMBER_SERVICE_HISTORY.SUCCESS,
  props<{ payload: Array<ServiceItem> }>()
)

export const memberServiceHistoryFailure = createAction(
  MEMBER_SERVICE_HISTORY.FAILURE
)

export const memberEligibilityRequest = createAction(
  MEMBER_ELIGIBILITY_INFO.REQUEST
)

export const memberEligibilitySuccess = createAction(
  MEMBER_ELIGIBILITY_INFO.SUCCESS,
  props<{ payload: MemberEligibility }>()
)

export const memberEligibilityFailure = createAction(
  MEMBER_ELIGIBILITY_INFO.FAILURE
)
// end ARR:POC
