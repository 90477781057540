import { IndexedCollection, PayloadedAction } from '../../../shared/types'
import { CLUB_SUPPORT } from './clubs.actions'

export interface ClubState {
  clubs: IndexedCollection<Club>
}

export interface Club {
  name: string
  hasTrackingSupport?: boolean
}

export const initialState: ClubState = {
  clubs: {},
}

export function reducer(
  state: ClubState | undefined = initialState,
  action: PayloadedAction
): ClubState {
  switch (action.type) {
    // TODO move business logic to effects
    case CLUB_SUPPORT.SUCCESS:
      const result = { ...state }
      const name = action.payload.name

      const club: Club = result.clubs[name] || { name }
      club.hasTrackingSupport = action.payload.hasTrackingSupport

      result.clubs[name] = club

      return result
    default:
      return state
  }
}
