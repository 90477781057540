import { Injectable } from '@angular/core'

import {
  VehicleRequestQueryParams,
  ModelType,
  ModelTypesResponse,
  PlateToVinResponse,
  VehicleImagesResponse,
  VehicleMakes,
  VehicleModels,
  VehicleYears
} from './vehicle.types'
import { XHRService } from '../../shared/services/xhr.service'
import {
  PlateToVinResponseSchema,
  VehicleImagesResponseSchema,
  VehicleMakesSchema,
  VehicleModelsSchema,
  VehicleYearsSchema,
} from './vehicle.model'
import { generateVehicleSlug } from '../../shared/utils'
import { IndexedCollection } from '../../shared/types'
import { VehicleUtils } from './vehicle.utils'
import { RequestOptions } from '../../shared/services/xhr.utils'

const EMPTY_PLATE_TO_VIN_RESPONSE: PlateToVinResponse = {
  vehicles: []
}

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  devOptions: RequestOptions = {}
  constructor(
    private xhr: XHRService,
    private vehicleUtils: VehicleUtils,
  ) {}

  defaultQueryParams = {
    showTypicalVehicles:true,
    showMotorcycles: false,
    showMotorhomes: false
  }

  async getYears() {
    const response = await this.xhr.authRequest<VehicleYears>(
      'bff/vehicles/years',
      this.devOptions
    )
    await VehicleYearsSchema.validate(response)
    return response
  }

  async getMakes(year: number, vehicleQueryParams?: VehicleRequestQueryParams) {
    const queryParams = vehicleQueryParams || this.defaultQueryParams

    const response = await this.xhr.authRequest<VehicleMakes>({
      url: `bff/vehicles/makes/${year}`,
      params: queryParams
    },
    this.devOptions
    )
    await VehicleMakesSchema.validate(response)
    return response
  }

  async getModels(year: number, make: string, vehicleQueryParams?: VehicleRequestQueryParams) {
    const queryParams = vehicleQueryParams || this.defaultQueryParams

    if (make.includes('Motorhome/')) {
      make = this.vehicleUtils.normalizeMotorHome(make) as string
    }
    const encodedMake = this.vehicleUtils.uriEncode(make)
    const response = await this.xhr.authRequest<VehicleModels>({
      url: `bff/vehicles/models/${year}/${encodedMake}`,
      params: queryParams
    },
    this.devOptions
    )
    await VehicleModelsSchema.validate(response)
    return response
  }

  async getModelTypes({ year, make, model }): Promise<IndexedCollection<ModelType[]>> {
    if (make.includes('Motorhome/') || model.includes('Motorhome/')) {
      [make, model] = this.vehicleUtils.normalizeMotorHome([make, model])
    }
    const [encodedMake, encodedModel] = this.vehicleUtils.uriEncode([make, model])

    const response = await this.xhr.authRequest<ModelTypesResponse>(
      `bff/vehicles/types/${year}/${encodedMake}/${encodedModel}`,
      this.devOptions
    )
    // await VehicleModelTypesSchema.validate(response)
    const slug = generateVehicleSlug({ year, make, model })
    return {
      [slug]: response.types
    }
  }

  async getVehicle(tag: string, state: string) {
    try {
      const response = await this.xhr.authRequest<PlateToVinResponse>({
        url: 'bff/vehicles/platetovin',
        params: { tag, state }
      }, {
        retryAll: false
      })
      await PlateToVinResponseSchema.validate(response)
      return response
    } catch (e) {
      if (e.response?.status === 404) {
        return EMPTY_PLATE_TO_VIN_RESPONSE
      }
      throw e
    }
  }

  async getVehicleImages(year: number | string, make: string, model: string) {
    try {
      const response = await this.xhr.authRequest<VehicleImagesResponse>({
        url: `bff/vehicles/images/${year}/${make}/${model}`
      }, {
        retryAll: false
      })
      await VehicleImagesResponseSchema.validate(response)
      return response
    } catch (e) {
      throw e;
    }
  }
}
