import { PayloadedAction } from '../../shared/types'
import { Uuid } from '../../shared/utils/uuid'

export interface SessionState {
  lastInteraction: number
  userSessionId: string
}

const uuid = new Uuid()

export const initialState: SessionState = {
  lastInteraction: null,
  userSessionId: uuid.getV4() as string,
}

export function reducer(
  state: SessionState | undefined = initialState,
  action: PayloadedAction
): SessionState {
  switch (action.type) {
    default:
      return state
  }
}
