<svg
  xmlns="http://www.w3.org/2000/svg"
  width="22"
  height="12"
  viewBox="0 0 22 12"
  id="icon-key"
>
  <path
    [attr.fill]="color"
    fill-rule="evenodd"
    d="M11.65 4A5.99 5.99 0 0 0 6 0C2.69 0 0 2.69 0 6s2.69 6 6 6a5.99 5.99 0 0 0 5.65-4H16v4h4V8h2V4H11.65zM6 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
  />
</svg>
