import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-trash',
  templateUrl: './trash.icon.html',
})
export class TrashIconComponent extends AbstractIconComponent {
  static icon = 'trash'
}
