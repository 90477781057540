import { props, createAction } from '@ngrx/store'
import { WizardState } from './wizard.reducer'

export const SET_EDITION_STEP_LOCATION = 'SET_EDITION_STEP_LOCATION'
export const RESET_EDITION_STEP_LOCATION = 'RESET_EDITION_STEP_LOCATION'
export const NAVIGATE_NEXT_STEP = 'NAVIGATE_NEXT_STEP'

export const setEditStepLocation = createAction(
  SET_EDITION_STEP_LOCATION,
  props<{ payload: WizardState }>()
)

export const navigateNextStep = createAction(NAVIGATE_NEXT_STEP)

export const resetEditStepLocation = createAction(RESET_EDITION_STEP_LOCATION)
