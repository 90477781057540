<svg
  width="20"
  height="21"
  viewBox="0 0 20 21"
  [attr.fill]="(colorName ? (colorName | color) : color)"
  xmlns="http://www.w3.org/2000/svg"
  id="icon-clock"
>
  <path
    d="M10 0.8125C4.64844 0.8125 0.3125 5.14844 0.3125 10.5C0.3125 15.8516 4.64844 20.1875 10 20.1875C15.3516 20.1875 19.6875 15.8516 19.6875 10.5C19.6875 5.14844 15.3516 0.8125 10 0.8125ZM18.4375 10.5C18.4375 15.1484 14.6484 18.9375 10 18.9375C5.35156 18.9375 1.5625 15.1875 1.5625 10.5C1.5625 5.89062 5.3125 2.0625 10 2.0625C14.6094 2.0625 18.4375 5.85156 18.4375 10.5ZM12.6172 13.9766C12.8125 14.1328 13.125 14.0547 13.2422 13.8594L13.5938 13.4297C13.75 13.1953 13.6719 12.9219 13.4766 12.7656L10.7422 10.7734V5.03125C10.7422 4.79688 10.5078 4.5625 10.2734 4.5625H9.72656C9.45312 4.5625 9.25781 4.79688 9.25781 5.03125V11.2812C9.25781 11.4375 9.29688 11.5547 9.41406 11.6719L12.6172 13.9766Z"
  />
</svg>
