import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import events from '../../../../tagging/events'
import { TaggingService } from '../../../../tagging/tagging.service'
import { DialogData, ErrorDialogTypes } from '../../../ui.types'
import { AbstractComponent } from '../../../../../shared/abstract.component'
import { AAAStore } from '../../../../../store/root-reducer'
import { openErrorDialog } from '../../../ui.actions'
import { Observable } from 'rxjs'
import { FormGroupState } from 'ngrx-forms'
import {
  CancelFeedbackOthersFormState,
  resetOthersForm,
  setResetCancelFeedbackOthersForm
} from '../../../forms/forms.reducer'
import { selectCancelFeedbackOthersForm } from '../../../forms/forms.selectors'
import { AdobeEventTypes } from '../../../../tagging/tagging.types'
import { AdobeEventService } from '../../../../tagging/adobe/event-adobe.service'
import { ConfigService } from '../../../../config/config.service'
import { Router } from '@angular/router'
import { cancelRequest } from '../../../../../shared/actions/shared.actions'

const CANCEL_SUBMIT_LATER = () => $localize`Please request roadside assistance when you are ready.`
const CANCEL_ANOTHER_PROVIDER = () => $localize`Thank you for notifying us that you’ve requested service from another service provider.`
const CANCEL_VEHICLE_STARTED = () => $localize`Thank you for notifying us that your vehicle started and that you no longer require roadside assistance.`
const CANCEL_NOT_WITH_VEHICLE_1 = () => $localize`An active AAA Membership to receive roadside service.`
const CANCEL_NOT_WITH_VEHICLE_2 = () => $localize`A valid photo ID at the time of service.`
const CANCEL_NOT_WITH_VEHICLE_3 = () => $localize`Members be with the vehicle at the time of service.`
const CANCEL_LONG_ETA = () => $localize`We are currently servicing AAA members around your area.`
const CANCEL_FAMILY_ASSIST = () => $localize`Thank you for notifying us that a friend or family member has assisted you and that you no longer require AAA assistance.`
const CANCEL_OTHER = () => $localize`Please contact AAA if you need further assistance.`

@Component({
  selector: 'app-cancel-feedback',
  templateUrl: './cancel-feedback.component.html',
  styleUrls: [
    '../../shared-dialog.component.scss',
    './cancel-feedback.component.scss',
  ],
})
export class CancelFeedbackDialogComponent
  extends AbstractComponent
  implements OnInit {

  form$: Observable<FormGroupState<CancelFeedbackOthersFormState>> =
    this.store$.pipe(select(selectCancelFeedbackOthersForm))
  form: FormGroupState<CancelFeedbackOthersFormState>
  hasAvailableCall: boolean
  isNativeAppView = this.configService.getConfig().nativeAppView

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService,
    private configService: ConfigService,
    public dialogRef: MatDialogRef<CancelFeedbackDialogComponent>
  ) {
    super()
  }

  ngOnInit() {
    this.hasAvailableCall = this?.data?.params.hasAvailableCall
    this.subscriptions.push(
      this.form$.subscribe((form) => {
        this.form = form
      }),
      this.dialogRef.backdropClick().subscribe(() => {
        this.onClose()
      }),
    )
  }

  handleOption(option: string) {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: this.hasAvailableCall
        ? events.dashboard.POST_SUBMISSION_CANCEL_SURVEY
        : events.dashboard.PRE_SUBMISSION_CANCEL_SURVEY,
    }, null, {
      event_detail: option
    })

    this.taggingService.setClickEvent(
      this.hasAvailableCall
        ? events.dashboard.POST_SUBMISSION_CANCEL_SURVEY
        : events.dashboard.PRE_SUBMISSION_CANCEL_SURVEY,
      events.dashboard.CALL_STATUS_PAGE_TYPE,
      null,
      { 'Event Detail': option }
    )
    return this.data.close()
  }

  handleSubmitLater() {
    this.store$.dispatch(openErrorDialog({
      payload: {
        type: ErrorDialogTypes.CANCEL_FEEDBACK_OPTION,
        title: $localize`We Will Be Here When You Need Us`,
        content: `<div>${CANCEL_SUBMIT_LATER()}</div>`
      },
    }))
    this.handleOption('I Will Submit My Request Later')
  }

  handleAnotherProvider() {
    this.store$.dispatch(openErrorDialog({
      payload: {
        type: ErrorDialogTypes.CANCEL_FEEDBACK_OPTION,
        title: $localize`Thank You for Keeping Us Informed`,
        content: `<div>${CANCEL_ANOTHER_PROVIDER()}</div>`
      },
    }))
    this.handleOption('Requested Service with Another Provider')
  }

  handleMyVehicleStarted() {
    this.store$.dispatch(openErrorDialog({
      payload: {
        type: ErrorDialogTypes.CANCEL_FEEDBACK_OPTION,
        title: $localize`You’re Back on the Road`,
        content:
          `<div>${CANCEL_VEHICLE_STARTED()}</div>`
      },
    }))
    this.handleOption('My Vehicle Started')
  }

  handleNotWithMyVehicle() {
    this.store$.dispatch(openErrorDialog({
      payload: {
        type: ErrorDialogTypes.CANCEL_FEEDBACK_OPTION,
        title: $localize`AAA Roadside Service Requires`,
        content:
          `<div>
            <div class="item">${CANCEL_NOT_WITH_VEHICLE_1()}</div>
            <div class="item">${CANCEL_NOT_WITH_VEHICLE_2()}</div>
            <div class="item">${CANCEL_NOT_WITH_VEHICLE_3()}</div>
          </div>`
      },
    }))
    this.handleOption('Not with my Vehicle or No Photo ID')
  }

  handleLongEta() {
    this.store$.dispatch(openErrorDialog({
      payload: {
        type: ErrorDialogTypes.CANCEL_FEEDBACK_OPTION,
        title: $localize`We Apologize for the Long Wait`,
        content:
          `<div>
            <p>${CANCEL_LONG_ETA()}</p>
          </div>`
      },
    }))
    this.handleOption('Long ETA/Wait Time')
  }

  handleFamilyAssist() {
    this.store$.dispatch(openErrorDialog({
      payload: {
        type: ErrorDialogTypes.CANCEL_FEEDBACK_OPTION,
        title: $localize`Thank you for Keeping us Informed`,
        content:
          `<div>
            <p>${CANCEL_FAMILY_ASSIST()}</p>
          </div>`
      },
    }))
    this.handleOption('Friend or Family Member Assisted Me')
  }

  handleOther() {
    this.store$.dispatch(openErrorDialog({
      payload: {
        type: ErrorDialogTypes.CANCEL_FEEDBACK_OPTION,
        title: $localize`Thank You for Your Feedback.`,
        content: `<div>${CANCEL_OTHER()}</div>`
      },
    }))
    this.handleOption(`Other: ${this.form.value.reason}`)
    this.store$.dispatch(resetOthersForm())
    this.store$.dispatch(setResetCancelFeedbackOthersForm())
  }

  onClose() {
    return this.hasAvailableCall
      ? this.handleOption('Post-submission cancel survey dismissed')
      : this.handleOption('Pre-submission cancel survey dismissed')
  }
}



