import { Component, HostListener, Input } from '@angular/core'

const SPACE_CODE = ' '
const ENTER_CODE = 'Enter'

@Component({
  selector: 'app-skip-to-content',
  templateUrl: './skip-to-content.component.html',
  styleUrls: ['./skip-to-content.component.scss'],
})
export class SkipToContentComponent {

  @Input() elementId = ''
  @Input() tabIndex = 0
  @Input() title = 'Skip to main content'

  onAnchorClick(event) {
    event.preventDefault()
    event.stopPropagation()
    this.scrollTo()
  }

  @HostListener('keydown', ['$event'])
  keyboardListener(event) {
    const keyCode = event.key || null;

    event.preventDefault()
    event.stopPropagation()

    if (keyCode === SPACE_CODE || keyCode === ENTER_CODE) {
      this.scrollTo()
    }
  }

  scrollTo() {
    const _elementId = this.elementId.replace('#', '');
    const offset = document.getElementById(_elementId).offsetTop;
    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    });
    // remove focus from element
    const link = document.getElementById('skipcontent');
    link.blur()

    // select first element that can be focused
    const elementsQuery: any = document.querySelectorAll('.first-focusable-element')
    const keyboardfocusableElements = [...elementsQuery]
      .filter(el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'))

    if (keyboardfocusableElements && keyboardfocusableElements.length) {
      keyboardfocusableElements[0].focus()
    }
  }
}
