import { Component, Output, EventEmitter, Input } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AAAStore } from '../../../store/root-reducer'
import events from '../../tagging/events'
import { TaggingService } from '../../tagging/tagging.service'
import { setProblemTooltip } from '../../ui/ui.actions'
import { selectProblemTooltip } from '../../ui/ui.selectors'
import { PaceSetterSituation } from '../issue.types'
import { AdobeEventTypes } from '../../tagging/tagging.types'

@Component({
  selector: 'app-problem-button',
  templateUrl: './problem-button.component.html',
  styleUrls: ['./problem-button.component.scss'],
})
export class ProblemButtonComponent {
  @Input() problemType: PaceSetterSituation = null
  @Input() tabIndex: Number = 0
  @Input() first: Boolean = false
  @Input() overrideProblem: PaceSetterSituation = null

  @Output() clickProblem: EventEmitter<PaceSetterSituation> =
    new EventEmitter<PaceSetterSituation>()

  problemTooltip$: Observable<PaceSetterSituation | null> = this.store$.pipe(
    select(selectProblemTooltip)
  )

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
  ) {}

  problemClick(event) {
    if (this.overrideProblem && !this.overrideProblem.enabled) {
      this.showProblemTooltip(event)
    } else {
      this.clickProblem.emit(this.problemType)
      this.taggingService.setClickEvent(
        this.problemType.name,
        events.issue.ISSUE_PAGE_TYPE,
      )
    }
  }

  public showProblemTooltip(event): void {
    event.stopPropagation()
    const problem = this.overrideProblem && !this.overrideProblem.enabled ? this.overrideProblem : this.problemType
    this.store$.dispatch(setProblemTooltip({ payload: problem }))
    const action = problem.name + events.issue.ISSUE_TOOLTIP_PROMPT
    this.taggingService.setClickEvent(action, events.issue.ISSUE_PAGE_TYPE)
  }
}
