<svg [ngClass]="{'aar-location-pin-selected' : selected, 'aar-location-pin': !selected}" width="32px" height="44px" viewBox="0 0 32 44" version="1.1" xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="icon/tow-locations" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(1.000000, 1.000000)">
      <path
        d="M15,42 C15,42 2.82149712,25.9625207 0.798712154,20.0152334 C0.794997214,19.9871536 0.781994923,19.9553299 0.770850102,19.9272502 C0.271190638,18.4128166 0,16.802912 0,15.12 C0,13.8807478 0.14674014,12.6789352 0.429075599,11.5314102 C2.02278497,4.90833725 7.94439973,4.54747351e-13 14.9972138,4.54747351e-13 L15,42 Z M15,42 L15.0027862,4.54747351e-13 C22.0556003,4.54747351e-13 27.977215,4.90833725 29.5709244,11.5314102 C29.8532599,12.6789352 30,13.8807478 30,15.12 C30,16.802912 29.7288094,18.4128166 29.2291499,19.9272502 C29.2180051,19.9553299 29.2050028,19.9871536 29.2012878,20.0152334 C27.1785029,25.9625207 15,42 15,42 Z"
        id="Combined-Shape" stroke="currentColor"
        fill="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
      <path
        d="M25,15 C25,17.1765507 24.3040679,19.1933886 23.1227169,20.8338492 C21.3061111,23.3583013 18.346542,25 14.9981425,25 C10.6083215,25 6.88099808,22.1709793 5.53247477,18.2375882 C5.52999814,18.219017 5.52132995,18.1979695 5.51390007,18.1793983 C5.18079376,17.1777888 5,16.113037 5,15 C5,14.1803888 5.09782676,13.3855392 5.2860504,12.626594 C6.34852331,8.2462548 10.2962665,5 14.9981425,5 C16.8163,5 18.5208893,5.4847536 19.9897837,6.33202442 C22.9848382,8.05959745 25,11.2943222 25,15 Z"
        id="Stroke-1" fill="#FFFFFF"></path>
    </g>
    <text id="1" [attr.transform]="'translate(' + (index.toString().length - 1) * -3 + '.0, 0.0)'"
      font-family="Lato-Regular, Lato" font-size="12" font-weight="normal"
      fill="currentColor">
      <tspan x="12.52" y="19">{{ index }}</tspan>
    </text>
  </g>
</svg>