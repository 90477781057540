import { Component } from '@angular/core'

@Component({
  selector: 'app-mobile-header-agent',
  templateUrl: './mobile-header-agent.component.html',
  styleUrls: ['./mobile-header-agent.component.scss'],
})
export class MobileHeaderAgentComponent {
  constructor() {}
}
