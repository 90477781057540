import { NgModule } from '@angular/core'

import { AuthRoutingModule } from './auth-routing.module'

import { SharedModule } from '../../shared'
import { NgrxFormsModule } from 'ngrx-forms'
import { authComponents } from './auth.component.declarations'
import { IonicModule } from '@ionic/angular';
import { ButtonComponent, TextComponent } from '@aaa-mobile/shared-components';
import { HeaderRedesignComponent } from '../../shared/layouts/header-redesign/header/header.component'
import { AaaLogoIconComponent } from '../../shared/icons/aaa-logo.icon'
import { LogoComponent } from '../../shared/layouts/header-redesign/logo/logo.component';
import { MenuComponent } from '../../shared/layouts/header-redesign/menu/menu.component';
import { MenuButtonComponent } from '../../shared/layouts/header-redesign/menu-button/menu-button.component';
import { ClosableHeaderComponent } from '../../shared/layouts/closable-header/closable-header.component'

@NgModule({
  declarations: [...authComponents()],
  imports: [
    NgrxFormsModule,
    AuthRoutingModule,
    SharedModule,
    IonicModule,
    ButtonComponent,
    TextComponent,
    HeaderRedesignComponent,
    AaaLogoIconComponent,
    LogoComponent,
    MenuComponent,
    MenuButtonComponent,
    ClosableHeaderComponent,
  ],
})
export class AuthModule {}
