import { Component, output } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { IconComponent, TextComponent } from '@aaa-mobile/shared-components'
import { AdobeEventService } from '../../../../modules/tagging/adobe/event-adobe.service';
import { AdobeEventTypes } from '../../../../modules/tagging/tagging.types';
import events from '../../../../modules/tagging/events';

@Component({
  selector: 'app-back-button',
  standalone: true,
  imports: [
    IonicModule,
    IconComponent,
    TextComponent,
  ],
  templateUrl: './back-button.component.html',
  styleUrl: './back-button.component.scss'
})
export class BackButtonComponent {
  aaaClick = output<Event>();

  constructor(
    private adobeEventService: AdobeEventService,
  ) { }

  handleBack($event) {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.shared.BACK_BUTTON
    })
    this.aaaClick.emit($event)
  }
}
