<div class="title">
  <ng-content select="[title]"></ng-content>

  <ng-container *ngIf="!keepOpen">
    <button
      type="button"
      class="toggleButton"
      (click)="toggleActive()"
      [tabIndex]="tabIndex"
    ></button>
  </ng-container>
</div>

<div class="body" [ngClass]="{ active: (active || keepOpen)  }">
  <ng-content select="[body]"></ng-content>
</div>
