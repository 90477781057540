import { Routes } from '@angular/router'
import { DrrWebMainComponent } from './drrweb-main.component'
import { MainChildrenRoutes } from '../modules/main-router.module'

export const DRR_WEB_MAIN_ROUTES: Routes = [
  {
    path: '',
    component: DrrWebMainComponent,
    children: MainChildrenRoutes,
  },
]
