<svg
  xmlns="http://www.w3.org/2000/svg"
  width="26"
  height="40"
  viewBox="0 0 26 40"
  id="icon-pin"
>
  <g fill="none" fill-rule="evenodd">
    <ellipse cx="13.5" cy="39.5" fill="gray" opacity=".541" rx="5.5" ry="1" />
    <path
      [attr.fill]="pinColor"
      d="M2.426 20.008A12.362 12.362 0 0 1 0 12.648C0 5.663 5.82 0 13 0s13 5.663 13 12.648c0 2.43-.704 4.7-1.925 6.627L13.581 39l-8.2-13.67-2.955-5.322z"
    />
    <circle cx="12.5" cy="12.5" r="8.5" [attr.fill]="circleColor" />
    <path
      [attr.fill]="pinColor"
      d="M16.729 7.631A.915.915 0 0 0 15.86 7H9.14a.92.92 0 0 0-.868.631L7 11.375v5a.62.62 0 0 0 .611.625h.611a.62.62 0 0 0 .611-.625v-.625h7.334v.625a.62.62 0 0 0 .61.625h.612a.62.62 0 0 0 .611-.625v-5l-1.271-3.744zm-7.59 6.244a.926.926 0 0 1-.917-.938c0-.518.41-.937.917-.937.507 0 .917.419.917.938 0 .518-.41.937-.917.937zm6.722 0a.926.926 0 0 1-.917-.938c0-.518.41-.937.917-.937.507 0 .917.419.917.938 0 .518-.41.937-.917.937zM8.222 10.75l.917-2.813h6.722l.917 2.813H8.222z"
    />
  </g>
</svg>
