import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'
import { TaggingService } from '../../../tagging/tagging.service'

@Component({
  selector: 'app-confirm-message-dialog-component',
  templateUrl: './confirm-message-dialog.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class ConfirmMessageDialogComponent {
  public cid = ''
  public pageType = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private tagging: TaggingService
  ) {}

  onSubmit() {
    // FIXME: adapt this common dialog from dialog.invoker.ts instead.
    // Skipped from https://app.zenhub.com/workspaces/aaa-drr-v2-5de5538749a5b40cd52dbbde/issues/clevertech/aaa-drr-v2/2392
    // as it is probably unused
    if (this.data.title.toLowerCase().trim().startsWith('cancelling call')) {
      this.tagging.setClickEvent(
        'RSO Cancel Request Confirmed',
        'RSO Cancel Request'
      )
    }

    this.data.submit()
    this.onClose()
  }

  onClose() {
    this.data.close()
  }
}
