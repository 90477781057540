<div class="dialog">
  <h1 mat-dialog-title i18n>Alert</h1>

  <div class="dialog-content">
    <p class="text-message" i18n>The destination address is incomplete. Please select a destination containing at least a street name.</p>
    <app-link-button i18n
      (clickLink)="onClose()"
      [ariaLabel]="'dismiss this message'">
      Dismiss
    </app-link-button>
  </div>

  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message" i18n-aria-label>
    <icon-close></icon-close>
  </button>
</div>
