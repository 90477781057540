<div class="agent-panel">
  <h4>Agent Settings</h4>
  <label for="agent-name">Agent Name:</label>
  <input
    name="agent-name"
    id="agent-name"
    type="text"
    [(ngModel)]="agentSettings.agentName"
  />
  <ng-select
    id="vehicle-type"
    [items]="vehicleTypeOptions"
    bindLabel="label"
    placeholder="Select vehicle type"
    [searchable]="false"
    [dropdownPosition]="'bottom'"
    (change)="handleSelect($event, 'vehicleType')"
  ></ng-select>
  <ng-select
    id="priority-code-type"
    [items]="priorityCodeOptions"
    bindLabel="label"
    placeholder="Select priority code"
    [searchable]="false"
    [dropdownPosition]="'bottom'"
    (change)="handleSelect($event, 'priorityCode')"
  ></ng-select>
  <ng-select
    id="cash-call-type"
    [items]="cashCallOptions"
    bindLabel="label"
    placeholder="Select cash call"
    [searchable]="false"
    [dropdownPosition]="'bottom'"
    (change)="handleSelect($event, 'cashCall')"
  ></ng-select>
</div>
