import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, } from '@angular/core'
import { ModalComponent } from '@aaa-mobile/shared-components'
import { getBrowserImageDRR, getBrowserImageRAP } from '../../location/location.utils'

@Component({
  selector: 'app-lets-start-sheet',
  templateUrl: './lets-start-sheet.component.html',
  styleUrls: ['./lets-start-sheet.component.scss'],
})
export class LetsStartSheetComponent implements OnChanges, OnInit {
  @ViewChild(ModalComponent, { static: true }) modalElem!: ModalComponent

  @Input() isRapUser = false
  @Input() isLoading = false
  @Input() hasDeniedGpsAccess = false
  @Input() isOpen = true;

  @Output() enterYourAddressClick = new EventEmitter()
  @Output() findMyLocationClick = new EventEmitter()
  @Output() onDismiss = new EventEmitter()

  public browserImage: ReturnType<
    typeof getBrowserImageDRR | typeof getBrowserImageRAP
  >
  public currentBreakpoint: number

  ngOnInit() {
    this.browserImage = this.getBrowserImage()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isRapUser) {
      this.browserImage = this.getBrowserImage()
    }
  }

  handleFindMyLocation() {
    this.findMyLocationClick.emit()
    this.isOpen = false;
  }

  handleEnterYourAddress() {
    this.enterYourAddressClick.emit()
    this.isOpen = false;
  }

  setCurrentBreakpoint(event) {
    this.currentBreakpoint = event.detail.breakpoint
  }

  getBrowserImage(): ReturnType<
    typeof getBrowserImageDRR | typeof getBrowserImageRAP
  > {
    return !this.isRapUser ? getBrowserImageDRR() : getBrowserImageRAP()
  }

  handleWillDismiss(
    event: CustomEvent<HTMLIonModalElementEventMap['ionModalWillDismiss']>
  ) {
    if (event.detail.role === 'backdrop') {
      this.handleFindMyLocation()
    }
    this.onDismiss.emit()
  }
}
