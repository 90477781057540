import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'
import { Store } from '@ngrx/store'
import { AAAStore } from '../../../../store/root-reducer'
import { confirmTowDistance } from '../../../location/tow-location/tow-location.actions'
import events from '../../../tagging/events'

@Component({
  selector: 'tow-boundary-message-dialog-component',
  templateUrl: './tow-boundary-message-dialog.component.html',
  styleUrls: ['./tow-boundary-message-dialog.component.scss'],
})
export class TowBoundaryMessageDialogComponent {
  pageType = events.towTo.DESTINATION_PAGE_TYPE

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>
  ) {}

  onSubmit() {
    this.onClose()
  }

  onClose() {
    this.store$.dispatch(confirmTowDistance())
    this.data.close()
  }
}
