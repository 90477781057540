import { reducers } from '../root-reducer'
import { ActionReducer } from '@ngrx/store'
import { localStorageSync } from 'ngrx-store-localstorage'

const customStorage: Storage = {
  length: 0,
  clear(): void {
    if (window && window.localStorage) {
      window.localStorage.clear()
      this.length = window.localStorage.length
    }
  },
  getItem(key: string): string | null {
    try {
      return window.localStorage.getItem(key)
    } catch {
      return null
    }
  },
  key(index: number): string | null {
    try {
      return window.localStorage.key(index)
    } catch {
      return null
    }
  },
  removeItem(key: string): void {
    try {
      window.localStorage.removeItem(key)
      this.length = window.localStorage.length
    } catch {
      return
    }
  },
  setItem(key: string, data: string): void {
    try {
      window.localStorage.setItem(key, data)
      this.length = window.localStorage.length
    } catch {
      return
    }
  },
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    // Only for this
    keys: ['noTrackingCall'],
    rehydrate: true,
    removeOnUndefined: false,
    storage: customStorage,
  })(reducer)
}
