<header
  role="banner"
  [ngClass]="{ 'sticky': isNativeAppView }"
>
  <div
    #container
    id="header-wrapper"
    class="app-header"
    [ngClass]="{
      'sticky': isNativeAppView,
      'sticky-on-scroll': isNativeAppView && isScrolled()
      }"
  >
    <ng-container *ngIf="localeSwitcherEnabled">
      <div
        id="i18n-switcher"
        (click)="openLanguageSwitcher()"
        class="language-switcher-container"
      >
        <div class="language-switcher-content">
          <span class="locale-label">{{locale.abbreviation}}</span>
          <icon-globe></icon-globe>
        </div>
      </div>
    </ng-container>
    <div
      class="full-logo center-aligned-logo"
    >
      <app-img
        *ngIf="showLogo"
        src="assets/drrweb-lib/images/{{appIdLogo.fileName.default}}"
        classNames="{{appIdLogo.className}}"
        alt="{{appIdLogo.alt}}"
        id="{{appIdLogo.className}}"
        width="{{appIdLogo.width['small']}}"
        height="{{appIdLogo.height}}"
      ></app-img>
      <span i18n class="roadside-request show-desktop">Roadside Request</span>
    </div>
    <app-closable-header *ngIf="(isCloseButtonVisible$ | async) || isNativeAppView"></app-closable-header>
  </div>
</header>
