export const markFormGroupAsTouched = (form) => {
  Object.values(form.controls).forEach((control: any) => {
    control.markAsTouched()
    control.markAsDirty()

    if (control.controls) {
      markFormGroupAsTouched(control)
    }
  })

  return form
}
