import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ConfigState } from './config.reducer'
import { SharedConfig } from '../../shared/shared.config'

const stateKey = 'config'

export const selectConfigState = createFeatureSelector<ConfigState>(stateKey)

export const selectSharedConfig = createSelector(
  selectConfigState,
  (state: ConfigState): SharedConfig => state.sharedConfig
)

export const selectAutoRequestBatteryQuote = createSelector(
  selectSharedConfig,
  (config: SharedConfig): boolean => config?.autoRequestBatteryQuote ?? false
)
