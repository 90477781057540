import { Component, OnInit } from '@angular/core'

import { Store, select } from '@ngrx/store'
import { setAgentSettings } from '../../auth/auth.actions'
import { AgentSettingsParams } from '../../auth/auth.types'
import { AAAStore } from '../../../store/root-reducer'

import { Observable } from 'rxjs'
import { selectAgentSettings } from '../../auth/auth.selectors'

// ARR:POC Component for selecting agent call settings
@Component({
  selector: 'app-agent-panel',
  templateUrl: './agent-panel.component.html',
  styleUrls: ['./agent-panel.component.scss'],
})
export class AgentPanelComponent implements OnInit {
  agentSettings$: Observable<AgentSettingsParams> = this.store$.pipe(
    select(selectAgentSettings)
  )

  agentSettings: AgentSettingsParams = {
    vehicleType: '',
    cashCall: false,
    priorityCode: '',
  }

  vehicleTypeOptions = [
    { label: 'Passenger Car Truck', value: 'PS' },
    { label: 'Two Wheeled Motorcycle', value: '2M' },
    { label: 'Three Wheeled Motorcycle', value: '3M' },
    { label: 'Dual Wheels', value: 'DW' },
    { label: 'Trailer', value: 'TR' },
    { label: 'RV Less Than 23 Feet', value: 'R1' },
    { label: 'RV 24 To 32 Feet', value: 'R2' },
    { label: 'RV Greater Than 33 Feet', value: 'R3' },
  ]

  cashCallOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  priorityCodeOptions = [
    { label: 'Baby/animal locked in car', value: 'P0' },
    { label: 'Safety Situation', value: 'P1' },
    { label: 'Medical Concern	', value: 'P2' },
    { label: 'Police Call', value: 'P3' },
    { label: 'Accident', value: 'P4' },
    { label: 'Claims accident/assist', value: 'P5' },
    { label: 'Highway/Interstate', value: 'P6' },
    { label: 'Service Failure/Previous Request', value: 'P7' },
    { label: 'Management priority	', value: 'P8' },
    { label: 'RAP Priority', value: 'P9' },
    { label: 'Child or pet w/vehicle on hwy or interstate	', value: 'CH' },
    { label: 'Medical Transportation', value: 'PT' },
    { label: 'VIP', value: 'R1' },
  ]

  handleSelect(e, key) {
    this.agentSettings[key] = e ? e.value : ''
    this.store$.dispatch(setAgentSettings({ payload: this.agentSettings }))
  }

  constructor(private store$: Store<AAAStore>) {}

  ngOnInit() {
    this.agentSettings$.subscribe((settings) => {
      this.agentSettings = { ...this.agentSettings, ...settings }
    })
  }
}
