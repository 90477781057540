import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import { filter, map, switchMap, tap, withLatestFrom, } from 'rxjs/operators';
import {
  addTowingStep,
  CANCEL_STEPS_AHEAD,
  DIALOG_CLOSE,
  DIALOG_OPEN,
  DONE_STEP,
  openPromptDialog,
  pendingStep,
  SET_USER_DEFAULT_COORDS
} from './ui.actions';
import { Dialog, PromptDialogTypes, Step } from './ui.types';
import { Title } from '@angular/platform-browser'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { combineLatest, of } from 'rxjs'
import { selectNeedsTow } from '../issue/issue.selectors'
import { Store } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { TaggingService } from '../tagging/tagging.service';
import events from '../tagging/events';
import { selectEditingStep, selectIterableSteps, selectWatchRecaptchaPosition } from './ui.selectors';
import { PayloadedAction } from '../../shared/types';
import { LOCATION_TYPE, setBreakdownLocationRequest } from '../location/location.actions';
import { selectIsBreakdownLocationValid } from '../location/location.selectors';

@Injectable()
export class UIEffects {

  recaptchaWatcher = null;
  PAGE_LOADER_TIMER = 500

  checkNeedTowing$ = createEffect(() =>
    combineLatest([this.store$.select(selectNeedsTow)]).pipe(
      map(([needsTow]: [boolean]) => addTowingStep({ payload: needsTow }))
    )
  )

  openBatteryQuotesPromptDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof openPromptDialog>>(DIALOG_OPEN.PROMPT),
        switchMap((action) =>
          of(action).pipe(
            filter((_) => action.payload.type === PromptDialogTypes.BATTERY_QUOTES),
            tap((_) => this.tagging.setClickEvent(
              events.dashboard.BATTERY_QUOTES_SHOW_CLICK,
              events.dashboard.SERVICE_TRACKING_PAGE_TYPE
            )),
          )
        )
      ),
    { dispatch: false }
  )

  afterEachStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DONE_STEP),
        switchMap((action: PayloadedAction<Step>) =>
          of(action).pipe(
            filter((_action) => !!_action.payload.afterEach),
            map((__action) => {
              __action.payload.afterEach.forEach((___action) => this.store$.dispatch(___action))
            })
          )
        )
      ),
    { dispatch: false }
  )

  closeDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DIALOG_CLOSE),
        filter((action: PayloadedAction<Dialog>) => action.payload.close !== undefined),
        map((action: PayloadedAction<Dialog>) => action.payload.close())
      ),
    { dispatch: false }
  )

  cancelStepsAhead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CANCEL_STEPS_AHEAD),
      withLatestFrom(
        this.store$.select(selectIterableSteps),
        this.store$.select(selectEditingStep),
      ),
      switchMap(([_, steps, editingStep]: [PayloadedAction, Step[], Step]) =>
        steps.filter((step) => step?.id > editingStep?.id).map((cancelStep) =>
          pendingStep({
            payload: cancelStep
          })
        )
      )
    )
  )

  setUserDefaultCoords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_USER_DEFAULT_COORDS),
      withLatestFrom(
        this.store$.select(selectIsBreakdownLocationValid),
      ),
      filter(([_, isBreakdownLocationValid]: [PayloadedAction, boolean]) => !isBreakdownLocationValid),
      map(([action, _]: [PayloadedAction, boolean]) => setBreakdownLocationRequest({
        payload: action.payload,
        meta: {
          locationType: LOCATION_TYPE.QUERY_PARAMS,
        }
      })
      )
    )
  )

  watchRecaptchaPosition$ = createEffect( () =>
    combineLatest([
      this.store$.select(selectWatchRecaptchaPosition)
    ]).pipe(
      filter(([watchPosition]: [boolean])=> {
        if(this.recaptchaWatcher && !watchPosition) {
          clearInterval(this.recaptchaWatcher)
          this.recaptchaWatcher = null
        }
        return watchPosition
      }),
      map(([watchPosition]) => this._initRecaptchaPositionWatcher(watchPosition))
    ),
  { dispatch: false }
  )

  _initRecaptchaPositionWatcher(watchPosition) {
    if(!this.recaptchaWatcher && watchPosition) {
      this.recaptchaWatcher = setInterval(() => {
        this._watchRecaptchaPosition()
      }, 300)
    }
  }

  _watchRecaptchaPosition() {
    const iframes: any = document.getElementsByTagName('iframe')
    let parent = null
    for (let i = 0; i < iframes.length; i++) {
      const title = iframes[i].getAttribute('title')
      // title can be null or contain a big phrase
      if (title && title.indexOf('recaptcha challenge') !== -1) {
        parent = iframes[i].parentNode
        const recaptchaWindow = parent.parentNode
        // repositioning only happens if the challenge is visible
        if (recaptchaWindow.style.opacity === '1') {
          recaptchaWindow.style.position = 'fixed'
          recaptchaWindow.style.top = '36px'
          if (window.innerHeight < 640) {
            recaptchaWindow.style.bottom = '50px'
          } else {
            recaptchaWindow.style.bottom = ''
          }
          const captchaWidth = recaptchaWindow.style.width || 400
          const catpchaHeight = recaptchaWindow.style.height || 580
          recaptchaWindow.style.left = `${
            window.innerWidth / 2 - captchaWidth / 2
          }px`

          if (window.innerWidth < captchaWidth) {
            parent.style.width = `${window.innerWidth - 10}px`
          }
          if (window.innerHeight < catpchaHeight) {
            parent.style.height = `${window.innerHeight - 10}px`
            parent.style.overflowY = 'scroll'
          }

          recaptchaWindow.style.right = ''
        }
        recaptchaWindow.style.overflow = 'visible'
      }
    }
  }

  constructor(
    private store$: Store<AAAStore>,
    private actions$: Actions,
    protected titleService: Title,
    private errorReportingService: ErrorReportingService,
    private tagging: TaggingService,
  ) {}
}
