import { Component } from '@angular/core'

@Component({
  selector: 'app-desktop-header-agent',
  templateUrl: './desktop-header-agent.component.html',
  styleUrls: ['./desktop-header-agent.component.scss'],
})
export class DesktopHeaderAgentComponent {
  constructor() {}
}
