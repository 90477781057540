<section class="minimal-credentials no-shadow">
  <ion-row>
    <ion-col class="ion-margin-bottom">
      <aaa-input
        i18n-label
        variant="secondary"
        name="firstName"
        id="firstName"
        type="text"
        [ngrxFormControlState]="controls.firstName"
        label="First Name"
        class="first-focusable-element"
        aria-required="true"
        autocomplete="given-name"
        required
      >
        <app-form-helper
          helper
          i18n
          [control]="controls?.firstName"
          name='firstName'
          type='required'
        > First Name is required.
        </app-form-helper>
      </aaa-input>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="ion-margin-vertical">
      <aaa-input
        i18n-label
        #inputHelper
        variant="secondary"
        name="lastName"
        id="lastName"
        type="text"
        [ngrxFormControlState]="controls.lastName"
        label="Last Name"
        aria-required="true"
        autocomplete="family-name"
        required
      >
        <app-form-helper
          helper
          i18n
          [control]="controls?.lastName"
          name='lastName'
          type='required'
        > Last Name is required.
        </app-form-helper>
      </aaa-input>
    </ion-col>
  </ion-row>
  <ion-row class="ion-margin-vertical">
    <ion-col class="ion-margin-bottom">
      <app-input-postal-code
        id="zipCode"
        [controls]="controls"
        [focusInputHelper]="inputHelper"
      ></app-input-postal-code>
    </ion-col>
  </ion-row>
</section>
