<svg
  width="50"
  height="40"
  viewBox="0 0 50 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0 20C0 11.822 3.92672 4.56114 9.99756 0H40.0024C46.0733 4.56114 50 11.822 50 20C50 28.178 46.0733 35.4389 40.0024 40H9.99756C3.92672 35.4389 0 28.178 0 20ZM14 4H19V23H14V16H7V11H14V4ZM34 7H39V20H34V7ZM25.5 24C23.0147 24 21 26.0147 21 28.5V36H30V28.5C30 26.0147 27.9853 24 25.5 24Z"
    fill="#525252"
  />
</svg>
