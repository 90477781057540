<div #touchLayer class="touch-overlay" [ngClass]="{'blocked': blockOverlay}">

  <ng-container *ngIf="stopped | async">
    <div class="tip-overlay">
      Please use a single finger to control the map
    </div>
  </ng-container>

  <ng-content></ng-content>
</div>
