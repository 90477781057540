import { Component, Inject, OnInit, Optional } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../../../store/root-reducer'
import { combineLatest, Observable } from 'rxjs'
import { AARData, AARDetail, FacilitiesDisplay } from '../../../../location/aar/aar.types'
import { filter, map } from 'rxjs/operators'
import { selectEvStationDetails, selectFacility, selectIsEVstation, } from '../../../../location/aar/aar.selectors'
import { DialogData } from '../../../ui.types'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { setAARAddress } from '../../../../location/tow-location/tow-location.actions'
import { areStringsEquivalent } from '../../../../../shared/utils/areStringsEquivalent'
import { aarAppendLocationMarker, convertToLocationMarker, LocationUtils } from '../../../../location/location.utils'
import { selectBreakdownLocationCoordinates, selectBreakdownMarker } from '../../../../location/location.selectors'
import { GenericCoordinates, GoogleLocationMarker } from '../../../../location/location.types'
import { selectIsRapUser, selectMaxTowMileLimit, selectModeConfiguration, } from '../../../../auth/auth.selectors'
import { VendorConfigurationMode } from '../../../../auth/mode-configuration.types'
import { requestAarDetail, setAarPreview } from '../../../../location/aar/aar.actions'
import { selectRoutesDistance } from '../../../../route-distance/route-distance.selector'
import { RouteDistance } from '../../../../route-distance/route-distance.reducer'
import {
  RAP_EV_TOW_LIMITATION_FLEX,
  RAP_EV_TOW_LIMITATION_STRICT,
  RAP_TOW_LIMITATION_FLEX,
  RAP_TOW_LIMITATION_STRICT
} from '../../../../../shared/utils/messages'
import { AbstractResponsiveComponent } from '../../../../../shared/abstract-responsive.component'
import events from '../../../../tagging/events';
import { TaggingService } from '../../../../tagging/tagging.service';
import {
  CLOSED_LABEL,
  DEFAULT_DISCOUNT_DESCRIPTION,
  DEFAULT_DISCOUNT_RESTRICTION
} from '../../../../constants/shared.constants';
import { AdobeEventTypes } from '../../../../tagging/tagging.types'
import { AdobeEventService } from '../../../../tagging/adobe/event-adobe.service'
import { selectDefaultPhoneNumber } from '../../../../servicing-club/servicing-club.selectors';

@Component({
  selector: 'app-shop-details',
  templateUrl: './shop-details.component.html',
  styleUrls: ['./shop-details.component.scss'],
})
export class ShopDetailsComponent extends AbstractResponsiveComponent implements OnInit {
  towDestinationMarker: GoogleLocationMarker
  facilitiesDisplay: FacilitiesDisplay
  breakdownMarker$: Observable<GoogleLocationMarker> = this.store$.pipe(
    select(selectBreakdownMarker),
    filter((marker) => Boolean(marker))
  )
  details$: Observable<AARDetail> = combineLatest([
    this.store$.pipe(select(selectFacility)),
    this.store$.pipe(select(selectRoutesDistance)),
  ]).pipe(
    map(([selector, routesDistance]: [any, RouteDistance[]]) => {
      const result = selector(this.id)
      if (result) {
        this.distanceTo = this.locationUtils.getDistance(
          routesDistance,
          this.breakdownCoordinates,
          result
        )
        result.compressedHours = this.calculateHours(result)
        if (!result.discount?.offerDescription) {
          result.discount = {
            ...result.discount,
            offerDescription: DEFAULT_DISCOUNT_DESCRIPTION(),
          }
        }
        if (!result.discount?.offerRestriction) {
          result.discount = {
            ...result.discount,
            offerRestriction: DEFAULT_DISCOUNT_RESTRICTION(),
          }
        }
        this.towDestinationMarker = { lat: result.latitude, lng: result.longitude, aarId: result.id }
        this.facilitiesDisplay = {
          aarData: [result],
          fitAll: true,
          expandedResult: false,
          markers: [convertToLocationMarker(result)].filter(Boolean),
        }
      }
      return result
    })
  )

  configData$: Observable<VendorConfigurationMode> = this.store$.pipe(
    select(selectModeConfiguration)
  )

  breakdownCoordinates$: Observable<GenericCoordinates> = this.store$.pipe(
    select(selectBreakdownLocationCoordinates)
  )

  isRapUser$: Observable<boolean> = this.store$.pipe(select(selectIsRapUser))

  callCenterTowMileage
  distanceTo
  breakdownCoordinates
  maxTowMileLimit
  id: number | string
  flexTowLimitMessage: string
  strictTowLimitMessage: string

  isEvStation$: Observable<boolean> = this.store$.pipe(select(selectIsEVstation))
  evStationDetails$ = this.store$.pipe(select(selectEvStationDetails))
  contactPhone$ = this.store$.pipe(select(selectDefaultPhoneNumber))

  showReviews = false

  constructor(
    protected store$: Store<AAAStore>,
    protected locationUtils: LocationUtils,
    protected taggingService: TaggingService,
    protected adobeEventService: AdobeEventService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    super()
    this.id = data?.params.id
  }

  ngOnInit() {
    this.taggingService.setPageLoadEvent({ pageType: events.towTo.PAGE_TYPE, pageName: events.towTo.PAGE_NAME_TOW_TO_DETAIL })
    this.store$.dispatch(requestAarDetail({ payload: this.id }))
    this.breakdownCoordinates$.subscribe((breakdownCoordinates) => {
      this.breakdownCoordinates = breakdownCoordinates
    })
    this.isEvStation$.subscribe((isEvStation) => {
      this.flexTowLimitMessage = isEvStation ? RAP_EV_TOW_LIMITATION_FLEX() : RAP_TOW_LIMITATION_FLEX()
      this.strictTowLimitMessage = isEvStation ? RAP_EV_TOW_LIMITATION_STRICT() : RAP_TOW_LIMITATION_STRICT()
    })
    this.store$
      .pipe(select(selectMaxTowMileLimit))
      .subscribe((maxTowMileLimit) => (this.maxTowMileLimit = maxTowMileLimit))

    this.configData$.subscribe((configData) => {
      this.callCenterTowMileage = configData?.callCenterTowMileage
    })
  }

  setLocation(aar: AARData) {
    this.data?.close()
    this.store$.dispatch(setAarPreview({ payload: { id: aar.id } }))
    const shop = aarAppendLocationMarker(aar)
    if (shop) {
      this.store$.dispatch(
        setAARAddress({
          payload: shop,
        })
      )
    }
  }

  calculateHours(details: AARData) {
    const days = [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
      'SUNDAY',
    ]
    let lastOpen = ''
    let lastClose = ''
    return days.reduce((output, day) => {
      const currentDay = details.operatingDays.find((operatingDay) =>
        areStringsEquivalent(operatingDay.day, day)
      )

      if (!currentDay) {
        lastOpen = ''
        lastClose = ''
        output.push({
          days: day,
          hours: CLOSED_LABEL(),
        })
        // note: below lines were commented to match with wireframes not grouping week days, to be confirmed
        // } else if (areStringsEquivalent(lastOpen, currentDay.open) && areStringsEquivalent(lastClose, currentDay.close)) {
        //   output[output.length - 1].days = `${firstDay} - ${day}`
      } else {
        lastOpen = currentDay.open
        lastClose = currentDay.close
        output.push({
          days: day,
          hours: `${lastOpen} - ${lastClose}`,
        })
      }

      return output
    }, [])
  }

  showReviewsList(show = true) {
    this.showReviews = show
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.towTo.DESTINATION_OPEN_FACILITY_REVIEWS
    })
  }

}
