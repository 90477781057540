<div class="dialog">
  <div class="mat-dialog-close" style="display: flex; width: 100%;">
    <ng-container *ngIf="showReviews">
      <aria-link-button i18n-ariaLabel ariaLabel="go back" (clickLink)="showReviewsList(false)" class="back-button">
        <div class="icon">
          <icon-arrow
            colorName="'blue'"
            direction="left">
          </icon-arrow>
          <span i18n class="banner-text">Back</span>
        </div>
      </aria-link-button>
    </ng-container>
    <button i18n-aria-label mat-dialog-close class="mat-dialog-close close-button" aria-label="dismiss this message">
      <icon-close></icon-close>
    </button>
  </div>
  <div class="details-container tall" mat-dialog-content>
    <ng-container *ngIf="showReviews; else detailsBlock">
      <app-review-list locationName="{{(details$ | async).name}}" [ratingSummary]="(details$ | async).ratingSummary"></app-review-list>
    </ng-container>
    <ng-template #detailsBlock>
    <ng-container *ngIf="(details$ | async) as details; else loading">
      <div class="name-title">{{ details.name }}</div>
      <ng-container *ngIf="details['ratingSummary']?.reviewCount > 0">
        <div class="rating-summary">
          <span class="normal-text">{{ details.ratingSummary.ratingAvg | number:'1.0-1' }}</span>
          <div class="rating">
            <app-rating [rate]="details.ratingSummary.ratingAvg"></app-rating>
          </div>
          <div class="open-reviews">
            <aria-link-button
              i18n-ariaLabel
              id="show-reviews-link"
              ariaLabel="See reviews"
              role="link"
              class="open-reviews__link"
              (clickLink)="showReviewsList()">
              <app-reviews-count [numberReviews]="details.ratingSummary.reviewCount" ></app-reviews-count>
            </aria-link-button>
          </div>
        </div>
      </ng-container>
      <div class="location-group">
        <div class="normal-text color-darkgrey" *ngIf="details.address as address">
          <div>{{ address.addressLine }} · {{ address.cityName }},&nbsp;{{ address.stateProv.code }} {{ address.postalCode }}</div>
        </div>
      </div>
      <div class="available-distance">
        <ng-container *ngIf="distanceTo; else loadingDistance">
          <div class="normal-text distance"><app-distance-display [distance]="distanceTo"></app-distance-display></div>
        </ng-container>
        <ng-template #loadingDistance>
          <app-loading [width]="'18'" [height]="'18'"></app-loading>
        </ng-template>
        <ng-container *ngIf="details['phone']?.value">
          <div class="normal-text distance-separator">·</div>
          <a
            i18n-aria-label
            *ngIf="details.phone.value"
            href="{{ details.phone.value | phoneConverter: 'href' }}"
            target="_blank"
            appCtaClickEvent="Call Shop"
            aria-label="Call Shop"
          >
            <span class="phone-number">
              {{ details.phone.value }}
            </span>
          </a>
        </ng-container>
        <ng-container *ngIf="details['priority']">
          <div class="normal-text distance-separator">·</div>
          <div i18n class="normal-text immediately-available">Immediately Available</div>
        </ng-container>
      </div>

      <!-- Tow to this location button -->
      <div class="button-wrapper">
        <app-button i18n
          id="aar-details-tow-to-button"
          (buttonClick)="setLocation(details)">
          SELECT THIS FACILITY
        </app-button>
        <ng-container *ngIf="isRapUser$ | async">
          <div class="warn-distance-message">
            <div class="small-text" *ngIf="distanceTo > maxTowMileLimit && distanceTo <= callCenterTowMileage">
              <hr />
              {{flexTowLimitMessage}}
            </div>
            <div class="small-text" *ngIf="distanceTo > callCenterTowMileage">
              <hr />
              {{strictTowLimitMessage}}
              <ng-container i18n>Please contact us at <a href="tel:{{contactPhone$ | async}}">{{contactPhone$ | async}}</a> so that we may assist you.</ng-container>
            </div>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="facilitiesDisplay && towDestinationMarker; else loadingMap">
        <div class="map-container">
          <app-map
            view='TOWING_DESTINATION'
            [static]="true"
            [breakdownMarker]="breakdownMarker$ | async"
            [center]="breakdownMarker$ | async"
            [(towDestinationMarker)]="towDestinationMarker"
            [facilitiesDisplay]="facilitiesDisplay"
          >
          </app-map>
        </div>
      </ng-container>
      <ng-template #loadingMap>
        <app-loading width="40" height="40"></app-loading>
      </ng-template>

      <!-- Electric Vehicle Charging -->
      <ng-container *ngIf="evStationDetails$ | async">
        <app-electric-vehicle [evStationDetails]="evStationDetails$ | async"></app-electric-vehicle>
      </ng-container>

      <ng-container *ngIf="details.discount && !(isRapUser$ | async) && !(isEvStation$ | async)">
        <!-- Special offers -->
        <div class="offers-hours bg-lightgrey bg-lightgrey-discounts">
          <div class="offers">
            <app-img i18n-alt
              classNames="logo-discounts"
              src="assets/drrweb-lib/images/discount-awards.svg"
              alt="AAA Members discount awards"></app-img>
            <h3 i18n class="large-text offer-title">This Facility Offers AAA Members:</h3>
            <p class="offer-description">{{ details.discount.offerDescription }}</p>
          </div>
        </div>

        <!-- Offer Restriction -->
        <div class="offers-hours bg-lightgrey bg-lightgrey-discounts">
          <div class="offers">
            <p class="offer-restriction">{{ details.discount.offerRestriction }}</p>
          </div>
        </div>
      </ng-container>

      <!-- Repair services -->
      <div class="services-details bg-lightgrey bg-lightgrey-repair-services" *ngIf="details.serviceOffers && details.serviceOffers.length">
        <div class="repair-services">
          <h3 i18n class="large-text">Repair Services</h3>
          <ul class="repair-services-list small-text"
            data-columns="2">
            <li class="repair-service-item"
              *ngFor="let offer of details.serviceOffers">
              {{ offer | titlecase }}
            </li>
          </ul>
        </div>
      </div>
      <div class="services-details bg-lightgrey bg-lightgrey-vehicles-serviced" *ngIf="details.vehicles && details.vehicles.length">
        <div class="vehicles-serviced">
          <h3 i18n class="large-text">Vehicles Serviced</h3>
          <ul class="vehicles-list">
            <li class="vehicle-list-item small-text"
              *ngFor="let vehicle of details.vehicles">
              {{ vehicle.value | titlecase }}
            </li>
          </ul>
        </div>
      </div>
      <ng-container *ngIf="!(isEvStation$ | async) && details.operatingDays && details.operatingDays.length">
        <div class="offers-hours bg-lightgrey bg-lightgrey-operating-days">
          <div class="hours">
            <h3 i18n class="large-text hours-title">Hours</h3>
            <ng-container *ngIf="details.operatingDays && details.operatingDays.length">
              <ul>
                <li class="small-text day-hours" *ngFor="let day of details.compressedHours">
                  <span class="day">{{ day.days | weekdayI18n }}</span>
                  <span class="hours">{{ day.hours }}</span>
                </li>
              </ul>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
    </ng-template>
    <ng-template #loading>
      <div class="loading">
        <app-loading [width]="64" [height]="64"></app-loading>
      </div>
    </ng-template>
  </div>
</div>
