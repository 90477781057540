import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ErrorReportingService } from '../../shared/services/error-reporting.service';
import { PayloadedAction } from '../../shared/types';
import { AAAStore } from '../../store/root-reducer';
import { LocationUtils } from '../location/location.utils';
import { checkTowDistance } from '../location/tow-location/tow-location.actions';
import { GoogleDirectionsService } from '../ui/autocomplete/google-directions/google-directions.service';
import { notifyRouteDistanceLoadFailure, routeDistanceSuccess, ROUTE_DISTANCE_LOAD } from './route-distance.actions';
import { RouteDistance } from './route-distance.reducer';
import { selectRoutesDistance } from './route-distance.selector';

@Injectable()
export class RouteDistanceEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private _googleDirectionsService: GoogleDirectionsService,
    private errorReportingService: ErrorReportingService,
    private locationUtils: LocationUtils,
  ) { }

  getRouteDistance$ = createEffect((): Observable<
    | ReturnType<typeof routeDistanceSuccess>
    | ReturnType<typeof notifyRouteDistanceLoadFailure>
  > => this.actions$.pipe(
    ofType(ROUTE_DISTANCE_LOAD.REQUEST),
    withLatestFrom(this.store$.pipe(select(selectRoutesDistance))),
    switchMap(([action, routesDistance]: [PayloadedAction, RouteDistance[]]) => {
      const distance = this.locationUtils.getDistance(routesDistance, action.payload.origin, action.payload.destination)
      if (distance) {
        return [routeDistanceSuccess({
          payload: {
            ...action.payload,
            distanceInMiles: distance,
          },
        })]
      } else {
        return from(
          this._googleDirectionsService.calculateDistance(action.payload)
        ).pipe(
          map((distanceInMiles: number) =>
            routeDistanceSuccess({
              payload: {
                ...action.payload,
                distanceInMiles,
              },
            })
          ),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              notifyRouteDistanceLoadFailure,
            )
          )
        )
      }
    })
  ))

  getRouteDistanceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTE_DISTANCE_LOAD.SUCCESS),
      filter((action: PayloadedAction) => action.payload.checkTowDistance),
      map((action: any) => checkTowDistance({
        payload: {
          breakdownCoordinates: action.payload.origin,
          towLocation: {
            lat: Number(action.payload.destination.latitude),
            lng: Number(action.payload.destination.longitude)
          },
          ...(action.payload.destination.id ? { destinationId: action.payload.destination.id } : {}),
        }
      }))
    )
  )

}
