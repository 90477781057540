import { Component, Input } from '@angular/core';
import { AARData, COR_TYPE } from '../aar/aar.types'

@Component({
  selector: 'app-destination-type',
  templateUrl: './destination-type.component.html',
  styleUrls: ['./destination-type.component.scss']
})
export class DestinationTypeComponent {
  @Input() destination: AARData

  isOwnedShop() {
    return this.destination?.services?.some(service => service.type === COR_TYPE)
  }

  getTitle() {
    if (this.isOwnedShop()) {
      return $localize`AAA Owned`
    } else {
      return $localize`AAA Approved`
    }
  }
}
