<button
  type="button"
  class="borderless-button"
  (click)="handleSummaryClick($event)"
  *ngIf="location"
  [tabIndex]="tabIndex"
>

  <ng-container *ngIf="location['priority']">
    <div class="priority-enabled">
      <span>Immediately available</span>
    </div>
  </ng-container>

  <div class="circle">
    {{ index }}
  </div>

  <div class="name-address">
    <div class="small-text bold">{{ location?.name }}</div>
    <div class="address small-text" *ngIf="location?.address as address">
      <div>
        {{ address?.addressLine }}<span class="show-desktop">,&nbsp;</span>
      </div>
      <div>
        {{ address?.cityName }}, {{ address?.stateProv?.code }}
        <span>{{ address?.postalCode }}</span>
      </div>
    </div>
  </div>

  <a
    class="show-mobile"
    href="tel:{{ location?.phone?.value | phone }}"
    target="_blank"
  >
    <div class="call"></div>
  </a>

  <aria-link-button
    class="details-link p10"
    (clickLink)="showDetails()"
    [tabIndex]="tabIndex + 1"
  >
    DETAILS
  </aria-link-button>

  <div class="distance small-text">≈ <app-distance-display [distance]="location?.distanceTo"></app-distance-display></div>
</button>
