import {
  Component,
  ViewEncapsulation,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
} from '@angular/core'
import { Step, StepStatus } from '../../modules/ui/ui.types'
import { Observable } from 'rxjs/internal/Observable'
import { AbstractComponent } from '../abstract.component'

@Component({
  styleUrls: ['./stepper.component.scss'],
  templateUrl: './stepper.component.html',
  selector: 'app-stepper',
  encapsulation: ViewEncapsulation.None,
})
export class StepperComponent extends AbstractComponent implements OnInit {
  @Input() steps$: Observable<Step[]>

  @ViewChild('stepperContainer') stepperContainer: ElementRef

  steps: Step[]
  activeStepIndex: number

  constructor() {
    super()
  }

  ngOnInit() {
    this.subscriptions.push(this.steps$.subscribe((steps) => {
      this.steps = steps
      this.activeStepIndex = steps.findIndex((step) => step.status === StepStatus.EDITING)
      this.scrollToActiveStep()
    }))
  }

  scrollToActiveStep() {
    if (this.stepperContainer?.nativeElement) {
      const container = this.stepperContainer.nativeElement
      const activeStepElement = container.children[this.activeStepIndex]
      if (activeStepElement && !this.isElementInViewport(container, activeStepElement)) {
        activeStepElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'end' })
      }
    }
  }

  isElementInViewport(container: HTMLElement, el: HTMLElement): boolean {
    const containerRect = container.getBoundingClientRect()
    const elRect = el.getBoundingClientRect()
    return (
      elRect.left >= containerRect.left &&
      elRect.right <= containerRect.right &&
      elRect.top >= containerRect.top &&
      elRect.bottom <= containerRect.bottom
    )
  }
}
