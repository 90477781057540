import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectAdvisories } from '../../../advisories/advisories.selectors'
import { Advisory } from '../../../advisories/advisories.types'
import { selectIsEnabled } from '../../../servicing-club/servicing-club.selectors'
import { AbstractComponent } from '../../../../shared/abstract.component'
import { AAAStore } from '../../../../store/root-reducer'

import { DialogData } from '../../ui.types'
import { MESSAGE_WE_ARE_SORRY } from '../../../constants/shared.constants';

@Component({
  selector: 'app-advisories-dialog-component',
  templateUrl: './advisories-dialog.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class AdvisoryDialogComponent
  extends AbstractComponent
  implements OnInit {
  advisories$: Observable<Array<Advisory>> = this.store$.select(selectAdvisories)

  isEnabled$: Observable<boolean> = this.store$.select(selectIsEnabled)
  title = () => $localize`Advisories`

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>
  ) {
    super()
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.isEnabled$.subscribe((isEnabled) => {
        if (!isEnabled) {
          this.title = MESSAGE_WE_ARE_SORRY
        }
      })
    )
  }

  onClose() {
    this.data.close()
  }
}
