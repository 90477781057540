import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'

@Component({
  selector: 'app-same-address-tow-bl-dialog-component',
  templateUrl: './same-address-tow-bl-dialog.component.html',
  styleUrls: [
    '../shared-dialog.component.scss',
    'same-address-tow-bl-dialog.component.scss',
  ],
})
export class SameAddressTowBlDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onContinue() {
    this.data.close()
  }
}
