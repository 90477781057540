<app-header-redesign>
  <app-back-button (aaaClick)="handleBack()" slot="start" />
  <app-title i18n>Vehicle Year</app-title>
</app-header-redesign>

<ion-content>
  <h3 class="ion-padding ion-no-margin" role="heading" aria-level="2">
    <aaa-text i18n size="subheadline" weight="medium" color="subtle">
      Select your vehicle's year
    </aaa-text>
  </h3>

  <app-vehicle-years-list
    [tabIndexPosition]="tabIndexPosition"
    [years]="years$ | async"
    [vehicle]="vehicle$ | async"
    [isLoading]="isLoading$ | async"
    (selectedYear)="yearChange($event)"
  />
</ion-content>