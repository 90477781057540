import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Routes, RouterModule } from '@angular/router'
import { CanComponentDeactivateGuard } from '../../shared/route-guard/can-component-deactivate.guard'
import { LogoutComponent } from '../auth/logout/logout.component';

const routes: Routes = [
  {
    path: '',
    component: LogoutComponent,
  },
]

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LogoutRoutingModule {}
