import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectGpsLocationDenied } from '../../location/location.selectors'
import { AbstractComponent } from '../../../shared/abstract.component'
import { AAAStore } from '../../../store/root-reducer'

@Component({
  selector: 'app-gps-status-icon',
  templateUrl: './gps-status-icon.component.html',
})
export class GpsStatusIconComponent extends AbstractComponent {
  hasDeniedGpsAccess$: Observable<boolean> = this.store$.pipe(select(selectGpsLocationDenied))

  constructor(
    private store$: Store<AAAStore>,
  ) {
    super()
  }
}
