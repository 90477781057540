import { AppWhiteLabelId } from '../../auth/auth.types'
import { ProductCategory } from '../tagging.types'
import events from '../events'

export function getProductCategory(appId: string): ProductCategory {
  const rapProductCategoryArray: string[] = Object.values(AppWhiteLabelId)
  if (rapProductCategoryArray.includes(appId.toUpperCase())) {
    return ProductCategory.RAP
  } else {
    return ProductCategory.DRR
  }
}

export const MEMBER_VALIDATION_ACTIONS = [
  events.auth.FORM_NAME_AUTH_PROMPT,
  events.auth.FORM_MEMBERSHIP_AUTH_PROMPT,
  events.auth.USE_FORM_NAME_CLICK,
  events.auth.USE_FORM_MEMBERSHIP_CLICK,
  events.auth.FORM_NAME_AUTH_REQUEST,
  events.auth.FORM_MEMBERSHIP_AUTH_REQUEST,
  events.auth.FORM_NAME_AUTH_SUCCESS,
  events.auth.FORM_MEMBERSHIP_AUTH_SUCCESS,
  events.auth.FORM_NAME_AUTH_FAILURE,
  events.auth.FORM_MEMBERSHIP_AUTH_FAILURE
]
