import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { Store, select } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { selectIsSecure } from '../auth.selectors'

// ARR:POC - This component controls the agent login layout.
@Component({
  selector: 'app-agent-identification',
  templateUrl: './agent-identification.component.html',
  styleUrls: ['./agent-identification.component.scss'],
})
export class AgentIdentificationComponent {
  isSecure$: Observable<boolean> = this.store$.pipe(select(selectIsSecure))

  constructor(private store$: Store<AAAStore>) {}
}
