import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-phone-number-link',
  templateUrl: './phone-number-link.component.html',
  styleUrls: ['./phone-number-link.component.scss']
})
export class PhoneNumberLinkComponent {
  @Input() phoneNumber = '';

  stopPropagation(event: Event) {
    event.stopPropagation();
  }
}
