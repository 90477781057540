import { isMobileApp } from './app-detect';

export function isMobileBrowser() {
  if (
    /webOS|BlackBerry|IEMobile|Opera Mini|Mobile|Tablet/i.test(
      navigator.userAgent
    )
  ) {
    return true
  } else {
    return false
  }
}

export const isAndroid = () => {
  if (/Android/.test(navigator?.userAgent)) {
    return true
  }
}

export const isIOS = () => {
  if (/iPhone|iPad|iPod/.test(navigator?.userAgent)) {
    return true
  }
}


export function isSafari() {
  if (/Safari/i.test(navigator.userAgent)) {
    if (isChrome() || isChromeIOS()) { // Chrome have both Chrome and Safari userAgents
      return false;
    } else {
      return true;
    }
  }
  return false;
}

export function isChrome() {
  return /Chrome/i.test(navigator.userAgent)
}

export function isChromeIOS() {
  return navigator.userAgent.match('CriOS')
}

export function isFirefoxIOS() {
  return navigator.userAgent.match('FxiOS')
}

export function isSafariMobile() {
  return isSafari() && isMobileBrowser() && !isMobileApp()
}

export function isSafariIOS() {
  return isSafari() && isIOS() && !isMobileApp()
}

export function isChromeMobile() {
  return (isChrome() || isChromeIOS()) && isMobileBrowser() && !isMobileApp()
}

export function isSafariDesktop() {
  return isSafari() && !isMobileBrowser() && !isMobileApp()
}

export function isChromeDesktop() {
  return isChrome() && !isMobileBrowser() && !isMobileApp()
}

export function isChromeAndroid() {
  return isChrome && isAndroid() && !isMobileApp()
}

export function isFirefox() {
  return /Firefox/i.test(navigator.userAgent)
}

export function isFirefoxDesktop() {
  return isFirefox() && !isMobileBrowser() && !isMobileApp()
}

export function isFirefoxMobile() {
  return (isFirefox() || isFirefoxIOS()) && isMobileBrowser() && !isMobileApp()
}

export function getBrowserDevice() {
  return isSafariIOS() ? BROWSERS_ENUM.IOS_SAFARI
    : isChromeDesktop() ? BROWSERS_ENUM.CHROME_DESKTOP
      : isChromeAndroid() ? BROWSERS_ENUM.CHROME_ANDROID
        : BROWSERS_ENUM.DEFAULT
}


export enum BROWSERS_ENUM {
  IOS_SAFARI = 0,
  CHROME_DESKTOP = 1,
  CHROME_ANDROID = 2,
  DEFAULT = 3,
}
