import { Routes } from '@angular/router'
import { IsAuthorizedRouteGuard } from '../shared/route-guard/is-authorized.route-guard'
import { IsNotAuthorizedRouteGuard } from '../shared/route-guard/is-not-authorized.route-guard'

export enum RouteTypes {
  AUTH = 'auth',
  SIGNIN = 'auth/signin',
  DASHBOARD = 'dashboard',
  STEPS = 'steps',
  RAP = 'rap',
  LOGOUT = 'logout',
  ROOT = '',
}

export const MainChildrenRoutes: Routes = [
  {
    path: '',
    redirectTo: RouteTypes.AUTH,
    pathMatch: 'full',
  },
  {
    path: RouteTypes.AUTH,
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: RouteTypes.RAP,
    loadChildren: () => import('./rap/rap.module').then((m) => m.RapModule),
    canActivate: [IsNotAuthorizedRouteGuard],
  },
  {
    path: RouteTypes.STEPS,
    loadChildren: () =>
      import('./wizard/wizard.module').then((m) => m.WizardModule),
    canActivate: [IsAuthorizedRouteGuard],
  },
  {
    path: RouteTypes.DASHBOARD,
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [IsAuthorizedRouteGuard],
  },
  {
    path: RouteTypes.LOGOUT,
    loadChildren: () =>
      import('./logout/logout.module').then((m) => m.LogoutModule),
  },
]
