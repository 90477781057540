import { Directive, Host, Input, Optional } from '@angular/core';
import { FormControlState } from 'ngrx-forms';
import { InputComponent } from '@aaa-mobile/shared-components';

@Directive({
  selector: '[ngrxFormControlState]',
})
export class FormInvalidDirective {
  constructor(
    @Host() @Optional() private input: InputComponent,
  ) { }

  @Input() set ngrxFormControlState(state: FormControlState<any>) {
    const errorsAreShown = state.isInvalid && (state.isTouched || state.isSubmitted);

    if (this.input) {
      if (errorsAreShown) {
        this.input.control.setErrors(state.errors);
      } else {
        this.input.control.setErrors(null);
      }
    }
  }
}
