<ion-row>
  <ion-col class="ion-margin-bottom">
    <aaa-input
      i18n-label
      variant="secondary"
      label="Vehicle Identification Number (VIN)"
      autocomplete="on"
      type="text"
      maxLength="17"
      [ngrxFormControlState]="controls.vinNumber"
    >
      <app-form-helper
        helper
        i18n
        [control]="controls?.vinNumber"
        name='vinNumber'
        type='required'
      >
        VIN is required.
      </app-form-helper>
      <app-form-helper
        helper
        [control]="controls?.vinNumber"
        name='vinNumber'
        type='pattern'
      >
        @if (is8VinForm) {
          <ng-container i18n>VIN is invalid.</ng-container>
        } @else {
          <ng-container i18n>VIN must be exactly 17 characters long.</ng-container>
        }
      </app-form-helper>
    </aaa-input>
  </ion-col>
</ion-row>
<ion-row class="ion-margin-vertical">
  <ion-col class="ion-margin-bottom">
    <aaa-input
      variant="secondary"
      [label]="mileageLabel$ | async"
      [ngrxFormControlState]="controls.mileage"
      type="number"
      autocomplete="on"
    >
      <app-form-helper
        helper
        i18n
        [control]="controls?.mileage"
        name='mileage'
        type='required'
      >
        Mileage is required.
      </app-form-helper>
      <app-form-helper
        helper
        i18n
        [control]="controls?.mileage"
        name='mileage'
        type='greaterThan'
      >
        Mileage must be greater than 0.
      </app-form-helper>
      <app-form-helper
        helper
        i18n
        [control]="controls?.mileage"
        name='mileage'
        type='lessThan'
      >
        Mileage number is too long.
      </app-form-helper>
    </aaa-input>
  </ion-col>
</ion-row>
