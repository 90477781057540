import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-member-number',
  templateUrl: './member-number.component.html',
  styleUrls: ['../member-credentials/member-credentials.component.scss']
})
export class MemberNumberComponent {
  @Input() controls

  constructor() {}
}
