import { EvStationVolts } from '../../types';

export const CHARGING_LEVEL_LIST = {
  [EvStationVolts.V480]: {
    backgroundColor: '#4481BF',
    textColor: '#1E354A',
  },
  [EvStationVolts.V240]: {
    backgroundColor: '#576E3B',
    textColor: '#8CB853',
  },
  [EvStationVolts.V120]: {
    backgroundColor: '#8DBA54',
    textColor: '#576E3B',
  },
}
