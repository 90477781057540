import { Observable, of, throwError } from 'rxjs'
import { delay, mergeMap, retryWhen } from 'rxjs/operators'

export function delayedRetry(delayMs = 1000, maxRetry = 3) {
  let attempt = 0
  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors) =>
        errors.pipe(
          delay(delayMs),
          mergeMap((error) => {
            if (attempt++ < maxRetry) {
              console.log(`${error} (RETRY #${attempt})`)
              return of(error)
            } else {
              return throwError(error)
            }
          })
        )
      )
    )
}
