import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';

import { IonItem, IonList, IonRadio, IonRadioGroup, } from '@ionic/angular/standalone';

import { Vehicle } from '../vehicle.types';
import { TextComponent } from '@aaa-mobile/shared-components';
import { CommonModule } from '@angular/common';

const VEHICLE_YEARS_LABEL = () => $localize`Vehicle year`

@Component({
  selector: 'app-vehicle-years-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './vehicle-years-list.component.html',
  styleUrl: './vehicle-years-list.component.scss',
  imports: [
    CommonModule,
    IonList,
    IonRadioGroup,
    IonRadio,
    TextComponent,
    IonItem
  ],
})
export class VehicleYearsListComponent {
  tabIndexPosition = input<number>(0);
  years = input<string[]>([]);
  vehicle = input<Vehicle>({});
  isLoading = input<boolean>(true);
  vehicleYear = computed(() => this.vehicle()?.year?.toString());

  selectedYear = output<string>();

  vehicleYearLabel = VEHICLE_YEARS_LABEL()

  constructor() {}

  selectYear(year: string) {
    this.selectedYear.emit(year);
  }
}
