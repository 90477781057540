import { Component, Inject, Input } from '@angular/core'

import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectIsSecure, } from '../../../modules/auth/auth.selectors'
import { AAAStore } from '../../../store/root-reducer'

import { AgentSettingsParams } from '../../../modules/auth/auth.types'

import { setAgentSettings } from '../../../modules/auth/auth.actions'

import { Router } from '@angular/router'
import { RouteTypes } from '../../../modules/main-router.module'
import { DRR_BASE_HREF } from '../../shared.config'

// ARR:POC - the component handles the login for the agent
@Component({
  selector: 'app-agent-login',
  templateUrl: './agent-login.component.html',
  styleUrls: ['./agent-login.component.scss'],
})
export class AgentLoginComponent {
  @Input() tabIndexPosition = 0

  loading = false

  agentSettings: AgentSettingsParams = {}

  isSecure$: Observable<boolean> = this.store$.pipe(select(selectIsSecure))

  constructor(
    private store$: Store<AAAStore>,
    private router: Router,
    @Inject(DRR_BASE_HREF) private drrBaseHref: string
  ) {}

  handleSubmit() {
    this.loading = true

    this.store$.dispatch(
      setAgentSettings({
        payload: this.agentSettings,
      })
    )

    this.loading = false

    this.router.navigate([this.drrBaseHref, ...RouteTypes.SIGNIN.split('/')])
  }
}
