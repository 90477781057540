import { AbstractIconComponent } from '../abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-ev-connector-tesla',
  templateUrl: './ev-connector-tesla.icon.html',
})
export class EvConnectorTeslaComponent extends AbstractIconComponent {
  static icon = 'ev-connector-tesla'
}
