<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="18"
  viewBox="0 0 24 18"
  id="icon-battery"
>
  <path
    [attr.fill]="color"
    fill-rule="evenodd"
    d="M21 2h.995A2 2 0 0 1 24 3.994v12.012A2 2 0 0 1 21.995 18H2.005A2 2 0 0 1 0 16.006V3.994A2 2 0 0 1 2.005 2H3V.999A.997.997 0 0 1 4 0h3c.552 0 1 .443 1 .999V2h8V.999A.997.997 0 0 1 17 0h3c.552 0 1 .443 1 .999V2zm-1 7.995V9h-1.935V7h-1.14v2H15v.995h1.925V12h1.14V9.995H20zM4 9v1h5V9H4z"
  />
</svg>
