<svg width="26"
  height="26"
  viewBox="0 0 26 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2229_9822)">
    <rect x="-12.1992" y="-14.0195" width="66.9062" height="35.4474" rx="5" transform="rotate(45 -12.1992 -14.0195)" fill="#FFE7E7" />
  </g>
  <rect x="1.46826" y="1" width="24" height="24" rx="12" stroke="#FA1223" />
  <defs>
    <clipPath id="clip0_2229_9822">
      <rect x="0.968262" y="0.5" width="25" height="25" rx="12.5" fill="white" />
    </clipPath>
  </defs>
</svg>
