<span 
  #ariaLinkTarget
  [attr.data-href]="href" 
  [tabindex]="tabIndex" 
  [attr.id]="elementId" 
  [attr.role]="role || 'link'" 
  class="link"
  (click)="handleClick($event)"
  triggerable-element
  [title]="ariaLabel || content.innerText"
  [ngClass]="{ 
    'inverted': inverted
  }">
  <div class="inherit-holder" #content>
    <ng-content ></ng-content>
  </div>
</span>