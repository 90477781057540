import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Inject, Injectable } from '@angular/core'
import { Action, select, Store } from '@ngrx/store'
import { catchError, filter, map, switchMap, tap, withLatestFrom, } from 'rxjs/operators'

import { AAAStore } from '../../store/root-reducer'

import { PACE_SETTER_NEXT, PACE_SETTER_PREVIOUS, SET_PACE_SETTER_SITUATION, setPaceSetterStep, } from './issue.actions'
import {
  selectActivePaceSetterCode,
  selectActivePaceSetterSituation,
  selectActivePaceSetterStep,
  selectNeedsTow,
} from './issue.selectors'

import {
  PACE_SETTER_SITUATION_CODES,
  PACE_SETTER_SITUATION_TYPES,
  PaceSetterCode,
  PaceSetterSituation
} from './issue.types'

import { Router } from '@angular/router'
import { ROUTER_NAVIGATED } from '@ngrx/router-store'
import { DestinationType, PayloadedAction } from '../../shared/types'
import { openMessageDialog } from '../ui/ui.actions'
import { MessageDialogTypes, StepTypes } from '../ui/ui.types'
import { WizardService } from '../wizard/wizard.service'
import { currentEditStepLocation } from '../wizard/wizard.selectors'
import { setResetPassengers } from '../submit/submit.actions'
import { resetTowDestination } from '../location/tow-location/tow-location.actions'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { from, of } from 'rxjs'
import { selectActiveCallStatus } from '../dashboard/calls-statuses/call-status.selectors'
import { AAACallStatus } from '../dashboard/calls.types'
import { filterPaceSetterSituation } from './issue.utils'
import { selectIsRapUser } from '../auth/auth.selectors'
import { navigateNextStep } from '../wizard/wizard.actions'
import { setDestinationType } from '../location/aar/aar.actions';
import { isAgentAppId } from '../ui/ui.utils';
import events from '../tagging/events';
import { TaggingService } from '../tagging/tagging.service';
import { RouteTypes } from '../main-router.module'
import { DRR_BASE_HREF } from '../../shared/shared.config'

@Injectable()
export class IssueEffects {
  handleSituationStepPrevious = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PACE_SETTER_PREVIOUS),
        switchMap((action) =>
          of(action).pipe(
            withLatestFrom(
              this.store$.pipe(select(selectActivePaceSetterStep))
            ),
            filter(([_, stepIndex]: [never, number]) => stepIndex > 0),
            map(([_, stepIndex]: [never, number]) => {
              const queryParams: any = { step: StepTypes.ISSUE }

              // Don't show index for landing screen in issues sequence
              if (stepIndex > 1) {
                queryParams.index = stepIndex - 1
              }

              return this.router.navigate([this.drrBaseHref, RouteTypes.STEPS], { queryParams })
            }),
            catchError((error) =>
              from(this.errorReportingService.notifyError(error))
            )
          )
        )
      ),
    { dispatch: false }
  )

  setDestinationType = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_PACE_SETTER_SITUATION, PACE_SETTER_NEXT),
        switchMap((action: Action) =>
          of(action).pipe(
            withLatestFrom(
              this.store$.pipe(select(selectActivePaceSetterCode)),
              this.store$.pipe(select(selectIsRapUser)),
            ),
            map(([_, paceSetterCode, isRapUser]: [
              Action,
                PaceSetterCode | null,
                boolean,
            ]) => {
              let destinationType = isRapUser && !isAgentAppId() ? DestinationType.TOW_DESTINATION : DestinationType.AAR
              if (paceSetterCode?.paceSetterCode === PACE_SETTER_SITUATION_CODES.T483) {
                destinationType = DestinationType.EV_STATION
              }
              return setDestinationType({
                payload: destinationType
              })
            }
            ),
          )
        )
      )
  )

  // TODO refactor
  handleSituationStepNext = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SET_PACE_SETTER_SITUATION, PACE_SETTER_NEXT),
        switchMap((action) =>
          of(action).pipe(
            withLatestFrom(
              this.store$.pipe(select(selectActiveCallStatus)),
              this.store$.pipe(select(selectActivePaceSetterSituation)),
              this.store$.pipe(select(selectActivePaceSetterStep)),
              this.store$.pipe(select(currentEditStepLocation)),
              this.store$.pipe(select(selectNeedsTow)),
            ),
            filter(([_, activeCallStatus]) => activeCallStatus === null),
            map(
              ([_, _2, situation, stepIndex, currentStepUrl, needsTow]: [
                Action,
                AAACallStatus,
                PaceSetterSituation,
                number,
                string | null,
                boolean,
              ]) => {
                if (situation) {
                  const options = filterPaceSetterSituation(situation)

                  // If tow is not needed we will reset the passengers form
                  if (!needsTow) {
                    this.store$.dispatch(resetTowDestination())
                    this.store$.dispatch(setResetPassengers())
                  }

                  if (options) {
                    // Once we've run through all the pages, move on
                    const maxGroups = options.detailGroups.length

                    if (stepIndex >= maxGroups && currentStepUrl && !needsTow) {
                      return this.wizardService.backToEditUrl(currentStepUrl)
                    }

                    if (stepIndex >= maxGroups) {
                      this.store$.dispatch(navigateNextStep())
                      return
                    }
                  }
                }

                return this.router.navigate([this.drrBaseHref, RouteTypes.STEPS], {
                  queryParams: {
                    step: StepTypes.ISSUE,
                    index: Number(stepIndex) + 1,
                  },
                })
              }
            ),
            catchError((error) =>
              from(this.errorReportingService.notifyError(error))
            )
          )
        )
      ),
    { dispatch: false }
  )

  handleNotifyPoliceDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_PACE_SETTER_SITUATION),
      withLatestFrom(
        this.store$.pipe(select(selectActiveCallStatus)),
      ),
      filter(
        ([action, activeCallStatus]: [PayloadedAction, AAACallStatus]) =>
          activeCallStatus === null &&
          PACE_SETTER_SITUATION_TYPES.ACCIDENT ===
          action.payload.name.toUpperCase()
      ),
      map(([_]) =>
        openMessageDialog({
          payload: {
            type: MessageDialogTypes.NOTIFY_POLICE,
          },
        })
      )
    )
  )

  handleNavigation = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map(
        (action: PayloadedAction) => action.payload.routerState.root.queryParams
      ),
      filter((params) => params.step === 'issue'),
      map(({ index }) => {
        if (!index) {
          this.taggingService.setPageLoadEvent({ pageType: events.issue.PAGE_TYPE, pageName: events.issue.PAGE_NAME_MENU })
        }
        return setPaceSetterStep({ index: index || 0 })
      }),
    )
  )

  constructor(
    private actions$: Actions,
    private router: Router,
    private store$: Store<AAAStore>,
    private wizardService: WizardService,
    private errorReportingService: ErrorReportingService,
    private taggingService: TaggingService,
    @Inject(DRR_BASE_HREF) private drrBaseHref: string
  ) {}
}
