import { createFeatureSelector, createSelector } from '@ngrx/store'
import { Coordinate, haversine, Options } from '../../../shared/haversine'

import {
  AAR_FILTER_TYPES,
  AAR_SORTING_ORDER,
  AAR_SORTING_TYPES,
  BreakdownLocation,
  GenericCoordinates,
  GoogleLocationMarker,
  LocationWithMarker,
  MapBoundsPadding,
  MapState
} from '../location.types'
import { generateVehicleSlug } from '../../../shared/utils/generateVehicleSlug'
import { selectMemberActiveVehicle } from '../../member/member.selectors'
import { Vehicle } from '../../member/member.types'
import { selectBreakdownLocation, selectBreakdownLocationCoordinates } from '../location.selectors'
import { AARState } from './aar.reducer'
import {
  AAR_TYPES,
  AARData,
  AARRequestParams,
  COR_TYPE,
  CUSTOM_TOW_DISTANCE_LIMIT,
  CUSTOM_TOW_LIMIT_NEARBY,
  CUSTOM_TOW_MIN_RATING,
  EvStationDetails,
  FacilitiesDisplay
} from './aar.types'
import { isAAROpen, objectToArray } from './aar.utils'
import { DestinationType, IndexedCollection } from '../../../shared/types';
import { selectLoading } from '../../ui/loading/loading.selectors'
import { AAR_LOAD } from './aar.actions'
import { convertToLocationMarker, isAddressComplete, isInBounds } from '../location.utils'
import {
  selectIsTowSelectionConfirmed,
  selectTowLocationPreview,
  selectTowLocationPreviewMarker
} from '../tow-location/tow-location.selectors'
import { NON_AAR_TOWING_NAMES } from '../tow-location/tow-location.actions'
import { deepCopy } from '../../../shared/utils/deepCopy';
import {
  MAP_BOTTOM_PADDING_AAR,
  MAP_BOTTOM_PADDING_ADDITIONAL_CHARGE_LEVEL,
  MAP_BOTTOM_PADDING_CONFIRMED_LOCATION,
  MAP_BOTTOM_PADDING_CUSTOM_DESTINATION,
  MAP_BOTTOM_PADDING_EV_STATION,
  MAP_BOUNDS_PADDING_REDESIGN
} from '../location.constants';

const MAXIMUM_MILES_DISTANCE = 5
const COVERAGE_SEARCH_AREA = 0.95

const stateKey = 'aar'

export const selectAARState = createFeatureSelector<AARState>(stateKey)

export const selectDestinationType = createSelector(
  selectAARState,
  (state: AARState) => state.destinationType
)

export const selectAARParams = createSelector(
  selectAARState,
  (state: AARState): AARRequestParams => state.aarParams
)

export const selectCurrentVehicleSlug = createSelector(
  selectMemberActiveVehicle,
  selectBreakdownLocation,
  selectDestinationType,
  (activeVehicle: Vehicle, coords: BreakdownLocation, destinationType: DestinationType) =>
    generateVehicleSlug(activeVehicle, coords ? coords['zip'] : '--', destinationType)
)

export const selectAarCollection = createSelector(
  selectAARState,
  (state: AARState): IndexedCollection<AARData> => state.data
)

export const selectAARData = createSelector(
  selectAarCollection,
  selectCurrentVehicleSlug,
  (aarDataCollection: IndexedCollection<AARData>, activeVehicleSlug: string): AARData[] => {
    const _data = aarDataCollection || {}
    return _data[activeVehicleSlug]
      ? Object.values(_data[activeVehicleSlug])
      : []
  }
)

export const selectAAROpenOnly = createSelector(
  selectAARState,
  (state: AARState): boolean => state.openOnly
)

export const selectFacilitiesDisplayFilter = createSelector(
  selectAAROpenOnly,
  selectTowLocationPreview,
  (openOnly: boolean, towPreview: LocationWithMarker): AAR_FILTER_TYPES[] => {
    const filters = []
    if (openOnly) {
      filters.push(AAR_FILTER_TYPES.OPEN)
    }
    if (NON_AAR_TOWING_NAMES.CUSTOM === towPreview?.location.name && isAddressComplete(towPreview.location)) {
      filters.push(AAR_FILTER_TYPES.TOW_DISTANCE_LIMIT)
    }
    return filters
  }
)

export const selectMaximumAarsShown = createSelector(
  selectAARState,
  (state: AARState): number => state.maximumAarsShown
)

export const selectAARAvailableCount = createSelector(
  selectAARData,
  selectAAROpenOnly,
  (data: AARData[], isOpenOnly: boolean): number =>
    isOpenOnly ? data.filter(isAAROpen).length : data.length
)

const handleCORfacilities = (serviceTypes, facilityInfo, breakdownLocation) => {
  const isCOR = Boolean(
    serviceTypes.filter((service) => service?.type?.toUpperCase() === COR_TYPE)
      .length
  )
  const locationCoords = {
    latitude: +breakdownLocation.latitude,
    longitude: +breakdownLocation.longitude,
  }
  const distance: any = haversine(
    {
      latitude: Number(facilityInfo.latitude),
      longitude: Number(facilityInfo.longitude),
    },
    locationCoords,
    { unit: 'mile' }
  )

  return isCOR && distance <= MAXIMUM_MILES_DISTANCE
}

const handlePriorityEnabledFacilities = (facilityInfo, breakdownLocation) => {
  const locationCoords = {
    latitude: +breakdownLocation.latitude,
    longitude: +breakdownLocation.longitude,
  }
  const distance: any = haversine(
    {
      latitude: Number(facilityInfo.latitude),
      longitude: Number(facilityInfo.longitude),
    },
    locationCoords,
    { unit: 'mile' }
  )

  return facilityInfo['priority'] && distance <= MAXIMUM_MILES_DISTANCE
}

export const selectNearbyAARs = createSelector(
  selectAARData,
  selectBreakdownLocation,
  (data: AARData[], breakdownLocation: BreakdownLocation) => {
    const corFacilities = []
    const rspFacilities = []

    let facilities = (data || [])
      .filter((item) => {
        const services = objectToArray(item?.services)
        const serviceTypes = services.find(
          (service) => AAR_TYPES.indexOf(service?.type?.toUpperCase()) !== -1
        )
        const isCORinRange = handleCORfacilities(
          services,
          item,
          breakdownLocation
        )
        const isPriorityInRange = handlePriorityEnabledFacilities(
          item,
          breakdownLocation
        )

        /// if is a COR facility and is within 10 miles range, exclude it from source
        // to be used later in the top of the list
        if (isCORinRange) {
          corFacilities.push(item)
          return null
        }

        // if priority is enabled, exclude from source and push it to a new array
        // to be used later on the list, also if facility is COR type avoid to be pushed
        // this will avoid to create a duplicated record
        if (isPriorityInRange && !isCORinRange) {
          rspFacilities.push(item)
          return null
        }

        return serviceTypes ? item : null
      })
      .filter((item) => item)

    // In case there is no facilities, display default list
    if (!facilities.length) {
      facilities = data
    }

    const sortedFacilities = facilities.sort(
      (a: AARData, b: AARData) =>
        +haversine(
          { latitude: Number(a.latitude), longitude: Number(a.longitude) },
          {
            latitude: Number(breakdownLocation.latitude),
            longitude: Number(breakdownLocation.longitude),
          },
          { unit: 'mile' }
        ) -
        +haversine(
          { latitude: Number(b.latitude), longitude: Number(b.longitude) },
          {
            latitude: Number(breakdownLocation.latitude),
            longitude: Number(breakdownLocation.longitude),
          },
          { unit: 'mile' }
        )
    )
    return [...corFacilities, ...rspFacilities, ...sortedFacilities]
  }
)

export const selectShownAARs = createSelector(
  selectNearbyAARs,
  selectAAROpenOnly,
  selectMaximumAarsShown,
  (
    nearbyAars: AARData[],
    isOpenOnly: boolean,
    maximumAarsShown: number
  ): AARData[] => (isOpenOnly ? nearbyAars.filter(isAAROpen) : nearbyAars).slice(
    0,
    maximumAarsShown
  )
)

export const selectShownAARsCount = createSelector(
  selectNearbyAARs,
  selectAAROpenOnly,
  (nearbyAars: AARData[], isOpenOnly: boolean): number =>
    isOpenOnly ? nearbyAars.filter(isAAROpen).length : nearbyAars.length
)

export const selectSuggestedShop = createSelector(
  selectAARState,
  (state: AARState): AARData => state.suggestedShop
)

export const selectFacility = createSelector(
  selectAARState,
  selectSuggestedShop,
  (state: AARState, suggestedShop: AARData) => (id: number): AARData => {
    if (!id) {
      return null
    }
    if (suggestedShop?.id === id) {
      return suggestedShop
    }
    const allAars = []
    const slugs = Object.keys(state.data)
    slugs.forEach(slug => Object.values(state.data[slug]).forEach(aar => allAars.push(aar)))
    const data = allAars.filter(aar => aar.id === id)[0]
    if (!data) {
      return null
    }
    return {
      ...state.details[id],
      ...data,
    }
  }
)

export const selectActiveFacility = createSelector(
  selectAARState,
  (state: AARState): AARData => state.details?.[state.active] || state.data?.[state.active]
)

export const selectEvStationDetails = createSelector(
  selectActiveFacility,
  (facility: AARData): EvStationDetails => facility?.evStationDetails
)

export const selectAARsCustomMessage = createSelector(
  selectAARState,
  (state: AARState) => state.noMakeFacilities
)

export const selectExistsAarForVehicle = createSelector(
  selectAARState,
  selectCurrentVehicleSlug,
  (state: AARState, activeVehicleSlug: string) => {
    const _data = state.data || {}
    return Boolean(_data[activeVehicleSlug])
  }
)

export const selectIsEVstation = createSelector(
  selectAARState,
  (state: AARState): boolean => state.destinationType === DestinationType.EV_STATION
)

export const selectArrSortingType = createSelector(
  selectAARState,
  (state: AARState): AAR_SORTING_TYPES => state.sortingType
)

export const selectAarHasRatings = createSelector(
  selectAARState,
  (state: AARState): boolean => state.hasRatingsSummary
)

export const selectAarSortingOrder = createSelector(
  selectAARState,
  (state: AARState): AAR_SORTING_ORDER => state.sortingOrder
)

export const selectIsLoadingSearchArea = createSelector(
  selectLoading,
  (loading: object): boolean => Boolean(loading[AAR_LOAD.ACTION])
)

export const selectTowLocationSearch = createSelector(
  selectAARState,
  (state: AARState): MapState => state.towLocationSearch
)

export const selectLastTowSearchArea = createSelector(
  selectTowLocationSearch,
  selectBreakdownLocationCoordinates, (
    towLocationSearch: MapState,
    breakdownLocationCoordinates: GenericCoordinates
  ): GenericCoordinates => towLocationSearch
    ? {
      latitude: towLocationSearch.center.latitude,
      longitude: towLocationSearch.center.longitude
    } as GenericCoordinates
    : breakdownLocationCoordinates
)

export const selectFacilitiesDisplay = createSelector(
  selectNearbyAARs,
  selectIsLoadingSearchArea,
  selectTowLocationSearch, (
    nearbyAars: AARData[],
    isLoadingSearchArea: boolean,
    towLocationSearch: MapState
  ): FacilitiesDisplay => {

    let facilities: AARData[] = nearbyAars
    let fitAllFacilities = !isLoadingSearchArea
    let expandedResult = false

    if (!isLoadingSearchArea
      && towLocationSearch
      && towLocationSearch.northEastBound
      && towLocationSearch.southWestBound) {
      const mapAars = nearbyAars.filter(aar =>
        isInBounds(aar,
          towLocationSearch.northEastBound,
          towLocationSearch.southWestBound,
          COVERAGE_SEARCH_AREA)
      )
      if (mapAars.length) {
        facilities = mapAars
        fitAllFacilities = false
      } else {
        expandedResult = true
      }
    }

    return {
      aarData: facilities,
      fitAll: fitAllFacilities,
      expandedResult,
      markers: facilities.map(convertToLocationMarker).filter(Boolean)
    }
  }
)

export const selectFilteredFacilitiesDisplay = createSelector(
  selectFacilitiesDisplay,
  selectFacilitiesDisplayFilter,
  selectTowLocationPreview,
  (
    facilitiesDisplay: FacilitiesDisplay,
    filters: AAR_FILTER_TYPES[],
    towPreview: LocationWithMarker
  ): FacilitiesDisplay => {
    const facilities = deepCopy(facilitiesDisplay)
    if(filters.includes(AAR_FILTER_TYPES.OPEN)) {
      facilities.aarData = facilities.aarData.filter(isAAROpen)
      facilities.markers = facilities.aarData.map(convertToLocationMarker).filter(Boolean)
    }
    if(filters.includes(AAR_FILTER_TYPES.TOW_DISTANCE_LIMIT)) {
      const towLocation = {
        latitude: Number(towPreview.location.latitude),
        longitude: Number(towPreview.location.longitude),
      }
      filterShopsForCustomLocation(towLocation, facilities)
    }
    return facilities
  }
)

export const selectSortedFacilitiesDisplay = createSelector(
  selectFilteredFacilitiesDisplay,
  selectArrSortingType,
  selectAarSortingOrder,
  (
    facilitiesDisplay: FacilitiesDisplay,
    sorting: AAR_SORTING_TYPES,
    order: AAR_SORTING_ORDER,
  ): FacilitiesDisplay => {
    const items = facilitiesDisplay.aarData
    switch (sorting) {
      case AAR_SORTING_TYPES.DISTANCE:
        sortByDistance(items, order)
        break
      case AAR_SORTING_TYPES.RATING:
        sortByRating(items, order)
        break
    }
    return facilitiesDisplay as FacilitiesDisplay
  }
)

export const selectShopPreferences = createSelector(
  selectAAROpenOnly,
  selectArrSortingType,
  selectAarSortingOrder,
  selectAarHasRatings,
  (
    openOnly: boolean,
    sorting: AAR_SORTING_TYPES,
    order: AAR_SORTING_ORDER,
    hasRatings: boolean
  ) => ({
    openOnly,
    sorting,
    order,
    hasRatings
  })
)


function filterShopsForCustomLocation(towLocation: Coordinate, facilitiesDisplay: FacilitiesDisplay) {
  // calculate distances from tow location
  const options = { unit: 'mile' } as Options
  const itemsWithDistance = facilitiesDisplay.aarData.map(item => ({
    ...item,
    distance: haversine({ latitude: Number(item.latitude), longitude: Number(item.longitude) }, towLocation, options)
  }))

  // filter and sort
  let sortedFacilities = itemsWithDistance
    .filter(shop => Math.round(shop.distance * 1000) <= CUSTOM_TOW_DISTANCE_LIMIT * 1000)
    .sort((a, b) => a.distance - b.distance)

  // select nearest
  const byDistance = sortedFacilities.slice(0, CUSTOM_TOW_LIMIT_NEARBY)

  // include extra in range with good rating
  const byRating = sortedFacilities.length > CUSTOM_TOW_LIMIT_NEARBY ?
    sortedFacilities
      .slice(CUSTOM_TOW_LIMIT_NEARBY)
      .filter(item => item.ratingSummary?.ratingAvg && Math.round(item.ratingSummary.ratingAvg * 10) >= CUSTOM_TOW_MIN_RATING * 10) :
    []

  facilitiesDisplay.aarData = [...byDistance, ...byRating]
  facilitiesDisplay.markers = facilitiesDisplay.aarData
    .map(aar => facilitiesDisplay.markers.find(marker => marker.marker.aarId === aar.id))
}

const sortByRating = (aars: AARData[], order: AAR_SORTING_ORDER): AARData[] => aars.sort((a, b) => {
  const ratingA = a.ratingSummary?.ratingAvg || 0
  const ratingB = b.ratingSummary?.ratingAvg || 0
  return order === AAR_SORTING_ORDER.DESCENDING ? ratingA - ratingB : ratingB - ratingA;
})

const sortByDistance = (aars: AARData[], order: AAR_SORTING_ORDER): AARData[] => aars.sort((a, b) =>
  order === AAR_SORTING_ORDER.ASCENDING ? b.distanceTo - a.distanceTo : a.distanceTo - b.distanceTo
)

export const selectAarPreview = createSelector(
  selectAARState,
  (state: AARState): number => state?.aarPreviewId // FIXME check why unit tests are failing
)

export const selectAarPreviewLocation = createSelector(
  selectAarPreview,
  selectFacility,
  (previewId: number, facilitySelector): AARData => facilitySelector(previewId)
)

export const selectMapBoundsPadding = createSelector(
  selectAarPreviewLocation,
  selectTowLocationPreviewMarker,
  selectIsTowSelectionConfirmed,
  (facilityPreview: AARData, preview: GoogleLocationMarker, towSelectionConfirmed): MapBoundsPadding => {
    let bottom
    if (facilityPreview) {
      if (facilityPreview.evStationDetails) {
        const chargesAvailable = Object.values(facilityPreview.evStationDetails.chargeLevel)
          .filter(chargesQty => chargesQty > 0)
          .length
        bottom = MAP_BOTTOM_PADDING_EV_STATION + (chargesAvailable * MAP_BOTTOM_PADDING_ADDITIONAL_CHARGE_LEVEL)
      } else {
        bottom = MAP_BOTTOM_PADDING_AAR
      }
    }
    if (preview) {
      bottom = MAP_BOTTOM_PADDING_CUSTOM_DESTINATION
    }
    if (towSelectionConfirmed) {
      bottom = MAP_BOTTOM_PADDING_CONFIRMED_LOCATION
    }
    return {
      ...MAP_BOUNDS_PADDING_REDESIGN,
      ...(bottom ? { bottom } : {})
    }
  }
)
