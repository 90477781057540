import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'

@Component({
  selector: 'app-notify-police-dialog-component',
  templateUrl: './notify-police-dialog.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class NotifyPoliceDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onClose() {
    this.data.close()
  }
}
