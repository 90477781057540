import { Component, Input } from '@angular/core'
import { ConnectorTypes } from '../../aar/aar.types';
@Component({
  selector: 'app-ev-station-connector-type',
  templateUrl: './connector-type.component.html',
  styleUrls: ['./connector-type.component.scss'],
})
export class EvStationConnectorTypeComponent {

  connectorTypesList = ConnectorTypes
  @Input() connectorType: ConnectorTypes

}
