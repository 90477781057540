import { createFeatureSelector, createSelector, defaultMemoize } from '@ngrx/store'
import { selectActiveCallStatus } from '../dashboard/calls-statuses/call-status.selectors'
import { BreakdownLocationParams } from '../dashboard/calls.types'
import { selectBreakdownLocation } from '../location/location.selectors'
import { BreakdownLocation } from '../location/location.types'
import { selectMemberActiveVehicle } from '../member/member.selectors'
import { VehicleData } from '../member/member.types'
import { AAAQuotesServicesState } from './quotes.reducer'
import { BatteryQuote, BatteryQuotePayload, QuotesParams } from './quotes.types'
import { generateVehicleSlug } from '../../shared/utils/generateVehicleSlug'

const stateKey = 'quotes'

export const selectQuoteServicesState = createFeatureSelector<AAAQuotesServicesState>(stateKey)

export const selectQuotesParams = createSelector(
  selectActiveCallStatus,
  selectBreakdownLocation,
  selectMemberActiveVehicle,
  (activeCallStatus, breakdownLocation, memberActiveVehicle): QuotesParams => {
    const vehicle =
      activeCallStatus?.vehicle || memberActiveVehicle || ({} as VehicleData)
    // TODO investigate curveball.
    const postalCode =
      (activeCallStatus?.breakdownLocation as BreakdownLocation)?.postalCode ||
      (activeCallStatus?.breakdownLocation as BreakdownLocationParams)?.zip ||
      breakdownLocation?.postalCode ||
      ''

    return { vehicle, postalCode }
  }
)

export const createSelectBatteryQuotes = createSelector(
  selectQuoteServicesState,
  (state: AAAQuotesServicesState) =>
    defaultMemoize(
      (vehicle: VehicleData, postalCode: string): Array<BatteryQuote> | null =>
        state.battery?.[generateVehicleSlug(vehicle, postalCode)]
    )
)

export const createSelectNotifiedQuotes = createSelector(
  selectQuoteServicesState,
  (state: AAAQuotesServicesState) =>
    defaultMemoize(
      (vehicle: VehicleData, postalCode: string): boolean =>
        state.notified?.[generateVehicleSlug(vehicle, postalCode)]
    )
)

export const selectActiveBatteryQuotes = createSelector(
  createSelectBatteryQuotes,
  selectQuotesParams,
  (selectBatteryQuotes, { vehicle, postalCode }): Array<BatteryQuote> | null =>
    selectBatteryQuotes.memoized(vehicle, postalCode) || []
)

export const selectIsQuoteNotified = createSelector(
  createSelectNotifiedQuotes,
  selectQuotesParams,
  (selectNotifiedQuotes, { vehicle, postalCode }): boolean =>
    selectNotifiedQuotes.memoized(vehicle, postalCode) || false
)

export const selectQuotePayload = createSelector(
  selectQuotesParams,
  ({ vehicle, postalCode }): BatteryQuotePayload => (
    vehicle?.year ?
      {
        imCarYear: vehicle.year.toString(),
        smCarMake: vehicle.make,
        smCarModel: vehicle.model,
        imZipCode: postalCode,
      } : null
  )
)

export const selectHasBatteryQuotes = createSelector(
  selectActiveBatteryQuotes,
  (batteryQuotes): boolean => batteryQuotes?.length > 0
)
