<app-map
  [tabIndexPosition]="0"
  [preventTouch]="false"
  [fitMarkers]="true"
  [mapCenter]="(viewModel$ | async).breakdownLocation"
  [customMarkers]="(viewModel$ | async).markers">
  @if (isRedesign()) {
    <ion-grid topSlot class="ion-no-padding ion-margin">
      <ion-row>
        <ion-col class="ion-text-center">
          <app-arrival-time-label style="margin: 0 auto;"></app-arrival-time-label>
        </ion-col>
      </ion-row>
    </ion-grid>
  }
</app-map>
