import { createAction, props } from '@ngrx/store'
import { ResetAction } from 'ngrx-forms'

export const PASSENGERS_FORM = 'form.passengers'
export const CONTACT_FORM = 'form.contact'

export const RESET_PASSENGERS = 'RESET_PASSENGERS'
export const SET_CONTACT_NUMBER = 'SET_CONTACT_NUMBER'

export const setResetPassengers = createAction(RESET_PASSENGERS)

export const resetPassengers = () => new ResetAction(PASSENGERS_FORM)

export const setContactNumber = createAction(
  SET_CONTACT_NUMBER,
  props<{ payload: string }>()
)
