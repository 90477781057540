import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'icon-pin',
  templateUrl: './pin.icon.html',
})
export class PinIconComponent extends AbstractIconComponent {
  static icon = 'pin'
  @Input() pinColor = '#1A1A1A'
  @Input() circleColor = '#F2F2F2'
  @Input() inverted = false
}
