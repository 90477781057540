import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-close-rounded',
  templateUrl: './close-rounded.icon.html',
})
export class CloseRoundedIconComponent extends AbstractIconComponent {
  static icon = 'close-rounded'
}
