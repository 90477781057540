import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { dashboardComponents } from './dashboard.component.declarations'
import { SharedModule } from '../../shared'
import { DashboardRoutingModule } from './dashboard-routing/dashboard-routing.module'
import { ServiceDeliveryComponent } from './service-delivery/service-delivery.component'
import { LocationModule } from '../location/location.module'
import { IonicModule } from '@ionic/angular';
import { ButtonComponent, IconComponent, TextComponent } from '@aaa-mobile/shared-components';
import { HeaderRedesignComponent } from '../../shared/layouts/header-redesign/header/header.component'
import { MenuButtonComponent } from '../../shared/layouts/header-redesign/menu-button/menu-button.component'
import { LogoComponent } from '../../shared/layouts/header-redesign/logo/logo.component'
import { TitleComponent } from '../../shared/layouts/header-redesign/title/title.component'
import { MenuComponent } from '../../shared/layouts/header-redesign/menu/menu.component'
import { ImageComponent } from '../../shared/controls/image/image.component'
import { CancelRequestComponent } from '../service-tracking/cancel-request/cancel-request.component';

@NgModule({
  declarations: [...dashboardComponents(), ServiceDeliveryComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    LocationModule,
    IonicModule,
    TextComponent,
    ButtonComponent,
    IconComponent,
    HeaderRedesignComponent,
    MenuButtonComponent,
    MenuComponent,
    LogoComponent,
    TitleComponent,
    ImageComponent,
    CancelRequestComponent,
  ]
})
export class DashboardModule {}
