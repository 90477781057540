import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '../../shared'
import { LogoutRoutingModule } from './logout-routing.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    LogoutRoutingModule,
    SharedModule,
  ],
})
export class LogoutModule {}
