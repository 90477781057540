import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core'
import { AriaLinkButtonComponent } from '../../../shared/controls/aria-link/aria-link-button.component'

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss'],
})
export class EditButtonComponent {

  @ViewChild('ariaLink') ariaLink: AriaLinkButtonComponent

  @Input() source: any = {}
  @Input() field = ''
  @Input() alias: string
  @Input() disabled = false
  @Input() tabIndexPosition = 0
  editLabel = () => $localize`Edit`

  @Output() selected: EventEmitter<any> = new EventEmitter<any>()

  handleSelected() {
    this.selected.emit()
  }
}
