<svg
  xmlns="http://www.w3.org/2000/svg"
  width="30"
  height="19"
  viewBox="0 0 30 19"
  id="icon-car-crash"
>
  <g [attr.fill]="color" fill-rule="evenodd">
    <path
      d="M28.577 6.82a1.124 1.124 0 0 0-.92-.933L19.51 4.45a1.131 1.131 0 0 0-1.183.563l-2.323 4.165-1.045 5.926c-.072.407.203.799.61.87l.74.131c.408.072.8-.202.872-.61l.13-.74 8.89 1.567-.132.74c-.071.408.203.8.61.872l.741.13c.408.072.8-.202.872-.61l1.044-5.925-.758-4.709zm-10.504 5.778a1.127 1.127 0 0 1-.915-1.307 1.127 1.127 0 0 1 1.307-.915c.614.108 1.023.692.915 1.307a1.127 1.127 0 0 1-1.307.915zm8.148 1.436a1.127 1.127 0 0 1-.916-1.306 1.127 1.127 0 0 1 1.307-.916c.615.109 1.024.693.916 1.307a1.127 1.127 0 0 1-1.307.915zm-8.606-5.336l1.699-3.137 8.147 1.437.524 3.529-10.37-1.829z"
    />
    <path
      d="M11.674 5.013a1.124 1.124 0 0 0-1.183-.563L2.343 5.887c-.49.086-.842.469-.92.933L.665 11.53l1.044 5.925a.754.754 0 0 0 .872.61l.74-.13a.754.754 0 0 0 .61-.871l-.13-.741 8.888-1.567.131.74a.754.754 0 0 0 .871.61l.741-.13a.754.754 0 0 0 .61-.871l-1.045-5.926-2.323-4.165zM3.78 14.034a1.127 1.127 0 0 1-1.307-.915c-.108-.614.3-1.198.916-1.307.614-.108 1.198.3 1.307.916.108.614-.3 1.198-.916 1.306zm8.148-1.436a1.127 1.127 0 0 1-1.307-.915c-.108-.615.3-1.199.915-1.307.615-.109 1.199.3 1.307.915.109.615-.3 1.198-.915 1.307zm-9.912-2.071l.524-3.53 8.147-1.436 1.7 3.137-10.37 1.829zM13.756 0l1.708.06-.95 5.448L13.756 0zm3.986.796L19.125 1.8 15.29 5.786l2.452-4.99zm-6.36 0l2.452 4.99L10 1.8 11.383.796z"
    />
  </g>
</svg>
