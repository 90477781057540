import { Component, Input, OnInit } from '@angular/core';
import { isRedesign } from '../utils/cookies';
import { WindowObject } from '../utils/window-object';

@Component({
  selector: 'app-third-party-cookies-instructions',
  templateUrl: './third-party-cookies-instructions.component.html',
  styleUrls: ['./third-party-cookies-instructions.component.scss']
})
export class ThirdPartyCookiesInstructionsComponent implements OnInit {

  @Input() url: string

  constructor(
    private windowObject: WindowObject
  ) { }

  ngOnInit() {
  }

  navigateToExternalLink() {
    this.windowObject.navigateTo(this.url)
  }

  protected readonly isRedesign = isRedesign;
}
