import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Step, StepStatus, StepTypes } from '../../modules/ui/ui.types'
import { Observable } from 'rxjs'
import { AbstractComponent } from '../abstract.component'
import { deepCopy } from '../utils/deepCopy'

@Component({
  selector: 'app-stepper-progress-bar',
  templateUrl: './stepper-progress-bar.component.html',
  styleUrls: ['./stepper-progress-bar.component.scss']
})
export class StepperProgressBarComponent extends AbstractComponent implements OnInit {
  @Input() steps: Observable<Step[]>
  @Input() editingStepUrl: string
  @Output() playAnimation: EventEmitter<any>
  progressSteps: Step[]
  progress = 0
  isStepCompleted = false

  ngOnInit(): void {
    this.subscriptions.push(this.steps.subscribe((steps) => {
      this.progressSteps = deepCopy(steps.slice(0, -1))
      this.progress = 0
      let activeStepFound = false
      steps.forEach((step, index) => {
        if(!step?.status) {
          return
        }
        if (activeStepFound && index < steps.length - 1) {
          this.progressSteps[index].status = StepStatus.PENDING
        } else {
          const partialProgress = step.progress
            ?.reduce((output, subStepCheck) => output + (subStepCheck ? (100*(1/step.progress.length))/ steps.length : 0), 0) || 0
          if (!activeStepFound) {
            this.progress += partialProgress
          }

          if (step.status === StepStatus.EDITING && !activeStepFound) {
            activeStepFound = true
            setTimeout(() => {
              this.isStepCompleted = partialProgress === 0 && step.url !== StepTypes.BREAKDOWN_LOCATION
            }, 0)
          }
        }
      })
    }))
  }

}
