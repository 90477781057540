import {
  Directive,
  ElementRef,
  HostListener,
} from '@angular/core'

const SPACE_CODE = ' '
const ENTER_CODE = 'Enter'

@Directive({
  selector: '[triggerable-element]'
})
export class TriggerableElementDirective {

  constructor(
    private element: ElementRef,
  ) { }

  @HostListener('keydown', ['$event'])
  keyboardListener(event) {
    const keyCode = event.key;

    if (keyCode === SPACE_CODE || keyCode === ENTER_CODE) {
      this.emitActions();
    }
  }

  private emitActions() {
    this.element.nativeElement.click()
  }
}
