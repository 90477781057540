import { get } from 'lodash'
import { wait } from './wait'

const MAX_RETRIES = 10
const RETRY_MS_INTERVAL = 2000

export function awaitHoistedFunction<F, T>({
  ref,
  target,
  path = [],
  callback,
  error,
  retries = 1,
}: {
  ref?: T
  target: F
  path?: Array<string>
  callback: () => T
  error: Error
  retries?: number
}) {
  return new Promise((resolve, reject) => {
    if (ref !== undefined) {
      resolve(ref)
    }

    if (retries <= MAX_RETRIES) {
      if (typeof target === 'function') {
        resolve(callback())
      } else if (path.length > 0 && typeof get(target, path) === 'function') {
        resolve(callback())
      } else {
        return wait(RETRY_MS_INTERVAL)
          .then(() =>
            resolve(
              awaitHoistedFunction({
                ref,
                target,
                path,
                callback,
                error,
                retries: retries + 1,
              })
            )
          )
          .catch(() => reject(error))
      }
    } else {
      reject(error)
    }
  })
}

export const __TEST__ = {
  MAX_RETRIES,
}
