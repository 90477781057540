import { createFeatureSelector, createSelector } from '@ngrx/store'
import { SessionState } from './session.reducer'

const stateKey = 'session'

export const selectSessionState = createFeatureSelector<SessionState>(stateKey)

export const selectLastInteraction = createSelector(
  selectSessionState,
  (state: SessionState): number => state.lastInteraction
)

export const selectUserSessionId = createSelector(
  selectSessionState,
  (state: SessionState): string => state.userSessionId
)
