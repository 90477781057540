<div 
  class="banner {{ classNames }}"
  [ngClass]="{
    animated: animated
  }"
  [tabIndex]="tabIndex"
>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
