import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { AAR_FILTER_TYPES, AAR_SORTING_ORDER, AAR_SORTING_TYPES, AARSorting } from '../location.types'

@Component({
  selector: 'app-shop-preferences',
  templateUrl: './shop-preferences.component.html',
  styleUrls: ['./shop-preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopPreferencesComponent {
  @Input() openOnly: boolean
  @Input() sorting: AAR_SORTING_TYPES
  @Input() order: AAR_SORTING_ORDER
  @Input() hasRatings: boolean

  @Output() filter = new EventEmitter<AAR_FILTER_TYPES>()
  @Output() sort = new EventEmitter<AARSorting>()

  readonly AAR_SORTING_TYPES = AAR_SORTING_TYPES
  readonly AAR_FILTER_TYPES = AAR_FILTER_TYPES

  private readonly icons = {
    asc: 'sort-ascending',
    desc: 'sort-descending',
  }

  get orderIcon() {
    return this.icons[this.order]
  }

  handleFilter(type: AAR_FILTER_TYPES) {
    this.filter.emit(type)
  }

  handleSorting(type: AAR_SORTING_TYPES) {
    this.sort.emit({ type, order: this.order })
  }
}
