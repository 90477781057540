<app-edit-button
  class="year-button"
  [source]="vehicle"
  field="year"
  (selected)="editVehicleSection('years')"
  [disabled]="yearDisabled$ | async"
  [tabIndexPosition]="tabIndexPosition + 1"
></app-edit-button>
<app-edit-button
  [source]="vehicle"
  field="make"
  (selected)="editVehicleSection('makes')"
  [tabIndexPosition]="tabIndexPosition + 2"
></app-edit-button>
<app-edit-button
  alias="{{ getModel(vehicle) }}"
  [source]="vehicle"
  field="model"
  (selected)="editVehicleSection('models')"
  [tabIndexPosition]="tabIndexPosition + 3"
></app-edit-button>
@if (vehicle.model) {
  <app-edit-button
    [source]="vehicle"
    field="color"
    (selected)="editVehicleSection('details')"
    [tabIndexPosition]="tabIndexPosition + 4"
  ></app-edit-button>
}
@if (clickCancel.observers.length > 0) {
  <aaa-button
    class="cancel-edit"
    (click)="editVehicleSection('')"
    [tabIndex]="tabIndexPosition + 5"
    size="small"
    variant="ghost"
    i18n
  >Cancel</aaa-button>
}
