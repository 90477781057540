import { BreakdownLocationComponent } from './breakdown-location/breakdown-location.component'
import { TowLocationPromoComponent } from './tow-location-promo/tow-location-promo.component'
import { TowLocationSummaryComponent } from './tow-location-summary/tow-location-summary.component'
import { TowLocationComponent } from './tow-location/tow-location.component'
import {
  TowLocationListSkeletonComponent
} from './tow-location-list-item/tow-location-skeleton-item/tow-location-skeleton-item.component'
import {
  BreakdownLocationStepComponent
} from '../breakdown-location/breakdown-location-step/breakdown-location-step.component';
import { AdjustLocationCardComponent } from '../breakdown-location/adjust-location-card/adjust-location-card.component'
import { TowLocationStepComponent } from '../tow-location/tow-location-step/tow-location-step.component';
import { ShopPreferencesComponent } from './shop-preferences/shop-preferences.component'
import { PassengersComponent } from './passengers/passengers.component'

export function locationComponents() {
  return [
    BreakdownLocationComponent,
    BreakdownLocationStepComponent,
    TowLocationComponent,
    TowLocationStepComponent,
    TowLocationPromoComponent,
    TowLocationSummaryComponent,
    TowLocationListSkeletonComponent,
    AdjustLocationCardComponent,
    ShopPreferencesComponent,
    PassengersComponent,
  ]
}
