import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-diesel',
  templateUrl: './diesel.icon.html',
})
export class DieselIconComponent extends AbstractIconComponent {
  static icon = 'diesel'
}
