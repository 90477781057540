import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable, Subject } from 'rxjs'
import {
  requestVehicleModels,
  requestVehicleModelTypes,
  setVehicleModelAndType,
  setVehicleStep,
  VEHICLE_MODEL_TYPES,
  VEHICLE_MODELS,
} from '../vehicle.actions'
import {
  createGetVehicleTypesByModel,
  selectEditOrAddVehicleDesc,
  selectVehicleModels,
  selectWorkingVehicle
} from '../vehicle.selectors'
import { TaggingService } from '../../tagging/tagging.service'
import { selectIsLoading } from '../../ui/loading/loading.selectors'
import events from '../../tagging/events'
import { AbstractComponent } from '../../../shared/abstract.component'
import { VehicleEditState } from '../vehicle.reducer'
import { ModelType, Selection, VehicleDriveTypes } from '../vehicle.types'
import { AdobeEventTypes } from '../../tagging/tagging.types'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { clearActiveVehicle } from '../../member/member.actions'
import { map, startWith } from 'rxjs/operators'
import { VEHICLE_MODEL_NAME_MAX_LENGTH } from '../vehicle.contants'
import { toTitleCase } from '../../../shared/utils/titlecase'

const ERROR_SELECT_A_MODEL = () => $localize`Please, select a model.`
const ERROR_INFORM_MODEL_DETAILS = () => $localize`Please, inform the model details.`

@Component({
  selector: 'app-models-step',
  templateUrl: './models-step.component.html',
  styleUrls: ['./models-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelsStepComponent extends AbstractComponent implements OnInit {
  @ViewChild('searchbar', { static: false }) searchbar: ElementRef
  searchInput$ = new Subject<string>()
  searchInput = ''

  @ViewChild('modelsContainer', { static: false }) modelsContainer: ElementRef
  isLoading$: Observable<any> = this.store$.pipe(
    select(selectIsLoading(VEHICLE_MODELS.ACTION))
  )

  maxChars = VEHICLE_MODEL_NAME_MAX_LENGTH
  isAWD: Boolean = null
  isPristine = true
  selectedModel: Selection = { value: null, other: false }
  workingVehicle: VehicleEditState
  workingVehicle$: Observable<VehicleEditState> = this.store$.pipe(select(selectWorkingVehicle))

  getVehicleTypesByModel: (model: string) => ModelType[]
  hasFourWheelDriveTypes = false
  selectedModelDriveType: VehicleDriveTypes

  models$: Observable<string[]> = this.store$.pipe(select(selectVehicleModels))
  filteredModels$ = combineLatest([
    this.models$,
    this.searchInput$.pipe(startWith(''))
  ]).pipe(
    map(([models, searchInput]) => {
      this.searchInput = searchInput
      const filteredModels = models.filter(model =>
        model.toLowerCase().includes(searchInput.toLowerCase())
      )
      this.isOtherModel = filteredModels.length === 0
      if (this.isOtherModel && searchInput) {
        filteredModels.push(searchInput)
      }
      return filteredModels
    })
  )

  createGetVehicleTypesByModel$ = this.store$.pipe(
    select(createGetVehicleTypesByModel)
  )

  isLoadingVehicleModelTypes$: Observable<boolean> =
    this.store$.pipe(select(selectIsLoading(VEHICLE_MODEL_TYPES.ACTION)))

  editOrAddVehicleDesc$ = this.store$.pipe(select(selectEditOrAddVehicleDesc))
  editOrAddVehicleDesc: string

  errorMessage: string = null

  isOpen = false
  userSelectedModel = false
  isOtherModel = false

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService,
  ) {
    super()
  }

  ngOnInit() {
    this.store$.dispatch(requestVehicleModels({ payload: null }))

    this.subscriptions.push(
      this.createGetVehicleTypesByModel$.subscribe((getVehicleTypesByModel) => {
        this.getVehicleTypesByModel = getVehicleTypesByModel.memoized
        this.updateHasFourWheelDriveTypes(this.selectedModel.value)
      }),
      this.workingVehicle$.subscribe((vehicle) => {
        if (vehicle?.model && this.workingVehicle?.model && vehicle.model === this.workingVehicle.model) {
          return
        }
        this.workingVehicle = vehicle
        this.selectedModel.value = vehicle.model
        this.updateHasFourWheelDriveTypes(vehicle.model)
        this.isAWD = vehicle.driveType !== undefined ? (
          vehicle.driveType === VehicleDriveTypes.ALL_WHEEL_DRIVE ||
          vehicle.driveType === VehicleDriveTypes.FOUR_WHEEL_DRIVE
        ) : null
      }),
      this.editOrAddVehicleDesc$.subscribe(
        editOrAdd => this.editOrAddVehicleDesc = editOrAdd
      )
    )
  }

  onSearch(event: any) {
    this.searchInput$.next(toTitleCase(event.target.value))
  }

  modelChange(selection: string) {
    this.userSelectedModel = true
    this.isAWD = null
    this.selectedModel = {
      value: selection,
      other: this.isOtherModel
    }
    if (!this.selectedModel.value) {
      return
    } else {
      this.errorMessage = null
    }

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: `${this.editOrAddVehicleDesc} ${events.vehicle.VEHICLE_MODEL_SELECT}`
    })

    this.taggingService.setClickEvent(
      events.vehicle.VEHICLE_MODEL_SELECT,
      events.vehicle.VEHICLE_PAGE_TYPE
    )

    const slug = this.getWorkingVehicleSlug(this.selectedModel.value)
    const modelTypes = this.getVehicleTypesByModel(slug)

    if (!modelTypes) {
      this.store$.dispatch(requestVehicleModelTypes({
        payload: {
          year: Number(this.workingVehicle.year),
          make: this.workingVehicle.make,
          model: this.selectedModel.value
        }
      }))
    } else {
      this.hasFourWheelDriveTypes = this.getHasFourWheelDriveTypes(modelTypes)
      this.isOpen = this.hasFourWheelDriveTypes
      if (!this.hasFourWheelDriveTypes) {
        this.onNext()
      }
    }
  }

  updateHasFourWheelDriveTypes(model: string) {
    if (model) {
      const slug = this.getWorkingVehicleSlug(model)
      const modelTypes = this.getVehicleTypesByModel(slug)

      this.hasFourWheelDriveTypes = this.getHasFourWheelDriveTypes(modelTypes)
      this.isOpen = this.hasFourWheelDriveTypes
      if (this.userSelectedModel && !this.hasFourWheelDriveTypes) {
        this.onNext()
      }
    }
  }

  getHasFourWheelDriveTypes(modelTypes: Array<ModelType> = []): boolean {
    const driveTypes = modelTypes.filter(
      (modelType) => modelType.driveType === VehicleDriveTypes.ALL_WHEEL_DRIVE
        || modelType.driveType === VehicleDriveTypes.FOUR_WHEEL_DRIVE
    )
    if (driveTypes?.length > 0) {
      this.selectedModelDriveType = driveTypes[0].driveType
      return true
    } else {
      return false
    }
  }

  getWorkingVehicleSlug(model: string) {
    return `${this.workingVehicle.year}-${this.workingVehicle.make}-${model}`
  }

  driveTrainChange(event: CustomEvent, isAWD: boolean) {
    if (!event.detail.checked) {
      this.isAWD = null
      return
    }

    this.isAWD = isAWD

    const yesOrNo = isAWD ? 'Yes' : 'No'
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: `${this.editOrAddVehicleDesc} ${events.vehicle.VEHICLE_SELECT_AWD_4WD} ${yesOrNo}`
    })
  }

  onBack() {
    this.store$.dispatch(clearActiveVehicle())
    this.store$.dispatch(setVehicleStep({ payload: { step: '' } }))
  }

  onNext() {
    this.isPristine = false
    this.errorMessage = null
    if (!this.selectedModel.value) {
      this.errorMessage = ERROR_SELECT_A_MODEL()
      return null
    } else if (!this.selectedModel.other && this.hasFourWheelDriveTypes && this.isAWD === null) {
      this.errorMessage = ERROR_INFORM_MODEL_DETAILS()
      return null
    }

    const payload = {
      model: this.selectedModel.value,
      ...(this.isAWD ? { driveType: this.selectedModelDriveType } : {})
    }

    if (this.selectedModel.other) {
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.vehicle.VEHICLE_OTHER_MODEL_SELECT
      })
      this.taggingService.setClickEvent(
        events.vehicle.VEHICLE_OTHER_MODEL_SELECT,
        events.vehicle.VEHICLE_PAGE_TYPE
      )
    }
    this.store$.dispatch(setVehicleModelAndType({ payload }))
  }

  handleDismissAWDModal() {
    this.isOpen = false
  }
}
