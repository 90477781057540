import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { CanComponentDeactivate } from '../types'
/*
 Known issues: https://github.com/angular/angular/issues/13586
*/
@Injectable({ providedIn: 'root' })
export class CanComponentDeactivateGuard {
  constructor(private router: Router) {}

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const observer = new Observable<boolean>((obs) => {
      switch (this.router.getCurrentNavigation().trigger) {
        case 'popstate':
          return obs.next(false)
        case 'imperative':
          return obs.next(true)
      }
    })

    return observer
  }
}
