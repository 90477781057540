import { createAction, props } from '@ngrx/store'
import { createAsyncConstants } from '../../shared/utils/createAsyncConstants'
import { VendorConfigurationMode } from './mode-configuration.types'

export const LOAD_MODE_CONFIGURATION = createAsyncConstants('LOAD_MODE_CONFIGURATION')

export const loadModeConfiguration = createAction(
  LOAD_MODE_CONFIGURATION.REQUEST,
  props<{ payload: string }>()
)

export const loadModeConfigurationSuccess = createAction(
  LOAD_MODE_CONFIGURATION.SUCCESS,
  props<{ payload: VendorConfigurationMode }>()
)

export const loadModeConfigurationFailure = createAction(
  LOAD_MODE_CONFIGURATION.FAILURE
)
