import { registerLocaleData } from '@angular/common'
import { APP_INITIALIZER, Injectable, LOCALE_ID } from '@angular/core'
import { loadTranslations } from '@angular/localize'
import { OEMBranding } from '../../modules/auth/auth.types'
import { RapService } from '../../modules/rap/rap.service'
import { SUPPORTED_LOCALES, I18nLocales } from './i18n.types'
import { getSupportedLocale, I18N_SUPPORTED_APP_IDS } from './i18n.utils'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { setDistanceUnit } from '../../modules/ui/ui.actions'
import { selectDistanceUnit } from '../../modules/ui/ui.selectors'
import { filter, map, take } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
class I18n {
  defaultLocale = SUPPORTED_LOCALES.EN_US
  localeByQueryParam = new URLSearchParams(window.location.search).get('locale')

  constructor(
    private store$: Store<AAAStore>,
    private rapService: RapService
  ) { }

  getNavigatorLocale = () => navigator.languages.find((lang) => lang.indexOf('-') || lang.indexOf('_'))

  detectLocale() {
    const localeByPriority =
      this.localeByQueryParam
      || localStorage.getItem('locale')
      || this.getNavigatorLocale()

    const rapId = this.rapService.getRapAppId() as OEMBranding
    if (I18N_SUPPORTED_APP_IDS.includes(rapId) || Boolean(this.localeByQueryParam) || localStorage.getItem('locale')) {
      return getSupportedLocale(localeByPriority)
    }
    return this.defaultLocale
  }

  async setLocale() {
    const locale = this.detectLocale()
    const [language, country] = locale.split('-')

    this.store$.pipe(
      select(selectDistanceUnit),
      take(1),
      filter((distanceUnit) => distanceUnit !== null),
      map(() => {
        const i18nLocale = I18nLocales().find((loc) => loc.locale === locale)
        this.store$.dispatch(setDistanceUnit({ payload: i18nLocale.defaultDistanceUnit }))
      })
    )
    // TODO change the loading strategy to prevent aaa-mobile app errors (Module not found: Error: Can't resolve)
    //  then move I18nModule providers from the app.component to the SharedModule
    const localeModule = await import(`/node_modules/@angular/common/locales/${language === 'en' ? language : locale}.mjs`)
    registerLocaleData(localeModule.default)

    const localeTranslationsModule = await import(`src/locale/messages${language === 'en' ? '' : '.' + locale}.json`)
    loadTranslations(localeTranslationsModule.default)
  }
}

function setLocale() {
  return {
    provide: APP_INITIALIZER,
    useFactory: (i18n: I18n) => () => i18n.setLocale(),
    deps: [I18n],
    multi: true,
  }
}

function setLocaleId() {
  return {
    provide: LOCALE_ID,
    useFactory: (i18n: I18n) => {
      const locale = i18n.detectLocale()
      localStorage.setItem('locale', locale)
      return locale
    },
    deps: [I18n],
  }
}

export const I18nModule = {
  setLocale,
  setLocaleId,
}
