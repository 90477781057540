<div class="dialog">
  <h1 i18n mat-dialog-title>Alert</h1>

  <div mat-dialog-content>
    <p class="font-size-medium">
      <ng-container i18n>If you have been in an accident please follow state laws </ng-container>
      <br class="show-desktop">
      <ng-container i18n>and guidelines for requesting emergency assistance.</ng-container>
    </p>
  </div>
  <div mat-dialog-actions>
    <app-link-button appCtaClickEvent="911 Click-To-Call" i18n class="show-mobile" href="tel: 911" (clickLink)="onClose()">
      CALL 9-1-1
    </app-link-button>
  </div>
  <button i18n-aria-label mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
