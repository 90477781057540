import { AbstractIconComponent } from '../abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-ev-connector-nema-520',
  templateUrl: './ev-connector-nema520.icon.html',
})
export class EvConnectorNema520IconComponent extends AbstractIconComponent {
  static icon = 'ev-connector-nema-520'
}
