import { createAction, props } from '@ngrx/store'


export const SET_CALL_STATUS_INTERVAL = 'SET_CALL_STATUS_INTERVAL'
export const SET_SESSION_TIMEOUT_INTERVAL = 'SET_SESSION_TIMEOUT_INTERVAL'
export const SET_CHECK_SESSION_TIMEOUT_INTERVAL = 'SET_CHECK_SESSION_TIMEOUT_INTERVAL'
export const SET_ALERT_SESSION_TIMEOUT_INTERVAL = 'SET_ALERT_SESSION_TIMEOUT_INTERVAL'
export const SET_FIRST_CALL_STATUS_TIMEOUT = 'SET_FIRST_CALL_STATUS_TIMEOUT'
export const SET_CREATE_CALL_TIMEOUT = 'SET_CREATE_CALL_TIMEOUT'
export const SET_GET_ACTIVE_CALLS_TIMEOUT = 'SET_GET_ACTIVE_CALLS_TIMEOUT'

export const setCallStatusInterval = createAction(
  SET_CALL_STATUS_INTERVAL,
  props<{ payload: number }>()
)

export const setSessionTimeoutInterval = createAction(
  SET_SESSION_TIMEOUT_INTERVAL,
  props<{ payload: number }>()
)

export const setCheckSessionTimeoutInterval = createAction(
  SET_CHECK_SESSION_TIMEOUT_INTERVAL,
  props<{ payload: number }>()
)

export const setAlertSessionTimeoutInterval = createAction(
  SET_ALERT_SESSION_TIMEOUT_INTERVAL,
  props<{ payload: number }>()
)

export const setFirstCallStatusTimeout = createAction(
  SET_FIRST_CALL_STATUS_TIMEOUT,
  props<{ payload: number }>()
)

export const setCreateCallTimeout = createAction(
  SET_CREATE_CALL_TIMEOUT,
  props<{ payload: number }>()
)

export const setGetActiveCallsTimeout = createAction(
  SET_GET_ACTIVE_CALLS_TIMEOUT,
  props<{ payload: number }>()
)
