import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'
import { Step, StepStatus, StepTypes } from '../../modules/ui/ui.types'

@Component({
  selector: 'icon-progress-check',
  templateUrl: './progress-check.icon.html',
})
export class ProgressCheckIconComponent extends AbstractIconComponent {
  static icon = 'progress-check'
  color = '#E1E8EE'
  @Input() step: Step

  ngOnInit(): void {
    if (this.step.status === StepStatus.DONE) {
      switch (this.step.url) {
        case StepTypes.BREAKDOWN_LOCATION:
          this.color = '#B639A9'
          break;
        case StepTypes.ISSUE:
          this.color = '#D5334A'
          break;
        case StepTypes.VEHICLE:
          this.color = '#E7522C'
          break;
        case StepTypes.TOWING:
          this.color = '#F58024'
          break;
        case StepTypes.SUBMIT:
          this.color = '#F47D24'
          break;
      }
    }
  }
}
