import { Component, HostListener } from '@angular/core'
import { AbstractComponent } from './abstract.component'
import { isMobileSize } from './utils/windowSize'

@Component({
  template: ''
})
export class AbstractResponsiveComponent extends AbstractComponent {
  isMobile: boolean

  constructor(){
    super()
    this.onResize()
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = isMobileSize()
  }
}
