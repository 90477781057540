import { Component, Input } from '@angular/core';
import { Make } from '../vehicle.types';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-vehicle-make-logo',
  standalone: true,
  imports: [NgIf],
  templateUrl: './vehicle-make-logo.component.html',
  styleUrl: './vehicle-make-logo.component.scss'
})
export class VehicleMakeLogoComponent {
  @Input() make: Make;
}
