import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Make } from '../vehicle.types';
import { JsonPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ButtonComponent, IconComponent } from '@aaa-mobile/shared-components';
import { IonCol, IonGrid, IonRippleEffect, IonRow } from '@ionic/angular/standalone';
import { VehicleMakeLogoComponent } from '../vehicle-make-logo/vehicle-make-logo.component';

const VEHICLE_MAKE_TILES_LABEL = () => $localize`Select vehicle make`

@Component({
  selector: 'app-vehicle-make-tiles',
  standalone: true,
  imports: [
    JsonPipe,
    NgForOf,
    NgIf,
    ButtonComponent,
    IonRippleEffect,
    IonGrid,
    IonRow,
    IonCol,
    IconComponent,
    VehicleMakeLogoComponent,
    NgClass
  ],
  templateUrl: './vehicle-make-tiles.component.html',
  styleUrl: './vehicle-make-tiles.component.scss'
})
export class VehicleMakeTilesComponent implements AfterViewInit {
  @Input() makes: Make[] = [];
  @Input() selectedMake: string;
  @Output() select = new EventEmitter<string>();

  vehicleMakeTilesLabel = VEHICLE_MAKE_TILES_LABEL()

  constructor(private element: ElementRef) {}

  onMakeSelect(make: Make) {
    this.select.emit(make.label);
  }

  ngAfterViewInit() {
    this.focusFirstElement()
  }

  private focusFirstElement() {
    const container = this.element.nativeElement as HTMLElement;
    const children = container.getElementsByTagName('button')

    if (children && children.length) {
      children[0].focus()
    }
  }
}
