<section>
  <div class="progress-bar">
    <div class="progress-bar__bar">
      <div class="progress" [ngStyle]="{'width': progress + '%'}">
        <app-bump-out-animation [isBumpOut]="isStepCompleted"></app-bump-out-animation>
      </div>
      <!-- <div class="progress-bar__steps">
        <ng-container *ngFor="let step of progressSteps">
          <icon-progress-check [step]="step"></icon-progress-check>
        </ng-container>
       </div> -->
    </div>

  </div>
</section>
