<app-header-redesign>
  <app-back-button (aaaClick)="onBack()" slot="start" />
  <app-title i18n>Vehicle Color</app-title>
</app-header-redesign>

<ion-content>
  <ion-grid class="ion-margin ion-no-padding">
    <aaa-text
      i18n
      size="subheadline"
      weight="regular"
      color="subtle"
      i18n-aria-label
      class="ion-margin-bottom"
      aria-label="Select your vehicle's color"
      role="heading"
      aria-level="1"
      aria-live="polite">
      Select your vehicle's color
    </aaa-text>

    <app-vehicle-header-step></app-vehicle-header-step>

  </ion-grid>

  <aaa-vehicle-color-list
    [color]="selectedColor"
    (selectedColor)="selectColor($event)"
    [isLoading]="isLoading$ | async"
  ></aaa-vehicle-color-list>
</ion-content>