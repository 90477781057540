import { Pipe, PipeTransform } from '@angular/core'
import { DateTime } from 'luxon'

export function formatDate(date: string, ...formats: string[]) {
  const formatedDate = DateTime.fromFormat(date, formats[0]).toFormat(
    formats[1]
  )
  return formatedDate
}

@Pipe({ name: 'customDate' })
export class CustomDatePipe implements PipeTransform {
  transform(customDate: string, ...args: any[]) {
    return formatDate(customDate, ...args)
  }
}
