import { Injectable } from '@angular/core'
import { AdobeAnalyticsParams, ScChannel } from '../tagging.types'
import { awaitHoistedFunction } from '../../../shared/utils/awaitHoistedFunction'
import { ConfigService } from '../../config/config.service'
import { getCookie } from '../../../shared/utils/cookies'
import { AppId } from '../../auth/auth.types'

@Injectable({
  providedIn: 'root',
})
export class AdobeAnalyticsService {
  constructor(private configService: ConfigService) { }

  getAdobeChannel(appId: string, channel: string) {
    if (channel) {
      return channel.toUpperCase()
    }
    if(Object.values(ScChannel).map((channel) => channel.toString()).includes(appId)) {
      return appId.toUpperCase()
    }
    return ScChannel.DIRECT
  }

  transmitAdobeAnalyticsEvents({ eventName, eventAttributes, directAttributes }: AdobeAnalyticsParams) {
    if (getCookie('AAA_AppId') === AppId.MONITOR) {
      return
    }
    if (this.configService.getConfig().loggingCallback) {
      this.configService.getConfig().loggingCallback({ eventName, eventAttributes, directAttributes })
    } else {
      return awaitHoistedFunction<Object, Function>({
        ref: (window as any).adobeAnalytics_logEvent,
        target: window,
        path: ['adobeAnalytics_logEvent'],
        callback: () => (window as any).adobeAnalytics_logEvent(eventName, eventAttributes, directAttributes),
        error: new Error(
          `Unable to load Adobe Logging Commons library! Got: (${typeof (window as any).adobeAnalytics_logEvent})`
        )
      })
    }
  }
}