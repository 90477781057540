<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
  <g fill="none" fill-rule="evenodd">
    <path
      d="M12.317 2.458a1.5 1.5 0 00-2.634 0l-8.63 15.824A1.5 1.5 0 002.368 20.5h17.262a1.5 1.5 0 001.317-2.218L12.317 2.458z"
      stroke="#FFF" fill="#D81E05" />
    <path
      d="M12 7.5v5.833a1 1 0 11-2 0V7.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zM10 17a.995.995 0 01.602-.921A.988.988 0 0111 16c.14 0 .27.026.392.079.121.053.227.124.318.213A.969.969 0 0112 17c0 .14-.026.272-.077.395a.961.961 0 01-.531.529c-.121.05-.252.076-.392.076a1.02 1.02 0 01-.398-.076.962.962 0 01-.525-.53A1.016 1.016 0 0110 17z"
      fill="#FFF" fill-rule="nonzero" />
  </g>
</svg>
