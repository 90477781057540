import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FUEL_TYPES, PaceSetterDetails, PaceSetterList, PaceSetterOptions, PaceSetterSituation } from '../issue.types'
import { Observable } from 'rxjs'
import { select, Store } from '@ngrx/store'
import {
  selectActivePaceSetterDetails,
  selectActivePaceSetterSelection,
  selectActivePaceSetterSituation,
  selectActivePaceSetterSituationName,
  selectActivePaceSetterStep,
} from '../issue.selectors'
import { AAAStore } from '../../../store/root-reducer'
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component'
import { selectEligibility, selectIsRapUser } from '../../auth/auth.selectors';
import { TaggingService } from '../../tagging/tagging.service';
import { paceSetterNext } from '../../issue/issue.actions'
import { openErrorDialog, openMessageDialog } from '../../ui/ui.actions'
import { RapService } from '../../rap/rap.service'
import events from '../../tagging/events'
import { addPartialCallRequest } from '../../dashboard/calls.actions'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { capitalize } from '../../../shared/utils/capitalize'
import { AdobeEventTypes } from '../../tagging/tagging.types'
import { Eligibility } from '../../auth/eligibility.types'
import { ErrorDialogTypes, MessageDialogTypes } from '../../ui/ui.types'

const DIALOG_TITLE_ALERT = () => $localize`Alert`
const DIALOG_BODY_ALERT = () => $localize`To continue your request for Roadside Assistance, please contact us at`

@Component({
  selector: 'app-situation-editor',
  templateUrl: './situation-editor.component.html',
  styleUrls: ['./situation-editor.component.scss'],
})
export class SituationEditorComponent
  extends AbstractResponsiveComponent
  implements OnInit {
  details$: Observable<PaceSetterDetails> = this.store$.pipe(
    select(selectActivePaceSetterDetails)
  )
  isRapUser$: Observable<boolean> = this.store$.pipe(
    select(selectIsRapUser)
  )

  activeStep$: Observable<number> = this.store$.pipe(
    select(selectActivePaceSetterStep)
  )

  activeSelections$: Observable<PaceSetterList[]> = this.store$.pipe(
    select(selectActivePaceSetterSelection)
  )

  activeSituation$: Observable<PaceSetterSituation> = this.store$.pipe(
    select(selectActivePaceSetterSituation)
  )

  activePaceSetterSelection$: Observable<PaceSetterList[]> = this.store$.pipe(
    select(selectActivePaceSetterSelection)
  )
  activePaceSetterSelection: PaceSetterList[]

  activeSituationName$: Observable<string> = this.store$.pipe(
    select(selectActivePaceSetterSituationName)
  )

  eligibility$: Observable<Eligibility> = this.store$.pipe(
    select(selectEligibility)
  )
  eligibility: Eligibility

  @Input() template: PaceSetterOptions
  @Input() isOpen = false
  @Output() close: EventEmitter<void> = new EventEmitter()

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService,
    private rapService: RapService,
  ) {
    super()

    this.eligibility$.subscribe((eligibility: Eligibility) => this.eligibility = eligibility)

    this.activePaceSetterSelection$.subscribe((activePaceSetterSelection: PaceSetterList[]) => {
      this.activePaceSetterSelection = activePaceSetterSelection
    })
  }

  handleNext($event, activeSituationName): void {
    const actionData = {
      cta_value: capitalize(`${activeSituationName} ${events.issue.ISSUE_PAGE_DETAILS_NEXT_CLICK}`),
      issue_type: activeSituationName
    }
    this.adobeEventService.sendEvent({
      eventValue: actionData.cta_value,
      eventName: AdobeEventTypes.CTA
    }, actionData.issue_type, actionData)

    this.taggingService.setClickEvent(
      `${capitalize(activeSituationName)} ${events.issue.ISSUE_PAGE_DETAILS_NEXT_CLICK}`,
      events.issue.ISSUE_PAGE_TYPE
    )

    const activePaceSetter = this.activePaceSetterSelection.length ? this.activePaceSetterSelection[0] : null
    const firstPaceSetterCode = activePaceSetter && activePaceSetter.members.length ? activePaceSetter.members[0] : null
    if (firstPaceSetterCode?.name === FUEL_TYPES.HYDROGEN_ALT) {
      this.store$.dispatch(
        openMessageDialog({
          payload: {
            type: MessageDialogTypes.CUSTOM_PHONE,
            title: DIALOG_TITLE_ALERT(),
            content: DIALOG_BODY_ALERT(),
          },
        })
      )
    } else if(this.rapService.isRapNotEligible(firstPaceSetterCode, this.eligibility)) {
      this.store$.dispatch(openErrorDialog({
        payload: {
          type: ErrorDialogTypes.NOT_ELIGIBLE
        }
      }))
    } else {
      this.store$.dispatch(paceSetterNext())
      this.store$.dispatch(addPartialCallRequest())
    }
  }

  ngOnInit() {
    const pageLoadTagging = this.template?.pageLoadTagging
    if (pageLoadTagging) {
      this.taggingService.setPageLoadEvent({ pageType: pageLoadTagging.pageType, pageName: pageLoadTagging.pageName })
    }
  }

  hasActivePaceSetterSelection(): boolean {
    return this.activePaceSetterSelection?.length > 0 && this.activePaceSetterSelection[0].members?.length > 0
  }

}
