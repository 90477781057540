import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { AbstractComponent } from '../../../shared/abstract.component'
import { openPromptDialog } from '../../ui/ui.actions'
import { PromptDialogTypes } from '../../ui/ui.types'
import { selectIsActiveBatteryCall } from '../calls-statuses/call-status.selectors'
import { selectIsLoading } from '../../ui/loading/loading.selectors'
import { BATTERY_QUOTES, batteryQuoteRequest } from '../../quotes/quotes.action'
import { TaggingService } from '../../tagging/tagging.service'
import { Observable } from 'rxjs'
import { selectHasBatteryQuotes, selectIsQuoteNotified, selectQuotePayload, } from '../../quotes/quotes.selectors'
import { BatteryQuotePayload } from '../../quotes/quotes.types'
import { wait } from '../../../shared/utils/wait'
import { selectHasBreakdownLocationPostalCode } from '../../location/location.selectors';
import events from '../../tagging/events'
import { AdobeEventTypes } from '../../tagging/tagging.types'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { selectIsRapUser } from '../../auth/auth.selectors';

@Component({
  selector: 'app-battery-quotes',
  templateUrl: './battery-quotes-banner.component.html',
  styleUrls: ['./battery-quotes-banner.component.scss'],
})
export class BatteryQuotesBannerComponent
  extends AbstractComponent
  implements OnInit {
  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }
  hasBatteryQuotes$: Observable<boolean> = this.store$.select(
    selectHasBatteryQuotes
  )
  isLoading$: Observable<boolean> = this.store$.select(
    selectIsLoading(BATTERY_QUOTES.ACTION)
  )
  isActiveBatteryCall$: Observable<boolean> = this.store$.select(
    selectIsActiveBatteryCall
  )
  isRapUser$: Observable<boolean> = this.store$.select(
    selectIsRapUser
  )
  isBatteryQuoteNotified$: Observable<boolean> = this.store$.select(
    selectIsQuoteNotified
  )
  hasBreakdownLocationPostalCode$: Observable<boolean> = this.store$.select(
    selectHasBreakdownLocationPostalCode
  )
  batteryQuotesPayload$: Observable<BatteryQuotePayload> =
    this.store$.select(selectQuotePayload)
  hasAttemptedRequest = false
  hasBatteryQuotes = false
  isHidden = false
  payload: BatteryQuotePayload
  classNames = 'bordered'

  async hide() {
    this.classNames = `${this.classNames} hiding`

    await wait(800)
    this.isHidden = true
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.batteryQuotesPayload$.subscribe((payload) => {
        this.payload = payload
      }),
      this.hasBatteryQuotes$.subscribe((hasBatteryQuotes) => {
        this.hasBatteryQuotes = hasBatteryQuotes
      })
    )
  }

  showBatteryQuotes() {
    if (!this.hasBatteryQuotes) {
      this.taggingService.setClickEvent(
        events.dashboard.SHOW_BATTERY_QUOTES_CLICK,
        events.dashboard.SERVICE_TRACKING_PAGE_TYPE
      )

      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.dashboard.SHOW_BATTERY_QUOTES_CLICK
      })

      this.store$.dispatch(batteryQuoteRequest({ payload: this.payload }))
      this.hasAttemptedRequest = true
    } else {
      this.store$.dispatch(
        openPromptDialog({
          payload: {
            type: PromptDialogTypes.BATTERY_QUOTES,
            panelClass: 'battery-quotes-banner-modal',
          },
        })
      )
    }
  }
}
