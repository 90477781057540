<ng-container *ngFor="let item of generateItems()">
  <div class="borderless-button skeleton"
    [tabIndex]="tabIndex">
    <div class="row">
      <div class="box one"></div>
      <div class="box one-second"></div>
    </div>
    <div class="row">
      <div class="box two"></div>
    </div>
    <div class="row">
      <div class="box three"></div>
    </div>
    <div class="row">
      <div class="box four"></div>
    </div>
  </div>
</ng-container>
