<ng-template #old>
  <ng-container *ngFor="let problem of problems; let index = index;">
    <app-problem-button [tabIndex]="102 + index"
      class="what-happened_button what-happened_primary-button"
      [first]="index === 0"
      [problemType]="problem"
      [overrideProblem]="shouldOverrideProblem(problem)"
      (clickProblem)="problemSelected($event)">
    </app-problem-button>
  </ng-container>
</ng-template>
<ng-container *ngIf="isRedesign else old">
  <div class="issue-button-container">
    <ng-container *ngFor="let problem of problems; let index = index;">
      <app-issue-button [tabIndex]="102 + index"
        class="what-happened-redesign_button what-happened-redesign_primary-button"
        [first]="index === 0"
        [problemType]="problem"
        [overrideProblem]="shouldOverrideProblem(problem)"
        (clickProblem)="problemSelected($event)">
      </app-issue-button>
    </ng-container>
  </div>
</ng-container>
