import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { selectVehicleYears, selectWorkingVehicle } from '../vehicle.selectors'
import { Observable } from 'rxjs'
import { requestVehicleYears, VEHICLE_YEARS } from '../vehicle.actions'
import { selectIsLoading } from '../../ui/loading/loading.selectors'
import { VehicleYearsListComponent } from '../vehicle-years-list/vehicle-years-list.component';
import { AsyncPipe } from '@angular/common';
import { IconComponent, TextComponent } from '@aaa-mobile/shared-components';
import { VehicleEditState, VehicleState } from '../vehicle.reducer';
import { IonButton, IonButtons, IonContent, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { HeaderRedesignComponent } from '../../../shared/layouts/header-redesign/header/header.component'
import { BackButtonComponent } from '../../../shared/layouts/header-redesign/back-button/back-button.component'
import { TitleComponent } from '../../../shared/layouts/header-redesign/title/title.component'

@Component({
  selector: 'app-vehicle-years-step',
  templateUrl: './vehicle-years-step.component.html',
  styleUrls: ['./vehicle-years-step.component.scss'],
  standalone: true,
  imports: [
    VehicleYearsListComponent,
    TextComponent,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle,
    IonContent,
    IconComponent,
    AsyncPipe,
    HeaderRedesignComponent,
    BackButtonComponent,
    TitleComponent
  ]
})
export class VehicleYearsStepComponent implements OnInit {

  @Input() tabIndexPosition = 0;
  @Input() isOpen = false;
  @Output() selected = new EventEmitter<number>()
  @Output() back = new EventEmitter<VehicleState>()

  isLoading$: Observable<any> = this.store$.pipe(
    select(selectIsLoading(VEHICLE_YEARS.ACTION))
  )
  years$: Observable<string[]> = this.store$.pipe(select(selectVehicleYears))
  vehicle$: Observable<VehicleEditState> = this.store$.pipe(select(selectWorkingVehicle))

  constructor(private store$: Store<AAAStore>) {}

  ngOnInit() {
    this.store$.dispatch(requestVehicleYears())
  }

  yearChange(year: string) {
    this.selected.emit(Number(year))
  }

  handleBack() {
    this.back.emit()
  }
}
