import { createAction, props } from '@ngrx/store'
import { createAsyncConstants } from '../../shared/utils'
import { RouteDistanceRequest, RouteDistanceResponse } from './route-distance.types'

export const ROUTE_DISTANCE_LOAD = createAsyncConstants('ROUTE_DISTANCE_LOAD')

export const requestRouteDistance = createAction(
  ROUTE_DISTANCE_LOAD.REQUEST,
  props<{ payload: RouteDistanceRequest }>()
)

export const routeDistanceSuccess = createAction(
  ROUTE_DISTANCE_LOAD.SUCCESS,
  props<{ payload: RouteDistanceResponse }>()
)

export const notifyRouteDistanceLoadFailure = createAction(ROUTE_DISTANCE_LOAD.FAILURE)
