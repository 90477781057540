import { Address, BooleanAnswer, Indicator, OperatingDay, Phone, } from '../../../shared/types'
import { LocationArea } from '../google-geocode/types'
import { GenericCoordinates, LocationWithMarker } from '../location.types'

export interface DRRCoordinates {
  lat: string
  lon: string
}

export interface AARGenericData {
  type: string // TODO convert to enum
  content: string
}

export interface PublicAARAddress {
  stateProv: {
    code: string
  }
  cityName: string
  cityCode: string
  postalCode: string
  countryName: {
    code: string
    content: string
  }
  type: string
  addressLine: string
}

export interface PublicAARData {
  loccode: string
  images: Object
  addresses: {
    address: PublicAARAddress
  }
  sortName: string
  brands: {}
  serviceProviderCode: string
  operatingDays: {
    operatingDay: OperatingDay[]
  }
  phones: {
    phone: AARGenericData
  }
  vehicles: {
    vehicle: AARGenericData[]
  }
  services: {
    service: AARGenericData[]
  }
  type: string
  indicators: {
    napaInd: BooleanAnswer
    sycsStatusInd: BooleanAnswer
    onlineAppointmentsInd: BooleanAnswer
  }
  distanceTo: string
  discountLabor: string
  activityid: string
  emailAddress: string
  serviceOffers: {
    serviceOffer: string[] // TODO convert to enum
  }
  name: string
  links: {
    urlAddress: string
  }
  id: string
  position: GenericCoordinates
}

export interface AARData extends GenericCoordinates {
  id: number
  type: string
  name: string
  serviceProviderCode: string
  distanceTo: number
  indicators: Indicator
  address: Address
  phone: Phone
  operatingDays: OperatingDay[]
  serviceOffers: string[]
  vehicles: AARVehicle[]
  activityid: string
  loccode: string
  services?: AARGenericData[]
  ratingSummary?: RatingSummary
  evStationDetails?: EvStationDetails
  distance?: number
  priority?: boolean
  emailAddress?: string
}

export interface EvStationDetails {
  chargeLevel: ChargeLevel
  connectorTypes: ConnectorTypes[]
  lastUpdated: Date
  stationNotes: string
}

export interface ChargeLevel {
  dcFast: number
  level1: number
  level2: number
}

export enum ConnectorTypes {
  NEMA1450 = 'NEMA 14-50',
  NEMA515 = 'NEMA 5-15',
  NEMA520 = 'NEMA 5-20',
  J1772 = 'J1772',
  J1772COMBO = 'CCS',
  CHADEMO = 'CHAdeMO',
  TESLA = 'Tesla',
  UNKNOWN = 'Unknown',
}

export interface FacilitiesDisplay {
  aarData: AARData[]
  fitAll: boolean
  markers: LocationWithMarker[]
  expandedResult: boolean
}

export interface AARDetail extends AARData {
  emailAddress: string
  webAddress: string
  discount: {
    offerDescription: string
    offerRestriction: string
  }
  compressedHours?: any[]
}

export interface AARVehicle {
  type: string
  value: string
}

export interface RatingSummary {
  businessId: string
  reviewCount: number
  ratingAvg: number
  recommendPct: number
}

export interface AARRequestParams extends LocationArea {
  make: string
  vehicleSlug: string
  searchType: SearchType
}

export const AAR_TYPES = ['AAR', 'COR', 'SPC', 'DUA']
export const COR_TYPE = 'COR'
export const RATING_SUMMARY_FIELD = 'ratingSummary'

export const CUSTOM_TOW_DISTANCE_LIMIT = 2
export const CUSTOM_TOW_LIMIT_NEARBY = 3
export const CUSTOM_TOW_MIN_RATING = 4

export interface EvStationDetailsApiModel extends Omit<EvStationDetails, 'connectorTypes'> {
  connectorTypes: string[]
}

export interface AarDataApiModel extends Omit<AARData, 'evStationDetails'> {
  evStationDetails: EvStationDetailsApiModel
}

export enum SearchType {
  BREAKDOWN_LOCATION,
  CUSTOM_LOCATION,
}
