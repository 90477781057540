import { GoogleCoordinates } from './google-geocode/types';
import { MapBoundsPadding } from './location.types';

export const DEFAULT_LAT = 39.828272
export const DEFAULT_LNG = -98.579469
export const INITIAL_COORDS: GoogleCoordinates = {
  lat: DEFAULT_LAT,
  lng: DEFAULT_LNG,
}
export const CHARACTER_LIMIT_FACILITY = 50
export const CHARACTER_LIMIT_VEHICLE_COLOR = 7
export const GPS_LOCATION_DISTANCE_WARN_LIMIT = 5
export const NO_FACILITIES_REQUEST_AREA_DURATION = 15_000
export const MAP_BOTTOM_PADDING_CUSTOM_DESTINATION = 210
export const MAP_BOTTOM_PADDING_CONFIRMED_LOCATION = 400
export const MAP_BOTTOM_PADDING_AAR = 310
export const MAP_BOTTOM_PADDING_EV_STATION = 350
export const MAP_BOTTOM_PADDING_ADDITIONAL_CHARGE_LEVEL = 70
export const MAP_BOUNDS_PADDING: MapBoundsPadding = {
  top: 64,
  left: 32,
  right: 32,
  bottom: 0
}
export const MAP_BOUNDS_PADDING_REDESIGN: MapBoundsPadding = {
  top: 96,
  left: 32,
  right: 32,
  bottom: 24
}
