@if (isRedesign) {
  <ng-container *ngIf="(isActiveBatteryCall$ | async) && !isHidden && !(isRapUser$ | async)">
    <ion-grid class="ion-padding-horizontal">
      <ion-row class="ion-align-items-center">
        <ng-container *ngIf="(hasBreakdownLocationPostalCode$ | async) && (hasBatteryQuotes || (!hasAttemptedRequest || (isLoading$ | async))); else noQuotes">
          <ion-col size="auto">
            <app-img src="assets/drrweb-lib/images/aaa-battery.png"
                     width="35"
                     alt="Battery Quotes"
                     i18n-alt></app-img>
          </ion-col>
          <ion-col class="ion-padding-start">
            <aaa-text i18n size="footnote" color="text" weight="bold">AAA Battery Quotes</aaa-text>
          </ion-col>
          <ion-col size="auto">
            <aaa-button
              class="get-quotes-btn"
              size="medium"
              variant="primary"
              [loading]="isLoading$ | async"
              (click)="showBatteryQuotes()"
              i18n>Get Quotes</aaa-button>
          </ion-col>
        </ng-container>
        <ng-template #noQuotes>
          <ion-col size="auto">
            <app-img src="assets/drrweb-lib/images/aaa-battery.png"
                     width="35"
                     alt="No battery quotes"
                     i18n-alt></app-img>
          </ion-col>
          <ion-col class="ion-padding-start">
            <aaa-text i18n size="footnote" color="text">No quotes available for your vehicle</aaa-text>
          </ion-col>
          <ion-col size="auto">
            <aaa-button
              size="medium"
              variant="outline"
              (click)="hide()"
              i18n>Close</aaa-button>
          </ion-col>
        </ng-template>
      </ion-row>
    </ion-grid>
  </ng-container>
} @else {
  <ng-container *ngIf="(isActiveBatteryCall$ | async) && !isHidden">
    <ng-container *ngIf="(hasBreakdownLocationPostalCode$ | async) && (hasBatteryQuotes || (!hasAttemptedRequest || (isLoading$ | async))); else noQuotes">
      <app-banner
        animated="true"
        (click)="showBatteryQuotes()"
        [classNames]="classNames"
      >
        <div class="battery-quotes-banner__description">
          <app-img
            src="assets/drrweb-lib/images/aaabattery.JPG"
            class="battery-quotes-banner__icon"
            alt="Battery Quotes"
            i18n-alt
          ></app-img>
          <span class="battery-quotes-banner__title" i18n>
            AAA Battery Quotes
          </span>
        </div>
        <app-button
          [loading]="(isLoading$ | async)"
          size="condensed"
          i18n
        >
          Get Quotes
        </app-button>
      </app-banner>
    </ng-container>
    <ng-template #noQuotes>
      <app-banner
        (click)="hide()"
        [classNames]="classNames"
      >
        <div class="battery-quotes-banner__description">
          <app-img
            src="assets/drrweb-lib/images/aaabattery.JPG"
            class="battery-quotes-banner__icon"
            alt="No battery quotes"
            i18n-alt
          ></app-img>
          <span class="battery-quotes-banner__title" i18n>
            No quotes available for your vehicle
          </span>
        </div>
        <app-button
          inverted="true"
          size="condensed"
          i18n
        >
          Close
        </app-button>
      </app-banner>
    </ng-template>
  </ng-container>
}
