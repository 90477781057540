<div
  #customMarker
  [ngClass]="styleClass"
  [hidden]="!display"
  (click)="handleMarkerClicked($event)"
  (touchstart)="handleMarkerTouchStart($event)"
  (mousedown)="handleMarkerTouchStart($event)"
  (mouseEnter)="hoverElement()"
  (mouseLeave)="hoverElement()"
  [tabIndex]="tabIndex"
  [style.z-index]="tabIndex"
  [attr.aria-label]="(data?.label || locationValue?.address || '') + ' Map Marker'"
>
  <ng-content></ng-content>

  <!-- tooltip -->
  <div *ngIf="data?.active && data?.label" class="info-tootlip">
    <p>{{ data?.label }}</p>
  </div>

  <!-- address/notes info -->
  <div
    *ngIf="data?.showAddress"
    class="marker-address-container"
  >
    <p class="marker-address" *ngIf="locationValue?.address">
      {{ locationValue?.address }}
    </p>
    <p class="marker-address-2-lines" *ngIf="!locationValue?.address">
      <label *ngIf="locationValue?.addressLine1">{{ locationValue?.addressLine1 }}</label>
      <label *ngIf="locationValue?.addressLine2">{{ locationValue?.addressLine2 }}</label>
    </p>
    <hr *ngIf="showNotes" />
    <p class="marker-notes" *ngIf="showNotes">
      {{ locationValue?.notes }}
    </p>
  </div>
</div>
