import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-pin-map',
  templateUrl: './pin-map.icon.html',
})
export class PinMapIconComponent extends AbstractIconComponent {
  static icon = 'pin-map'
}
