import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'
import events from '../../../tagging/events'
import { OEMBranding } from '../../../auth/auth.types'
import { getCookie } from '../../../../shared/utils/cookies'
import { ClosableDialog } from '../closable-dialog'

@Component({
  selector: 'app-membership-issues-dialog-component',
  templateUrl: './membership-issues-dialog.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class MembershipIssuesDialogComponent extends ClosableDialog {
  pageType = events.auth.MEMBER_ISSUE_PAGE_TYPE

  isRapUser = (() => {
    const appId = getCookie('AAA_AppId');
    if (appId in OEMBranding) {
      return true
    }

    return false
  })()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    super(data)
  }

  onCallAAAClick() {
    this.onClose()
  }

}
