import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AbstractComponent } from '../../../shared/abstract.component';
import { AAAStore } from '../../../store/root-reducer';
import { selectIsRapUser, selectIsVehicleChangeAllowed, selectModeConfiguration } from '../../auth/auth.selectors';
import { VendorConfigurationMode } from '../../auth/mode-configuration.types';
import { addPartialCallRequest } from '../../dashboard/calls.actions';
import events from '../../tagging/events';
import { TaggingService } from '../../tagging/tagging.service';
import { setProblemTooltip } from '../../ui/ui.actions';
import { selectTelematics } from '../../ui/ui.selectors';
import { setPaceSetterSituation } from '../issue.actions';
import { PACE_SETTER_SITUATION_TYPES, PaceSetterSituation } from '../issue.types';
import { selectIssueConfig } from '../../servicing-club/servicing-club.selectors'
import { selectMemberActiveVehicle } from '../../member/member.selectors'
import { VehicleData } from '../../member/member.types'

const LABEL_ACCIDENT = () => $localize`Accident`
const DESCRIPTION_ACCIDENT = () => $localize`If this is an emergency, please call 911. If not, an expert technician can help transport your vehicle to the nearest authorized auto repair facility.`
const LABEL_BATTERY = () => $localize`Battery Test Or Replace`
const LABEL_BATTERY_RAP = () => $localize`Battery Issue`
const DESCRIPTION_BATTERY = () => $localize`We can test your battery and jump-start or replace it with a new one on the spot. Please note that battery testing replacement may not be available in all areas.`
const LABEL_WONT_START = () => $localize`Car Won't Start`
const DESCRIPTION_WONT_START = () => $localize`Does your car not start, and are you having trouble identifying the problem? We can help.`
const LABEL_TIRE = () => $localize`Flat Tire`
const DESCRIPTION_TIRE = () => $localize`Depending on the damage to your tire, we\'ll either install your usable spare, re-inflate your tire, or tow your vehicle.`
const LABEL_LOCKED = () => $localize`Locked Out`
const DESCRIPTION_LOCKED = () => $localize`Locked out? We\'ll send someone to help you get back into your vehicle.`
const LABEL_TOW = () => $localize`Need A Tow`
const DESCRIPTION_TOW = () => $localize`An expert technician can help transport your vehicle to the nearest authorized auto repair facility.`
const LABEL_FUEL = () => $localize`Fuel/EV Charge`
const DESCRIPTION_FUEL = () => $localize`Need a charge or have you run out of fuel? We\'ll help to get you to the nearest service station. Diesel fuel delivery and charging capabilities available in some locations.`
const LABEL_STUCK = () => $localize`Stuck`
const DESCRIPTION_STUCK = () => $localize`If your vehicle is stuck, a technician will help get you back on the road.`

export const PRIMARY_PACE_SETTER_SITUATIONS = (): Array<PaceSetterSituation> => [
  {
    name: PACE_SETTER_SITUATION_TYPES.BATTERY_ISSUE,
    label: LABEL_BATTERY(),
    icon: 'assets/drrweb-lib/images/icons/battery.svg',
    iconName: 'drr-battery-out',
    description: DESCRIPTION_BATTERY(),
  },
  {
    name: PACE_SETTER_SITUATION_TYPES.FLAT_TIRE,
    label: LABEL_TIRE(),
    icon: 'assets/drrweb-lib/images/icons/flat tire.svg',
    iconName: 'drr-flat-tire',
    description: DESCRIPTION_TIRE(),
  },
  {
    name: PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL,
    label: LABEL_FUEL(),
    icon: 'assets/drrweb-lib/images/icons/fuel.svg',
    iconName: 'drr-gas-ev',
    description: DESCRIPTION_FUEL(),
  },
  {
    name: PACE_SETTER_SITUATION_TYPES.LOCKED_OUT,
    label: LABEL_LOCKED(),
    icon: 'assets/drrweb-lib/images/icons/locked out.svg',
    iconName: 'drr-locked',
    description: DESCRIPTION_LOCKED(),
  },
  {
    name: PACE_SETTER_SITUATION_TYPES.STUCK,
    label: LABEL_STUCK(),
    icon: 'assets/drrweb-lib/images/icons/stuck.svg',
    iconName: 'drr-stuck',
    description: DESCRIPTION_STUCK(),
  },
  {
    name: PACE_SETTER_SITUATION_TYPES.ACCIDENT,
    label: LABEL_ACCIDENT(),
    icon: 'assets/drrweb-lib/images/icons/accident.svg',
    iconName: 'drr-accident',
    description: DESCRIPTION_ACCIDENT(),
  },
]

export const SECONDARY_PACE_SETTER_SITUATIONS = (): PaceSetterSituation[] => [
  {
    name: PACE_SETTER_SITUATION_TYPES.CAR_WONT_START,
    label: LABEL_WONT_START(),
    icon: 'assets/drrweb-lib/images/icons/car wont start.svg',
    iconName: 'drr-wont-start',
    description: DESCRIPTION_WONT_START(),
  },
  {
    name: PACE_SETTER_SITUATION_TYPES.NEED_A_TOW,
    label: LABEL_TOW(),
    icon: 'assets/drrweb-lib/images/icons/tow-small.svg',
    iconName: 'drr-tow',
    description: DESCRIPTION_TOW(),
  },
]


@Component({
  selector: 'app-issue-selection',
  templateUrl: './issue-selection.component.html',
  styleUrls: ['./issue-selection.component.scss']
})
export class IssueSelectionComponent extends AbstractComponent implements OnInit {
  @Output() selectIssue: EventEmitter<PaceSetterSituation> = new EventEmitter<PaceSetterSituation>()

  problems: PaceSetterSituation[] = []
  primaryProblems: PaceSetterSituation[] = PRIMARY_PACE_SETTER_SITUATIONS()
  secondaryProblems: PaceSetterSituation[] = SECONDARY_PACE_SETTER_SITUATIONS()

  telematics$: Observable<string[]> = this.store$.pipe(select(selectTelematics))
  telematics: string[]
  configuration$: Observable<VendorConfigurationMode> = this.store$.pipe(select(selectModeConfiguration))
  configuration: VendorConfigurationMode
  isRapUser$: Observable<boolean> = this.store$.pipe(select(selectIsRapUser))
  selectIssueConfig$ = this.store$.select(selectIssueConfig)
  issuesConfig = []
  isIssueHide = true

  memberActiveVehicle$ = this.store$.select(selectMemberActiveVehicle)
  memberActiveVehicle: VehicleData

  isVehicleChangeAllowed$ = this.store$.select(selectIsVehicleChangeAllowed)
  isVehicleChangeAllowed: boolean

  constructor(
    public store$: Store<AAAStore>,
    public taggingService: TaggingService,
    public titleService: Title,
  ) {
    super()
  }

  ngOnInit() {
    this.refreshProblems()
    this.subscriptions.push(
      this.telematics$.subscribe((telematics) => this.telematics = telematics),
      this.configuration$.subscribe((configuration) => {
        this.configuration = configuration
        this.refreshProblems()
      }),
      this.selectIssueConfig$.subscribe((issueConfig) => {
        if (issueConfig) {
          this.issuesConfig = this.problems.map((problem) => {
            const paceSetterCode = Object.values(PACE_SETTER_SITUATION_TYPES).find((value) => value === problem.name)
            const issue = issueConfig(paceSetterCode)
            return {
              ...problem,
              description: issue && !issue.enabled ? issue.value : problem.description,
              enabled: issue ? issue.enabled : true
            }
          })
        }
      }),
      this.memberActiveVehicle$.subscribe((vehicle) => this.memberActiveVehicle = vehicle),
      this.isVehicleChangeAllowed$.subscribe((allowed) => this.isVehicleChangeAllowed = allowed)
    )
  }

  constructProblems(): PaceSetterSituation[] {
    let problems = [...this.primaryProblems, ...this.secondaryProblems]
    if (this.configuration) {
      const batteryIndex = problems.findIndex(p => p.name === PACE_SETTER_SITUATION_TYPES.BATTERY_ISSUE)
      problems[batteryIndex] = {
        ...problems[batteryIndex],
        label: LABEL_BATTERY_RAP()
      }
    }
    if (this.hasTelematics()) {
      problems = this.loadProblemsFromTelematics(problems);
    }
    return problems;
  }

  hasTelematics() {
    return this.configuration && this.telematics.length > 0 && this.configuration.telematics.length > 0;
  }

  loadProblemsFromTelematics(problems: PaceSetterSituation[]): PaceSetterSituation[] {
    let issues = [];
    this.configuration.telematics
      .filter(t => this.telematics.includes(t.code))
      .forEach(t => issues = issues.concat(t.issues));
    return problems.filter(problem => issues.includes(problem.name));
  }

  refreshProblems() {
    this.problems = this.constructProblems()
  }

  problemSelected(problem: PaceSetterSituation) {
    if (!this.isRedesign) {
      this.store$.dispatch(setPaceSetterSituation({
        payload: {
          ...problem,
          allowChangeVehicle: this.isVehicleChangeAllowed,
          ...(this.memberActiveVehicle?.fuelType ? { vehicleFuelType: this.memberActiveVehicle.fuelType } : {})
        }
      }))

      this.store$.dispatch(addPartialCallRequest())

      this.taggingService.setClickEvent(events.issue.ISSUE_PAGE_NEXT_CLICK, events.issue.ISSUE_PAGE_TYPE)

      // dispatch action to set the tooltip as null when the user selects an issue
      this.store$.dispatch(setProblemTooltip({ payload: null }))
    } else {
      this.selectIssue.next(problem)
    }
  }

  shouldOverrideProblem(problem: PaceSetterSituation): PaceSetterSituation {
    return this.issuesConfig.find(({ name }) => name === problem.name)
  }

  onSeeMoreClick(event) {
    this.isIssueHide = !this.isIssueHide
    event.target.hidden = true
  }
}


