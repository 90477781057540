import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-flat-tire',
  templateUrl: './flat-tire.icon.html',
})
export class FlatTireIconComponent extends AbstractIconComponent {
  static icon = 'flat-tire'
}
