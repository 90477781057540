import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['./location-card.component.scss'],
})
export class LocationCardComponent {
  @Input() gpsDisabled = false;
  @Input() isHomeAddressVisible = true;
  @Input() userDefaultCoordsLoading = false;
  @Input() set isLoading(value: boolean) {
    this.isDisabled = value
    if(!value) {
      this.isCurrentLocationSpinnerVisible = false
      this.isHomeSpinnerVisible = false
    }
  }

  @Output() onHomeLocationClick = new EventEmitter<void>();
  @Output() onCurrentLocationClick = new EventEmitter<void>();

  isDisabled = false
  isHomeSpinnerVisible = false
  isCurrentLocationSpinnerVisible = false

  handleHomeLocationClick() {
    this.isHomeSpinnerVisible = true
    this.onHomeLocationClick.emit()
  }

  handleCurrentLocationClick() {
    this.isCurrentLocationSpinnerVisible = true
    this.onCurrentLocationClick.emit()
  }
}
