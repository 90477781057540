import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, Observable } from 'rxjs'
import {
  catchError,
  mergeMap,
  switchMap,
} from 'rxjs/operators'
import { PayloadedAction } from '../../shared/types'

import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { loadModeConfigurationFailure, loadModeConfigurationSuccess, LOAD_MODE_CONFIGURATION } from './mode-configuration.actions'
import { ModeConfigurationService } from './mode-configuration.service'
import { VendorConfigurationMode } from './mode-configuration.types'

const _pageType = 'Configuration File'

const CONFIGURATION_FILE_NOT_FOUND = 'Configuration file not found'

const FIRST_CALL_STATUS_TIMEOUT = 15000

@Injectable()
export class ModeConfigurationEffects {
  constructor(
    private actions$: Actions,
    private _modeConfigurationService: ModeConfigurationService,
    private errorReportingService: ErrorReportingService,
  ) { }

  // Load Mode configuration file
  handleModeConfigFile$: Observable<
    | ReturnType<typeof loadModeConfigurationSuccess>
    | ReturnType<typeof loadModeConfigurationFailure>
  > = createEffect(() =>
      this.actions$.pipe(
        ofType(LOAD_MODE_CONFIGURATION.REQUEST),
        switchMap((action: PayloadedAction) =>
          from(this._modeConfigurationService.loadModeConfiguration(action.payload)).pipe(
            mergeMap((data: VendorConfigurationMode) => [
              loadModeConfigurationSuccess({
                payload: data,
              }),
            ]),
            catchError((error) => this.errorReportingService.notifyErrorObservable(
              error,
              loadModeConfigurationFailure
            )
            )
          )
        ),
        catchError((error) => this.errorReportingService.notifyErrorObservable(
          error,
          loadModeConfigurationFailure
        )
        )
      )
    )
}

export const __TEST__ = {
  CONFIGURATION_FILE_NOT_FOUND,
  FIRST_CALL_STATUS_TIMEOUT,
  _pageType,
}
