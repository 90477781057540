<svg
  xmlns="http://www.w3.org/2000/svg"
  width="18"
  height="16"
  viewBox="0 0 18 16"
  id="icon-vehicle-stuck"
>
  <path
    [attr.fill]="color"
    fill-rule="evenodd"
    d="M15.92 14.99c-.2.59-.76 1.01-1.42 1.01h-11c-.66 0-1.21-.42-1.42-1.01L0 9V1c0-.55.45-1 1-1h1c.55 0 1 .45 1 1v1h12V1c0-.55.45-1 1-1h1c.55 0 1 .45 1 1v8l-2.08 5.99zM3.5 5C2.67 5 2 5.67 2 6.5S2.67 8 3.5 8 5 7.33 5 6.5 4.33 5 3.5 5zm11 0c-.83 0-1.5.67-1.5 1.5S13.67 8 14.5 8 16 7.33 16 6.5 15.33 5 14.5 5zM2 10l1.5 4.5h11L16 10H2z"
  />
</svg>
