<aaa-button
  *ngIf="source[field]"
  variant="secondary"
  size="small"
  [ariaLabel]="editLabel() + ' ' + (alias || '' + source[field])"
  (click)="handleSelected()"
  [class]="{ disabled: disabled }"
  [tabIndex]="tabIndexPosition"
>
  <aaa-icon name="pencil-outline" size="small"></aaa-icon>
  {{ alias || '' + source[field] }}
</aaa-button>
