import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-member-discount',
  templateUrl: './member-discount.icon.html',
})
export class MemberDiscountIconComponent extends AbstractIconComponent {
  static icon = 'member-discount'
}
