import { Component, ViewEncapsulation, Input, Inject } from '@angular/core'
import { DRR_BASE_HREF } from '../shared.config'
import { RouteTypes } from '../../modules/main-router.module'

@Component({
  styleUrls: ['./step.component.scss'],
  templateUrl: './step.component.html',
  selector: 'app-step',
  encapsulation: ViewEncapsulation.None,
})
export class StepComponent {
  @Input() stepId: string
  @Input() stepTitle: string
  @Input() stepStatus: string
  @Input() stepUrl: string
  @Input() tabIndex = 0

  route = `/${this.drrBaseHref}/${RouteTypes.STEPS}`

  constructor(@Inject(DRR_BASE_HREF) private drrBaseHref: string) {}
}
