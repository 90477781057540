import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  HostListener,
  SimpleChanges,
} from '@angular/core'

import { GestureController } from '@ionic/angular/standalone'

@Component({
  selector: 'app-alphabet-scroll-list',
  templateUrl: './alphabet-scroll-list.component.html',
  styleUrls: ['./alphabet-scroll-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlphabetScrollListComponent implements OnInit, AfterViewInit {
  @Input() scrollTop = 0
  @Input() items: string[] = []
  @Input() selected: string
  @Input() value: string
  @Input() isLoading: boolean
  @Input() isHideAlphabet = false
  @Output() letterScrolling = new EventEmitter<boolean>()
  @Output() itemSelected = new EventEmitter<string>()

  @ViewChild('bar', { static: false }) letterSidebar: ElementRef
  @ViewChild('itemsList', { static: false }) itemsList: ElementRef
  letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ#'.split('')
  dividers: { key: string; items: string[] }[] = []

  shouldStick = false

  constructor(private gestureCtrl: GestureController) {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.scrollTop = window.scrollY
    this.shouldStick = this.getShouldStick()
  }

  getShouldStick() {
    const firstLetter = document.getElementById('letter-divider-A')
    if (firstLetter) {
      return firstLetter.parentElement.getBoundingClientRect().top <= 16
    }
    return false
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateList()
  }

  ngOnInit(): void {
    this.updateList()
  }

  updateList() {
    if (this.items) {
      const groupedItemsMap = new Map<string, string[]>()
      this.items.forEach((item) => {
        let firstLetter = item.charAt(0).toUpperCase()
        if (firstLetter.match(/[0-9]/)) {
          firstLetter = '#'
        }
        if (groupedItemsMap.has(firstLetter)) {
          groupedItemsMap.get(firstLetter)?.push(item)
        } else {
          groupedItemsMap.set(firstLetter, [item])
        }
      })

      this.dividers = this.letters.filter((letter) => groupedItemsMap.has(letter)).map(
        (letter) => ({ key: letter, items: groupedItemsMap.get(letter) })
      )
    }
  }

  ngAfterViewInit(): void {
    if (this.letterSidebar?.nativeElement) {
      const moveGesture = this.gestureCtrl.create({
        el: this.letterSidebar.nativeElement,
        direction: 'y',
        threshold: 0,
        gestureName: 'letterScroll',
        onStart: () => {
          this.letterScrolling.emit(true)
        },
        onMove: (ev) => {
          const closestEle: any = document.elementFromPoint(
            ev.currentX,
            ev.currentY
          )
          if (closestEle && ['LI', 'A'].includes(closestEle.tagName)) {
            const letter = closestEle.innerText
            this.scrollToLetter(letter)
          }
        },
        onEnd: () => {
          this.letterScrolling.emit(false)
        },
      })
      moveGesture.enable(true)
    }
  }

  scrollToLetter(letter: string) {
    const closestLetter = this.findClosestLetter(letter);
    const element = document.getElementById('letter-divider-' + closestLetter)
    if (element) {
      element.parentElement.scrollIntoView()
    }
  }

  selectItem(item: string) {
    this.itemSelected.emit(item)
  }

  loading(item: string) {
    return item === this.selected && this.isLoading
  }

  findClosestLetter(letter: string) {
    if (letter === '#') {
      return this.dividers[this.dividers.length - 1]?.key
    }

    let left = 0
    let right = this.dividers.length - 1
    let closestLetter = this.dividers[0].key
    let minDistance = Math.abs(
      letter.charCodeAt(0) - closestLetter.charCodeAt(0)
    )

    while (left <= right) {
      const mid = Math.floor((left + right) / 2)
      const currentLetter = this.dividers[mid].key
      const currentDistance = Math.abs(
        letter.charCodeAt(0) - currentLetter.charCodeAt(0)
      )

      if (currentDistance < minDistance) {
        closestLetter = currentLetter
        minDistance = currentDistance
      }

      if (currentLetter < letter) {
        left = mid + 1
      } else if (currentLetter > letter) {
        right = mid - 1
      } else {
        return currentLetter
      }
    }

    return closestLetter
  }
}
