import { AbstractIconComponent } from '../abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-ev-connector-j1772',
  templateUrl: './ev-connector-j1772.icon.html',
})
export class EvConnectorJ1772IconComponent extends AbstractIconComponent {
  static icon = 'ev-connector-j1772'
}
