import { Injectable } from '@angular/core'
import { get } from 'lodash'
import debounce from 'p-debounce'
import { guessLocationFromCoordsResults, parseLocation, } from './google-geocode.utils'
import { GeoLocationOptions, GOOGLE_LOCATION_TYPES, GoogleCoordinates, } from './types'
import { GoogleLocation } from '../location.types'
import { awaitHoistedFunction } from '../../../shared/utils/awaitHoistedFunction'
import { of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { delayedRetry } from '../../../shared/utils/delayedRetryOperator'
import { isMobileApp } from '../../../shared/utils/app-detect';
import { setLocationServicesAvailable } from '../location.actions';
import { Store } from '@ngrx/store';
import { AAAStore } from '../../../store/root-reducer';
import { TaggingService } from '../../tagging/tagging.service';
import events from '../../tagging/events';
import { isIOS } from '../../../shared/utils/browser-detect';
import { isAgentAppId } from '../../ui/ui.utils';
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { AdobeEventTypes } from '../../tagging/tagging.types'

@Injectable({ providedIn: 'root' })
export class GoogleGeocodeService {
  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService
  ) {
  }
  geocoder: any

  // workaround for OVER_QUERY_LIMIT - see https://developers.google.com/maps/premium/previous-licenses/articles/usage-limits
  debounceTime = 300
  retryDelay = 2000
  retries = 2

  _setConfig({ debounceTime = 300, retryDelay = 2000 }) {
    this.debounceTime = debounceTime
    this.retryDelay = retryDelay
  }

  _guessLocationFromCoords: (
    coords: GoogleCoordinates,
    options?: GeoLocationOptions
  ) => Promise<GoogleLocation> = debounce<
      [GoogleCoordinates, GeoLocationOptions],
      GoogleLocation
    >(
      (
        coords,
        options = { preferredType: GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER }
      ) =>
        new Promise((resolve, reject) => {
          this.getGeocoder()
            .then((geocoder) =>
              geocoder.geocode(
                { location: coords },
                (allLocations: Array<GoogleLocation>, status: string) => {
                  if (status === 'OK') {
                    resolve(
                      parseLocation(
                        guessLocationFromCoordsResults(
                          allLocations,
                          coords,
                          options
                        ),
                        coords
                      )
                    )
                  } else {
                    reject(status)
                  }
                }
              )
            )
            .catch((error) => reject(error))
        }),
      this.debounceTime
    )

  _getLocationFromAddress: (address: string) => Promise<Array<GoogleLocation>> =
    debounce<[string], Array<GoogleLocation>>((address: string) => {
      if (!address) {
        return Promise.resolve(null)
      }

      return new Promise((resolve, reject) => {
        this.getGeocoder()
          .then((geocoder) =>
            geocoder.geocode({ address }, (results, status) => {
              if (status === 'OK') {
                resolve(results)
              } else {
                reject(status)
              }
            })
          )
          .catch((error) => reject(error))
      })
    }, this.debounceTime)

  _getLocationFromCoords: (
    coords: GoogleCoordinates
  ) => Promise<Array<GoogleLocation>> = debounce<
      [GoogleCoordinates],
      Array<GoogleLocation>
    >((coords: GoogleCoordinates) => {
      if (!coords) {
        return Promise.resolve(null)
      }
      return new Promise((resolve, reject) => {
        this.getGeocoder()
          .then((geocoder) =>
            geocoder.geocode({ location: coords }, (results, status) => {
              if (status === 'OK') {
                resolve(results)
              } else {
                reject(status)
              }
            })
          )
          .catch((error) => reject(error))
      })
    }, this.debounceTime)

  guessLocationFromCoords = (
    coordinates: GoogleCoordinates,
    options?: GeoLocationOptions
  ) =>
    of(coordinates)
      .pipe(
        switchMap((coords) => this._guessLocationFromCoords(coords, options)),
        delayedRetry(this.retryDelay, this.retries)
      )
      .toPromise()

  getLocationFromAddress = (address: string) =>
    of(address)
      .pipe(
        switchMap((addr) => this._getLocationFromAddress(addr)),
        delayedRetry(this.retryDelay, this.retries)
      )
      .toPromise()

  getLocationFromCoords = (coordinates: GoogleCoordinates) =>
    of(coordinates)
      .pipe(
        switchMap((coords) => this._getLocationFromCoords(coords)),
        delayedRetry(this.retryDelay, this.retries)
      )
      .toPromise()

  async getGeocoder(): Promise<google.maps.Geocoder> {
    return await awaitHoistedFunction<Object, google.maps.Geocoder>({
      ref: this.geocoder,
      target: window,
      path: ['google', 'maps', 'Geocoder'],
      callback: () =>
        new (get(window, ['google', 'maps', 'Geocoder']))() as google.maps.Geocoder,
      error: new Error('Error loading Google Maps.'),
    })
  }

  _handleGpsError(error, callback, promptState?) {
    if (promptState) {
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.location.LOCATION_SERVICE_DENIED
      })
      this.taggingService.setAutomatedEvent(
        events.location.LOCATION_SERVICE_DENIED,
        events.location.LOCATION_PAGE_TYPE
      )
    }
    this.store$.dispatch(setLocationServicesAvailable({
      payload: { available: false, coordinates: null, denied: true },
    }))
    callback(error)
  }

  _handleGpsSuccess(coordinates, callback, promptState) {
    if (promptState) {
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.location.LOCATION_SERVICE_ALLOWED
      })
      this.taggingService.setAutomatedEvent(
        events.location.LOCATION_SERVICE_ALLOWED,
        events.location.LOCATION_PAGE_TYPE
      )
    }
    this.store$.dispatch(setLocationServicesAvailable({
      payload: { available: true, coordinates },
    }))
    callback(coordinates)
  }

  /**
   * Request permissions to use browser coords
   *
   * @param enabled By default will be enabled
   * @param defaultCoords
   */
  getGPSCoords(
    enabled: Boolean = true,
    defaultCoords?: GoogleCoordinates
  ): Promise<GoogleCoordinates> {
    return new Promise(async (resolve, reject) => {
      // fallback response with default user coords
      if (!enabled && isMobileApp()) {
        this._handleGpsError('permissions disabled', reject)
        return
      }

      let promptState = !isMobileApp() && !enabled;
      if (promptState) {
        try {
          const locationPermission = await navigator.permissions.query({ name: 'geolocation' })
          promptState = locationPermission?.state === 'prompt'
          if (promptState) {
            this.adobeEventService.sendEvent({
              eventName: AdobeEventTypes.SYSTEM,
              eventValue: events.location.LOCATION_SERVICE_PROMPT
            })

            this.taggingService.setAutomatedEvent(
              events.location.LOCATION_SERVICE_PROMPT,
              events.location.LOCATION_PAGE_TYPE
            )
          }
        } catch (e) {
          console.warn('Could not determine location service permission state', e)
        }
      }

      if(isAgentAppId() || (isMobileApp() && isIOS())) {
        if (defaultCoords) {
          this._handleGpsSuccess(defaultCoords, resolve, promptState)
        } else {
          this._handleGpsError('defaultCoords is empty', reject, promptState)
        }
      } else {
        navigator.geolocation.getCurrentPosition((position) => this._handleGpsSuccess({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          accuracy: position.coords.accuracy,
        }, resolve, promptState),
        (error) => {
          if (defaultCoords) {
            this._handleGpsSuccess(defaultCoords, resolve, promptState)
          } else {
            this._handleGpsError(error, reject, promptState)
          }
        }, {
          timeout: 15000,
          enableHighAccuracy: true,
        })
      }
    })
  }

}

