import { DateTime } from 'luxon';

const HEADER_USER_YEARS_ENTITLEMENT_FOR = () => $localize`Thank you for being a valued AAA member for`
const TEXT_YEAR = () => $localize`year`
const TEXT_YEARS = () => $localize`years`

export const getHeaderUsersYearsEntitlement = (years: number) =>
  `${HEADER_USER_YEARS_ENTITLEMENT_FOR()} ${years} ${years === 1 ? TEXT_YEAR() : TEXT_YEARS()}!`

export function getMemberSinceYears(joinYearDate: string) {
  // backend can return the joinDate with two or four digits 😱
  const fullYear = DateTime.local().year
  if (joinYearDate.length === 1 || joinYearDate.length === 2) {
    return fullYear - 2000 - Number(joinYearDate)
  } else if (joinYearDate.length === 4) {
    return fullYear - Number(joinYearDate)
  } else {
    return null
  }
}

export function getMemberGreetings(joinYearDate: string) {
  const years = getMemberSinceYears(joinYearDate)
  if (years > 0) {
    return getHeaderUsersYearsEntitlement(years)
  }
  return $localize`Thank you for being a valued AAA member!`
}
