<svg
  width="50"
  height="50"
  viewBox="0 0 50 50"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25ZM9 18H14V31.7647H9V18ZM41 18H36V31.7647H41V18ZM23 44.7647V31H28V44.7647H23ZM25.5 17C27.9853 17 30 14.9853 30 12.5V8L21 8V12.5C21 14.9853 23.0147 17 25.5 17Z"
    fill="#525252"
  />
</svg>
