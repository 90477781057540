<svg
  xmlns="http://www.w3.org/2000/svg"
  width="12"
  height="21"
  viewBox="0 0 12 21"
  id="icon-key-lost"
>
  <path
    [attr.fill]="color"
    fill-rule="evenodd"
    d="M.858 2.853c.312-.269.648-.52 1.007-.754.36-.234.75-.438 1.17-.611.42-.173.875-.31 1.365-.41C4.89.978 5.416.93 5.98.93c.779 0 1.485.106 2.118.318a4.71 4.71 0 0 1 1.624.91c.451.395.8.869 1.047 1.424.247.554.37 1.174.37 1.858 0 .659-.093 1.228-.28 1.71-.186.48-.42.898-.701 1.254a5.346 5.346 0 0 1-.93.923c-.337.26-.658.502-.961.727a7.094 7.094 0 0 0-.787.67 1.41 1.41 0 0 0-.41.734l-.324 1.859H4.289l-.247-2.106c-.06-.416-.008-.778.156-1.085.165-.308.386-.59.663-.845.278-.256.587-.503.93-.74.342-.24.663-.495.961-.768.3-.273.55-.58.754-.923.204-.342.306-.747.306-1.215 0-.572-.197-1.027-.592-1.365-.394-.338-.916-.507-1.566-.507-.476 0-.877.052-1.202.156a5.004 5.004 0 0 0-.838.345c-.234.126-.434.24-.598.344a.883.883 0 0 1-.468.156.802.802 0 0 1-.741-.429L.858 2.853zm2.43 15.259a2.06 2.06 0 0 1 1.28-1.917c.256-.109.536-.163.84-.163a2.14 2.14 0 0 1 1.507.605 2.06 2.06 0 0 1 .61 1.475 2.047 2.047 0 0 1-.61 1.475c-.19.186-.416.332-.676.436-.26.104-.537.156-.832.156-.303 0-.583-.052-.838-.156a2.033 2.033 0 0 1-1.28-1.91v-.001z"
  />
</svg>
