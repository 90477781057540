import { Component } from '@angular/core'
import { TextComponent } from '@aaa-mobile/shared-components'
import { IonTitle } from '@ionic/angular/standalone'

@Component({
  selector: 'app-title',
  standalone: true,
  imports: [IonTitle, TextComponent],
  templateUrl: './title.component.html',
  styleUrl: './title.component.scss',
})
export class TitleComponent {}
