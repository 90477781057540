import { ButtonComponent, IconComponent, TextComponent } from '@aaa-mobile/shared-components'
import { AsyncPipe, TitleCasePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import {
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRow,
  IonToolbar
} from '@ionic/angular/standalone'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectAdvisories } from '../../../../modules/advisories/advisories.selectors'
import { Advisory } from '../../../../modules/advisories/advisories.types'
import { selectEligibility, selectIsRapUser } from '../../../../modules/auth/auth.selectors'
import { OEMBranding } from '../../../../modules/auth/auth.types'
import { Eligibility } from '../../../../modules/auth/eligibility.types'
import { ConfigService } from '../../../../modules/config/config.service'
import { selectActiveCallStatus } from '../../../../modules/dashboard/calls-statuses/call-status.selectors'
import { AAACallStatus } from '../../../../modules/dashboard/calls.types'
import { selectMemberData } from '../../../../modules/member/member.selectors'
import { MemberInfo } from '../../../../modules/member/member.types'
import { RapService } from '../../../../modules/rap/rap.service'
import { CancelRequestComponent } from '../../../../modules/service-tracking/cancel-request/cancel-request.component'
import { openPromptDialog } from '../../../../modules/ui/ui.actions'
import { PromptDialogTypes } from '../../../../modules/ui/ui.types'
import { AAAStore } from '../../../../store/root-reducer'
import { I18N_SUPPORTED_APP_IDS } from '../../../i18n/i18n.utils'
import { LogoComponent } from '../../../layouts/header-redesign/logo/logo.component'
import { PrivacyPolicyService } from '../../../services/privacy-policy.service'
import { TermsConditionsService } from '../../../services/terms-conditions.service'
import { PlusIndicatorValues } from '../../../types'
import { AppVersion } from '../../../utils/app-version'
import { getMemberGreetings } from '../../../utils/entitlement.utils'

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    IonMenu,
    IonHeader,
    IonContent,
    TextComponent,
    IonList,
    IonItem,
    IonLabel,
    ButtonComponent,
    IconComponent,
    IonGrid,
    IonRow,
    IonCol,
    IonMenuToggle,
    AsyncPipe,
    TitleCasePipe,
    LogoComponent,
    CancelRequestComponent,
    IonFooter,
    IonToolbar,
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent implements OnInit {
  memberData$: Observable<MemberInfo> = this.store$.pipe(select(selectMemberData));
  eligibility$: Observable<Eligibility> = this.store$.pipe(select(selectEligibility));
  isRapUser$: Observable<boolean> = this.store$.pipe(select(selectIsRapUser));
  advisories$: Observable<Advisory[]> = this.store$.select(selectAdvisories);
  activeCall$: Observable<AAACallStatus> = this.store$.pipe(select(selectActiveCallStatus))

  appVersion: string
  appEnv: string | boolean = this.configService.getConfig().description
  displayEnv = Boolean(this.configService.getConfig().description)
  displayVersion: boolean = this.configService.getConfig().displayVersion
  isLocaleSwitcherEnabled: boolean = false

  constructor(
    private store$: Store<AAAStore>,
    private configService: ConfigService,
    private appVersionObj: AppVersion,
    private rapService: RapService,
    private termsConditionsService: TermsConditionsService,
    private privacyPolicy: PrivacyPolicyService,
  ) {
    this.appVersion = this.appVersionObj.get(this.configService.getConfig().nativeAppView)
  }

  ngOnInit() {
    this.isLocaleSwitcherEnabled = I18N_SUPPORTED_APP_IDS.includes(this.rapService.getRapAppId() as OEMBranding)
  }

  openPrivacyPolicy() {
    this.privacyPolicy.open()
  }

  openTerms() {
    this.termsConditionsService.open()
  }

  getPlusIndicatorName(plusIndicator: string) {
    return PlusIndicatorValues[plusIndicator] || plusIndicator
  }

  _getMemberGreetings(joinYearDate) {
    return getMemberGreetings(joinYearDate)
  }

  openLanguageSwitcher() {
    this.store$.dispatch(openPromptDialog({
      payload: {
        type: PromptDialogTypes.I18N_SWITCHER
      }
    }))
  }
}
