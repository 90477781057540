import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-warning',
  templateUrl: './warning.icon.html',
})
export class WarningIconComponent extends AbstractIconComponent {
  static icon = 'warning'
}
