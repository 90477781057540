import { Component, Input, OnInit } from '@angular/core';
import { EvStationDetails } from '../../aar/aar.types';

@Component({
  selector: 'app-electric-vehicle',
  templateUrl: './electric-vehicle.component.html',
  styleUrls: ['./electric-vehicle.component.scss']
})
export class ElectricVehicleComponent implements OnInit {

  @Input() evStationDetails: EvStationDetails

  constructor() {}

  ngOnInit(): void {
  }

}
