import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-mechanical-problem',
  templateUrl: './mechanical-problem.icon.html',
})
export class MechanicalProblemIconComponent extends AbstractIconComponent {
  static icon = 'mechanical-problem'
}
