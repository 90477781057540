export function isElementEmpty(node, defaultId): boolean {
  if (node.children.length === 0) {
    return true
  }

  for (let i = 0; i < node.children.length; i++) {
    if (
      node.children[i].id !== defaultId &&
      node.children[i].offsetHeight > 0
    ) {
      return false
    }
  }

  return true
}
