import { Injectable } from '@angular/core'
import { UIConfigParamsKeys } from '../../modules/config/config.types'
import { ABTestParams } from '../../modules/ui/ui.types'
import { turnOnRedesign } from '../utils/cookies'

@Injectable({
  providedIn: 'root'
})
export class UrlParamsService {

  checkUrlParams(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.handleUrlParams()
      resolve()
    })
  }

  private handleUrlParams(): void {
    const params = this.getQueryParams()

    if (params[UIConfigParamsKeys.AB_TEST]) {
      const abTests = params[UIConfigParamsKeys.AB_TEST]
        .toLowerCase()
        .split(':')

      if (abTests.find((value: ABTestParams) => [ABTestParams.REDESIGN, ABTestParams.REDESIGN_SHORT].includes(value))) {
        turnOnRedesign()
      }
    }
  }

  private getQueryParams(): { [key: string]: string } {
    const queryParams = new URLSearchParams(window.location.search)
    const params: { [key: string]: string } = {}

    queryParams.forEach((value, key) => {
      params[key.toLowerCase()] = value
    })

    return params
  }
}
