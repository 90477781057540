<svg
  id="icon-advisory"
  width="24px"
  height="24px"
  viewBox="0 0 24 24"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g
    id="Service-Tracker---Active-Requests-and-Advisory"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      id="Desktop-HD---AAA-RSO---Active-Request-Tabs---Details"
      transform="translate(-233.000000, -490.000000)"
    >
      <g id="Service-Tracker" transform="translate(18.000000, 159.000000)">
        <g id="Tabs-2" transform="translate(0.000000, 318.000000)">
          <g id="Tabs" transform="translate(-14.000000, 1.000000)">
            <g id="Advisory" transform="translate(215.000000, 11.000000)">
              <g id="advisory_icon" transform="translate(14.000000, 1.000000)">
                <polygon
                  id="Triangle-1"
                  fill="#003893"
                  points="11.7018284 0 23.4036568 24 0 24"
                ></polygon>
                <text
                  id="!"
                  font-family="Lato-Bold, Lato"
                  font-size="14"
                  font-weight="bold"
                  fill="#FFFFFF"
                >
                  <tspan x="9" y="19">!</tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
