import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'
import { TaggingService } from '../../../tagging/tagging.service'

@Component({
  selector: 'call-request-issue-dialog-component',
  templateUrl: './call-request-issue-dialog.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class CallRequestIssueDialogComponent {
  pageType = 'Call Cancel Issue'

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private taggingService: TaggingService,
  ) {
    const _action = 'Call Cancel Issue Prompt'
    this.taggingService.setPageEvent(_action, this.pageType)
  }

  onCallAAAClick() {
    this.onClose()
  }

  onClose() {
    this.data.close()
  }
}
