import { Component, OnInit, HostBinding, Input } from '@angular/core'

@Component({
  selector: 'app-expanding-panel',
  templateUrl: './expanding-panel.component.html',
  styleUrls: ['./expanding-panel.component.scss'],
})
export class ExpandingPanelComponent {
  @HostBinding('class.active')
  @Input()
  active = false
  @Input() tabIndex = 0
  @Input() keepOpen = false

  constructor() {}

  toggleActive() {
    this.active = !this.active
  }
}
