import { NgModule } from '@angular/core'
import { SharedModule } from '../../shared'
import { RapRoutingModule } from './rap-routing.module'
import { rapComponents } from './rap.component.declarations'



@NgModule({
  declarations: [...rapComponents()],
  imports: [
    RapRoutingModule,
    SharedModule,
  ],
})
export class RapModule { }
