import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'icon-check',
  templateUrl: './check.icon.html',
})
export class CheckIconComponent extends AbstractIconComponent {
  static icon = 'check'
}
