import { PayloadedAction } from '../../shared/types'
import { CONTACT_FORM, PASSENGERS_FORM, RESET_PASSENGERS, SET_CONTACT_NUMBER, } from './submit.actions'
import { Option } from '../ui/ui.types'
import { CANCEL_EDITING_REQUEST } from '../../shared/actions/shared.actions'
import {
  createFormGroupState,
  formGroupReducer,
  FormGroupState,
  formStateReducer,
  SetValueAction,
  updateGroup,
  validate,
} from 'ngrx-forms'
import { pattern, required, requiredTrue } from 'ngrx-forms/validation'

export interface Passengers {
  quantity: Option
  notes?: string
}

export interface UserContactInfo {
  contactNumber: string
  firstName: string
  lastName: string
  shouldTextUpdates?: boolean
  displayId?: boolean
  additionalDetails: string
}

export interface SubmitState {
  contactInfo: FormGroupState<UserContactInfo>
  passengers: FormGroupState<Passengers>
}

export const validatePassengers = updateGroup<Passengers>({
  quantity: validate(required),
})

export const validateContactInfo = updateGroup<UserContactInfo>({
  contactNumber: validate(
    required,
    pattern(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
  ),
  firstName: validate(required),
  lastName: validate(required),
  displayId: validate(requiredTrue),
})

export const initialState: SubmitState = createInitialState()

function createInitialState(): SubmitState {
  return {
    contactInfo: createFormGroupState<UserContactInfo>(CONTACT_FORM, {
      contactNumber: '',
      firstName: '',
      lastName: '',
      shouldTextUpdates: true,
      displayId: false,
      additionalDetails: '',
    }),
    passengers: createFormGroupState<Passengers>(PASSENGERS_FORM, {
      quantity: null,
      notes: '',
    }),
  }
}

export const passengerOptions = (): Option[] => [
  { label: '0' },
  { label: '1' },
  { label: '2' },
  { label: $localize`3 OR MORE`, value: '3 OR MORE' },
]

export function reducer(
  state: SubmitState | undefined = initialState,
  action: PayloadedAction
): SubmitState {
  const passengers = validatePassengers(
    formGroupReducer(state.passengers, action)
  )
  if (passengers !== state.passengers) {
    return {
      ...state,
      passengers,
    }
  }

  const contactInfo = validateContactInfo(
    formGroupReducer(state.contactInfo, action)
  )
  if (contactInfo !== state.contactInfo) {
    return {
      ...state,
      contactInfo,
    }
  }

  switch (action.type) {
    case RESET_PASSENGERS:
      const resetControlViaAction = formStateReducer(
        passengers,
        new SetValueAction(passengers.id, {
          quantity: null,
          notes: '',
        })
      )
      return {
        ...state,
        passengers: resetControlViaAction,
      }
    case SET_CONTACT_NUMBER:
      return {
        ...state,
        contactInfo: formStateReducer(
          contactInfo,
          new SetValueAction(contactInfo.id, {
            ...contactInfo.value,
            contactNumber: action.payload,
          })
        ),
      }
    case CANCEL_EDITING_REQUEST:
      // Allowing for a new initial state prevents corruption of the original initialState value
      return createInitialState()
    default:
      return state
  }
}
