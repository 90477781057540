import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../../../store/root-reducer'

import { AbstractComponent } from '../../../../../shared/abstract.component'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogData } from '../../../ui.types'
import { MEMBER_BASIC_INFO, MEMBER_INFO, MEMBERS_SEARCH, } from '../../../../member/member.actions'
import { map } from 'rxjs/operators'
import { authNameSearchInfoRequest, } from '../../../../auth/auth.actions'
import { ADD_CALL } from '../../../../dashboard/calls.actions'
import { selectIsLoading } from '../../../loading/loading.selectors'

import { combineLatest } from 'rxjs'
import { NgForm } from '@angular/forms'


@Component({
  selector: 'app-auth-additional-info-search',
  templateUrl: './auth-additional-info-search.component.html',
  styleUrls: ['./auth-additional-info-search.component.scss'],
})
export class AuthAdditionalInfoSearchComponent
  extends AbstractComponent
  implements OnInit {
  isLoading$ = combineLatest([
    this.store$.pipe(select(selectIsLoading(MEMBER_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBER_BASIC_INFO.ACTION))),
    this.store$.pipe(select(selectIsLoading(MEMBERS_SEARCH.ACTION))),
    this.store$.pipe(select(selectIsLoading(ADD_CALL.ACTION))),
  ]).pipe(map((areActionsLoading) => areActionsLoading.find(Boolean)))

  @ViewChild('f') searchForm: NgForm

  additionalInfoModel = {
    firstName: '',
    lastName: ''
  }

  searchId: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>,
  ) {
    super()
  }

  ngOnInit() {
    this.searchId = this.data.params?.searchId || null
  }

  handleSearchForm({ value }) {
    const { firstName, lastName } = value
    const searchId = this.searchId

    this.store$.dispatch(
      authNameSearchInfoRequest({
        payload: {
          searchId,
          firstName: firstName.toUpperCase(),
          lastName: lastName.toUpperCase()
        }
      })
    )
  }
}
