<label
  class="{{
    'app-checkbox_label app-checkbox_' + active + ' app-checkbox_' + focus
  }}"
>
  <input
    type="checkbox"
    class="app-checkbox"
    [name]="name"
    [checked]="checked"
    [value]="value"
    (change)="handleChange($event)"
    (focus)="handleFocus()"
    (blur)="handleBlur()"
    [tabIndex]="tabIndex"
    [required]="required"
    [attr.aria-required]="required"
    [ngClass]="{
      'first-focusable-element': first
    }"
  />
  <div class="app-checkbox_selection-indicator"></div>
  <ng-content></ng-content>
</label>
