import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-charging-level-list-v2',
  templateUrl: './charging-level-list-v2.component.html',
  styleUrls: ['./charging-level-list-v2.component.scss'],
})
export class ChargingLevelListV2Component {

  @Input() dcFast = 0
  @Input() level1 = 0
  @Input() level2 = 0

  constructor() {
  }

}
