import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LocationAddress } from '../../breakdown-location/components/breakdown-location.types'
import { GoogleLocationMarker } from '../location.types'
import { formatAddress } from '../location.utils'

@Component({
  selector: 'app-custom-destination-card',
  templateUrl: './custom-destination-card.component.html',
  styleUrls: ['./custom-destination-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDestinationCardComponent {
  address: LocationAddress
  _location: GoogleLocationMarker
  @Input() get location(): GoogleLocationMarker {
    return this._location
  }
  set location(location: GoogleLocationMarker) {
    this._location = location
    this.address = formatAddress(this.location.address)
    this.address.icon = 'drr-place-pin'
  }
  @Input() loading: boolean

  @Output() onUseThisLocation = new EventEmitter<GoogleLocationMarker>()
  @Output() onClearLocation = new EventEmitter<void>();

  handleUseThisLocation() {
    this.onUseThisLocation.emit(this.location)
  }

  handleClearLocation() {
    this.address = null
    this.onClearLocation.emit()
  }

}
