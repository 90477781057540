import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-battery-jump',
  templateUrl: './battery-jump.icon.html',
})
export class BatteryJumpIconComponent extends AbstractIconComponent {
  static icon = 'battery-jump'
}
