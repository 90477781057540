import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'

@Component({
  selector: 'tow-location-address-warning-dialog-component',
  templateUrl: './tow-location-address-warning-dialog.component.html',
  styleUrls: ['./tow-location-address-warning-dialog.component.scss'],
})
export class TowLocationAddressWarningDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onClose() {
    this.data.close()
  }
}
