import { trigger, transition, animate, style, state } from '@angular/animations'

export const flyInOutAnimation = [
  trigger('flyInOut', [
    transition(':enter', [
      style({ transform: 'translateX(-100%)' }),
      animate('100ms 100ms ease-in'),
    ]),
    transition(':leave', [
      animate('100ms ease-out', style({ transform: 'translateX(-100%)' })),
    ]),
  ]),
]

export const flyInOutAnimationLeft = [
  trigger('flyInOut', [
    transition(':enter', [
      style({ transform: 'translateX(100%)' }),
      animate('100ms 100ms ease-in'),
    ]),
    transition(':leave', [
      animate('100ms ease-out', style({ transform: 'translateX(100%)' })),
    ]),
  ]),
]
