<svg
  width="50"
  height="50"
  viewBox="0 0 50 50"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50ZM14 9H19V28H14V21H7V16H14V9ZM34 12H39V25H34V12ZM25.5 30C23.0147 30 21 32.0147 21 34.5V42H30V34.5C30 32.0147 27.9853 30 25.5 30Z"
    fill="#525252"
  />
</svg>
