import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-electricity',
  templateUrl: './electricity.icon.html',
})
export class ElectricityIconComponent extends AbstractIconComponent {
  static icon = 'electricity'
}
