import { Pipe, PipeTransform } from '@angular/core'
import { RapService } from '../../modules/rap/rap.service';
import { PACE_SETTER_SITUATION_CODES } from '../../modules/issue/issue.types';

const LABEL_PACE_SETTER_FLAT_TIRE = () => $localize`Flat tire`
const LABEL_PACE_SETTER_MULTIPLE_FLAT_TIRES = () => $localize`Multiple Flat Tires`
const LABEL_PACE_SETTER_BICYCLE_ASSIST_SERVICE = () => $localize`Bicycle Assist Service`
const LABEL_PACE_SETTER_OTHER_TIRE_PROBLEM = () => $localize`Other Tire Problem`
const LABEL_PACE_SETTER_ENGINE_OVERHEAT = () => $localize`Engine Overheat`
const LABEL_PACE_SETTER_CAR_WONT_START_BATTERY_ISSUE = () => $localize`Car won't start / Battery issue`
const LABEL_PACE_SETTER_BATTERY_TEST_OR_REPLACE = () => $localize`Battery Test or Replace`
const LABEL_PACE_SETTER_NO_CRANK_BAT_SVC_AAA_BAT = () => $localize`No Crank - Bat Svc (AAA Bat)`
const LABEL_PACE_SETTER_MEMBER_REQUESTS_BATTERY_SERVICE = () => $localize`Member Requests Battery Service`
const LABEL_PACE_SETTER_NEED_A_TOW = () => $localize`Need a tow`
const LABEL_PACE_SETTER_KNOWN_STARTER_PROBLEM = () => $localize`Known Starter Problem`
const LABEL_PACE_SETTER_KNOWN_ALTERNATOR_PROBLEM = () => $localize`Known Alternator Problem`
const LABEL_PACE_SETTER_OTHER_NO_CRANK_PROBLEM = () => $localize`Other No Crank Problem`
const LABEL_PACE_SETTER_CAR_WONT_START = () => $localize`Car won't start`
const LABEL_PACE_SETTER_OUT_OF_FUEL = () => $localize`Out of fuel`
const LABEL_PACE_SETTER_ELECTRIC_VEHICLE_EV_OUT_OF_CHARGE = () => $localize`Electric Vehicle (EV) Out of Charge`
const LABEL_PACE_SETTER_VEHICLE_MIS_FUELED = () => $localize`Vehicle Mis-Fueled`
const LABEL_PACE_SETTER_OUT_OF_CHARGE = () => $localize`Out of charge`
const LABEL_PACE_SETTER_OTHER_CRANK_NO_START_PROBLEM = () => $localize`Other Crank No Start Problem`
const LABEL_PACE_SETTER_WONT_GO_INTO_GEAR = () => $localize`Won’t Go Into Gear`
const LABEL_PACE_SETTER_PARKING_BRAKE_WONT_RELEASE = () => $localize`Parking Brake Won’t Release`
const LABEL_PACE_SETTER_HOOD_DOOR_WONT_CLOSE_LATCH = () => $localize`Hood/Door Won’t Close/Latch`
const LABEL_PACE_SETTER_UNDERCAR_COMPONENT_DRAGGING = () => $localize`Undercar Component Dragging`
const LABEL_PACE_SETTER_LEAKING_FLUIDS = () => $localize`Leaking Fluids`
const LABEL_PACE_SETTER_WINDSHIELD_DAMAGE = () => $localize`Windshield Damage`
const LABEL_PACE_SETTER_CAR_ALARM_ISSUE = () => $localize`Car Alarm Issue`
const LABEL_PACE_SETTER_TRANSMISSION_CLUTCH_FAILURE = () => $localize`Transmission/Clutch Failure`
const LABEL_PACE_SETTER_AXLE_DRIVESHAFT_SUSPENSION_FAILURE = () => $localize`Axle/Driveshaft/Suspension Failure`
const LABEL_PACE_SETTER_BRAKE_SYSTEM_FAILURE = () => $localize`Brake System Failure`
const LABEL_PACE_SETTER_OTHER_RUNS_WONT_MOVE_PROBLEM = () => $localize`Other Runs Won’t Move Problem`
const LABEL_PACE_SETTER_ENGINE_STALLED_WHILE_DRIVING_OR_ENGINE_RUNS_POORLY = () => $localize`Engine Stalled While Driving (or Engine Runs Poorly)`
const LABEL_PACE_SETTER_ENGINE_STALLED_WHILE_DRIVING_OR_OTHER_RUNS_POORLY = () => $localize`Engine Stalled While Driving (or Other Runs Poorly Problem)`
const LABEL_PACE_SETTER_KEY_LOCKED_IN_TRUNK_W_TRUNK_RELEASE = () => $localize`Key Locked In Trunk w/Trunk Release`
const LABEL_PACE_SETTER_KEY_BROKEN_IN_DOOR_NOT_IGNITION_KEY = () => $localize`Key Broken In Door - Not Ignition Key`
const LABEL_PACE_SETTER_FROZEN_DOOR_LOCK = () => $localize`Frozen Door Lock`
const LABEL_PACE_SETTER_OTHER_LOCKOUT_PROBLEM = () => $localize`Other Lockout Problem`
const LABEL_PACE_SETTER_KEY_LOCKED_IN_TRUNK_NO_TRUNK_RELEASE = () => $localize`Key Locked In Trunk-No Trunk Release`
const LABEL_PACE_SETTER_LOST_DAMAGED_VEHICLE_KEY = () => $localize`Lost/Damaged Vehicle Key`
const LABEL_PACE_SETTER_LOST_DAMAGED_CLUB_KEY = () => $localize`Lost/Damaged “Club” Key`
const LABEL_PACE_SETTER_KEY_BROKEN_IN_DOOR_IGNITION_KEY = () => $localize`Key Broken In Door - Ignition Key`
const LABEL_PACE_SETTER_KEY_BROKEN_IN_IGNITION_SWITCH = () => $localize`Key Broken In Ignition Switch`
const LABEL_PACE_SETTER_HOME_LOCKOUT = () => $localize`Home Lockout`
const LABEL_PACE_SETTER_VEHICLE_FIRE = () => $localize`Vehicle Fire`
const LABEL_PACE_SETTER_ACCIDENT = () => $localize`Accident`
const LABEL_PACE_SETTER_CONVENIENCE_MEMBER_CONCERN_TOW = () => $localize`Convenience/Member Concern Tow`
const LABEL_PACE_SETTER_INSURANCE_ACCIDENT_ASSIST = () => $localize`Insurance Accident Assist`
const LABEL_PACE_SETTER_INSURANCE_ACCIDENT_ASSIST_SECOND_TOW = () => $localize`Insurance Accident Assist - Second Tow`
const LABEL_PACE_SETTER_STUCK = () => $localize`Stuck`
const LABEL_PACE_SETTER_LIGHT_SERVICE_REDISPATCH_AS_TOW = () => $localize`Light Service Redispatch As Tow`
const LABEL_PACE_SETTER_OTHER_REQUIRED_TOW = () => $localize`Other Required Tow`
const LABEL_PACE_SETTER_BATTERY = () => $localize`Battery`
const LABEL_PACE_SETTER_LOCKED_OUT = () => $localize`Locked out`
const LABEL_PACE_SETTER_BATTERY_ISSUE = () => $localize`Battery Issue`
const LABEL_PACE_SETTER_NO_CRANK_BAT_SVC = () => $localize`No Crank - Bat Svc`
const LABEL_PACE_SETTER_BATTERY_SERVICE = () => $localize`Battery Service`
const LABEL_PACE_SETTER_CONVENIENCE_CONCERN_TOW = () => $localize`Convenience Concern Tow`

type PacesetterValue = {
  [T in PACE_SETTER_SITUATION_CODES]: {
    title: string,
    icon: Svg,
  }
}

type Types = 'icon' | 'title'

enum Svg {
  ACCIDENT = 'assets/drrweb-lib/images/icons/accident.svg',
  STUCK = 'assets/drrweb-lib/images/icons/stuck.svg',
  BATTERY = 'assets/drrweb-lib/images/icons/battery.svg',
  FLAT_TIRE = 'assets/drrweb-lib/images/icons/flat tire.svg',
  LOCKED_OUT = 'assets/drrweb-lib/images/icons/locked out.svg',
  FUEL = 'assets/drrweb-lib/images/icons/fuel.svg',
  TOW = 'assets/drrweb-lib/images/icons/tow-small.svg',
  CAR_WONT_START = 'assets/drrweb-lib/images/icons/car wont start.svg',
}

export const PACE_SETTER_DATA = (): PacesetterValue => ({
  L101: { title: LABEL_PACE_SETTER_FLAT_TIRE(), icon: Svg.FLAT_TIRE },
  L102: { title: LABEL_PACE_SETTER_FLAT_TIRE(), icon: Svg.FLAT_TIRE },
  L103: { title: LABEL_PACE_SETTER_MULTIPLE_FLAT_TIRES(), icon: Svg.FLAT_TIRE },
  L104: { title: LABEL_PACE_SETTER_FLAT_TIRE(), icon: Svg.FLAT_TIRE },
  L105: { title: LABEL_PACE_SETTER_BICYCLE_ASSIST_SERVICE(), icon: Svg.FLAT_TIRE },
  T180: { title: LABEL_PACE_SETTER_FLAT_TIRE(), icon: Svg.FLAT_TIRE },
  L190: { title: LABEL_PACE_SETTER_OTHER_TIRE_PROBLEM(), icon: Svg.FLAT_TIRE },

  L201: { title: LABEL_PACE_SETTER_ENGINE_OVERHEAT(), icon: Svg.TOW },
  T280: { title: LABEL_PACE_SETTER_ENGINE_OVERHEAT(), icon: Svg.TOW },

  L301: { title: LABEL_PACE_SETTER_CAR_WONT_START_BATTERY_ISSUE(), icon: Svg.CAR_WONT_START },
  L302: { title: LABEL_PACE_SETTER_BATTERY_TEST_OR_REPLACE(), icon: Svg.BATTERY },
  L303: { title: LABEL_PACE_SETTER_NO_CRANK_BAT_SVC_AAA_BAT(), icon: Svg.BATTERY },
  L304: { title: LABEL_PACE_SETTER_MEMBER_REQUESTS_BATTERY_SERVICE(), icon: Svg.BATTERY },
  T380: { title: LABEL_PACE_SETTER_NEED_A_TOW(), icon: Svg.TOW },
  T381: { title: LABEL_PACE_SETTER_KNOWN_STARTER_PROBLEM(), icon: Svg.TOW },
  T382: { title: LABEL_PACE_SETTER_KNOWN_ALTERNATOR_PROBLEM(), icon: Svg.TOW },
  L390: { title: LABEL_PACE_SETTER_OTHER_NO_CRANK_PROBLEM(), icon: Svg.BATTERY },

  L401: { title: LABEL_PACE_SETTER_CAR_WONT_START(), icon: Svg.CAR_WONT_START },
  L402: { title: LABEL_PACE_SETTER_OUT_OF_FUEL(), icon: Svg.FUEL },
  L403: { title: LABEL_PACE_SETTER_OUT_OF_FUEL(), icon: Svg.FUEL },
  L404: { title: LABEL_PACE_SETTER_ELECTRIC_VEHICLE_EV_OUT_OF_CHARGE(), icon: Svg.CAR_WONT_START },
  T480: { title: LABEL_PACE_SETTER_NEED_A_TOW(), icon: Svg.TOW },
  T481: { title: LABEL_PACE_SETTER_VEHICLE_MIS_FUELED(), icon: Svg.CAR_WONT_START },
  T483: { title: LABEL_PACE_SETTER_OUT_OF_CHARGE(), icon: Svg.FUEL },
  T484: { title: LABEL_PACE_SETTER_KNOWN_ALTERNATOR_PROBLEM(), icon: Svg.CAR_WONT_START },
  L490: { title: LABEL_PACE_SETTER_OTHER_CRANK_NO_START_PROBLEM(), icon: Svg.CAR_WONT_START },

  L501: { title: LABEL_PACE_SETTER_WONT_GO_INTO_GEAR(), icon: Svg.TOW },
  L505: { title: LABEL_PACE_SETTER_PARKING_BRAKE_WONT_RELEASE(), icon: Svg.TOW },
  L506: { title: LABEL_PACE_SETTER_HOOD_DOOR_WONT_CLOSE_LATCH(), icon: Svg.TOW },
  L507: { title: LABEL_PACE_SETTER_UNDERCAR_COMPONENT_DRAGGING(), icon: Svg.TOW },
  L508: { title: LABEL_PACE_SETTER_LEAKING_FLUIDS(), icon: Svg.TOW },
  L509: { title: LABEL_PACE_SETTER_WINDSHIELD_DAMAGE(), icon: Svg.TOW },
  L510: { title: LABEL_PACE_SETTER_CAR_ALARM_ISSUE(), icon: Svg.TOW },
  T580: { title: LABEL_PACE_SETTER_TRANSMISSION_CLUTCH_FAILURE(), icon: Svg.TOW },
  T581: { title: LABEL_PACE_SETTER_AXLE_DRIVESHAFT_SUSPENSION_FAILURE(), icon: Svg.TOW },
  T582: { title: LABEL_PACE_SETTER_BRAKE_SYSTEM_FAILURE(), icon: Svg.TOW },
  L590: { title: LABEL_PACE_SETTER_OTHER_RUNS_WONT_MOVE_PROBLEM(), icon: Svg.TOW },

  T601: { title: LABEL_PACE_SETTER_ENGINE_STALLED_WHILE_DRIVING_OR_ENGINE_RUNS_POORLY(), icon: Svg.TOW },
  T680: { title: LABEL_PACE_SETTER_CAR_WONT_START(), icon: Svg.CAR_WONT_START },
  T690: { title: LABEL_PACE_SETTER_ENGINE_STALLED_WHILE_DRIVING_OR_OTHER_RUNS_POORLY(), icon: Svg.TOW },

  L701: { title: LABEL_PACE_SETTER_LOCKED_OUT(), icon: Svg.LOCKED_OUT },
  L702: { title: LABEL_PACE_SETTER_KEY_LOCKED_IN_TRUNK_W_TRUNK_RELEASE(), icon: Svg.LOCKED_OUT },
  L703: { title: LABEL_PACE_SETTER_KEY_BROKEN_IN_DOOR_NOT_IGNITION_KEY(), icon: Svg.LOCKED_OUT },
  L704: { title: LABEL_PACE_SETTER_FROZEN_DOOR_LOCK(), icon: Svg.LOCKED_OUT },
  L790: { title: LABEL_PACE_SETTER_OTHER_LOCKOUT_PROBLEM(), icon: Svg.LOCKED_OUT },

  L801: { title: LABEL_PACE_SETTER_LOCKED_OUT(), icon: Svg.LOCKED_OUT },
  L802: { title: LABEL_PACE_SETTER_KEY_LOCKED_IN_TRUNK_NO_TRUNK_RELEASE(), icon: Svg.LOCKED_OUT },
  L803: { title: LABEL_PACE_SETTER_LOST_DAMAGED_VEHICLE_KEY(), icon: Svg.LOCKED_OUT },
  L804: { title: LABEL_PACE_SETTER_LOST_DAMAGED_CLUB_KEY(), icon: Svg.LOCKED_OUT },
  L805: { title: LABEL_PACE_SETTER_KEY_BROKEN_IN_DOOR_IGNITION_KEY(), icon: Svg.LOCKED_OUT },
  L806: { title: LABEL_PACE_SETTER_KEY_BROKEN_IN_IGNITION_SWITCH(), icon: Svg.LOCKED_OUT },
  L807: { title: LABEL_PACE_SETTER_HOME_LOCKOUT(), icon: Svg.LOCKED_OUT },
  L890: { title: LABEL_PACE_SETTER_LOCKED_OUT(), icon: Svg.LOCKED_OUT },

  T905: { title: LABEL_PACE_SETTER_VEHICLE_FIRE(), icon: Svg.TOW },
  T906: { title: LABEL_PACE_SETTER_ACCIDENT(), icon: Svg.ACCIDENT },
  T907: { title: LABEL_PACE_SETTER_CONVENIENCE_MEMBER_CONCERN_TOW(), icon: Svg.TOW },
  T921: { title: LABEL_PACE_SETTER_INSURANCE_ACCIDENT_ASSIST(), icon: Svg.TOW },
  T922: { title: LABEL_PACE_SETTER_INSURANCE_ACCIDENT_ASSIST_SECOND_TOW(), icon: Svg.TOW },
  T931: { title: LABEL_PACE_SETTER_STUCK(), icon: Svg.STUCK },
  T932: { title: LABEL_PACE_SETTER_STUCK(), icon: Svg.STUCK },
  T980: { title: LABEL_PACE_SETTER_LIGHT_SERVICE_REDISPATCH_AS_TOW(), icon: Svg.TOW },
  T990: { title: LABEL_PACE_SETTER_OTHER_REQUIRED_TOW(), icon: Svg.TOW },

  T1: { title: LABEL_PACE_SETTER_FLAT_TIRE(), icon: Svg.FLAT_TIRE },
  T2: { title: LABEL_PACE_SETTER_FLAT_TIRE(), icon: Svg.FLAT_TIRE },
  T3: { title: LABEL_PACE_SETTER_BATTERY(), icon: Svg.BATTERY },
  T4: { title: LABEL_PACE_SETTER_CAR_WONT_START(), icon: Svg.CAR_WONT_START },
  T5: { title: LABEL_PACE_SETTER_OUT_OF_FUEL(), icon: Svg.FUEL },
  T6: { title: LABEL_PACE_SETTER_NEED_A_TOW(), icon: Svg.TOW },
  T7: { title: LABEL_PACE_SETTER_LOCKED_OUT(), icon: Svg.LOCKED_OUT },
  '7A': { title: LABEL_PACE_SETTER_LOCKED_OUT(), icon: Svg.LOCKED_OUT },
  '7B': { title: LABEL_PACE_SETTER_LOCKED_OUT(), icon: Svg.LOCKED_OUT },
  T8: { title: LABEL_PACE_SETTER_STUCK(), icon: Svg.STUCK },
})

export const PACE_SETTER_DATA_RAP = (): PacesetterValue => ({
  ...PACE_SETTER_DATA(),
  L302: { title: LABEL_PACE_SETTER_BATTERY_ISSUE(), icon: Svg.BATTERY },
  L303: { title: LABEL_PACE_SETTER_NO_CRANK_BAT_SVC(), icon: Svg.BATTERY },
  L304: { title: LABEL_PACE_SETTER_BATTERY_SERVICE(), icon: Svg.BATTERY },
  T907: { title: LABEL_PACE_SETTER_CONVENIENCE_CONCERN_TOW(), icon: Svg.TOW },
})

@Pipe({ name: 'issue' })
export class IssuePipe implements PipeTransform {
  constructor(
    private rapService: RapService,
  ) {
  }

  transform(paceSetterCode: PACE_SETTER_SITUATION_CODES, attribute: Types = 'icon'): string {
    const paceSetterData = this.rapService.isRapUser() ? PACE_SETTER_DATA_RAP() : PACE_SETTER_DATA()
    const defaultValue = attribute === 'icon' ? Svg.TOW : $localize`Request`
    return paceSetterData[paceSetterCode]?.[attribute] || defaultValue
  }
}
