<ng-container *ngIf="make">
  <ng-container *ngIf="make.icon; else makeLabel">
    <img
      src="assets/drrweb-lib/images/common-makes/{{ make.icon }}"
      [alt]="make.label"
      draggable="false"
    />
  </ng-container>
  <ng-template #makeLabel>
    {{ make.label }}
  </ng-template>
</ng-container>
