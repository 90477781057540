import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'icon-ev-station-pin',
  templateUrl: './ev-station-pin.icon.html',
  styleUrls: ['ev-station-pin.icon.scss']
})
export class EvStationPinIconComponent extends AbstractIconComponent {
  @Input() selected = false
}
