<ng-container>
  <div class="service-history-row">
    <p class="service-history-row-title small-text bold uppercase">
      Call ID: <span class="service-history-row-text small-text">{{ item.callId }}</span>
    </p>
  </div>
  <div class="service-history-row">
    <p class="service-history-row-title small-text bold uppercase">
      Date: <span class="service-history-row-text small-text">{{ item.callDate }}</span>
    </p>
  </div>
  <div class="service-history-row">
    <p class="service-history-row-title small-text bold uppercase">
      Location: <span class="service-history-row-text small-text">{{ item.breakdownLocation.city }}, {{ item.breakdownLocation.state }}</span>
    </p>
  </div>
  <div class="service-history-row">
    <p class="service-history-row-title small-text bold uppercase">
      Issue: <span class="service-history-row-text small-text">{{ item.pacesetterCode1 }}</span>
    </p>
  </div>
  <div class="collapse-button">
    <a (click)="collapsed = !collapsed" class="small-text red-highlight">SHOW {{ collapsed ? "MORE" : "LESS" }}</a>
  </div>
    <div class="collapsible" [@collapse]="collapsed">
      <div class="service-history-row">
        <p class="service-history-row-title small-text bold uppercase">
          Number of Passengers: <span class="service-history-row-text small-text">{{ item.numberOfPassengers }}</span>
        </p>
      </div>
      <div class="service-history-row">
        <p class="service-history-row-title small-text bold uppercase">
          Legacy Trouble Code: <span class="service-history-row-text small-text">{{ item.legacyTrouble1 }}</span>
        </p>
      </div>
      <div class="service-history-row">
        <p class="service-history-row-title small-text bold uppercase">
          Received Time: <span class="service-history-row-text small-text">{{ item?.receivedTime? getDate(item.receivedTime) : "" }}</span>
        </p>
      </div>
      <div class="service-history-row">
        <p class="service-history-row-title small-text bold uppercase">
          Breakdown Location:
          <span class="service-history-row-text small-text">
            <div *ngFor="let i of Object.entries(item.breakdownLocation)" >
              {{ i[0] }}: {{ i[1] }}
            </div>
          </span>
        </p>
      </div>
      <div class="service-history-row" *ngIf="item.towDestination">
        <p class="service-history-row-title small-text bold uppercase">
          Destination:
          <span class="service-history-row-text small-text">
            <div *ngFor="let i of Object.entries(item.towDestination)" >
              {{ i[0] }}: {{ i[1] }}
            </div>
          </span>
        </p>
      </div>
      <div class="service-history-row">
        <p class="service-history-row-title small-text bold uppercase">
          Service Provider: <span class="service-history-row-text small-text">{{ item.serviceProvider }}</span>
        </p>
      </div>
      <div class="service-history-row">
        <p class="service-history-row-title small-text bold uppercase">
          Vehicle:
          <span class="service-history-row-text small-text">
            <div *ngFor="let i of Object.entries(item.vehicle)">
              {{ i[0] }}: {{ i[1] }}
            </div>
          </span>
        </p>
      </div>
    </div>
</ng-container>
