import { Component, input } from '@angular/core'
import { IonHeader, IonToolbar } from '@ionic/angular/standalone'
import { IconComponent } from '@aaa-mobile/shared-components'
import { Observable } from 'rxjs/internal/Observable'
import { select, Store } from '@ngrx/store'
import { selectIsBgContrast } from '../../../../modules/ui/ui.selectors'
import { AAAStore } from '../../../../store/root-reducer'
import { AsyncPipe, NgClass } from '@angular/common'

@Component({
  selector: 'app-header-redesign',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [IonHeader, IonToolbar, IconComponent, AsyncPipe, NgClass],
  standalone: true,
})
export class HeaderRedesignComponent {
  bgContrast$: Observable<boolean> = this.store$.pipe(select(selectIsBgContrast))
  bgContrast = input(false);

  constructor(private store$: Store<AAAStore>) {}
}
