<svg
  version="1.0"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="19"
  viewBox="0 0 512.000000 512.000000"
  preserveAspectRatio="xMidYMid meet"
  id="icon-engine"
>
  <g
    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
    [attr.fill]="color"
    stroke="none"
  >
    <path
      d="M2075 4496 c-37 -17 -80 -62 -94 -99 -14 -37 -14 -291 0 -342 14 -50
64 -100 114 -114 23 -6 129 -11 253 -11 l212 0 0 -100 0 -100 300 0 300 0 0
100 0 100 208 0 c221 0 264 6 310 45 57 48 67 84 65 245 -1 172 -13 214 -76
260 l-41 30 -760 0 c-614 -1 -767 -3 -791 -14z"
    />
    <path
      d="M1902 3529 c-24 -7 -114 -89 -321 -294 l-288 -285 -177 0 c-203 0
-220 -5 -282 -72 l-41 -45 -8 -231 -7 -232 -88 0 -88 0 -4 218 c-3 210 -4 218
-28 260 -15 27 -42 54 -70 70 -44 26 -50 27 -206 27 -158 0 -161 0 -202 -28
-23 -15 -51 -45 -62 -67 -20 -38 -20 -56 -20 -793 l0 -754 30 -41 c50 -69 94
-86 243 -90 141 -4 190 6 248 55 60 51 63 67 67 311 l4 222 88 0 88 0 7 -222
c4 -123 12 -234 17 -248 5 -14 21 -38 36 -53 51 -54 72 -59 268 -65 l182 -5
275 -267 c152 -147 291 -274 310 -283 32 -15 109 -17 789 -17 720 0 754 1 784
19 70 43 686 652 844 834 40 46 49 86 50 210 l0 97 89 0 88 0 5 -222 c5 -238
9 -258 60 -304 53 -49 87 -58 221 -62 141 -4 190 6 248 55 62 52 59 11 59 833
0 734 0 752 -20 790 -11 22 -39 52 -62 67 -39 27 -49 28 -178 31 -156 4 -205
-5 -256 -50 -64 -56 -67 -70 -72 -310 l-5 -218 -87 0 -87 0 -6 521 -5 520 -27
39 c-15 21 -44 50 -66 64 l-39 26 -1132 -1 c-690 0 -1146 -5 -1166 -10z m1268
-594 c0 -3 -96 -164 -214 -358 -241 -399 -240 -396 -231 -405 4 -4 99 -8 211
-10 112 -2 204 -5 204 -8 0 -2 -103 -133 -228 -291 -125 -159 -257 -326 -292
-373 -85 -112 -310 -394 -310 -387 0 2 42 123 94 268 109 309 186 544 186 570
0 18 -11 19 -177 19 -98 0 -183 4 -189 8 -7 4 69 171 224 490 l236 482 243 0
c134 0 243 -2 243 -5z"
    />
  </g>
</svg>
