import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-login-lite',
  templateUrl: './login-lite.component.html',
  styleUrls: ['./login-lite.component.scss'],
})
export class LoginLiteComponent {
  @Input() controls

  constructor() {}
}
