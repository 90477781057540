import { Inject, Injectable } from '@angular/core'
import { Params, Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map, withLatestFrom } from 'rxjs/operators'
import { selectIsRapEligible, selectIsSignedIn } from '../../modules/auth/auth.selectors'
import { selectHasAvailableCall } from '../../modules/dashboard/calls-statuses/call-status.selectors'
import { StepTypes } from '../../modules/ui/ui.types'
import { AAAStore } from '../../store/root-reducer'
import { selectIsEligible } from '../../modules/member/member.selectors';
import { RouteTypes } from '../../modules/main-router.module'
import { DRR_BASE_HREF } from '../shared.config'

@Injectable({
  providedIn: 'root',
})
export class RouteGuardService {
  constructor(
    private store$: Store<AAAStore>,
    private router: Router,
    @Inject(DRR_BASE_HREF) private drrBaseHref: string
  ) {}

  auth$: Observable<[boolean, boolean, boolean, boolean]> = this.store$.pipe(
    select(selectIsSignedIn),
    withLatestFrom(
      this.store$.pipe(select(selectIsEligible)),
      this.store$.pipe(select(selectIsRapEligible)),
      this.store$.pipe(select(selectHasAvailableCall)),
    )
  )

  hasAvailableCall$: Observable<boolean> = this.store$.pipe(
    select(selectHasAvailableCall)
  )

  isPathAllowed(): Observable<boolean> {
    return this.auth$.pipe(
      map(([isSignedIn, isEligible, isRapEligible, hasActiveCall]) => hasActiveCall || isSignedIn && (isEligible || isRapEligible))
    )
  }

  redirectToLogin(queryParams: Params = {}) {
    this.router.navigate([this.drrBaseHref, ...RouteTypes.SIGNIN.split('/')], { queryParams })
  }

  redirectToFirstStep(queryParams: Params = {}) {
    this.router.navigate([this.drrBaseHref, RouteTypes.STEPS], {
      queryParams: { ...queryParams, step: StepTypes.BREAKDOWN_LOCATION },
      replaceUrl: true,
    })
  }

  redirectToServiceTracker() {
    return this.hasAvailableCall$.pipe(
      map((hasAvailableCall) =>
        hasAvailableCall
          ? this.router.navigate([this.drrBaseHref, RouteTypes.DASHBOARD])
          : this.router.navigate([this.drrBaseHref, RouteTypes.STEPS])
      )
    )
  }
}
