import { Component, Input, OnInit } from '@angular/core';
import { Color, VehicleUtils } from '../../../modules/vehicle/vehicle.utils';

@Component({
  selector: 'app-vehicle-colors',
  templateUrl: './vehicle-colors.component.html',
  styleUrls: ['./vehicle-colors.component.scss']
})
export class VehicleColorsComponent{
  @Input() color: string
  @Input() width = 12
  @Input() height = 30

  constructor(
    private vehicleUtils: VehicleUtils,
  ) {
  }

  isColorAvailable() {
    return this.vehicleUtils.isColorAvailable(this.color)
  }

}
