import { createFeatureSelector, createSelector, defaultMemoize } from '@ngrx/store'
import { TaggingState } from './tagging.reducer'
import { CALL_STATUS_CODES } from '../dashboard/calls.types'

const stateKey = 'tagging'

export const selectTaggingState = createFeatureSelector<TaggingState>(stateKey)

export const createSelectTaggedCallStatus = createSelector(
  selectTaggingState,
  (state: TaggingState) => defaultMemoize((callId: string) => state[callId])
)

export const createSelectIsCallStatusTagged = createSelector(
  createSelectTaggedCallStatus,
  (selectTaggedCallStatus) =>
    (callId: string, callStatus: CALL_STATUS_CODES): boolean =>
      callStatus === selectTaggedCallStatus.memoized(callId)
)

export const createSelectIsTaggedCallStatusNew = createSelector(
  createSelectTaggedCallStatus,
  (selectTaggedCallStatus) =>
    (callId: string): boolean =>
      CALL_STATUS_CODES.NEW === selectTaggedCallStatus.memoized(callId)
)
