import { Component } from '@angular/core'

import { AbstractIconComponent } from './abstract.icon'

@Component({
  selector: 'icon-details',
  templateUrl: './details.icon.html',
})
export class DetailsIconComponent extends AbstractIconComponent {
  static icon = 'details'
}
