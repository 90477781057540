import { Component } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { selectIsCouponApplied } from '../../ui/ui.selectors'
import { Observable } from 'rxjs'
import { setIsCouponApplied } from '../../ui/ui.actions'
import { TaggingService } from '../../tagging/tagging.service'
import events from '../../tagging/events'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { AdobeEventTypes } from '../../tagging/tagging.types'

@Component({
  selector: 'app-tow-location-discount-banner',
  templateUrl: './tow-location-discount-banner.component.html',
  styleUrls: ['./tow-location-discount-banner.component.scss'],
})
export class TowLocationDiscountBannerComponent {
  isLoading = false
  isToastOpen = false

  isCouponApplied$: Observable<boolean> = this.store$.pipe(
    select(selectIsCouponApplied)
  )

  shopBenefits = () => [
    $localize`Quality and Reliable Service`,
    $localize`Expert Staff & Mechanics`,
    $localize`Member Discounts`
  ]

  constructor(
    private store$: Store<AAAStore>,
    private tagging: TaggingService,
    private adobeEventService: AdobeEventService
  ) {}

  applyCoupon(payload = true) {
    this.isLoading = true
    setTimeout(() => {
      this.isLoading = false
      this.isToastOpen = true
      this.store$.dispatch(setIsCouponApplied({ payload }))
      this.tagging.setClickEvent(
        events.towTo.DESTINATION_COUPON_APPLIED,
        events.towTo.DESTINATION_PAGE_TYPE,
      )
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.towTo.DESTINATION_COUPON_APPLIED,
      })
    }, 1000)
  }
}
