<div class="vin-mileage-credentials">
    <label  i18n for="vinNumber">Vehicle Identification Number (VIN)</label>
    <input
        i18n-placeholder
        name="vinNumber"
        id="vinNumber"
        #vinNumber
        type="text"
        placeholder="Vehicle Identification Number (VIN)"
        autocomplete="on"
        mask="A{17}"
        [showMaskTyped]="!is8VinForm && controls.vinNumber.value.length > 0"
        [ngrxFormControlState]="controls.vinNumber"
        [tabIndex]="2" />
    <app-form-error i18n [control]="controls?.vinNumber" name='vinNumber' type='required'>
        Vehicle Identification Number (VIN) is required.
    </app-form-error>
    <app-form-error i18n [control]="controls?.vinNumber" name='vinNumber' type='pattern'>
        <ng-container *ngIf="is8VinForm; else only17Vin">
          Vehicle Identification Number (VIN) is invalid.
        </ng-container>
    </app-form-error>
    <label i18n id="last-name-label" for="mileage">{{mileageLabel}}</label>
    <input
        i18n-placeholder
        name="mileage"
        id="mileage"
        #mileage
        type="number"
        placeholder="Mileage"
        autocomplete="on"
        [ngrxFormControlState]="controls.mileage"
        [tabIndex]="3" />
    <app-form-error i18n [control]="controls?.mileage" name='mileage' type='required'>
        Mileage is required.
    </app-form-error>
    <app-form-error i18n [control]="controls?.mileage" name='mileage' type='greaterThan'>
        Mileage must be greater than 0.
    </app-form-error>
    <app-form-error i18n [control]="controls?.mileage" name='mileage' type='lessThan'>
        Mileage number is too long.
    </app-form-error>
</div>

<ng-template #only17Vin>
  Vehicle Identification Number (VIN) must be exactly 17 characters long.
</ng-template>
