import { Component } from '@angular/core'
import { IonButtons, IonMenuButton } from '@ionic/angular/standalone'
import { MenuComponent } from '../menu/menu.component'

@Component({
  selector: 'app-menu-button',
  standalone: true,
  imports: [IonButtons, IonMenuButton, MenuComponent],
  templateUrl: './menu-button.component.html',
  styleUrl: './menu-button.component.scss',
})
export class MenuButtonComponent {}
