<!-- Dialog Content -->
<div class="dialog phone-number">
  <h1 mat-dialog-title i18n>
      Help us Find Your Account
  </h1>
  <p i18n>Please enter the phone number associated with your AAA membership:</p>

  <div class="phone-numbers-hint">
    <span class="hint-label" i18n>HINT:</span>
    <div class="hint-numbers">
      <ng-container *ngFor="let hint of data.params?.phoneNumbersHint" >
        <span class="hint-number">(XXX) XXX {{hint.phoneNumber}}</span>
      </ng-container>
    </div>
  </div>

  <form id="form"
        (ngSubmit)="form.isValid && handlePhoneNumberForm()"
        [ngClass]="{'error': !form.isValid, 'error-hint': !hintMatches }"
        [ngrxFormState]="form">

    <div mat-dialog-content class="modal-form">
      <label for="phoneNumber" i18n>Phone Number of primary account holder</label>
      <input #input
             name="phoneNumber"
             id="phoneNumber"
             type="tel"
             placeholder="10 digit phone number"
             i18n-placeholder
             [ngrxFormControlState]="form.controls.phoneNumber"
             [mask]="phoneNumberMask"
             aria-required="true"
             autocomplete="tel-national"
             [autofocus]="true"
             (keyup)="validateNumber()"
             required/>
      <app-form-error i18n *ngIf="form.isSubmitted" [control]="form.controls.phoneNumber"
                      name='phoneNumber'
                      type='required'>
        Phone number is required.
      </app-form-error>
      <app-form-error i18n *ngIf="form.isSubmitted" [control]="form.controls.phoneNumber"
                      name='phoneNumber'
                      type='pattern'>
        Phone number must be exactly 10 digits.
      </app-form-error>
      <ng-container *ngIf="!hintMatches">
        <span class="error-hint" i18n>The phone number entered does not match any of the above.</span>
      </ng-container>
    </div>

    <div mat-dialog-actions>
      <app-button i18n
        type="submit"
        class="phone-number-confirm-button"
        [loading]="(isSigningIn$ | async) === true"
        [disabled]="(isSigningIn$ | async)">
        Confirm
      </app-button>
    </div>
  </form>

  <button i18n-aria-label mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message" (click)="handleClose()">
    <icon-close></icon-close>
  </button>
</div>

