import { NgModule } from '@angular/core'
import { DrrWebMainComponent } from './drrweb-main.component'
import { SharedModule } from '../shared'
import { DRR_WEB_MAIN_ROUTES } from './drrweb-main.routes'
import { RouterModule } from '@angular/router'
import { MainModule } from '../modules/main.module'

@NgModule({
  declarations: [DrrWebMainComponent],
  exports: [DrrWebMainComponent],
  imports: [
    RouterModule.forChild(DRR_WEB_MAIN_ROUTES),
    MainModule,
    SharedModule,
  ],
})
export class DrrWebMainModule {}
