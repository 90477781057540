import { Injectable } from '@angular/core'
import {
  AdobeAnalyticsParams,
  AdobeEventTypes,
  MemberValidationType,
  BusinessLineProduct
} from '../tagging.types'
import { AdobeAnalyticsService } from './adobe-analytics.service'
import { Store, select } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { selectUserSessionId } from '../../session/session.selectors'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { getAppId } from '../../../shared/utils/cookies'
import { RapService } from '../../rap/rap.service'
import { AppId } from '../../auth/auth.types'
import { isAndroid, isChromeMobile, isIOS, isSafariMobile } from '../../../shared/utils/browser-detect'
import { ConfigService } from '../../config/config.service'

@Injectable({
  providedIn: 'root',
})
export class AdobeMemberValidationService {
  businessLine = 'Automotive'
  businessLineProduct = BusinessLineProduct.DIGITAL_ERS
  isNativeAppView = this.configService.getConfig().nativeAppView

  isIos = isIOS()
  isAndroid = isAndroid()
  isSafariMobile = isSafariMobile()
  isChromeMobile = isChromeMobile()

  constructor(
    private adobeAnalyticsService: AdobeAnalyticsService,
    private store$: Store<AAAStore>,
    private rapService: RapService,
    private configService: ConfigService
  ) { }

  sendEvent(
    eventType:
      AdobeEventTypes.MEMBER_VALIDATION |
      AdobeEventTypes.MEMBER_VALIDATION_PROMPT |
      AdobeEventTypes.MEMBER_VALIDATION_TOGGLE |
      AdobeEventTypes.MEMBER_VALIDATION_RETURN,
    memberValidationType: MemberValidationType,
    directAttributes?: {}
  ) {
    const params$ = this.getMemberValidationParams(eventType, memberValidationType, directAttributes)

    params$.subscribe(params => {
      try {
        this.adobeAnalyticsService.transmitAdobeAnalyticsEvents(params)
      } catch (error) {
        console.warn('Error transmitting Adobe Analytics events', error)
      }
    })
  }

  private getMemberValidationParams(
    eventType:
      AdobeEventTypes.MEMBER_VALIDATION |
      AdobeEventTypes.MEMBER_VALIDATION_PROMPT |
      AdobeEventTypes.MEMBER_VALIDATION_TOGGLE |
      AdobeEventTypes.MEMBER_VALIDATION_RETURN,
    memberValidationType: MemberValidationType,
    directAttributes?: {}
  ): Observable<AdobeAnalyticsParams> {
    return this.store$.pipe(
      select(selectUserSessionId),
      take(1),
      map((sessionId: string) => ({
        eventName: eventType,
        directAttributes: {
          ...directAttributes,
          member_validation_type: memberValidationType,
          business_line: this.businessLine,
          business_line_product: this.businessLineProduct,
          app_id: this.getAdobeAppId(),
          session_id: sessionId
        }
      })
      )
    )
  }

  getAdobeAppId(): string {
    const appId = getAppId()

    if (this.rapService.isRapUser()) {
      return AdobeAppId.RAP
    }

    if (appId === AppId.IOSMOBILE || this.isNativeAppView && this.isIos) {
      return AdobeAppId.IOS_NATIVE
    }

    if (appId === AppId.ANDROIDMOBILE || this.isNativeAppView && this.isAndroid) {
      return AdobeAppId.ANDROID_NATIVE
    }

    if (appId in AppId) {
      return appId
    }

    if (this.isSafariMobile) {
      return AdobeAppId.IOS_BROWSER
    }

    if (this.isChromeMobile) {
      return AdobeAppId.ANDROID_BROWSER
    }

    return AdobeAppId.DRRWEB
  }
}

export enum AdobeAppId {
  IOS_NATIVE = "IOS_NATIVE",
  IOS_BROWSER = "IOS_BROWSER",
  ANDROID_NATIVE = "ANDROID_NATIVE",
  ANDROID_BROWSER = "ANDROID_BROWSER",
  RAP = "RAP",
  DRRWEB = "DRRWEB",
}
