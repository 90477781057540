<div class="add-plate-section" [ngClass]="{'warning': displayError}">
  <div>
    <div i18n class="title-small" *ngIf="!(memberVehicles$ | async).length">ADD A VEHICLE</div>
    <div i18n class="title">
      Enter your Vehicle's License Plate and State below:
    </div>

    <ng-container *ngIf="displayError">
      <div class="warning-banner">
        <app-banner
          animated="true"
          [classNames]="'warning'"
        >
          <div class="warning-content">
            <icon-warning/>
            <div i18n class="message">
              The License Plate or State you entered is not correct. Please try again.
            </div>
          </div>
        </app-banner>
      </div>
    </ng-container>

    <form name="form"
          id="plate-to-vin-form"
          (ngSubmit)="handleAddVehicle()"
          [ngClass]="{'error': !form.isValid }"
          [ngrxFormState]="form">
      <div class="plate-form">
        <app-input-license-plate
          [error]="displayError"
          (licensePlateChange)="searchVehicle()"
          [tabIndex]="0"
        ></app-input-license-plate>

        <app-input-us-state
          (selectedUsStateChange)="setState($event)"
          [selection]="state"
          [error]="errorState || displayError"
          [tabIndex]="1"
        ></app-input-us-state>

        <app-input-vehicle-color #vehicleColor
          (selectedColorChange)="setColor($event)"
          [selectedColor]="color"
          [error]="errorColor"
          [tabIndex]="2"
        ></app-input-vehicle-color>
      </div>

      <app-button
        i18n
        id="add-vehicle-btn"
        class="add-vehicle-btn"
        [color]="'primary-yellow'"
        [hasShadow]="true"
        [disabled]="displayError"
        [loading]="submitPending"
        [tabindex]="3"
        type="submit">
        ADD VEHICLE
      </app-button>
    </form>
  </div>

  <ng-container *ngIf="displayAddVehicle; else dontKnowLicensePlate">
    <app-clickable-link i18n (clickLink)="clearVehicleAndGoToYears()" class="bottom-link add-vehicle-year-make-model">
      Add Vehicle Year/Make/Model
    </app-clickable-link>
  </ng-container>
  <ng-template #dontKnowLicensePlate>
    <app-clickable-link i18n (clickLink)="handleDontKnowLicensePlate()" class="bottom-link dont-know-license-plate">
      I don't know my license plate.
    </app-clickable-link>
  </ng-template>
</div>

