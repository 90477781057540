import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadModeConfiguration } from '../../auth/mode-configuration.actions';
import { setAppId } from '../../config/config.actions';
import { AuthConfigParamsKeys } from '../../config/config.types';
import { RouteTypes } from '../../main-router.module';
import { setRapAuthRequest, setRapConfirmOdometer } from '../../auth/auth.actions';
import { DRR_BASE_HREF } from '../../../shared/shared.config';

@Component({
  selector: 'app-rap-app-link',
  templateUrl: './rap-app-link.component.html'
})
export class RapAppLinkComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store$: Store,
    @Inject(DRR_BASE_HREF) private drrBaseHref: string,
  ) { }

  ngOnInit(): void {
    const appId = this.route.snapshot.paramMap.get('appid')
    this.store$.dispatch(loadModeConfiguration({ payload: appId.toUpperCase() }))
    this.store$.dispatch(setAppId({ payload: appId.toUpperCase() }))

    const rapToken = this.route.snapshot.queryParams[AuthConfigParamsKeys.RAP_TOKEN]
    if (rapToken) {
      const odometer = this.route.snapshot.queryParams[AuthConfigParamsKeys.ODOMETER]
      if (odometer === undefined) {
        this.store$.dispatch(setRapAuthRequest({
          payload: {
            access_token: rapToken,
          }
        }))
      } else {
        const odometerNumber = odometer === ''? 0 : Number(odometer)
        this.store$.dispatch(setRapConfirmOdometer({
          payload: {
            access_token: rapToken,
            odometer: odometerNumber
          }
        }))
        this.store$.dispatch({
          controlId: 'form.odometerConfirmation.mileage',
          value: odometerNumber,
          type: 'ngrx/forms/SET_VALUE'
        })
        this.router.navigate([this.drrBaseHref, ...RouteTypes.SIGNIN.split('/')])
      }
    } else {
      this.router.navigate([this.drrBaseHref, ...RouteTypes.SIGNIN.split('/')])
    }
  }

}
