import { RESET_LOADING } from './loading.actions'

const initialState: LoadingState = {}

export interface LoadingState {
  [action: string]: boolean
}

export function reducer(
  state: LoadingState | undefined = initialState,
  action
): LoadingState {
  switch (action.type) {
    case '@ngrx/effects/init':
      return {}
    case RESET_LOADING:
      return { ...initialState }
    default:
      return { ...state }
  }
}
