import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'icon-car',
  templateUrl: './car.icon.html',
})
export class CarIconComponent extends AbstractIconComponent {
  static icon = 'car'
}
