import { MEMBERSHIP_NUMBER_LENGTH } from '../../modules/ui/forms/forms.reducer'

function luhnChecksum(code: string) {
  const len = code.length
  const parity = len % 2
  let sum = 0
  for (let i = len-1; i >= 0; i--) {
    let d = parseInt(code.charAt(i), 10)
    if (i % 2 === parity) {
      d *= 2
    }
    if (d > 9) {
      d -= 9
    }
    sum += d
  }
  return sum % 10
}

export function validateMembershipNumber(membershipNumber: string) {
  if(!membershipNumber || membershipNumber.replace(/\s/g, '').length !== MEMBERSHIP_NUMBER_LENGTH) {
    return false
  }
  return luhnChecksum(membershipNumber.replace(/\s/g, '')) === 0
}
