import { Pipe, PipeTransform } from '@angular/core'

const PHONE_FORMAT = /[^0-9]/g
const PHONE_LENGTH = 11

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    const number = (value || '')
      .toString()
      .replace(PHONE_FORMAT, '')
      .padStart(PHONE_LENGTH, '1')

    return `+${number}`
  }
}
