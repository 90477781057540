<svg
  width="18px"
  height="24px"
  viewBox="0 0 18 24"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  id="icon-details"
>
  <g
    id="Service-Tracker---Active-Requests-and-Advisory"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      id="Desktop-HD---AAA-RSO---Active-Request-Tabs---Details"
      transform="translate(-50.000000, -490.000000)"
      fill="#003893"
      fill-rule="nonzero"
    >
      <g id="Service-Tracker" transform="translate(18.000000, 159.000000)">
        <g id="Tabs-2" transform="translate(0.000000, 318.000000)">
          <g id="Tabs" transform="translate(-14.000000, 1.000000)">
            <g id="Details" transform="translate(37.000000, 12.000000)">
              <g id="details_icon" transform="translate(9.000000, 0.000000)">
                <path
                  d="M17.1998021,2 L13.461321,2 L13.7135541,3.16363862 C13.7445756,3.30809334 13.7430588,3.45313349 13.7268141,3.59583192 L16.3994085,3.59583192 L16.3994085,22.4042657 L1.59902575,22.4042657 L1.59902575,3.59583192 L4.27240305,3.59583192 C4.25615836,3.45235292 4.25537548,3.30731277 4.28634804,3.16207748 L4.53863007,2 L0.799512877,2 C0.357627883,2 0,2.35730711 0,2.79784278 L0,23.2021572 C0,23.6420099 0.357627883,24 0.799512877,24 L17.1998021,24 C17.6416871,24 18,23.6418635 18,23.2021572 L18,2.79789157 C18.0000489,2.35730711 17.6416871,2 17.1998021,2 Z"
                  id="Shape"
                ></path>
                <path
                  d="M14.2073203,8 L8.7927281,8 C8.35421932,8 8,8.44801957 8,8.99954121 C8,9.55222511 8.35426779,10 8.7927281,10 L14.2073203,10 C14.6458291,10 15,9.55222511 15,8.99954121 C15.0000969,8.44801957 14.6458291,8 14.2073203,8 Z"
                  id="Shape"
                ></path>
                <path
                  d="M14.2073203,12 L8.7927281,12 C8.35421932,12 8,12.4477393 8,12.9999083 C8,13.5514659 8.35426779,14 8.7927281,14 L14.2073203,14 C14.6458291,14 15,13.5512213 15,12.9999083 C15.0000969,12.4477393 14.6458291,12 14.2073203,12 Z"
                  id="Shape"
                ></path>
                <path
                  d="M3.29216872,7.20521099 C2.94540677,7.48996418 2.90076708,7.9947406 3.19338832,8.33282386 L4.39257742,9.7159807 C4.54926878,9.89696044 4.77905261,10 5.02075042,10 C5.04075785,10 5.0605642,9.99921717 5.08122514,9.99770045 C5.34358389,9.97915724 5.58136064,9.83829737 5.71980402,9.62003551 L7.87708765,6.22075754 C8.11566872,5.84460933 7.99647872,5.35230922 7.6100538,5.12029897 C7.2235786,4.88657629 6.7177626,5.00409814 6.47913126,5.38024635 L4.91744572,7.83993662 L4.44988513,7.30115618 C4.15716335,6.96307292 3.63958419,6.91967496 3.29216872,7.20521099 Z"
                  id="Shape"
                ></path>
                <path
                  d="M5.00030578,12 C4.44770156,12 4,12.4477435 4,12.9999388 C4,13.5522972 4.44766079,14 5.00030578,14 C5.55246152,14 6,13.5522972 6,12.9999388 C6,12.447662 5.55246152,12 5.00030578,12 Z"
                  id="Shape"
                ></path>
                <path
                  d="M14.2073203,17 L8.7927281,17 C8.35421932,17 8,17.447061 8,18.0005505 C8,18.5520827 8.35426779,19 8.7927281,19 L14.2073203,19 C14.6458291,19 15,18.5520827 15,18.0005505 C15.0000969,17.4469998 14.6458291,17 14.2073203,17 Z"
                  id="Shape"
                ></path>
                <path
                  d="M5.00030578,17 C4.44770156,17 4,17.4476662 4,17.9999796 C4,18.5521299 4.44766079,19 5.00030578,19 C5.55246152,19 6,18.5522522 6,17.9999796 C6,17.4476662 5.55246152,17 5.00030578,17 Z"
                  id="Shape"
                ></path>
                <path
                  d="M5.55681293,4 L12.4423053,4 C12.6111048,4 12.7705238,3.91953765 12.8763896,3.77908561 C12.9824538,3.64000899 13.0233509,3.45612527 12.98702,3.28022959 L12.4171417,0.468649571 C12.3614542,0.195045825 12.1346345,0 11.872278,0 L6.12758466,0 C5.86547636,0 5.638607,0.195098726 5.58277064,0.468649571 L5.01199891,3.28022959 C4.97750445,3.45607237 5.01820295,3.64000899 5.12352274,3.77908561 C5.22948776,3.91959055 5.38890678,4 5.55681293,4 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
