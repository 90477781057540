<svg
  xmlns="http://www.w3.org/2000/svg"
  width="18"
  height="18"
  viewBox="0 0 18 18"
  id="icon-mechanical-problem"
>
  <path
    [attr.fill]="color"
    fill-rule="evenodd"
    d="M10.33 7.233c.735-1.89.327-4.11-1.225-5.671C7.47-.082 5.02-.411 3.059.493l3.513 3.534L4.12 6.493.526 2.96c-.98 1.973-.571 4.438 1.062 6.082 1.553 1.562 3.758 1.973 5.637 1.233l7.435 7.48a.786.786 0 0 0 1.144 0l1.879-1.891c.408-.329.408-.904.081-1.15l-7.434-7.48z"
  />
</svg>
