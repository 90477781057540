import { Directive, ElementRef, Input } from '@angular/core'
import { FormControlState } from 'ngrx-forms'

@Directive({
  selector: '[ngrxFormControlState]',
})
export class FormAriaInvalidDirective {

  @Input() ngrxFormControlState: FormControlState<string>

  constructor(
    private element: ElementRef
  ) { }

  ngOnChanges(changes) {
    if(changes?.ngrxFormControlState && changes.ngrxFormControlState?.currentValue) {
      this.setElementState(changes.ngrxFormControlState?.currentValue)
    }
  }

  private setElementState(control: FormControlState<any>) {
    const formElement = this.element.nativeElement

    const isRequired =  Boolean(control.errors.required);
    const invalid = control.isTouched && control.isInvalid && isRequired

    formElement.setAttribute('aria-invalid', invalid)

    if(invalid) {
      formElement.focus();
    }
  }

}
