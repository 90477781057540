import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'
import { TaggingService } from '../../../tagging/tagging.service'
import { ClosableDialog } from '../closable-dialog'

@Component({
  selector: 'app-vin-issues-dialog-component',
  templateUrl: './rap-not-eligible-dialog.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class RapNotEligibleDialogComponent extends ClosableDialog {
  pageType = 'Verification VIN Issue'

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private taggingService: TaggingService,
  ) {
    super(data)
    const _action = 'Verification VIN Issue Prompt'
    this.taggingService.setPageEvent(_action, this.pageType)
  }

  onCallAAAClick() {
    this.onClose()
  }

}
