export default {
  LOST_MEMBERSHIP_CLICK: 'Don\'t Have Membership Number',
  NOT_A_MEMBER_CLICK: 'Not a Member',
  AAA_NATIONAL_AUTH_REQUEST: 'Membership Validation Request - Secure',
  AAA_NATIONAL_AUTH_SUCCESS: 'Membership Validation Success - Secure',
  AAA_NATIONAL_AUTH_FAILURE: 'Membership Validation Failed - Secure',
  AAA_MOBILE_AUTH_REQUEST: 'Membership Validation Request - Token',
  AAA_MOBILE_AUTH_SUCCESS: 'Membership Validation Success - Token',
  AAA_MOBILE_AUTH_FAILURE: 'Membership Validation Failed - Token',
  FORM_MEMBERSHIP_AUTH_PROMPT: 'Membership Validation Prompt',
  FORM_MEMBERSHIP_AUTH_REQUEST: 'Membership Validation Request',
  FORM_MEMBERSHIP_AUTH_SUCCESS: 'Membership Validation Success',
  FORM_MEMBERSHIP_AUTH_FAILURE: 'Membership Validation Failed',
  FORM_PHONE_NUMBER_ONLY_AUTH_PROMPT: 'Phone Number Only Validation Prompt',
  FORM_PHONE_NUMBER_ONLY_AUTH_REQUEST: 'Phone Number Only Validation Request',
  FORM_PHONE_NUMBER_ONLY_AUTH_SUCCESS: 'Phone Number Only Validation Success',
  FORM_PHONE_NUMBER_ONLY_AUTH_FAILURE: 'Phone Number Only Validation Failed',
  FORM_NAME_AUTH_PROMPT: 'Membership Validation Prompt - Name',
  FORM_NAME_AUTH_REQUEST: 'Membership Validation Request - Name',
  FORM_NAME_AUTH_SUCCESS: 'Membership Validation Success - Name',
  FORM_NAME_AUTH_FAILURE: 'Membership Validation Failed - Name',
  MEMBER_VALIDATION_INVALID_ZIP_CODE: 'Invalid member info',
  MEMBER_VALIDATION_INVALID_NUMBER: 'Error validating member',
  MEMBER_VALIDATION_INACTIVE_MEMBER: 'Membership not active',
  FORM_RAP_VALIDATION_SUCCESS: 'RAP Validation Success',
  FORM_RAP_VALIDATION_FAILURE: 'RAP Validation Failed',
  FORM_RAP_TOKEN_VALIDATION_REQUEST: 'RAP Login Bypass Validation Request - Token',
  FORM_RAP_TOKEN_VALIDATION_SUCCESS: 'RAP Login Bypass Validation Success - Token',
  FORM_RAP_TOKEN_VALIDATION_FAILURE: 'RAP Login Bypass Validation Failed',
  USE_FORM_NAME_CLICK: 'Used Member Name',
  USE_FORM_MEMBERSHIP_CLICK: 'Used Member Number',
  USE_FORM_MOBILE_CLICK: 'Used Member Phone Number',
  MEMBER_ISSUE_PAGE_TYPE: 'Verification Membership Issue',
  MEMBER_ISSUE_PROMPT: 'Verification Membership Issue Prompt',
  FIRST_ERROR_ATTEMPT: 'First failed attempt',
  SECOND_ERROR_ATTEMPT: 'Second failed attempt',
  THIRD_ERROR_ATTEMPT: 'Third failed attempt',
  AUTH_SUCCESS_AFTER_FAILED_ATTEMPT: 'Validation success after failed attempt',
  CAPTCHA_VALIDATION_SUCCESS: 'Captcha validation success',
  CAPTCHA_VALIDATION_FAILURE: 'Captcha validation failed',
  CHANGE_TYPE_ZIP_CODE: 'Changed zip code input type to',
  CAA_ZIP_FORMAT_AUTH_NAME: 'Membership Validation Name Success - CAA',
  CAA_ZIP_FORMAT_AUTH_NUMBER: 'Membership Validation Number Success - CAA',
  CAA_ZIP_FORMAT_AUTH_PHONE: 'Membership Validation Phone Success - CAA',

  PAGE_TYPE: 'Verification Home',
  PAGE_NAME_LOGIN_LITE: 'Member Name Verification',
  PAGE_NAME_MEMBERSHIP_NUMBER: 'Member Number Verification',
  PAGE_NAME_RAP_VERIFICATION: 'Verification',
}
