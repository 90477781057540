<div class="dialog dialog-error">
  <h1 i18n mat-dialog-title>Notice</h1>
  <div id="dialog-content" mat-dialog-content>
    <p i18n class="font-size-large">
      We are having trouble verifying your information, please try again. If the problem persists please contact us:
    </p>
  </div>
  <div *ngIf="!data.params.hideActions" mat-dialog-actions>
    <app-call-aaa-button
      i18n-aria-label
      [clickEventPage]="pageType"
      (buttonClick)="onCallAAAClick()"
      aria-label="Contact us"
      >
    </app-call-aaa-button>
    <button
      i18n
      i18n-aria-label
      *ngIf="!!data.params.showDismissButton"
      id="dialog-dismiss-submit"
      name="dialogGenericErrorDismiss"
      class="btn primary btn-sm"
      (click)="onClose()"
      aria-label="dismiss this message"
    >
      Dismiss
    </button>
  </div>
  <button i18n-aria-label *ngIf="!hideCloseButton" mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message">
    <icon-close></icon-close>
  </button>
</div>
