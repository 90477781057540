import { deepCopy } from '../utils/deepCopy'
import { CALL_STATUS_CODES } from '../../modules/dashboard/calls.types'

export const CALLS_STATUSES_STORE_FIXTURE = () =>
  deepCopy({
    activeCallStatus: '20153',
    canceledCalls: ['20153', '20148'],
    drivers: {
      'driverId-1': {
        driverId: 'driverId-1',
        firstName: 'Jhon',
        lastName: 'Doe',
        initial: 'JD',
        images: [],
      },
      'driverId-2': {
        driverId: 'driverId-1',
        firstName: 'Jhon',
        lastName: 'Doe',
        initial: 'JD',
        images: [],
      },
    },
    data: {
      '20153-20201012': {
        callId: '20153',
        callDate: '20201012',
        callToken: 'MjAxNTMtMzExLUNBTEwtMjAyMDEwMTI',
        updateToken: 'MjAxNTMtMzExLVVQREFURS0yMDIwMTAxMg',
        trackToken: 'MjAxNTMtMzExLVRSQUNLLTIwMjAxMDEy',
        callStatus: 'RE',
        pta: '2020-10-12T23:26:45Z',
        servicingClub: '311',
        pacesetterCode: 'L301',
        vehicle: {
          year: 2020,
          make: 'Chevrolet',
          model: 'Blazer',
          color: 'silver',
        },
        towDestination: {
          location: 'AAA Car shop',
          latitude: 33.74012,
          longitude: -84.39392,
          streetNumber: '2161',
          streetName: 'Lavista Road',
          city: 'Atlanta',
          state: 'GA',
          isAar: true,
        },
        breakdownLocation: {
          streetNumber: '123',
          streetName: 'Atlanta Ave SE',
          city: 'ATLANTA',
          state: 'GA',
          postalCode: '30315',
          latitude: 33.73012,
          longitude: -84.38392,
        },
        driverData: {
          id: 'driverId-1',
          eta: '07:26 PM',
          latitude: 33.73012,
          longitude: -84.38392,
        },
        tracking: {
          supported: true,
          provider: 'AAA',
        },
      },
    },
  })

export const CALLS_STATUSES_FIXTURE = () =>
  deepCopy({
    callStatuses: [
      {
        callId: '20153',
        callDate: '20201012',
        callToken: 'MjAxNTMtMzExLUNBTEwtMjAyMDEwMTI',
        updateToken: 'MjAxNTMtMzExLVVQREFURS0yMDIwMTAxMg',
        trackToken: 'MjAxNTMtMzExLVRSQUNLLTIwMjAxMDEy',
        callStatus: 'RE',
        pta: '2020-10-12T23:26:45Z',
        servicingClub: '311',
        pacesetterCode: 'L301',
        vehicle: {
          year: 2020,
          make: 'Chevrolet',
          model: 'Blazer',
          color: 'silver',
        },
        towDestination: {
          location: 'AAA Car shop',
          latitude: 33.74012,
          longitude: -84.39392,
          streetNumber: '2161',
          streetName: 'Lavista Road',
          city: 'Atlanta',
          state: 'GA',
        },
        breakdownLocation: {
          streetNumber: '123',
          streetName: 'Atlanta Ave SE',
          city: 'ATLANTA',
          state: 'GA',
          postalCode: '30315',
          latitude: 33.73012,
          longitude: -84.38392,
        },
        driverData: {
          eta: '07:26 PM',
        },
        tracking: {
          supported: true,
          provider: 'AAA',
        },
      },
    ],
    callHistory: [
      {
        callId: '20148',
        callDate: '20201012',
        callStatus: 'CA',
        servicingClub: '311',
      },
      {
        callId: '20146',
        callDate: '20201012',
        callStatus: 'CA',
        servicingClub: '311',
      },
      {
        callId: '20135',
        callDate: '20201012',
        callStatus: 'CA',
        servicingClub: '311',
      },
    ],
  })

export const CALLS_STATUSES_MESSAGES_FIXTURE = (activeCallStatus: CALL_STATUS_CODES, needsTow: boolean) => {
  const messages = deepCopy([
    {
      title: $localize`Request Received`,
      description: $localize`We will notify you once your request has been assigned to a service provider.`,
      active: ![
        CALL_STATUS_CODES.ER,
        CALL_STATUS_CODES.OL,
        CALL_STATUS_CODES.OS,
        CALL_STATUS_CODES.TW
      ].includes(activeCallStatus),
    },
    {
      title: $localize`En Route`,
      description: $localize`Please plan to meet your service provider at your vehicle's breakdown location.`,
      active: activeCallStatus === CALL_STATUS_CODES.ER,
    },
    {
      title: $localize`Arrived`,
      description: $localize`Please meet your service provider at your vehicle's breakdown location.`,
      active: [CALL_STATUS_CODES.OL, CALL_STATUS_CODES.OS].includes(activeCallStatus),
    }
  ])
  if (needsTow) {
    messages.push({
      title: $localize`Tow`,
      description: $localize`Rest easy during your towing service. We appreciate your patience and thank you for choosing us.`,
      active: activeCallStatus === CALL_STATUS_CODES.TW,
    })
  }
  return messages
}
