import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'

import { WizardComponent } from './wizard.component'

const routes: Routes = [
  {
    path: '',
    component: WizardComponent,
  },
]

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule],
})
export class WizardRoutingModule {}
