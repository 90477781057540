import { ProgressMapComponent } from './progress-map/progress-map.component'
import { BatteryQuotesBannerComponent } from './battery-quotes-banner/battery-quotes-banner.component'
import { ExternalTrackerComponent } from './external-tracker/external-tracker.component'
import { ArrivalTimeLabelComponent } from './arrival-time-label/arrival-time-label.component'
import { MapServiceTrackingComponent } from '../location/map-service-tracking/map-service-tracking.component'
import { ServiceTrackingComponent } from '../service-tracking/service-tracking.component'
import { CallStatusHistoryComponent } from '../service-tracking/call-status-history/call-status-history.component'

export function dashboardComponents() {
  return [
    ProgressMapComponent,
    MapServiceTrackingComponent,
    BatteryQuotesBannerComponent,
    ExternalTrackerComponent,
    ArrivalTimeLabelComponent,
    ServiceTrackingComponent,
    CallStatusHistoryComponent,
  ]
}
