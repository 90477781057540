import { reducers } from '../root-reducer'
import { ActionReducer } from '@ngrx/store'
import { localStorageSync as sessionStorageSync } from 'ngrx-store-localstorage'

const customStorage: Storage = {
  length: 0,
  clear(): void {
    if (window && window.sessionStorage) {
      window.sessionStorage.clear()
      this.length = window.sessionStorage.length
    }
  },
  getItem(key: string): string | null {
    try {
      return window.sessionStorage.getItem(key)
    } catch {
      return null
    }
  },
  key(index: number): string | null {
    try {
      return window.sessionStorage.key(index)
    } catch {
      return null
    }
  },
  removeItem(key: string): void {
    try {
      window.sessionStorage.removeItem(key)
      this.length = window.sessionStorage.length
    } catch {
      return
    }
  },
  setItem(key: string, data: string): void {
    try {
      window.sessionStorage.setItem(key, data)
      this.length = window.sessionStorage.length
    } catch {
      return
    }
  },
}

export function sessionStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  const blackList = ['forms', 'noTrackingCall', 'loading', 'router']

  const keys = Object.keys(reducers).filter(
    (feature) => blackList.indexOf(feature) === -1
  )

  return sessionStorageSync({
    keys,
    rehydrate: true,
    removeOnUndefined: false,
    storage: customStorage,
  })(reducer)
}
