import { XHRService } from '../../shared/services/xhr.service'
import { Injectable } from '@angular/core'
import { GenericCoordinates, LocationClubResponse } from './location.types'
import { HighwayExits } from './location.types'

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private xhr: XHRService) {}

  async getHighwayExits(coordinates: GenericCoordinates) {
    const response = await this.xhr.authRequest<HighwayExits>({
      url: 'v2/roadservice/highway/exits',
      params: coordinates,
      headers: {
        Accept: 'application/json',
      },
    })
    return response
  }

  async getClubByLocation(coordinates: GenericCoordinates) {
    const response = await this.xhr.authRequest<LocationClubResponse>({
      url: 'v2/roadservice/club',
      params: coordinates,
      headers: {
        Accept: 'application/json',
      },
    })
    return response
  }
}
