<!-- Dialog Content -->
<div class="dialog not-a-member">
  <h1 mat-dialog-title i18n>
      Additional info required
  </h1>

  <form name="form" #f="ngForm">

    <div mat-dialog-content class="modal-form">
      <label for="streetNumber" i18n>Street Number </label>
      <input
        [(ngModel)]="streetFormModel"
        #streetNumber="ngModel"
        name="streetNumber"
        id="streetNumber"
        type="text"
        aria-required="true"
        required />

        <div [ngClass]="{ hidden: !f?.form?.controls?.streetNumber?.valid && !f?.touched }">
          <div *ngIf="f?.form?.controls?.streetNumber?.errors?.required" class="text-error membership-validation" i18n>
            Street number is required
          </div>
        </div>
    </div>

    <div mat-dialog-actions class="buttons-row">
      <app-button type="button"
        mat-dialog-close
        aria-label="dismiss this message"
        [inverted]="true"
        i18n
        i18n-aria-label>
        Cancel
      </app-button>
      <app-button type="submit"
        (buttonClick)="handleStreetForm(f)"
        [disabled]="(isLoading$ | async) || !f.valid"
        i18n>
        Confirm
      </app-button>
    </div>
  </form>

  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message" i18n-aria-label>
    <icon-close></icon-close>
  </button>
</div>

