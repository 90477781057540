<ion-item class="ion-margin ion-no-padding" (click)="click()" [detail]="clickable && itemClick.observers.length > 0">
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col size="auto">
        <aaa-icon [name]="icon" [color]="color" size="small" class="margin-right-8"></aaa-icon>
      </ion-col>
      <ion-col>
        <aaa-text size="footnote" color="text" weight="semibold" class="margin-bottom-4">{{title}}</aaa-text>
        <ng-container *ngIf="isCaptionArray; else captionString">
          <aaa-text *ngFor="let item of caption" size="caption" color="subtle">{{item}}</aaa-text>
        </ng-container>
        <ng-template #captionString>
          <aaa-text size="caption" color="subtle">{{caption}}</aaa-text>
        </ng-template>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-item>
