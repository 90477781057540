<svg width="27" height="36"
     viewBox="0 0 27 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path [ngClass]="{
    'pin-selected': isSelected,
    'pin-destination': rapAppId,
    'aar-owned-pin': !rapAppId && !isSelected && isCOR,
    'aar-approved-pin': !rapAppId && !isSelected && !isCOR
    }"
        d="M11.0859 32.4805C11.666 33.3828 13.0195 33.3828 13.5996 32.4805C23.0098 18.9453 24.75 17.5273 24.75 12.5C24.75 5.66797 19.207 0.125 12.375 0.125C5.47852 0.125 0 5.66797 0 12.5C0 17.5273 1.67578 18.9453 11.0859 32.4805Z"/>
  <ng-container *ngIf="rapAppId; else wrench_pin">
    <path
      d="M7.21875 12.5C7.21875 15.4004 9.47461 17.6562 12.375 17.6562C15.2109 17.6562 17.5312 15.4004 17.5312 12.5C17.5312 9.66406 15.2109 7.34375 12.375 7.34375C9.47461 7.34375 7.21875 9.66406 7.21875 12.5Z"
      fill="#FFFFFF"/>
  </ng-container>
  <ng-template #wrench_pin>
    <circle cx="12.5" cy="12.5" r="9.5" fill="#FFFFFF"/>
    <svg x="8" y="8" width="9" height="9" viewBox="0 0 512 512">
      <path [ngClass]="{
      'pin-selected': isSelected,
      'aar-owned-pin': !rapAppId && !isSelected && isCOR,
      'aar-approved-pin': !rapAppId && !isSelected && !isCOR
      }"
            d="M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7H336c-8.8 0-16-7.2-16-16V118.6c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/>
    </svg>
  </ng-template>
</svg>
