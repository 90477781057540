import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'icon-breakdown-location-pin',
  templateUrl: './breakdown-location-pin.icon.html',
  styleUrls: ['breakdown-location-pin.icon.scss']
})
export class BreakdownLocationPinIconComponent extends AbstractIconComponent {
  static icon = 'breakdown'
  @Input() index: number
  @Input() small = false
}
