<svg
  width="50"
  height="50"
  viewBox="0 0 50 50"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50ZM14.5 24C18.0899 24 21 21.0899 21 17.5C21 13.9101 18.0899 11 14.5 11C10.9101 11 8 13.9101 8 17.5C8 21.0899 10.9101 24 14.5 24ZM41 17.5C41 21.0899 38.0899 24 34.5 24C30.9101 24 28 21.0899 28 17.5C28 13.9101 30.9101 11 34.5 11C38.0899 11 41 13.9101 41 17.5ZM24.5 47C28.0899 47 31 44.0899 31 40.5C31 36.9101 28.0899 34 24.5 34C20.9101 34 18 36.9101 18 40.5C18 44.0899 20.9101 47 24.5 47ZM44 33.5C44 35.9853 41.9853 38 39.5 38C37.0147 38 35 35.9853 35 33.5C35 31.0147 37.0147 29 39.5 29C41.9853 29 44 31.0147 44 33.5ZM9.5 38C11.9853 38 14 35.9853 14 33.5C14 31.0147 11.9853 29 9.5 29C7.01472 29 5 31.0147 5 33.5C5 35.9853 7.01472 38 9.5 38ZM14.5 20C15.8807 20 17 18.8807 17 17.5C17 16.1193 15.8807 15 14.5 15C13.1193 15 12 16.1193 12 17.5C12 18.8807 13.1193 20 14.5 20ZM34.5 20C35.8807 20 37 18.8807 37 17.5C37 16.1193 35.8807 15 34.5 15C33.1193 15 32 16.1193 32 17.5C32 18.8807 33.1193 20 34.5 20ZM27 40.5C27 41.8807 25.8807 43 24.5 43C23.1193 43 22 41.8807 22 40.5C22 39.1193 23.1193 38 24.5 38C25.8807 38 27 39.1193 27 40.5ZM39.5 35C40.3284 35 41 34.3284 41 33.5C41 32.6716 40.3284 32 39.5 32C38.6716 32 38 32.6716 38 33.5C38 34.3284 38.6716 35 39.5 35ZM9.5 35C10.3284 35 11 34.3284 11 33.5C11 32.6716 10.3284 32 9.5 32C8.67157 32 8 32.6716 8 33.5C8 34.3284 8.67157 35 9.5 35Z"
    fill="#525252"
  />
</svg>
