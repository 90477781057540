<svg
  width="18px"
  height="25px"
  id="icon-share"
  viewBox="0 0 18 25"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g
    id="Service-Tracker---Flow"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      id="iPhone-6---AAA-RSO---Request-In-Tow"
      transform="translate(-326.000000, -145.000000)"
      fill="#003893"
    >
      <g id="Service-Tracker" transform="translate(10.000000, 74.000000)">
        <g id="Service-Tracker-Content">
          <g id="share-icon" transform="translate(316.000000, 71.952229)">
            <polygon
              id="Fill-1"
              points="0 6.44076248 0 23.9235071 17.6 23.9235071 17.6 6.45730962 11.256 6.45730962 11.256 8.00237331 15.9214 8.00237331 15.9214 22.2749739 1.664 22.2749739 1.664 7.93439051 6.3496 7.93439051 6.3496 6.44076248"
            ></polygon>
            <path
              d="M9,0 C9.8954,0.958936306 10.7754,1.93242611 11.694,2.86883439 C12.0378,3.21911529 12.471,3.48247389 12.9956,3.88718089 C12.4128,4.35508599 12.0572,4.64057389 11.6084,5.00122166 C11.0396,4.46194459 10.4072,3.86226051 9.6264,3.12182611 L9.6264,15.1031471 L8.05,15.1031471 L8.05,3.0544414 C7.234,3.82717261 6.6056,4.42227134 5.869,5.11984268 C5.5678,4.72271146 5.2916,4.35867452 4.9172,3.86525096 C5.5828,3.20157134 6.3592,2.45136815 7.107,1.67385223 C7.625,1.13517325 8.1038,0.559412739 8.6,0 L9,0 Z"
              id="Fill-2"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
