<svg
  xmlns="http://www.w3.org/2000/svg"
  width="26"
  height="20"
  viewBox="0 0 26 20"
  id="icon-tires"
>
  <g [attr.fill]="color" fill-rule="evenodd">
    <path
      d="M8.955 17.289v-3.743c1.281 2.501 3.896 4.187 6.956 4.188 4.407.001 7.853-3.462 7.804-7.844-.049-4.268-3.481-7.625-7.798-7.625-3.079-.001-5.685 1.684-6.962 4.193v-3.75C10.752 1.02 13.192-.001 15.918 0c5.58.001 10.02 4.341 10.081 9.859.064 5.665-4.391 10.143-10.09 10.141-2.711 0-5.153-1.025-6.954-2.711zm7.721-1.685c.263-1.145.172-2.79.891-3.291.687-.48 2.218.23 3.378.417l.22.822-3.6 2.635-.889-.583zm4.79-4.561c-1.045-.601-2.7-1.017-2.959-1.856-.247-.806.953-2.049 1.517-3.1l.829.06 1.442 4.28-.83.616zM13.814 4.26h4.308l.443.658c-.88.851-1.702 2.275-2.659 2.37-.763.076-1.69-1.467-2.545-2.291l.453-.737zm-4.276 6.006l1.363-4.2.834-.157 1.724 3.276-3.313 1.678-.608-.597zm4.531 5.815l-3.502-2.475.048-.816c1.187-.245 2.79-1.057 3.45-.583.75.538.653 2.231.925 3.422l-.921.452zm1.844-4.147A1.942 1.942 0 0 1 13.964 10c0-1.068.873-1.934 1.95-1.934s1.95.866 1.95 1.934a1.942 1.942 0 0 1-1.95 1.934z"
    />
    <path
      d="M2.742 19.75A1.99 1.99 0 0 1 .75 17.755V2.245C.75 1.143 1.644.25 2.742.25h5.516c1.1 0 1.992.889 1.992 1.995v15.51a1.995 1.995 0 0 1-1.992 1.995H2.742zM2.5 16.5l2.4 1v-2l-2.4-1v2zm0 3h2.4v-1l-2.4-1v2zm0-6l2.4 1v-2l-2.4-1v2zm0-3l2.4 1v-2l-2.4-1v2zm0-3l2.4 1v-2l-2.4-1v2zm0-3l2.4 1v-2l-2.4-1v2zm0-3l2.4 1v-2H2.5v1zm3.6 16l2.4-1v-2l-2.4 1v2zm0 2h2.4v-2l-2.4 1v1zm0-5l2.4-1v-2l-2.4 1v2zm0-3l2.4-1v-2l-2.4 1v2zm0-3l2.4-1v-2l-2.4 1v2zm0-3l2.4-1v-2l-2.4 1v2zm0-3l2.4-1v-1H6.1v2z"
    />
  </g>
</svg>
