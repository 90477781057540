<svg
  xmlns="http://www.w3.org/2000/svg"
  width="17"
  height="18"
  viewBox="0 0 17 18"
  id="icon-out-of-fuel"
>
  <path
    [attr.fill]="color"
    fill-rule="evenodd"
    d="M15.77 4.23l.01-.01L12.06.5 11 1.56l2.11 2.11c-.94.36-1.61 1.26-1.61 2.33A2.5 2.5 0 0 0 14 8.5c.36 0 .69-.08 1-.21v7.21c0 .55-.45 1-1 1s-1-.45-1-1V11c0-1.1-.9-2-2-2h-1V2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2v16h10v-7.5h1.5v5a2.5 2.5 0 0 0 5 0V6c0-.69-.28-1.32-.73-1.77zM8 7H2V2h6v5zm6 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
  />
</svg>
