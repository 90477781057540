import { createFeatureSelector, createSelector } from '@ngrx/store'
import { TimeoutState } from './timeout.reducer'

const timeoutStateKey = 'timeout'

export const selectTimeoutState = createFeatureSelector<TimeoutState>(timeoutStateKey)

export const selectCallStatusInterval = createSelector(
  selectTimeoutState,
  (state: TimeoutState): number => state.callStatusInterval
)

export const selectSessionTimeoutInterval = createSelector(
  selectTimeoutState,
  (state: TimeoutState): number => state.sessionTimeoutInterval
)

export const selectCheckSessionTimeoutInterval = createSelector(
  selectTimeoutState,
  (state: TimeoutState): number => state.checkSessionTimeoutInterval
)

export const selectAlertSessionTimeoutInterval = createSelector(
  selectTimeoutState,
  (state: TimeoutState): number => state.alertSessionTimeoutInterval
)

export const selectFirstCallStatusTimeout = createSelector(
  selectTimeoutState,
  (state: TimeoutState): number => state.firstCallStatusTimeout
)

export const selectCreateCallTimeout = createSelector(
  selectTimeoutState,
  (state: TimeoutState): number => state.createCallTimeout
)

export const selectGetActiveCallsTimeout = createSelector(
  selectTimeoutState,
  (state: TimeoutState): number => state.getActiveCallsTimeout
)

