import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { CarRentalAuthFormState } from '../../../modules/ui/forms/forms.reducer';
import { selectCarRentalAuthForm } from '../../../modules/ui/forms/forms.selectors';
import { AAAStore } from '../../../store/root-reducer';
import { ValidationFormComponent } from '../../types';

@Component({
  selector: 'app-car-rental-credentials',
  templateUrl: './car-rental-credentials.component.html',
  styleUrls: ['./car-rental-credentials.component.scss']
})
export class CarRentalCredentialsComponent implements ValidationFormComponent,OnInit {
  isSecure$: Observable<boolean>;

  constructor(private store$: Store<AAAStore>) {
  }

  carRentalAuthForm: FormGroupState<CarRentalAuthFormState>;
  carRentalAuthForm$: Observable<FormGroupState<CarRentalAuthFormState>> = this.store$.pipe(
    select(selectCarRentalAuthForm)
  );

  get formState(): FormGroupState<any> {
    return this.carRentalAuthForm
  }

  get controls(): any {
    return this.formState && this.formState.controls
  }

  ngOnInit(): void {
    this.carRentalAuthForm$.subscribe((carRentalAuthForm) => {
      this.carRentalAuthForm = carRentalAuthForm;
    })
  }
}
