import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { Color } from '@aaa-mobile/shared-components/interface';

@Component({
  selector: 'app-request-details-item',
  templateUrl: './request-details-item.component.html',
  styleUrls: ['./request-details-item.component.scss'],
})
export class RequestDetailsItemComponent implements OnChanges {

  @Input() title: string
  @Input() caption: string | string[]
  @Input() icon: string
  @Input() clickable = true
  @Input() color: Color = 'tertiary'
  @Output() itemClick: EventEmitter<any> = new EventEmitter<any>()
  isCaptionArray = false

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.caption) {
      this.isCaptionArray = Array.isArray(this.caption);
    }
  }

  click() {
    if (this.clickable) {
      this.itemClick.emit()
    }
  }
}
