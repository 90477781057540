<div class="white-label-credentials entry">
    <form
        id="signon-form"
        (ngSubmit)="checkFieldsAndSubmit()"
        class="white-label-credentials_form"
        [ngrxFormState]="formState"
    >

        <ng-template formMode></ng-template>

        <re-captcha
          *ngIf="!(isOdometerConfirmation$ | async)"
          #captchaElement
          [siteKey]="siteKey"
          (resolved)="handleCaptchaSuccess($event)">
        </re-captcha>

        <div i18n class="form-error text-error small-text" *ngIf="captchaError">
            You must confirm you are not a robot.
        </div>

        <app-button i18n
                    [disabled]="(isSigningIn$ | async)"
                    type="submit"
                    [loading]="(isSigningIn$ | async) === true"
                    [tabIndex]="(12 + tabIndexPosition)">
            Confirm
        </app-button>


    </form>

    <div class="white-label-credentials_terms">
        <ng-container i18n>By selecting Confirm, you accept the</ng-container>
        <aria-link-button
          i18n
          (clickLink)="openTerms()"
          [tabIndex]="(24 + tabIndexPosition)"
          class="terms-and-conditions__link"
        >
          Terms and Conditions
        </aria-link-button>
    </div>

    <ng-container *ngIf="!(isSecure$ | async) && isWebApp()">
        <div class="privacy-policy">
            <app-clickable-link i18n
                                (clickLink)="openPrivacyPolicy()"
                                class="privacy-policy__link"
                                [tabIndex]="(25 + tabIndexPosition)">
                Privacy Policy
            </app-clickable-link>
        </div>
    </ng-container>
</div>
