<section
  id="credentials-entry"
  class="member-credentials"
  [ngClass]="{
    entry: !(isSecure$ | async),
    placeholder: isSecure$ | async
  }"
>
  <form
    id="signon-form"
    (ngSubmit)="formState.isValid && handleSubmit()"
    class="member-credentials_form"
    [ngClass]="{'error': isErrorBoxDisplayed }"
    novalidate
    [ngrxFormState]="formState"
  >
    <ng-container *ngIf="!(isSecure$ | async)">
      <ng-container *ngIf="allowNameAuth; else membershipLines">
        <app-login-lite [controls]="controls"></app-login-lite>
      </ng-container>
      <ng-template #membershipLines>
        <app-member-number [controls]="controls"></app-member-number>
      </ng-template>
      <div>
        <ng-container *ngIf="isPasswordCredentialSupported">
          <aaa-checkbox
            i18n
            name="remember-me"
            (aaaChange)="handleRememberMe($event)"
            [checked]="rememberMe"
            justify="start"
            labelPlacement="end"
          >
            Remember me
          </aaa-checkbox>
        </ng-container>
        <app-login-fail-assistant (hasError)="setHasError($event)"></app-login-fail-assistant>
        <ng-container *ngIf="displayCaptcha">
          <re-captcha
            #captchaElement
            [siteKey]="siteKey"
            (resolved)="handleCaptchaSuccess($event)"
          ></re-captcha>
        </ng-container>
        <app-form-helper
          i18n
          *ngIf="captchaError"
        >
          You must confirm you are not a robot.
        </app-form-helper>
        <aaa-button
          i18n
          id="btn-confirm-default"
          type="submit"
          [loading]="(isSigningIn$ | async) === true"
          expand="block"
          variant="primary"
        >
          Confirm
        </aaa-button>
        <ng-container *ngIf="allowNameAuth; else btnUseMemberName">
          <aaa-button
            i18n
            id="use-member-number"
            expand="block"
            variant="ghost"
            (click)="useMembershipNumberClicked($event)"
          >
            Use Member Number To Sign In
          </aaa-button>
        </ng-container>
        <ng-template #btnUseMemberName>
          <aaa-button
            i18n
            id="use-member-name"
            expand="block"
            variant="ghost"
            (click)="useMemberNameClicked($event)"
          >
            Use Member Name To Sign In
          </aaa-button>
        </ng-template>
      </div>
    </ng-container>
  </form>
  <ion-row class="bottom-text">
    <ion-col>
      <aaa-text
        *ngIf="!(isSecure$ | async)"
        class="minimal-credentials_not-a-member color-black "
        display="block"
        align="center"
        role="navigation"
        aria-labelledby="login-help"
        color="primary"
        size="caption"
        weight="light"
      >
        <ng-container *ngIf="!allowNameAuth">
          <aria-link-button
            appCtaClickEvent="Forgot Member Number"
            i18n
            class="link-forgot-member-number"
            (clickLink)="openCannotRemember($event)"
            elementId="forgot-member-number"
            role="button"
          >
            Forgot Member Number
          </aria-link-button>
          |
        </ng-container>
        <aria-link-button
          appCtaClickEvent="Not A Member"
          i18n
          (clickLink)="openNotMember($event)"
          class="minimal-credentials_not-a-member-dialog"
          elementId="not-a-member"
          role="button"
        >
          Not a Member?
        </aria-link-button>
      </aaa-text>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="!configService.getConfig().nativeAppView">
    <ion-col class="ion-margin-bottom ion-text-center">
      <aaa-text
        i18n
        class="minimal-credentials_terms ion-margin-bottom"
        align="center"
        color="dark"
        size="caption"
        weight="regular"
        display="block"
      >
        By selecting Confirm, you accept the
      </aaa-text>
      <aaa-text
        class="minimal-credentials_terms"
        color="danger"
        align="center"
        size="caption"
        weight="bold"
        display="inline"
        role="navigation"
        aria-labelledby="terms"
      >
        <aria-link-button
          i18n
          elementId="terms"
          (clickLink)="openTerms()"
          class="terms-and-conditions__link"
        >
          Terms and Conditions
        </aria-link-button>
      </aaa-text>
      <ng-container *ngIf="!(isSecure$ | async) && isWebApp()">
        <aaa-text
          i18n
          color="dark"
          size="caption"
          weight="light"
          display="inline"
        > and </aaa-text>
        <aaa-text
          class="privacy-policy"
          id="privacy-policy"
          color="danger"
          size="caption"
          weight="bold"
          display="inline"
          aria-labelledby="privacy-policy"
          role="navigation"
        >
          <aria-link-button
            i18n
            (clickLink)="openPrivacyPolicy()"
            elementId="privacy-policy-link"
          >
            Privacy Policy
          </aria-link-button>
        </aaa-text>
      </ng-container>
    </ion-col>
  </ion-row>
</section>
