import { AdobeEventService } from '../../../../tagging/adobe/event-adobe.service';
import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable } from 'rxjs'
import events from '../../../../tagging/events'
import { TaggingService } from '../../../../tagging/tagging.service'
import { DialogData } from '../../../ui.types'
import { AbstractComponent } from '../../../../../shared/abstract.component'
import { AAAStore } from '../../../../../store/root-reducer'
import { AdditionalLocationInfoFormState } from '../../../forms/forms.reducer'
import { selectAdditionalLocationInfoForm } from '../../../forms/forms.selectors'
import { AdobeEventTypes } from '../../../../tagging/tagging.types';
import { isRedesign } from '../../../../../shared/utils/cookies';
import { locationNext } from '../../../../location/location.actions';
import { addPartialCallRequest } from '../../../../dashboard/calls.actions';

@Component({
  selector: 'app-location-additional-info',
  templateUrl: './location-additional-info.component.html',
  styleUrls: [
    '../../shared-dialog.component.scss',
    './location-additional-info.component.scss',
  ],
})
export class LocationAdditionalInfoComponent
  extends AbstractComponent
  implements OnInit {
  form$: Observable<FormGroupState<AdditionalLocationInfoFormState>> =
    this.store$.pipe(select(selectAdditionalLocationInfoForm))
  form: FormGroupState<AdditionalLocationInfoFormState>

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  ngOnInit() {
    this.form$.subscribe((form) => {
      this.form = form
    })

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.SYSTEM,
      eventValue: events.location.LOCATION_ADDITIONAL_INFO_PROMPT
    })

    this.taggingService.setAutomatedEvent(
      events.location.LOCATION_ADDITIONAL_INFO_PROMPT,
      events.location.LOCATION_PAGE_TYPE
    )
  }

  handleCommentForm(form: FormGroupState<AdditionalLocationInfoFormState>) {
    if (!form.isValid) {
      return null
    }

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.location.LOCATION_ADDITIONAL_INFO_CONFIRM_CLICK
    })

    this.taggingService.setClickEvent(
      events.location.LOCATION_ADDITIONAL_INFO_CONFIRM_CLICK,
      events.location.LOCATION_PAGE_TYPE
    )

    if (isRedesign()) {
      this.store$.dispatch(locationNext())
      this.store$.dispatch(addPartialCallRequest())
    }

    return this.data.close()
  }

  onClose() {
    return this.data.close()
  }
}
