import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'matcherHighlight'
})
export class MatcherHighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, keyword: string, fontWeight: string = 'bold'): SafeHtml {
    if (!keyword) {
      return value;
    }

    let escaped_keyword = keyword.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&'
    );

    const regex = new RegExp(escaped_keyword, 'gi');
    const highlightedValue = value.replace(regex, (str) => `<span style="font-weight: ${fontWeight}">${str}</span>`);

    return this.sanitizer.bypassSecurityTrustHtml(highlightedValue);
  }
}
