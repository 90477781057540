import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'
import { TaggingService } from '../../../tagging/tagging.service'
import events from '../../../tagging/events'
import { RapService } from '../../../rap/rap.service'
import { ClosableDialog } from '../closable-dialog'
import { MESSAGE_WE_ARE_SORRY } from '../../../constants/shared.constants';

const MESSAGE_GENERIC_ERROR = () => $localize`An error has occurred at this time.`
const MESSAGE_GENERIC_ASSISTANCE_DRR = () => $localize`For additional assistance, please call AAA`
const MESSAGE_GENERIC_ASSISTANCE_RAP = () => $localize`For additional assistance, please contact us at`

@Component({
  selector: 'app-generic-error-dialog-component',
  templateUrl: './generic-error-dialog.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class GenericErrorDialogComponent extends ClosableDialog {
  pageType = events.shared.CALL_AAA_PAGE_TYPE
  isRapUser = this.rapService.isRapUser()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private taggingService: TaggingService,
    private rapService: RapService
  ) {
    super(data)
    this.data.title = this.data.title || MESSAGE_WE_ARE_SORRY()
    this.data.content =
      this.data.content ||
      ((): string => {
        const isRapUser = this.rapService.isRapUser()
        const _defaultText = isRapUser
          ? `<p>${MESSAGE_GENERIC_ERROR()}<br/>${MESSAGE_GENERIC_ASSISTANCE_RAP()}</p>`
          : `<p>${MESSAGE_GENERIC_ERROR()}<br/> ${MESSAGE_GENERIC_ASSISTANCE_DRR()} </p>`

        return _defaultText
      })()

    this.taggingService.setPageEvent(
      events.shared.CALL_AAA_PROMPT,
      events.shared.CALL_AAA_PAGE_TYPE
    )
    this.taggingService.setPageLoadEvent({
      pageName: events.shared.CALL_AAA_PROMPT
    })
  }

  onCallAAAClick() {
    this.onClose()
  }
}
