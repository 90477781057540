import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core'
import { AriaLinkButtonComponent } from '../../../shared/controls/aria-link/aria-link-button.component'

@Component({
  selector: 'app-edit-tag',
  templateUrl: './edit-tag.component.html',
  styleUrls: ['./edit-tag.component.scss'],
})
export class EditTagComponent {

  @ViewChild('ariaLink') ariaLink: AriaLinkButtonComponent

  @Input() source: any = {}
  @Input() field = ''
  @Input() alias: string
  @Input() disabled = false
  @Input() tabIndexPosition = 0
  editLabel = () => $localize`Edit`

  @Output() selected: EventEmitter<any> = new EventEmitter<any>()

  constructor() {}

  setFocus() {
    if(this.ariaLink) {
      this.ariaLink.setFocus();
    }
  }

  handleSelected() {
    this.selected.emit()
  }
}
