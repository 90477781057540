import { AUTH } from '../auth/auth.actions'
import { PayloadedAction } from '../../shared/types'
import {
  SET_COMMENT,
  SET_TOWING,
  CALL_RESET,
  CALL_STATUS_ERROR,
  RESET_CALL_STATUS_ERROR,
  SERVICE_DELIVERY_CALL_STATUS,
} from './calls.actions'
import { TowLocation } from '../location/location.types'
import { BreakdownLocation } from '../location/location.types'
import { ServiceDeliveryCallStatus } from './calls.types'

export interface AAACallState {
  comments?: string
  breakdownLocationAddress: string
  breakdownLocation: BreakdownLocation
  towing: TowLocation
  callWithError?: boolean
  callStatus?: ServiceDeliveryCallStatus
}

export const initialState: AAACallState = {
  comments: '',
  breakdownLocationAddress: '',
  breakdownLocation: null,
  towing: null,
  callWithError: false,
  callStatus: null,
}

export function reducer(
  state: AAACallState | undefined = initialState,
  action: PayloadedAction | any
): AAACallState {
  switch (action.type) {
    case CALL_RESET:
      return { ...initialState }
    case SET_TOWING:
      let towing = null
      if (action.payload) {
        towing = {
          ...action.payload,
          // simplify the location/name to present towing information
          name: action.payload.name || action.payload.location,
        }

        // TODO move to a selector.
        const streetNumber = towing.streetNumber
        if (towing.streetName && towing.streetName.indexOf(streetNumber) > -1) {
          towing.streetNumber = ''
        }
      }

      return {
        ...state,
        towing,
      }
    case SET_COMMENT:
      return {
        ...state,
        comments: action.payload,
      }
    case CALL_STATUS_ERROR:
      return {
        ...state,
        callWithError: true,
      }
    case RESET_CALL_STATUS_ERROR:
      return {
        ...state,
        callWithError: false,
      }
    case SERVICE_DELIVERY_CALL_STATUS.SUCCESS:
      return {
        ...state,
        callStatus: action.payload,
      }
    default:
      return state
  }
}
