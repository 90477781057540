<svg
  width="15"
  height="14"
  viewBox="0 0 15 14"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill="currentColor"
    d="M7.70898 13.7617L8.29492 13.2051C8.44141 13.0586 8.44141 12.8242 8.29492 12.707L3.75391 8.13672H13.7734C13.9492 8.13672 14.125 7.99023 14.125 7.78516V6.96484C14.125 6.78906 13.9492 6.61328 13.7734 6.61328H3.75391L8.29492 2.07227C8.44141 1.95508 8.44141 1.7207 8.29492 1.57422L7.70898 1.01758C7.5918 0.871094 7.35742 0.871094 7.21094 1.01758L1.08789 7.14062C0.941406 7.28711 0.941406 7.49219 1.08789 7.63867L7.21094 13.7617C7.35742 13.9082 7.5918 13.9082 7.70898 13.7617Z"
  />
</svg>
