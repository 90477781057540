import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { ConfigService } from './modules/config/config.service'
import { getOemBrandingId } from './shared/utils/branding'
import { Loader } from '@googlemaps/js-api-loader'

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsConfig {

  private currentApiStatus: BehaviorSubject<Boolean>;
  obsCurrentApiStatus: Observable<Boolean>;

  constructor(
    private configService: ConfigService,
  ) {
    this.currentApiStatus =  new BehaviorSubject(Boolean(false));
    this.obsCurrentApiStatus = this.currentApiStatus.asObservable();

    const rapAppId = getOemBrandingId(window.location.href)
    const apiKey = rapAppId
      ? this.configService.getConfig().rapGoogleMapsApiKey
      : this.configService.getConfig().googleMapsApiKey

    const loader = new Loader({
      apiKey,
      version: 'weekly',
    })

    loader
      .importLibrary('places')
      .then(() => this.currentApiStatus.next(true))
      .catch(() => this.currentApiStatus.next(false))
  }
}
