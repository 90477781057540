import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Routes, RouterModule } from '@angular/router'
import { CanComponentDeactivateGuard } from '../../../shared/route-guard/can-component-deactivate.guard'
import { ServiceTrackingComponent } from '../../service-tracking/service-tracking.component'

const routes: Routes = [
  {
    path: '',
    component: ServiceTrackingComponent,
    canDeactivate: [CanComponentDeactivateGuard],
  },
]

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanComponentDeactivateGuard],
})
export class DashboardRoutingModule {}
