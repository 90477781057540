<svg
  id="icon-add"
  xmlns="http://www.w3.org/2000/svg"
  width="11"
  height="11"
  viewBox="0 0 19 19"
>
  <path
    [attr.fill]="color"
    fill-rule="evenodd"
    d="M.617 11.3V8.349h7.664V.63h3.006v7.717h7.664V11.3h-7.664V19H8.281v-7.7z"
  />
</svg>
