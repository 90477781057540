import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core'
import { AbstractComponent } from '../../../shared/abstract.component'
import { fromEvent } from 'rxjs'
import { filter } from 'rxjs/operators'
import { FormViewAdapter, NGRX_FORM_VIEW_ADAPTER } from 'ngrx-forms'

const MIN_EXPANDED_HEIGHT = 150
const DEFAULT_TEXTAREA_HEIGHT = 40
const DEFAULT_PARENT_HEIGHT = 50
const DEFAULT_DIFF_HEIGHT = DEFAULT_PARENT_HEIGHT - DEFAULT_TEXTAREA_HEIGHT

@Component({
  selector: 'app-notes-input[fieldId][placeholder]',
  templateUrl: './notes-input.component.html',
  styleUrls: ['./notes-input.component.scss'],
  providers: [
    {
      // This allows this control to act as its own FormViewAdapter.
      provide: NGRX_FORM_VIEW_ADAPTER,
      useExisting: forwardRef(() => NotesInputComponent),
      multi: true,
    },
  ],
})
export class NotesInputComponent
  extends AbstractComponent
  implements OnInit, FormViewAdapter {
  constructor() {
    super()
  }

  @ViewChild('noteInput', { static: true }) noteInput: ElementRef
  @ViewChild('container', { static: true }) container: ElementRef

  @Input() placeholder = 'Add notes (Optional)...'
  @Input() inputValue: string
  @Input() expandable = true
  @Input() fieldId: string
  @Input() tabIndex = 0
  @Input() showToggle = true
  @Input() showExpanded = false

  @Output() inputValueChange: EventEmitter<string> = new EventEmitter<string>()

  expanded = false

  onTouchedCallback: () => void = () => {}

  ngOnInit() {
    this.subscriptions.push(
      fromEvent(this.noteInput.nativeElement, 'keyup')
        .pipe(
          filter(
            (event: KeyboardEvent) =>
              event.key !== 'Tab' && event.key !== 'Shift'
          ),
          filter(() => this.expandable)
        )
        .subscribe(() => this.fitContent())
    )
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.showExpanded) {
        this.adjustComponentHeight(MIN_EXPANDED_HEIGHT)
      } else {
        this.fitContent()
      }
    }, 200)
  }

  setViewValue(value: any): void {
    this.inputValue = value
  }

  setOnChangeCallback(fn: (value: any) => void): void {
    this.inputValueChange.subscribe(fn)
  }

  setOnTouchedCallback(fn: () => void): void {
    this.onTouchedCallback = fn
  }

  setIsDisabled?(isDisabled: boolean): void {
    this.noteInput.nativeElement.disabled = isDisabled
  }

  private fitContent(): number {
    let adjustedHeight = DEFAULT_TEXTAREA_HEIGHT
    const textArea = this.noteInput.nativeElement
    if (textArea.value) {
      let height = textArea.scrollHeight
      if (height > DEFAULT_TEXTAREA_HEIGHT) {
        this.expanded = true
        if (height < MIN_EXPANDED_HEIGHT) {
          height = MIN_EXPANDED_HEIGHT
        }
      } else {
        height = DEFAULT_TEXTAREA_HEIGHT
      }
      this.adjustComponentHeight(height)
      adjustedHeight = height
    } else {
      // reset defaults
      this.adjustComponentHeight(DEFAULT_TEXTAREA_HEIGHT)
      this.expanded = false
    }
    return adjustedHeight
  }

  toggle() {
    const textArea = this.noteInput.nativeElement
    if (this.expanded) {
      this.adjustComponentHeight(DEFAULT_TEXTAREA_HEIGHT)
      this.expanded = false
    } else {
      if (textArea.value) {
        if (this.fitContent() < MIN_EXPANDED_HEIGHT) {
          this.adjustComponentHeight(MIN_EXPANDED_HEIGHT)
          this.expanded = true
        }
      } else {
        this.adjustComponentHeight(MIN_EXPANDED_HEIGHT)
        this.expanded = true
      }
    }
  }

  private adjustComponentHeight(textareaHeight) {
    const textArea = this.noteInput.nativeElement
    const parent = this.container.nativeElement
    textArea.style.height = ` ${textareaHeight}px`
    parent.style.height = ` ${textareaHeight + DEFAULT_DIFF_HEIGHT}px`
  }

  onTextAreaFocus() {
    const textArea = this.noteInput.nativeElement
    if (textArea.value) {
      this.fitContent()
    }
  }
}
