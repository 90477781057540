export const BATTERY_QUOTES_FIXTURE = {
  '2007-Honda-Accord-30303': [
    {
      cmake: 'Honda',
      cmodel: 'Accord',
      iyear: '2007',
      cengine: 'L4 2.4L ',
      ccustomfit: '51R-C',
      MemberPrice: '124.23',
      NonMemberPrice: '149.23',
      cca: '450',
      difficult: false,
      bms: false,
    },
    {
      cmake: 'Honda',
      cmodel: 'Accord',
      iyear: '2007',
      cengine: 'V6 3.0L ',
      ccustomfit: '35N-C',
      MemberPrice: '138.86',
      NonMemberPrice: '163.86',
      cca: '640',
      difficult: false,
      bms: false,
    },
  ],
}
