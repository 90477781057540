import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-info',
  templateUrl: './info.icon.html',
})
export class InfoIconComponent extends AbstractIconComponent {
  static icon = 'info'
}
