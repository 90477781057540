import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogData } from '../../ui.types'

@Component({
  selector: 'app-session-timeout-dialog-component',
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: [
    '../shared-dialog.component.scss',
    './session-timeout-dialog.component.scss',
  ],
})
export class SessionTimeoutDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onSessionContinue() {
    this.data.close()
  }
}
