import { AuthState } from '../../modules/auth/auth.reducer'
import { AAACallStatus } from '../../modules/dashboard/calls.types'
import { getCookie, setCookie } from '../utils/cookies'
import { AxiosRequestConfig } from 'axios'
import debounce from 'p-debounce'
import { XHRService } from './xhr.service'

export type RequestArgs = string | AxiosRequestConfig
export interface Headers {
  aaa: {}
  club: {}
  auth: {}
}

interface URLMapping {
  [url: string]: number
}

export interface RequestOptions {
  retryAll?: boolean | number
  overrideBaseURL?: string
  overrideAuthHeader?: string
  withCredentials?: boolean
  useCustomAAAheaders?: boolean
}

export const RETRY_INTERVAL = 20000
export const TIMEOUT_INTERVAL = 30000

export const MAXIMUM_TIMEOUTS = 5
export const MAXIMUM_RETRIES = 3

export const IDEMPOTENT_VERBS = ['GET', 'HEAD', 'PUT', 'DELETE', 'OPTIONS', 'TRACE']
export const APP_ID_DRR_WEB = 'DRRWEB'
export const X_REQUEST_ID_HEADER = 'x-request-id'

export let timedOuts: URLMapping = {}

export const createTimeoutManager = () => {
  const _getURLTimeout = (url: string): number => timedOuts[url] || 0
  const _addURLTimeout = (url: string): void => {
    timedOuts = {
      ...timedOuts,
      [url]: getURLTimeout(url) + 1,
    }
  }
  const _resetURLTimeout = (url: string): void => {
    timedOuts = {
      ...timedOuts,
      [url]: 0
    }
  }

  return { _getURLTimeout, _addURLTimeout, _resetURLTimeout }
}

const timeoutManager = createTimeoutManager()
export const getURLTimeout = timeoutManager._getURLTimeout
export const addURLTimeout = timeoutManager._addURLTimeout
export const resetURLTimeout = timeoutManager._resetURLTimeout

export let retries: URLMapping = {}
const createURLRetryManager = () => {

  const _getURLRetry = (url: string): number => retries[url] || 0
  const _addURLRetry = (url: string): void => {
    retries = {
      ...retries,
      [url]: _getURLRetry(url) + 1,
    }
  }
  const _resetURLRetry = (url: string): void => {
    retries = {
      ...retries,
      [url]: 0
    }
  }

  return { getURLRetry: _getURLRetry, addURLRetry: _addURLRetry, resetURLRetry: _resetURLRetry }
}

const urlRetryManager = createURLRetryManager()

export const getURLRetry = urlRetryManager.getURLRetry
export const addURLRetry = urlRetryManager.addURLRetry
export const resetURLRetry = urlRetryManager.resetURLRetry

export const DEFAULT_CONFIG = {
  timeout: TIMEOUT_INTERVAL,
  responseType: 'json',
  method: 'GET',
  retryAll: true,
  withCredentials: true,
  useCustomAAAheaders: true,
}

function clubFromMembership(auth: AuthState) {
  return auth.memberNumber ? auth.memberNumber.substring(3, 6) : auth.club
}

export const createRequestConfig = ({
  options,
  baseURL
}: { options: RequestOptions, baseURL: string }) => ({
  ...DEFAULT_CONFIG,
  retryAll:
    options.retryAll === undefined
      ? DEFAULT_CONFIG.retryAll
      : options.retryAll,
  withCredentials:
    options.withCredentials === undefined
      ? DEFAULT_CONFIG.withCredentials
      : options.withCredentials,
  baseURL: options.overrideBaseURL || baseURL,
})

export const createHeaders = ({
  auth,
  call,
  userSessionId,
  clientId
}: {
  auth?: AuthState
  call?: AAACallStatus,
  userSessionId: string,
  clientId: string,
}): Headers => {
  const club = {}
  const aaa = {}
  const authTokens = {}

  aaa['x-aaa-client-id'] = clientId
  aaa['x-aaa-app-id'] = getCookie('AAA_AppId') || APP_ID_DRR_WEB
  aaa[X_REQUEST_ID_HEADER] = userSessionId

  if (auth) {
    club['x-aaa-restws-club'] = auth.club
    aaa['x-aaa-rsoweb-secure'] = auth.isSecure
    aaa['x-aaa-rsoweb-club'] = clubFromMembership(auth)

    authTokens['authorization'] = `Bearer ${auth.accessToken}`
  }

  if (call) {
    aaa['x-aaa-rsoweb-callid'] = call.callId
  }

  return {
    aaa,
    club,
    auth: authTokens,
  }
}

export const constructUrl = (args: RequestArgs, additionalCookieDomain: boolean) => {
  const baseURL = typeof args === 'string' ? args : args.url

  try {
    if (getCookie('dtCookie')) {
      const additionalDomain = additionalCookieDomain
        ? 'domain=national.aaa.com'
        : ''
      setCookie('dtCookie', `${getCookie('dtCookie')}${additionalDomain}`)
      setCookie('rxVisitor', `${getCookie('rxVisitor')}${additionalDomain}`)
    }
  } catch (error) {
    return baseURL
  }

  return `${baseURL}`
}

export const canRetryRequest = (verb: string) =>
  IDEMPOTENT_VERBS.indexOf((verb || '').trim().toUpperCase()) >= 0

export function handleRequestRetry(
  xhrService: XHRService,
  options: RequestOptions
) {
  const interval =
    typeof options.retryAll === 'number' ? options.retryAll : RETRY_INTERVAL

  return debounce((args: AxiosRequestConfig) => {
    const { url } = args
    addURLRetry(url)

    let urlRetry = getURLRetry(url)

    if (urlRetry <= MAXIMUM_RETRIES) {
      return xhrService.request(args, options)
    } else if(urlRetry > MAXIMUM_RETRIES){
      resetURLRetry(url)
      return Promise.reject('Request failed after maximum retries')
    }
  }, interval)
}

export function handleRequestTimeout<T>(
  xhrService: XHRService,
  args: AxiosRequestConfig,
  options: RequestOptions
): Promise<T> {
  const { url } = args

  addURLTimeout(url)

  let urlTimeOut = getURLTimeout(url)

  if (urlTimeOut <= MAXIMUM_TIMEOUTS) {
    return xhrService.request<T>(args, options)
  } else if (getURLTimeout(url) > MAXIMUM_TIMEOUTS) {
    resetURLTimeout(url)
  }
}
