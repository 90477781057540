import { array, number, object, string, date } from 'yup'
import { Review, ReviewPagination } from './review.types'

export const ReviewSchema = object<Review>().shape({
  id: number().required(),
  title: string(),
  comments: string(),
  createdDate: date().required(),
  displayName: string().required(),
  rating: number().required(),
})

export const ReviewPaginationSchema = object<ReviewPagination>().shape({
  businessId: string().required(),
  reviews: array().of(ReviewSchema),
})
