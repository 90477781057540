import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'icon-aar-destination-pin',
  templateUrl: './aar-destination-pin.icon.html',
  styleUrls: ['aar-destination-pin.icon.scss']
})
export class AarDestinationPinIconComponent extends AbstractIconComponent {
  @Input() isSelected = false
  @Input() rapAppId = null
  @Input() isCOR = false
}
