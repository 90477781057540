<div class="connector-type-container">

  <div class="connector-type-icon">
    <ng-template [ngTemplateOutlet]="connectorTypeIcon"></ng-template>
  </div>

  <ng-container *ngIf="connectorType">
    <span class="connector-type-label"> {{ connectorType }} </span>
  </ng-container>
</div>

<!-- List of icon components -->
<ng-template #connectorTypeIcon>
  <ng-container [ngSwitch]="connectorType">
    <ng-container *ngSwitchCase="connectorTypesList.NEMA1450">
      <icon-ev-connector-nema-1450></icon-ev-connector-nema-1450>
    </ng-container>
    <ng-container *ngSwitchCase="connectorTypesList.NEMA515">
      <icon-ev-connector-nema-515></icon-ev-connector-nema-515>
    </ng-container>
    <ng-container *ngSwitchCase="connectorTypesList.NEMA520">
      <icon-ev-connector-nema-520></icon-ev-connector-nema-520>
    </ng-container>
    <ng-container *ngSwitchCase="connectorTypesList.J1772">
      <icon-ev-connector-j1772></icon-ev-connector-j1772>
    </ng-container>
    <ng-container *ngSwitchCase="connectorTypesList.J1772COMBO">
      <icon-ev-connector-j1772-combo></icon-ev-connector-j1772-combo>
    </ng-container>
    <ng-container *ngSwitchCase="connectorTypesList.CHADEMO">
      <icon-ev-connector-chademo></icon-ev-connector-chademo>
    </ng-container>
    <ng-container *ngSwitchCase="connectorTypesList.TESLA">
      <icon-ev-connector-tesla></icon-ev-connector-tesla>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <icon-ev-connector-unknown></icon-ev-connector-unknown>
    </ng-container>
  </ng-container>
</ng-template>