import { EventEmitter, NgZone } from '@angular/core';

export class ZoneEventEmitter<T> extends EventEmitter<T> {

  constructor(private ngZone: NgZone) {
    super()
  }

  emit(value: T): void {
    this.ngZone.run(() => {
      super.emit(value)
    })
  }
}
