import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs';
import { selectCanCancelCall } from '../../dashboard/calls-statuses/call-status.selectors';
import { ADD_CALL, CALL_CANCEL } from '../../dashboard/calls.actions';
import { selectIsLoading } from '../../ui/loading/loading.selectors';
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service';
import { cancelRequest } from '../../../shared/actions/shared.actions';
import { AdobeEventTypes } from '../../tagging/tagging.types';
import { AAAStore } from '../../../store/root-reducer';
import { TaggingService } from '../../tagging/tagging.service';
import events from '../../tagging/events';
import { AsyncPipe } from '@angular/common';
import { IonCol, IonGrid, IonRow } from '@ionic/angular/standalone';
import { ButtonComponent, TextComponent } from '@aaa-mobile/shared-components';


@Component({
  selector: 'app-cancel-request',
  templateUrl: './cancel-request.component.html',
  styleUrls: ['./cancel-request.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    IonGrid,
    IonRow,
    IonCol,
    TextComponent,
    ButtonComponent,
  ],
})
export class CancelRequestComponent {

  canCancel$: Observable<boolean> = this.store$.pipe(
    select(selectCanCancelCall)
  )

  isCallRequesting$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoading(ADD_CALL.ACTION))
  )

  isCallCanceling$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoading(CALL_CANCEL.ACTION))
  )

  canceling = false

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService,
  ) {}

  setCanceling(canceling: boolean) {
    this.canceling = canceling
  }

  confirmCancel() {
    this.store$.dispatch(cancelRequest())
    this.setCanceling(false)
    this.taggingService.setClickEvent(events.dashboard.CALL_CANCEL_REQUEST, events.dashboard.CALL_STATUS_PAGE_TYPE)
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.dashboard.CANCEL_CALL_REQUEST
    })
  }
}
