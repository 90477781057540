import { createAsyncConstants } from '../../../shared/utils'
import { createAction, props } from '@ngrx/store'

export const CLUB_SUPPORT = createAsyncConstants('CLUB_SUPPORT')

export interface ClubSupportResponse {
  name: string
  hasTrackingSupport: boolean
}

export const requestClubSupport = createAction(
  CLUB_SUPPORT.REQUEST,
  props<{ payload: string }>()
)

export const clubSupportSuccess = createAction(
  CLUB_SUPPORT.SUCCESS,
  props<{ payload: ClubSupportResponse }>()
)

export const notifyClubSupportFailure = createAction(CLUB_SUPPORT.FAILURE)
