import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-edit',
  templateUrl: './edit.icon.html',
})
export class EditIconComponent extends AbstractIconComponent {
  static icon = 'edit'
}
