import { Inject, Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable, Subscription, timer } from 'rxjs'

import { callStatusRequest, resetCallStatus } from './call-status.actions'
import { AAAStore } from '../../../store/root-reducer'
import { resetCall } from '../calls.actions'
import { cancelEditingRequest } from '../../../shared/actions/shared.actions'
import { MessageDialogTypes, StepTypes } from '../../ui/ui.types'
import { Router } from '@angular/router'
import { openMessageDialog } from '../../ui/ui.actions'
import { ActionRequestParams } from '../../../shared/types'
import { selectCallStatusInterval } from '../../ui/timeout/timeout.selectors'
import { startSessionTimeout } from '../../session/session.actions';
import { RouteTypes } from '../../main-router.module'
import { DRR_BASE_HREF } from '../../../shared/shared.config'

const CALL_STATUS_INTERVAL = 60000

enum TimerStatus {
  Stopped = 0,
  Running,
}

@Injectable({
  providedIn: 'root',
})
export class CallStatusService {
  callStatusInterval = CALL_STATUS_INTERVAL
  callStatusesTimer = null
  timerStatus = TimerStatus.Stopped
  callStatusTimerSubscription: Subscription = null

  selectCallStatusInterval$: Observable<number> = this.store$.pipe(
    select(selectCallStatusInterval)
  )

  constructor(
    private store$: Store<AAAStore>,
    private router: Router,
    @Inject(DRR_BASE_HREF) private drrBaseHref: string
  ) {
    this.selectCallStatusInterval$.subscribe((interval) => {
      if (interval && interval > 0) {
        this.callStatusInterval = interval
      }
    })
  }

  startCallStatusesUpdater(params: ActionRequestParams) {
    if (this.timerStatus === TimerStatus.Running) {
      return null
    }

    this.callStatusesTimer = timer(
      this.callStatusInterval - 1,
      this.callStatusInterval
    )

    if (this.callStatusTimerSubscription) {
      this.callStatusTimerSubscription.unsubscribe()
    }

    this.callStatusTimerSubscription = this.callStatusesTimer.subscribe(() => {
      this.store$.dispatch(callStatusRequest({ payload: params }))
    })

    this.timerStatus = TimerStatus.Running
  }

  stopCallStatusesUpdater() {
    if (this.timerStatus === TimerStatus.Stopped) {
      return null
    }

    if (this.callStatusTimerSubscription) {
      this.callStatusTimerSubscription.unsubscribe()
    }

    this.callStatusesTimer = null

    this.timerStatus = TimerStatus.Stopped
  }

  resetCallStatus(dialogType: MessageDialogTypes) {
    this.store$.dispatch(startSessionTimeout())
    this.store$.dispatch(resetCallStatus())
    this.store$.dispatch(resetCall())
    this.store$.dispatch(cancelEditingRequest())
    this.store$.dispatch(
      openMessageDialog({
        payload: {
          type: dialogType,
        },
      })
    )
    this.router.navigate([this.drrBaseHref, RouteTypes.STEPS], {
      queryParams: { step: StepTypes.BREAKDOWN_LOCATION },
    })
  }
}
