import { I18nLocales, SUPPORTED_LOCALES, DISTANCE_UNIT, I18nLocale, I18nDistanceUnits } from './i18n.types'
import { OEMBranding } from '../../modules/auth/auth.types'

export const I18N_SUPPORTED_APP_IDS = [OEMBranding.GMC, OEMBranding.BUICK, OEMBranding.CHEVROLET, OEMBranding.ONSTAR]
export const normalizeLocale = (locale: string) => locale.replace(/-|_/, '').toLowerCase()

export const getSupportedLocale = (locale: string) =>
  Object.values(SUPPORTED_LOCALES).find((supportedLocale) =>
    normalizeLocale(supportedLocale) === normalizeLocale(locale))
    || SUPPORTED_LOCALES.EN_US

export const getCurrentLocale = () => {
  const locale = localStorage.getItem('locale') || SUPPORTED_LOCALES.EN_US
  return I18nLocales().find((loc) => loc.locale === locale)
}

