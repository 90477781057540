<div class="dialog">
  <div mat-dialog-content
    class="modal-form">

    <h1 class="title" i18n>
      Choose your new language:
    </h1>
    <div class="language-selector">
      <app-locale-selector
              *ngFor="let option of supportedLocales"
              [locale]="option"
              [selected]="option.locale === locale"
              (onClick)="handleSwitchLocale($event)"
      >
      </app-locale-selector>
    </div>

    <h1 class="title" i18n>
      Units System:
    </h1>
    <div class="unit-system-selector">
      <app-unit-system-selector
        *ngFor="let option of supportedUnits"
        [unitSystem]="option"
        [selected]="option.unit === distanceUnit"
        (onClick)="handleSwitchUnitSystem($event)"
      >
      </app-unit-system-selector>
    </div>

  </div>

  <div mat-dialog-actions
    class="buttons-row">
    <app-button i18n (buttonClick)="handleConfirm()">
      Confirm
    </app-button>
  </div>
  <button i18n-aria-label
    class="mat-dialog-close"
    aria-label="dismiss this message"
    (click)="onClose()">
    <icon-close></icon-close>
  </button>
</div>
