<svg
  xmlns="http://www.w3.org/2000/svg"
  width="26"
  height="23"
  viewBox="0 0 26 23"
  id="icon-car"
>
  <path
    fill="#1A1A1A"
    fill-rule="evenodd"
    d="M23.375 1.452C23.095.604 21.216 0 20.292 0H4.898c-.924 0-1.693.604-1.987 1.452L0 10.062v11.5C0 22.354.63 23 1.4 23h1.399c.77 0 1.4-.647 1.4-1.438v-1.437h16.793v1.438c0 .79.63 1.437 1.4 1.437h1.399c.77 0 1.4-.647 1.4-1.438v-11.5l-2.911-8.61h1.095zM3.285 14.238c0-1.212.98-2.19 2.191-2.19 1.212 0 2.19.978 2.19 2.19s-.978 2.19-2.19 2.19a2.188 2.188 0 0 1-2.19-2.19zm14.334 0c0-1.212.978-2.19 2.19-2.19 1.213 0 2.191.978 2.191 2.19s-.978 2.19-2.19 2.19a2.188 2.188 0 0 1-2.191-2.19zM5.112 2h15.49l2.112 6.571H3L5.112 2z"
  />
</svg>
