import { AbstractIconComponent } from '../abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-ev-connector-j1772-combo',
  templateUrl: './ev-connector-j1772Combo.icon.html',
})
export class EvConnectorJ1772ComboIconComponent extends AbstractIconComponent {
  static icon = 'ev-connector-j1772-combo'
}
