import { Action, ActionReducer } from '@ngrx/store'
import { AAAStore } from '../root-reducer'

export function loadingMiddleware(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function newReducer(state: AAAStore, action: Action) {
    const asyncActionRegex = /(.REQUEST|.SUCCESS|.FAILURE)/g
    const isAsync = asyncActionRegex.test(action.type)

    if (isAsync) {
      const [feature, featureState] = action.type.split('.')
      const loading = state.loading

      let isLoading = loading[feature]
      switch (featureState) {
        case 'REQUEST':
          isLoading = true
          break
        case 'SUCCESS':
        case 'FAILURE':
          isLoading = false
          break
      }

      state.loading = {
        ...state.loading,
        [feature]: isLoading,
      }
    }

    return reducer(state, action)
  }
}
