import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ALL_MAKES } from '../../../modules/vehicle/vehicle.contants';

@Component({
  selector: 'app-vehicle-image',
  templateUrl: './vehicle-image.component.html',
  styleUrls: ['./vehicle-image.component.scss']
})
export class VehicleImageComponent implements AfterViewInit {
  @Input() thumbnailUrl: string
  @Input() imageUrl: string
  @Input() make: string
  @Input() maxWidthImage = '100%'
  @Input() maxWidthMake = '100%'
  @Input() maxHeight = '100%'
  @ViewChild('thumbnail') thumbnailImg: ElementRef<HTMLImageElement>
  @ViewChild('image') imageImg: ElementRef<HTMLImageElement>
  @ViewChild('make') makeImg: ElementRef<HTMLImageElement>
  thumbSuccess = false
  imageSuccess = false
  showSpinner = true
  displayElement = true
  @Output() loadImgError: EventEmitter<void> = new EventEmitter<void>()

  ngAfterViewInit(): void {
    if (this.thumbnailUrl) {
      this.thumbnailImg.nativeElement.src = this.thumbnailUrl
    } else {
      this.onThumbnailComplete(false)
    }
  }

  onThumbnailComplete(success?) {
    this.thumbSuccess = Boolean(success)
    if(this.thumbSuccess) {
      this.hideSpinner()
    }
    if (this.imageUrl) {
      this.imageImg.nativeElement.src = this.imageUrl
    } else {
      this.onImageComplete(false)
    }
  }

  onImageComplete(success?) {
    this.imageSuccess = Boolean(success)
    if(this.imageSuccess) {
      this.hideSpinner()
    }
    if (!this.thumbSuccess && !this.imageSuccess) {
      this.makeImg.nativeElement.src = this.buildMakeUrl()
    }
  }

  hideSpinner() {
    this.showSpinner = false
  }

  onMakeError() {
    this.loadImgError.emit()
    this.displayElement = false
  }

  buildMakeUrl() {
    const finalMake = ALL_MAKES.filter(make => make.label.toUpperCase() === this.make.toUpperCase())
    return finalMake.length ? `assets/drrweb-lib/images/common-makes/${finalMake[0].icon}` : ''
  }

}
