import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AAAStore } from '../../../store/root-reducer'
import { RapService } from '../../rap/rap.service'
import { selectIsEVstation } from '../aar/aar.selectors'

@Component({
  selector: 'app-tow-location-promo',
  templateUrl: './tow-location-promo.component.html',
  styleUrls: ['./tow-location-promo.component.scss'],
})
export class TowLocationPromoComponent {
  isRapUser = this.rapService.isRapUser()
  isEVstation$: Observable<boolean> = this.store$.pipe(
    select(selectIsEVstation)
  )

  constructor(
    private rapService: RapService,
    private store$: Store<AAAStore>
  ) {}
}
