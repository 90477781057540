<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="19"
  viewBox="0 0 20 19"
  id="icon-flat-tire"
>
  <path
    [attr.fill]="color"
    fill-rule="evenodd"
    d="M12.56.968L1.84 3.442A1.504 1.504 0 0 0 .685 4.746l-.68 6.304 1.66 7.185 2.733.011s.23-.514.105-1.05l11.693-2.7.225.975c.124.536.663.873 1.2.75l.974-.226c.535-.123.873-.663.749-1.199l-1.8-7.795-3.374-5.369a1.494 1.494 0 0 0-1.61-.664zM4.315 14.16a1.498 1.498 0 0 1-1.8-1.124 1.498 1.498 0 0 1 1.125-1.799 1.498 1.498 0 0 1 1.799 1.124 1.498 1.498 0 0 1-1.124 1.8zm10.718-2.474a1.498 1.498 0 0 1-1.8-1.124 1.498 1.498 0 0 1 1.125-1.8 1.498 1.498 0 0 1 1.799 1.125 1.498 1.498 0 0 1-1.124 1.799zM2.179 4.904l10.718-2.475 2.474 4.048L1.73 9.626l.449-4.722z"
  />
</svg>
