import { Component, EventEmitter, Input, Output } from '@angular/core'
import { I18nLocale } from '../i18n.types'

@Component({
  selector: 'app-locale-selector',
  templateUrl: './locale-selector.component.html',
  styleUrls: ['./locale-selector.component.scss'],
})
export class LocaleSelectorComponent {
  @Input()
  locale: I18nLocale
  @Input()
  selected: boolean
  @Output() onClick = new EventEmitter<I18nLocale>()

  constructor() {
  }

  handleClick(event: Event) {
    event.preventDefault()
    this.onClick.emit(this.locale)
  }

}
