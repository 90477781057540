import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-share',
  templateUrl: './share.icon.html',
})
export class ShareIconComponent extends AbstractIconComponent {
  static icon = 'share'
}
