import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-truck',
  templateUrl: './truck.icon.html',
})
export class TruckIconComponent extends AbstractIconComponent {
  static icon = 'truck'
}
