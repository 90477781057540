import {
  Component,
  Output,
  EventEmitter,
  Input,
} from '@angular/core'
import { Observable } from 'rxjs'
import { FormGroupState } from 'ngrx-forms'
import { PlateToVinFormState } from '../../../modules/ui/forms/forms.reducer'
import { select, Store } from '@ngrx/store'
import { selectPlateToVinForm } from '../../../modules/ui/forms/forms.selectors'
import { AAAStore } from '../../../store/root-reducer'

@Component({
  selector: 'app-input-license-plate',
  templateUrl: './input-license-plate.component.html',
  styleUrls: ['./input-license-plate.component.scss'],
})
export class InputLicensePlateComponent {
  licensePlate: string

  form$: Observable<FormGroupState<PlateToVinFormState>> =
    this.store$.pipe(select(selectPlateToVinForm))

  @Input() error: boolean
  @Output() licensePlateChange: EventEmitter<string> = new EventEmitter<string>()

  constructor(
    private store$: Store<AAAStore>,
  ) {}

  sanitizeKeyup(event: FocusEvent): string {
    return (event.target as HTMLInputElement).value.replace(
      /[^a-zA-Z0-9]|\s{2,}/g, ''
    ).toUpperCase()
  }

  handleBlurText(event: FocusEvent) {
    this.licensePlate = this.sanitizeKeyup(event)
    this.licensePlateChange.emit(this.licensePlate)
  }
}
