import { Component } from '@angular/core'
import { openPromptDialog } from '../../../modules/ui/ui.actions'
import { PromptDialogTypes } from '../../../modules/ui/ui.types'
import { AAAStore } from '../../../store/root-reducer'
import { Store } from '@ngrx/store'
import { IconComponent } from '@aaa-mobile/shared-components'
import { AbstractComponent } from '../../abstract.component'
import { IonButton, IonButtons } from '@ionic/angular/standalone'
import { AdobeEventService } from '../../../modules/tagging/adobe/event-adobe.service';
import { AdobeEventTypes } from '../../../modules/tagging/tagging.types';
import events from '../../../modules/tagging/events';

@Component({
  selector: 'app-closable-header',
  templateUrl: './closable-header.component.html',
  styleUrls: ['./closable-header.component.scss'],
  imports: [IconComponent, IonButtons, IonButton],
  standalone: true
})
export class ClosableHeaderComponent extends AbstractComponent {

  constructor(
    private store$: Store<AAAStore>,
    private adobeEventService: AdobeEventService,
  ) {
    super()
  }

  onClose() {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.shared.CLOSE_BUTTON
    })
    this.store$.dispatch(openPromptDialog({
      payload: {
        type: PromptDialogTypes.CLOSE_REQUEST_DIALOG
      }
    }))
  }
}
