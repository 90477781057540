import { Injectable } from '@angular/core'
import { Adapter } from '../types'
import {
  AARData,
  AarDataApiModel,
  EvStationDetails,
  EvStationDetailsApiModel
} from '../../modules/location/aar/aar.types';
import { ConnectorTypeAdapter } from './ConnectorTypeAdapter';

@Injectable({
  providedIn: 'root',
})
export class AARDataAdapter implements Adapter<AARData> {

  constructor(
    private connectorTypeAdapter: ConnectorTypeAdapter,
  ) {
  }

  adapt(data: AarDataApiModel): AARData {
    return {
      ...data,
      evStationDetails: this.getEvStationDetails(data.evStationDetails)
    }
  }

  private getEvStationDetails(apiModel: EvStationDetailsApiModel): EvStationDetails {
    return apiModel
      ? {
        ...apiModel,
        connectorTypes: apiModel.connectorTypes?.map(ct => this.connectorTypeAdapter.adapt(ct)),
      }
      : null
  }

}
