import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-details-entry',
  templateUrl: './details-entry.component.html',
})
export class DetailsEntryComponent {
  @Input() details

  @Output() detailsChange: EventEmitter<any> = new EventEmitter<any>()

  constructor() {}
}
