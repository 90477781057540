export default {
  SUMMARY_PAGE_TYPE: 'Summary',
  SUMMARY_PAGE_PROMPT: 'Summary screen displayed',
  SUBMIT_PAGE_TYPE: 'Submit DRR Request',
  SUBMIT_SUCCESS: 'RSO Send Request Response',
  SUBMIT_FAILURE: 'RSO Send Request Failed',
  SUBMIT_DUPLICATE_PROMPT: 'Call Duplicate Prompt',
  SUMMARY_SUBMIT_CLICK: 'RSO Send Request',
  SUMMARY_OPTIONAL_DETAILS_ENTERED: 'Optional Details Entered',
  SUMMARY_TEXT_UPDATES_REMOVED: 'Text me updates removed',
  SUMMARY_EDIT_STEP: 'Edit Step:',
  SUMMARY_BACK_TO_STEP: 'Back to Step:',
  SUMMARY_CALL_REQUEST_SUBMITTED: 'Call Request Submitted',
  SUMMARY_CALL_REQUEST_SUCCESS: 'Call Request Success',
  SUMMARY_CALL_REQUEST_FAILED: 'Call Request Failed',

  PAGE_TYPE: 'Summary',
  PAGE_NAME_REVIEW: 'Summary Review',
}
