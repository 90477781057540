import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-clear',
  templateUrl: './clear.icon.html',
})
export class ClearIconComponent extends AbstractIconComponent {
  static icon = 'clear'
}
