export default {
  SERVICE_TRACKING_PAGE_TYPE: 'Service Tracking',
  SERVICE_TRACKING_PAGE_PROMPT: 'Service tracking screen displayed',
  SHOW_BATTERY_QUOTES_CLICK: 'Battery Quote Request',
  CALL_STATUS_PAGE_TYPE: 'DRR Request Received',
  CALL_STATUS_RECEIVED_SUCCESS: 'RSO Request Received',
  CALL_STATUS_RECEIVED_SUCCESS_DUPLICATED: 'RSO Request Received - Duplicated',
  CALL_STATUS_ENROUTE_SUCCESS: 'RSO Request Enroute',
  CALL_STATUS_ONLOCATION_SUCCESS: 'RSO Request on Location',
  CALL_STATUS_TOWING_SUCCESS: 'RSO Request Towing',
  CALL_CANCEL_REQUEST: 'RSO Cancel Request',
  CALL_CANCEL_SUCCESS: 'RSO Cancel Success',
  CALL_CANCEL_FAILURE: 'RSO Cancel Failed',
  PARTIAL_CALL_CANCELED: 'Partial Call Canceled',
  BATTERY_QUOTES_SHOW_CLICK: 'Get battery quote selected',
  BATTERY_QUOTES_DECLINE_CLICK: 'Battery Quote Declined',
  BATTERY_QUOTES_REQUEST: 'Battery Quote Request',
  BATTERY_QUOTES_SUCCESS: 'Battery Quote Success',
  BATTERY_QUOTES_FAILURE: 'Battery Quote failed - No Quotes',
  BATTERY_QUOTES_NO_PRICE: 'Battery Quote Failed - N/A Price',
  BATTERY_QUOTES_PROMPT: 'Battery Quote Prompt',

  CALL_STATUS_ERROR_PAGE_TYPE: 'Call Status Error Popup',
  CALL_STATUS_ERROR_PROMPT: 'Call Status Error Popup Prompt',
  PRE_SUBMISSION_CANCEL_SURVEY: 'Pre-Submission Cancel Survey',
  POST_SUBMISSION_CANCEL_SURVEY: 'Post-Submission Cancel Survey',

  CALL_REQUEST_STATUS: 'Call Request Status',
  CALL_STATUS_RECEIVED: 'Received',
  CALL_STATUS_RECEIVED_DUPLICATED: 'Received - Duplicated',
  CALL_STATUS_ENROUTE: 'En Route',
  CALL_STATUS_ARRIVED: 'Arrived',
  CALL_STATUS_TOW: 'Tow',
  CANCEL_CALL_REQUEST: 'Call Request Cancel Submitted',
  CANCEL_CALL_SUCCESS: 'Call Request Cancel Success',
  CANCEL_CALL_FAILED: 'Call Request Cancel Failed',

  PAGE_TYPE: 'Service Tracking',
  PAGE_NAME_DEFAULT: 'Service Tracker Default',
  PAGE_NAME_LIVE_TRACKING: 'Service Tracker Live Tracking',
}
