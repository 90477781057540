import { Component } from '@angular/core'

import { AbstractIconComponent } from './abstract.icon'

@Component({
  selector: 'icon-tow-truck',
  templateUrl: './tow-truck.icon.html',
})
export class TowTruckIconComponent extends AbstractIconComponent {
  static icon = 'tow-truck'
}
