import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-reviews-count-v2',
  templateUrl: 'reviews-count-v2.component.html',
  styleUrls: ['./reviews-count-v2.component.scss'],
})
export class ReviewsCountV2Component {

  @Input() numberReviews: number

  constructor() {
  }
}
