import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'

type IconDirection = 'top' | 'down' | 'left' | 'right'

@Component({
  selector: 'icon-arrow',
  templateUrl: './arrow.icon.html',
  styleUrls: ['./arrow.icon.scss'],
})
export class ArrowIconComponent extends AbstractIconComponent {
  static icon = 'arrow'

  @Input() direction: IconDirection = 'top'
}
