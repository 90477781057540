import { PaceSetterSituation, PaceSetterCode } from './issue.types'
import { props, createAction } from '@ngrx/store'

export const SET_PACE_SETTER_SITUATION = 'SET_PACE_SETTER_SITUATION'
export const SET_PACE_SETTER_OPTIONS = 'SET_PACE_SETTER_OPTIONS'
export const SET_PACE_SETTER_NOTES = 'SET_PACE_SETTER_NOTES'
export const PACE_SETTER_PREVIOUS = 'PACE_SETTER_PREVIOUS'
export const PACE_SETTER_NEXT = 'PACE_SETTER_NEXT'
export const SET_PACE_SETTER_STEP = 'SET_PACE_SETTER_STEP'
export const SET_CLEAR_ISSUE = 'SET_CLEAR_ISSUE'
export const SET_PACE_SETTER_OPTIONS_ADDITIONAL_REQUIREMENT = 'SET_PACE_SETTER_OPTIONS_ADDITIONAL_REQUIREMENT'

export const setPaceSetterSituation = createAction(
  SET_PACE_SETTER_SITUATION,
  props<{ payload: PaceSetterSituation }>()
)

export interface PaceSetterOptionsIdentifier {
  groupIndex?: number
  listIndex?: number
  selection?: PaceSetterCode[]
}

export const setPaceSetterOptions = createAction(
  SET_PACE_SETTER_OPTIONS,
  props<PaceSetterOptionsIdentifier>()
)

export const setPaceSetterOptionsAdditionalRequirement = createAction(
  SET_PACE_SETTER_OPTIONS_ADDITIONAL_REQUIREMENT,
  props<PaceSetterOptionsIdentifier>()
)

export interface PaceSetterNotesContainer {
  notes?: string
}

export const setPaceSetterNotes = createAction(
  SET_PACE_SETTER_NOTES,
  props<PaceSetterNotesContainer>()
)

export const paceSetterPrevious = createAction(PACE_SETTER_PREVIOUS)

export const paceSetterNext = createAction(PACE_SETTER_NEXT)

export const setPaceSetterStep = createAction(
  SET_PACE_SETTER_STEP,
  props<{ index: number }>()
)

export const setClearIssue = createAction(SET_CLEAR_ISSUE)
