import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { Store, select } from '@ngrx/store'

import { AAAStore } from '../../../store/root-reducer'

@Component({
  selector: 'app-auth-security-wrapper',
  templateUrl: './auth-security-wrapper.component.html',
})
export class AuthSecurityWrapperComponent {
  isSecure$: Observable<boolean> = this.store.pipe(
    select((state: AAAStore) => state.auth.isSecure)
  )

  constructor(private store: Store<AAAStore>) {}
}
