import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core'
import { Option } from '../ui.types'
import { NGRX_FORM_VIEW_ADAPTER, FormViewAdapter } from 'ngrx-forms'
@Component({
  selector: 'app-option-selector',
  templateUrl: './option-selector.component.html',
  styleUrls: ['./option-selector.component.scss'],
  host: { class: 'option-selector' },
  providers: [
    {
      // This allows this control to act as its own FormViewAdapter.
      provide: NGRX_FORM_VIEW_ADAPTER,
      useExisting: forwardRef(() => OptionSelectorComponent),
      multi: true,
    },
  ],
})
export class OptionSelectorComponent implements FormViewAdapter {
  _values: Option[]
  @Input() set values(value: Option[]) {
    this._values = value
    this.updateHasSelection(this._model)
  }
  get values(): Option[] {
    return this._values
  }

  _model: Option
  @Input() set model(value: Option) {
    this._model = value
    this.updateHasSelection(this._model)
  }
  get model(): Option {
    return this._model
  }
  @Output() modelChange: EventEmitter<Option> = new EventEmitter()

  @Input() tabIndex = 0

  _selectedIndex = -1

  setIsDisabled = null

  setViewValue(value: any): void {
    this.model = value
  }

  setOnChangeCallback(fn: (value: any) => void): void {
    this.modelChange.subscribe(fn)
  }

  setOnTouchedCallback(fn: () => void): void {
    this.modelChange.subscribe(fn)
  }

  updateHasSelection(selection: Option) {
    this._selectedIndex = -1
    if (this._values && selection) {
      this._selectedIndex = this._values.findIndex(
        (option) => option.label === selection.label
      )
    }
    if (this._model !== selection) {
      this._model = selection
      this.modelChange.emit(selection)
    }
  }
}
