import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { getURLQueryParams } from '../utils/query-params'
import { RouteGuardService } from './route-guard.service'

@Injectable()
export class IsNotAuthorizedRouteGuard  {
  constructor(private routeGuardService: RouteGuardService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ): Observable<boolean> {
    const { memid, ets, token, club } = getURLQueryParams({
      memid: '',
      ets: '',
      token: '',
      club: '',
    })(route.queryParams)
    const hasSecureParams =
      (Boolean(memid) && Boolean(ets)) || (Boolean(token) && Boolean(club))

    return this.routeGuardService.isPathAllowed().pipe(
      map((isAllowed) => {
        if (hasSecureParams) {
          return false
        }

        if (isAllowed) {
          this.routeGuardService.redirectToFirstStep(route.queryParams)
        }

        return !isAllowed
      })
    )
  }
}
