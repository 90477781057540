import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { from, Observable, of, Subject } from 'rxjs'
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import { dispatchErrorAction } from '../../shared/utils'
import { AAAStore } from '../../store/root-reducer'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { PayloadedAction } from '../../shared/types'
import { ConfigService } from '../config/config.service'
import { callStatusRequest } from '../dashboard/calls-statuses/call-status.actions'
import {
  MEMBER_BASIC_INFO,
  MEMBER_INFO,
  memberBasicInfoRequest,
  memberBasicInfoSuccess,
  memberInfoRequest,
  memberInfoSuccess,
  MEMBERS_SEARCH,
  requestVehicleLoad,
  searchMembersReset,
  setEligibilityVehicle,
  vehicleLoadSuccess,
} from '../member/member.actions'
import { MemberTypesFromMessage, Vehicle } from '../member/member.types'
import { startSessionTimeout } from '../session/session.actions'
import { TaggingService } from '../tagging/tagging.service'
import { handleAuthAnalytics } from '../tagging/tagging.utils'
import {
  closeDialog,
  openErrorDialog,
  openPromptDialog,
  resetSkipIssue,
  setSplashscreenStep,
  setUserDefaultCoords,
} from '../ui/ui.actions'
import { selectCountry, selectQueryParamsVehicleData } from '../ui/ui.selectors'
import { selectFirstCallStatusTimeout } from '../ui/timeout/timeout.selectors'
import { ErrorDialogTypes, PromptDialogTypes } from '../ui/ui.types'
import { selectVehicleYears } from '../vehicle/vehicle.selectors'
import {
  assignEncryptedMemberNumberParams,
  assignSecureParams,
  AUTH,
  AUTH_NAME_SEARCH,
  AUTH_NAME_SEARCH_INFO,
  AUTH_NO_ROUTING,
  AUTH_OEM,
  AUTH_OEM_VALIDATION,
  AUTH_PHONE_NUMBER,
  AUTH_RENTAL,
  AUTH_VAS,
  authNameSearchSuccess,
  authNoRouting,
  authOemSuccess,
  authOemValidation,
  authRentalSuccess,
  authSuccess,
  authVasSuccess,
  FIRST_MATCH_FROM_SEARCH,
  incrementErrorCount,
  notifyAuthFailure,
  notifyAuthNameSearchFailure,
  notifyAuthRentalFailure,
  notifyAuthVasFailure,
  notifyOemAuthFailure,
  notifyRapAuthFailure,
  notifyRentalAuthFailure,
  notifyVasAuthFailure,
  SET_AUTH,
  SET_ENCRYPTED_MEMBER_NUMBER,
  SET_RAP_AUTH,
  SET_SECURE_PARAMS,
  setLastSearchId,
  setRapAuthSuccess,
} from './auth.actions'
import {
  selectAuthErrorCount,
  selectAuthMethod,
  selectIsSecure,
  selectLastSearchId,
  selectModeConfiguration
} from './auth.selectors'
import { AuthService } from './auth.service'
import {
  AuthMethods, AuthNoRoutingResponse,
  AuthRequestParams,
  AuthResponse,
  BaseAuthRequestParams,
  MemberNumberAuthRequestParams,
  NameAuthRequestParams,
  OemAuthRequestParams,
  RapAuthFailure,
  RapAuthResponse,
  SearchIdPhoneNumberParams
} from './auth.types'
import { getAutomatedEventDetails, getEncodedAuthCredentials, isCaaZipCode } from '../member/member.utils'
import { RouteTypes } from '../main-router.module'
import events from '../tagging/events'
import auth from '../tagging/events/auth'
import { Uuid } from '../../shared/utils/uuid'
import { selectUserSessionId } from '../session/session.selectors'
import { VehicleUtils } from '../vehicle/vehicle.utils';
import { ContractIdentityDataOem, Eligibility } from './eligibility.types';
import { VendorConfigurationMode } from './mode-configuration.types';
import { CredentialsManagementService } from './credential-management/credential-management.service'
import { automatedEventAction } from '../tagging/tagging.actions';
import { AdobeMemberValidationService } from '../tagging/adobe/member-validation-adobe.service'
import { AdobeEventTypes, MemberValidationType } from '../tagging/tagging.types'
import { setContactNumber } from '../submit/submit.actions';
import { DRR_BASE_HREF } from '../../shared/shared.config'

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private _authService: AuthService,
    private errorReportingService: ErrorReportingService,
    private router: Router,
    private taggingService: TaggingService,
    private adobeMemberValidationService: AdobeMemberValidationService,
    private configService: ConfigService,
    private vehicleUtils: VehicleUtils,
    private uuid: Uuid,
    private credentialsService: CredentialsManagementService,
    @Inject(DRR_BASE_HREF) private drrBaseHref: string
  ) {}

  getEncodedCredentials = (
    credentials: NameAuthRequestParams | MemberNumberAuthRequestParams,
    userSessionId: string,
    errorCount: number) => getEncodedAuthCredentials(credentials, userSessionId, errorCount)

  handleEncryptedLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_SECURE_PARAMS),
      tap(() => {
        this.taggingService.setAutomatedEvent(
          events.auth.AAA_NATIONAL_AUTH_REQUEST,
          events.auth.PAGE_TYPE
        )
        this.adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION, MemberValidationType.CLUB_SIGN_IN)
      }),
      switchMap((action: ReturnType<typeof assignSecureParams>) =>
        from(this._authService.secureSignin(action.payload)).pipe(
          switchMap((data: AuthResponse) => [
            authSuccess({
              payload: {
                ...data,
                club: this.configService.getConfig().unsecureClub,
                method: AuthMethods.AAA_NATIONAL,
                isSecure: true,
              },
            }),
            resetSkipIssue(),
            memberInfoRequest(),
          ]),
          catchError((error) => {
            const { reason } = getAutomatedEventDetails(error)

            handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
              reason,
              method: AuthMethods.AAA_NATIONAL,
              isValid: false,
            })
            return this.errorReportingService.notifyErrorObservable(
              error,
              notifyAuthFailure
            )
          })
        )
      ),
      catchError((error) => {
        const { reason } = getAutomatedEventDetails(error)

        handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
          reason,
          method: AuthMethods.AAA_NATIONAL,
          isValid: false,
        })

        return this.errorReportingService.notifyErrorObservable(
          error,
          notifyAuthFailure
        )
      })
    )
  )

  handleEncryptedMemberNumberLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_ENCRYPTED_MEMBER_NUMBER),
      switchMap((action: ReturnType<typeof assignEncryptedMemberNumberParams>) =>
        from(this._authService.secureMembershipNumberSignin(action.payload)).pipe(
          switchMap((data: AuthResponse) => [
            authSuccess({
              payload: {
                ...data,
                club: this.configService.getConfig().unsecureClub,
                method: AuthMethods.ENCRYPTED_MEMBER_NUMBER,
                isSecure: true,
              },
            }),
            resetSkipIssue(),
            memberInfoRequest(),
          ]),
          catchError((error) => this.errorReportingService.notifyErrorObservable(
            error,
            notifyAuthFailure
          ))
        )
      ),
      catchError((error) => this.errorReportingService.notifyErrorObservable(
        error,
        notifyAuthFailure
      ))
    )
  )

  handleLogin$: Observable<
    | ReturnType<typeof authSuccess>
    | ReturnType<typeof memberBasicInfoRequest>
    | ReturnType<typeof requestVehicleLoad>
    | ReturnType<typeof notifyAuthFailure>
  > = createEffect(() =>
      this.actions$.pipe(
        ofType(AUTH.REQUEST),
        withLatestFrom(
          this.store$.select(selectUserSessionId),
          this.store$.select(selectAuthErrorCount),
        ),
        switchMap(([action, userSessionId, errorCount]: [PayloadedAction, string, number]) =>
          from(this._authService.unsecureSignin(action.payload)).pipe(
            mergeMap((data: AuthResponse) => {
              if (action.payload.rememberMe) {
                this.credentialsService.storeCredentials({
                  memberNumber: action.payload.memberNumber,
                  zipCode: action.payload.zipCode,
                })
              }
              if (isCaaZipCode(action.payload.zipCode)) {
                this.taggingService.setAutomatedEvent(auth.CAA_ZIP_FORMAT_AUTH_NUMBER, auth.PAGE_TYPE)
              }
              window.localStorage.setItem('remember-credentials', action.payload.rememberMe)
              return this.loginSuccessActions(data, action.payload.method)
            }),
            catchError((error) => {
              const { errorEventAction } = getAutomatedEventDetails(error)
              if (errorEventAction) {
                this.taggingService.setAutomatedEvent(errorEventAction, events.auth.PAGE_TYPE)
              }

              if(error.response?.data?.searchId && error.response?.data?.phoneNumbersHint.length) {
                this.store$.dispatch(setLastSearchId({
                  payload: error.response.data.searchId
                }))
                return this.handlePhoneNumberTry(error.response.data.searchId, error.response.data.phoneNumbersHint)
              } else {
                return this.handleInvalidAuth(error, userSessionId, errorCount, action.payload)
              }
            })
          )
        ),
        catchError((error) =>
          this.errorReportingService.notifyErrorObservable(
            error,
            notifyAuthFailure
          )
        )
      )
    )

  handleSearchIdPhoneNumberAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH_PHONE_NUMBER.REQUEST),
      withLatestFrom(
        this.store$.select(selectLastSearchId),
      ),
      map(([action, lastSearchId]: [PayloadedAction, string]) => ({
        searchId: lastSearchId,
        phoneNumber: action.payload,
      })),
      withLatestFrom(
        this.store$.select(selectUserSessionId),
        this.store$.select(selectAuthErrorCount),
      ),
      switchMap(([params, userSessionId, errorCount]: [SearchIdPhoneNumberParams, string, number]) =>
        from(this._authService.selectBySearchIdAndPhoneNumber(params)).pipe(
          mergeMap((data: AuthResponse) => [
            ...this.loginSuccessActions(data, AuthMethods.MEMBERSHIP_NUMBER),
            automatedEventAction({
              payload: {
                action: events.auth.FORM_PHONE_NUMBER_ONLY_AUTH_SUCCESS,
                pageType: events.auth.PAGE_TYPE
              }
            }),
          ]),
          catchError((e) => {
            this.taggingService.setAutomatedEvent(
              events.auth.FORM_PHONE_NUMBER_ONLY_AUTH_FAILURE,
              events.auth.PAGE_TYPE
            )
            const error = {
              ...e,
              response: {
                data: {
                  message: MemberTypesFromMessage.MEMBER_LOOKUP_ERROR
                }
              }
            }
            const payload = {
              ...params,
              method: AuthMethods.MEMBERSHIP_NUMBER
            }
            return this.handleInvalidAuth(error, userSessionId, errorCount, payload)
          })
        )
      )
    )
  )

  handleSearchIdPhoneNumberAuthFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH_PHONE_NUMBER.FAILURE),
      withLatestFrom(
        this.store$.select(selectUserSessionId),
        this.store$.select(selectAuthErrorCount),
      ),
      switchMap(([_, userSessionId, errorCount]: [PayloadedAction, string, number]) => {
        const error = {
          response: {
            data: {
              message: MemberTypesFromMessage.MEMBER_LOOKUP_ERROR
            }
          }
        }
        const payload = {
          method: AuthMethods.MEMBERSHIP_NUMBER
        }
        return this.handleInvalidAuth(error, userSessionId, errorCount, payload)
      })
    )
  )

  handlePhoneNumberTry = (searchId, phoneNumbersHint) => [
    setLastSearchId({
      payload: searchId
    }),
    openPromptDialog({
      payload: {
        type: PromptDialogTypes.AUTH_PHONE_NUMBER_DIALOG,
        disableClose: true,
        params: {
          phoneNumbersHint
        }
      }
    })
  ]

  handleInvalidAuth = (error, userSessionId, errorCount, payload: AuthRequestParams | BaseAuthRequestParams) => {
    const { reason } = getAutomatedEventDetails(error)
    const encodedCredentials = this.getEncodedCredentials(payload as NameAuthRequestParams | MemberNumberAuthRequestParams, userSessionId, errorCount)

    handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
      reason,
      method: payload.method,
      isValid: false,
      membershipNumber: payload.memberNumber,
    }, {
      propertyName: encodedCredentials,
      ...(error.response?.status ? { status: error.response.status } : {})
    })

    return this.errorReportingService.notifyErrorObservable(
      error,
      notifyAuthFailure
    )
  }

  loginSuccessActions = (data: AuthResponse, method: AuthMethods) => [
    authSuccess({
      payload: {
        ...data,
        club: this.configService.getConfig().unsecureClub,
        method,
        isSecure: false,
      },
    }),
    resetSkipIssue(),
    memberBasicInfoRequest(),
  ]

  handleLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | ReturnType<typeof memberInfoSuccess>
        | ReturnType<typeof memberBasicInfoSuccess>
      >(MEMBER_INFO.SUCCESS, MEMBER_BASIC_INFO.SUCCESS),
      withLatestFrom(
        this.store$.pipe(select(selectAuthMethod)),
        this.store$.pipe(select(selectIsSecure)),
        this.store$.pipe(select(selectFirstCallStatusTimeout)),
      ),
      switchMap((inputs) =>
        of(inputs).pipe(
          tap(([action, authMethod]) => {
            if (action.payload.eligible && !action.payload.ersAbuser) {
              handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
                method: authMethod as AuthMethods,
                isValid: true,
                membershipNumber: action.payload.fullMembershipNumber,
              })
            }
          }),
          mergeMap(([action, _, isSecure, firstCallStatusTimeout]) => {
            const actions = [
              startSessionTimeout(),
              callStatusRequest({
                payload: {
                  retry: false,
                  timeout: firstCallStatusTimeout,
                  ...(action.payload.eligible !== undefined ? { eligible: action.payload.eligible } : {}),
                  ...(action.payload.ersAbuser !== undefined ? { ersAbuser: action.payload.ersAbuser } : {}),
                },
              }),
              requestVehicleLoad(),
            ]
            if (isSecure) {
              actions.push(setSplashscreenStep({ payload: 2 }))
            }
            return actions
          }),
          catchError((error) => {
            const { reason } = getAutomatedEventDetails(error)
            handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
              reason,
              method: inputs[1],
              isValid: false,
            })

            return this.errorReportingService.notifyErrorObservable(
              error,
              notifyAuthFailure
            )
          })
        )
      )
    )
  )

  handleLoginBySearch$ = createEffect(
    (): Observable<
      | ReturnType<typeof authNameSearchSuccess>
      | ReturnType<typeof notifyAuthFailure>
    > =>
      this.actions$.pipe(
        ofType(AUTH_NAME_SEARCH.REQUEST),
        switchMap((action: PayloadedAction) =>
          from(this._authService.searchSignin(action.payload)).pipe(
            concatMap((response) => [
              searchMembersReset(),
              authNameSearchSuccess({
                payload: {
                  ...response,
                  club: this.configService.getConfig().unsecureClub,
                  isSecure: false,
                },
              }),
              resetSkipIssue(),
              memberBasicInfoRequest(),
            ]),
            catchError((error) => {
              const { reason } = getAutomatedEventDetails(error)

              handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
                reason,
                method: AuthMethods.MEMBER_NAME,
                isValid: false,
              })
              return this.errorReportingService.notifyErrorObservable(error, [
                openErrorDialog({
                  payload: { type: ErrorDialogTypes.MEMBER_NO_MATCHES },
                }),
                dispatchErrorAction(notifyAuthNameSearchFailure, error),
              ])
            })
          )
        )
      )
  )

  handleLoginBySearchInfo$ = createEffect(
    (): Observable<
      | ReturnType<typeof authNameSearchSuccess>
      | ReturnType<typeof notifyAuthFailure>
    > =>
      this.actions$.pipe(
        ofType(AUTH_NAME_SEARCH_INFO.REQUEST),
        switchMap((action: PayloadedAction) =>
          from(this._authService.searchSigninInfo(action.payload)).pipe(
            concatMap((response) => [
              searchMembersReset(),
              authNameSearchSuccess({
                payload: {
                  ...response,
                  club: this.configService.getConfig().unsecureClub,
                  isSecure: false,
                },
              }),
              resetSkipIssue(),
              memberBasicInfoRequest(),
              closeDialog({
                payload: { type: PromptDialogTypes.ADDITIONAL_AUTH_SEARCH },
              })
            ]),
            catchError((error) => {
              const { reason } = getAutomatedEventDetails(error)

              handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
                reason,
                method: AuthMethods.MEMBER_NAME,
                isValid: false,
              })
              return this.errorReportingService.notifyErrorObservable(error, [
                openErrorDialog({
                  payload: { type: ErrorDialogTypes.MEMBER_NO_MATCHES },
                }),
                dispatchErrorAction(notifyAuthNameSearchFailure, error),
              ])
            })
          )
        )
      )
  )

  handleFirstMatchSearch$ = createEffect(
    (): Observable<
      | ReturnType<typeof authNameSearchSuccess>
      | ReturnType<typeof notifyAuthFailure>
    > =>
      this.actions$.pipe(
        ofType(FIRST_MATCH_FROM_SEARCH),
        switchMap((action: PayloadedAction) =>
          of(action).pipe(
            concatMap((response) => [
              searchMembersReset(),
              authNameSearchSuccess({
                payload: {
                  ...response.payload,
                  club: this.configService.getConfig().unsecureClub,
                  isSecure: false,
                },
              }),
              resetSkipIssue(),
              memberBasicInfoRequest(),
            ]),
            catchError((error) => {
              const { reason } = getAutomatedEventDetails(error)

              handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
                reason,
                method: AuthMethods.MEMBER_NAME,
                isValid: false,
              })
              return this.errorReportingService.notifyErrorObservable(error, [
                openErrorDialog({
                  payload: { type: ErrorDialogTypes.MEMBER_NO_MATCHES },
                }),
                dispatchErrorAction(notifyAuthNameSearchFailure, error),
              ])
            })
          )
        )
      )
  )

  handleLoginBypass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_AUTH),
      switchMap((action) =>
        of(action).pipe(
          concatMap(() => {
            this.taggingService.setAutomatedEvent(
              events.auth.AAA_MOBILE_AUTH_REQUEST,
              events.auth.PAGE_TYPE
            )
            this.adobeMemberValidationService.sendEvent(AdobeEventTypes.MEMBER_VALIDATION, MemberValidationType.APP_SIGN_IN)
            return [memberInfoRequest()]
          }),
          catchError((error) => this.errorReportingService.notifyErrorObservable(
            error,
            notifyAuthFailure
          )
          )
        )
      )
    )
  )

  // FIXME: This shouldn't be necessary. For whatever reason, route-guard.service.ts:32
  // does not detect state changes on AUTH.FAILURE, even setting ...initialState in reducer.
  // This effect has been added to mitigate the damage.
  handleLoginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AUTH.FAILURE, AUTH_OEM.FAILURE),
        switchMap(() =>
          of([]).pipe(
            withLatestFrom(this.store$.pipe(select(selectAuthMethod))),
            map(([_, authMethod]) => {
              if (![AuthMethods.MEMBER_NAME, AuthMethods.MEMBERSHIP_NUMBER].includes(authMethod)) {
                this.taggingService.setPageEvent(
                  events.auth.MEMBER_ISSUE_PROMPT,
                  events.auth.MEMBER_ISSUE_PAGE_TYPE,
                )
              }
              this.router.navigate([this.drrBaseHref, ...RouteTypes.SIGNIN.split('/')], {
                queryParamsHandling: 'preserve',
              })
            }),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(error, [])
            )
          )
        )
      ),
    { dispatch: false }
  )

  handleLoginFailAssistant$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AUTH.FAILURE, MEMBERS_SEARCH.FAILURE),
        withLatestFrom(this.store$.pipe(select(selectAuthMethod))),
        filter(([_, authMethod]) =>
          [AuthMethods.MEMBER_NAME, AuthMethods.MEMBERSHIP_NUMBER].includes(authMethod)),
        map(() => incrementErrorCount())
      ),
  )

  handleRapLoginBypass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_RAP_AUTH.REQUEST),
      switchMap((action: PayloadedAction) => from(this._authService.rapVerification(action.payload.access_token, action.payload.odometer)).pipe(
        switchMap((response) => {
          this.taggingService.setAutomatedEvent(auth.FORM_RAP_TOKEN_VALIDATION_REQUEST, events.auth.PAGE_TYPE)
          return [
            setRapAuthSuccess(),
            authOemValidation({
              payload: {
                response,
                method: AuthMethods.RAP_TOKEN,
              }
            })]
        }),
        catchError((error) => this.handleRapRequestValidationError(error, AuthMethods.RAP_TOKEN, 'Invalid Token')),
      )
      ),
    )
  )

  handleOemAuth$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_OEM.REQUEST),
    withLatestFrom(
      this.store$.pipe(select(selectCountry)),
    ),
    switchMap(([action, country]: [PayloadedAction, string]) => {
      const params: OemAuthRequestParams = action.payload
      return from(this._authService.oemValidation(params.captchaToken, params.vin, params.mileage, params.manufacturer, params.referenceId, country)).pipe(
        map((response) => authOemValidation({
          payload: {
            response,
            method: AuthMethods.OEM,
          }
        })),
        catchError((error) => this.handleRapRequestValidationError(error, AuthMethods.OEM, 'invalid vin number')),
      );
    })
  ))

  handleRapRequestValidationError = (error, method: AuthMethods, reason: string) => {
    if (!error.response || error.response.status === 500) {
      handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
        method,
        isValid: false,
        reason: error.message
      })
      return this.errorReportingService.notifyErrorObservable(
        error,
        [notifyRapAuthFailure({ payload: RapAuthFailure.OEM_SERVER_ERROR })]
      )
    }
    handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
      method,
      isValid: false,
      reason,
    })
    return this.errorReportingService.notifyErrorObservable(
      error,
      notifyAuthFailure
    )
  }

  handleAuthOemValidation$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_OEM_VALIDATION),
    withLatestFrom(
      this.store$.pipe(select(selectModeConfiguration)),
    ),
    switchMap(([action, modeConfig]: [PayloadedAction, VendorConfigurationMode]) => {
      const response: RapAuthResponse = action.payload.response
      const failure = this.extractOemFailure(response.eligibility, modeConfig)
      if(failure) {
        handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
          method: action.payload.method,
          isValid: false,
          reason: failure.reason
        })
        return [notifyRapAuthFailure({ payload: failure.rapAuthFailure })]
      }

      const successActions = []
      successActions.push(authOemSuccess({
        payload: {
          ...action.payload.response,
          isSecure: false,
        }
      }))
      if (response.latitude && response.longitude) {
        successActions.push(
          setUserDefaultCoords({
            payload: {
              lat: response.latitude,
              lng: response.longitude,
            }
          })
        )
      }
      return successActions
    })
  ))

  extractOemFailure = (eligibility: Eligibility, rapConfig: VendorConfigurationMode): {
    rapAuthFailure: RapAuthFailure,
    reason: string,
  } => {
    if(!eligibility.eligible) {
      return {
        rapAuthFailure: RapAuthFailure.NOT_ELIGIBLE,
        reason: 'Eligibility Fail',
      }
    }
    if(this.vehicleUtils.isRestrictModel(rapConfig, (eligibility.contractIdentityData as ContractIdentityDataOem).vehicle)) {
      return {
        rapAuthFailure: RapAuthFailure.RESTRICT_MODEL,
        reason: 'Restricted Model',
      }
    }
    return null
  }

  handleOemAuthSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_OEM.SUCCESS),
    withLatestFrom(
      this.store$.pipe(select(selectAuthMethod)),
      this.store$.pipe(select(selectQueryParamsVehicleData)),
      this.store$.pipe(select(selectModeConfiguration)),
      this.store$.pipe(select(selectVehicleYears)),
      this.store$.pipe(select(selectFirstCallStatusTimeout)),
    ),
    switchMap((action: any) => of(action).pipe(
      mergeMap(([_action, authMethod, queryParamsVehicle, modeConfig, vehicleYears, firstCallStatusTimeout]) => {
        handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
          method: authMethod as AuthMethods,
          isValid: _action.payload.eligibility.eligible,
        })

        const actions: any[] = [
          callStatusRequest({
            payload: {
              retry: false,
              timeout: firstCallStatusTimeout,
              eligible: _action.payload.eligibility.eligible,
            },
          }),
        ]

        const contractIdentityData = _action.payload.eligibility.contractIdentityData
        let vehicle: Vehicle = contractIdentityData?.vehicle;
        const isAllowedParamsYears = vehicleYears.includes(queryParamsVehicle?.year)
        const isAllowedParamsMake = modeConfig.makes?.find(make => make.toLowerCase() === queryParamsVehicle?.make?.toLowerCase())
        const isVehiclePresent = (v: Vehicle) => v && Object.keys(v).length
        if (!isVehiclePresent(vehicle) && isAllowedParamsMake && isAllowedParamsYears) {
          vehicle = queryParamsVehicle
        }
        if (isVehiclePresent(vehicle)) {
          vehicle.id = this.uuid.getV4()
          actions.push(vehicleLoadSuccess({ payload: { vehicles: [vehicle] } }))
          actions.push(setEligibilityVehicle({ payload: vehicle }))
        }
        if (contractIdentityData?.contact?.phones?.length) {
          actions.push(setContactNumber({ payload: contractIdentityData.contact.phones[0].phoneNumber }))
        }

        actions.push(startSessionTimeout())

        return actions;
      }),
      catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifyOemAuthFailure))
    )))
  )

  // RENTAL Effect
  handleRentalAuth$: Observable<
    | ReturnType<typeof authRentalSuccess>
    | ReturnType<typeof notifyAuthFailure>
    | ReturnType<typeof notifyRentalAuthFailure>
  > = createEffect(() => this.actions$.pipe(
      ofType(AUTH_RENTAL.REQUEST),
      switchMap((action: PayloadedAction) => {
        const rentalSignInRequest = this._authService.rentalValidation.bind(this._authService)
        let resolvePostSignInActions: (authResponse: AuthResponse) => any[]
        let resolvePostSignInActionsFailure: () => any[];

        resolvePostSignInActions = (data: AuthResponse) => [
          authRentalSuccess({
            payload: {
              ...data,
              isSecure: false,
              method: AuthMethods.RENTAL
            }
          }),
        ]
        resolvePostSignInActionsFailure = () => [
          notifyAuthRentalFailure(),
        ]
        return from(rentalSignInRequest(action.payload.captchaToken, action.payload.refNumber, action.payload.referenceId)).pipe(
          mergeMap((data: any) => {
            if (data.rentalValidation.eligibility.eligible) {
              return resolvePostSignInActions({
                ...data.rentalValidation,
                eligibility: data.rentalValidation.eligibility,
                method: action.payload.method
              })
            } else {
              return resolvePostSignInActionsFailure()
            }
          }),
          catchError((error) => {
            this.taggingService.setAutomatedEvent('invalid rental number', events.auth.PAGE_TYPE)

            handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
              method: action.payload.method,
              isValid: false,
            })
            return this.errorReportingService.notifyErrorObservable(
              error,
              notifyAuthFailure
            )
          }),
        );
      }),
      catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifyAuthFailure))
    ))

  handleRentalAuthSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_RENTAL.SUCCESS),
    withLatestFrom(
      this.store$.pipe(select(selectAuthMethod)),
      this.store$.pipe(select(selectFirstCallStatusTimeout)),
    ),
    switchMap((action: any) => of(action).pipe(
      mergeMap(([_action, authMethod, firstCallStatusTimeout]) => {
        handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
          method: authMethod as AuthMethods,
          isValid: _action.payload.eligibility.eligible,
        })

        return [
          callStatusRequest({
            payload: {
              retry: false,
              timeout: firstCallStatusTimeout,
              eligible: _action.payload.eligibility.eligible,
            },
          }),
        ]
      }),
      catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifyRentalAuthFailure))
    )))
  )

  // VAS Effect
  handleVasAuth$: Observable<
    | ReturnType<typeof authVasSuccess>
    | ReturnType<typeof notifyAuthFailure>
    | ReturnType<typeof notifyVasAuthFailure>
  > = createEffect(() => this.actions$.pipe(
      ofType(AUTH_VAS.REQUEST),
      switchMap((action: PayloadedAction) => {
        const vasSignInRequest = this._authService.vasValidation.bind(this._authService)
        let resolvePostSignInActions: (authResponse: AuthResponse) => any[]
        let resolvePostSignInActionsFailure: () => any[];

        resolvePostSignInActions = (data: AuthResponse) => [
          authVasSuccess({
            payload: {
              ...data,
              isSecure: false,
              method: AuthMethods.VAS
            }
          }),
        ]
        resolvePostSignInActionsFailure = () => [
          notifyAuthVasFailure(),
        ]
        return from(vasSignInRequest(action.payload.captchaToken, action.payload.subscriber, action.payload.referenceId)).pipe(
          mergeMap((data: any) => {
            if (data.vasValidation.eligibility.eligible) {
              return resolvePostSignInActions({
                ...data.vasValidation,
                eligibility: data.vasValidation.eligibility,
                method: action.payload.method
              })
            } else {
              return resolvePostSignInActionsFailure()
            }
          }),
          catchError((error) => {
            this.taggingService.setAutomatedEvent('invalid vas number', events.auth.PAGE_TYPE)

            handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
              method: action.payload.method,
              isValid: false,
            })
            return this.errorReportingService.notifyErrorObservable(
              error,
              notifyAuthFailure
            )
          }),
        );
      }),
      catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifyAuthFailure))
    ))

  handleVasAuthSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_VAS.SUCCESS),
    withLatestFrom(
      this.store$.pipe(select(selectAuthMethod)),
      this.store$.pipe(select(selectFirstCallStatusTimeout)),
    ),
    switchMap((action: any) => of(action).pipe(
      mergeMap(([_action, authMethod, firstCallStatusTimeout]) => {
        handleAuthAnalytics(this.taggingService, this.adobeMemberValidationService, {
          method: authMethod as AuthMethods,
          isValid: _action.payload.eligibility.eligible,
        })

        return [
          callStatusRequest({
            payload: {
              retry: false,
              timeout: firstCallStatusTimeout,
              eligible: _action.payload.eligibility.eligible,
            },
          }),
        ]
      }),
      catchError((error) => this.errorReportingService.notifyErrorObservable(error, notifyVasAuthFailure))
    )))
  )

  libAuthResponse$ = new Subject<AuthNoRoutingResponse>()
  handleLibAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof authNoRouting>>(AUTH_NO_ROUTING),
      switchMap((act) =>
        of(act).pipe(
          tap((action) => {
            this.libAuthResponse$.next(action.payload)
          }),
          catchError((error) => this.errorReportingService.notifyErrorObservable(
            error,
            notifyAuthFailure
          )
          )
        )
      )
    )
  , { dispatch: false })
}

