import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-destination-card-template',
  templateUrl: './destination-card-template.component.html',
  styleUrls: ['./destination-card-template.component.scss'],
})
export class DestinationCardTemplateComponent {
  @Input() title: string;
  @Input() distance: number;
  @Input() status?: string;
  @Input() nextAction?: string;
  @Input() closeable = false;
  @Input() borderShadow = true;

  @Output() close = new EventEmitter<void>();
}
