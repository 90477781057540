import { ProblemButtonComponent } from './problem-button/problem-button.component'
import { IssueSelectionComponent } from './issue-selection/issue-selection.component'
import { IssueStepComponent } from './issue-step/issue-step.component';
import { IssueButtonComponent } from './issue-button/issue-button.component';
import { IssueTooltipComponent } from './issue-tooltip/issue-tooltip.component';
import { SituationEditorComponent } from './situation-editor/situation-editor.component'
import { SituationDetailsComponent } from './situation-details/situation-details.component'
import { ProblemChecklistComponent } from './problem-checklist/problem-checklist.component'

export function issueComponents() {
  return [
    ProblemButtonComponent,
    IssueButtonComponent,
    IssueStepComponent,
    IssueTooltipComponent,
    IssueSelectionComponent,
    SituationEditorComponent,
    SituationDetailsComponent,
    ProblemChecklistComponent,
  ]
}
