import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-home',
  templateUrl: './home.icon.html',
})
export class HomeIconComponent extends AbstractIconComponent {
  static icon = 'home'
}
