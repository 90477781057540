import { AdobeAnalyticsParams, AdobeEventTypes, PageLoadEventParams, ProductCategory } from '../tagging.types'
import { Inject, Injectable, OnDestroy } from '@angular/core'
import { select, Store } from '@ngrx/store';
import { AAAStore } from '../../../store/root-reducer';
import { selectAuthClub, selectIsSignedIn, selectModeConfiguration } from '../../auth/auth.selectors';
import { VendorConfigurationMode } from '../../auth/mode-configuration.types';
import { isAgentAppId } from '../../ui/ui.utils';
import { getAppId } from '../../../shared/utils/cookies';
import { getProductCategory } from './utils';
import { AdobeAnalyticsService } from './adobe-analytics.service';
import { selectIssueTypeTagging } from '../../issue/issue.selectors';
import { ConfigService } from '../../config/config.service'
import { selectChannel } from '../../ui/ui.selectors'
import { selectLocationClub } from '../../location/location.selectors';

@Injectable({
  providedIn: 'root',
})
export class PageLoadAdobeService implements OnDestroy {

  _subscriptions = []
  isSignedIn$ = this.store.pipe(select(selectIsSignedIn))
  isSignedIn = false
  rapConfig$ = this.store.pipe(select(selectModeConfiguration))
  rapConfig: VendorConfigurationMode
  issueTypeTagging$ = this.store.pipe(select(selectIssueTypeTagging))
  issueType: string
  channel$ = this.store.pipe(select(selectChannel))
  channel: string
  authClub$ = this.store.pipe(select(selectAuthClub))
  authClub: string
  locationClub$ = this.store.pipe(select(selectLocationClub))
  locationClub: string

  constructor(
    private store: Store<AAAStore>,
    private adobeAnalyticsService: AdobeAnalyticsService,
    private configService: ConfigService,
  ) {
    this._subscriptions.push(
      this.isSignedIn$.subscribe((isSignedIn) => this.isSignedIn = isSignedIn),
      this.rapConfig$.subscribe((rapConfig) => this.rapConfig = rapConfig),
      this.issueTypeTagging$.subscribe((issueType) => this.issueType = issueType),
      this.channel$.subscribe((channel) => this.channel = channel),
      this.authClub$.subscribe((club) => this.authClub = club),
      this.locationClub$.subscribe((club) => this.locationClub = club)
    )
  }

  sendEvent({ pageType, pageName }: PageLoadEventParams): void {
    const params: AdobeAnalyticsParams = {
      eventName: AdobeEventTypes.PAGE_LOAD,
      eventAttributes: {
        ...(pageType ? { page_type: pageType } : {}),
        business_line: 'Automotive',
        business_line_product: 'Digital ERS',
        product_category: getProductCategory(getAppId()) as ProductCategory,
        logged_in: this.isSignedIn ? 'Y' : 'N',
        user_type: this.getUserType(),
        vendor_name: this.getVendor(),
        vendor_code: this.getVendor(),
      },
      directAttributes: {
        page_name: pageName,
        sc_channel: this.adobeAnalyticsService.getAdobeChannel(getAppId(), this.channel),
        ...(this.issueType ? { issue_type: this.issueType } : {}),
        ...(this.authClub ? { local_club: this.authClub } : {}),
        ...(this.locationClub ? { servicing_club: this.locationClub } : {}),

      }
    }

    if (this.configService.getConfig().configTools) {
      console.log(`Tagging Service - Page Load Event
        Page Type: ${pageType}
        Page Name: ${pageName}
        Issue Type: ${this.issueType}`)
    }
    try {
      this.adobeAnalyticsService.transmitAdobeAnalyticsEvents(params)
    } catch (error) {
      console.warn('Error transmitting Adobe Analytics events', error)
    }
  }

  getVendor() {
    return this.rapConfig ? this.rapConfig.id : 'NA'
  }

  getUserType() {
    if (isAgentAppId()) {
      return 'agent'
    }
    if (this.rapConfig) {
      return this.rapConfig.validation
    }
    return 'member'
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
  }
}
