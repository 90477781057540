import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogData } from '../../ui.types'
import { ConfigService } from '../../../config/config.service'

@Component({
  selector: 'app-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.scss'],
})
export class IframeDialogComponent {
  constructor(
    private configService: ConfigService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  close() {
    this.data.close()
  }

  showAbTestSwitcher() {
    return this.configService.getConfig().clientId === 'drr-web.qa' && this.data?.params?.ariaLabel === 'Terms and conditions content'
  }
}
