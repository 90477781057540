import { Component, Input, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AbstractComponent } from '../../../shared/abstract.component'
import { ThirdPartyCookies } from '../../../shared/utils/third-party-cookies/third-party-cookies'
import { AAAStore } from '../../../store/root-reducer'
import { selectExternalService } from '../calls-statuses/call-status.selectors'
import { EXTERNAL_SERVICES } from '../calls-statuses/call-status.types'
import { AAATracking } from '../calls.types'

const PROVIDERS_THIRD_PARTY_COOKIE_REQUIRED: Array<string> = [
  EXTERNAL_SERVICES.GLYMPSE,
  EXTERNAL_SERVICES.APPOINTMENT_ASSISTANT,
]
const FORCE_REFRESH = 60_000
const BYPASS_URL_CACHE_PARAM = 'bypass-url-cache'

@Component({
  selector: 'app-external-tracker',
  templateUrl: './external-tracker.component.html',
  styleUrls: ['./external-tracker.component.scss']
})
export class ExternalTrackerComponent extends AbstractComponent implements OnInit {

  externalService$: Observable<AAATracking> = this.store$.select(selectExternalService)

  @Input() url: string
  forceRefreshInterval: number
  urlDisplay: string
  interval

  supported: boolean = null

  constructor(
    private store$: Store<AAAStore>,
    private thirdPartyCookies: ThirdPartyCookies,
  ) {
    super()
  }

  ngOnInit() {
    this.urlDisplay = this.url
    const interval = this.forceRefreshInterval ? this.forceRefreshInterval : FORCE_REFRESH
    this.interval = setInterval(() => this.updateUrlDisplay(), interval)
    this.subscriptions.push(
      this.externalService$.subscribe((externalService) => {
        let supported = !PROVIDERS_THIRD_PARTY_COOKIE_REQUIRED.includes(externalService?.provider?.toUpperCase())
        if (!supported) {
          this.thirdPartyCookies.isEnabled().then((sup) => {
            this.supported = sup
          })
        } else {
          this.supported = supported
        }
      })
    )
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    this.stopInterval()
  }

  updateUrlDisplay = () => {
    const urlObject = new URL(this.url)
    urlObject.searchParams.append(BYPASS_URL_CACHE_PARAM, String(new Date().getTime()))
    this.urlDisplay = urlObject.href
  }

  stopInterval = () => clearInterval(this.interval)

}
