<a i18n-aria-label
  href="{{ phoneNumber | phoneConverter: 'href' }}"
  target="_blank"
  class="phone-link"
  appCtaClickEvent="Call Shop"
  aria-label="Call Shop"
  (click)="stopPropagation($event)"
>
  <aaa-text display="flex">
    <div class="icon-container">
      <aaa-icon name="phone"></aaa-icon>
    </div>
  <span class="phone-number">{{ phoneNumber }}</span>
  </aaa-text>
</a>
