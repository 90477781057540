import { Component, Input, ViewEncapsulation } from '@angular/core'

@Component({
  standalone: true,
  selector: 'app-img[src]',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageComponent {
  @Input() alt = ''
  @Input() src: string
  @Input() srcset: string
  @Input() classNames: string
  @Input() elementId: string
  @Input() width: string
  @Input() height: string
}
