<div class="option-selector_container normal-text">
  <div
    class="option-selector_option"
    *ngFor="let option of _values; let i = index"
    (click)="updateHasSelection(option)"
    triggerable-element
    [tabIndex]="tabIndex + i"
    role="radio" 
    [attr.aria-checked]="i === _selectedIndex"
    [ngClass]="{
      'first-focusable-element': i === 0,
      active: i === _selectedIndex
    }"
  >
    <hr aria-hidden="true" />
    <div class="option-selector_marker"></div>
    <span>{{ option.label }}</span>
  </div>
</div>
