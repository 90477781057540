import { Component } from '@angular/core'

import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AAAStore } from '../../../store/root-reducer'
import { selectServiceDeliveryStatus } from '../calls.selectors'
import { ServiceDeliveryCallStatus } from '../calls.types'

import { DateTime } from 'luxon'

// ARR:POC - The below controls the display and render of the call history data
@Component({
  selector: 'app-service-delivery',
  templateUrl: './service-delivery.component.html',
  styleUrls: ['./service-delivery.component.scss'],
})
export class ServiceDeliveryComponent {
  serviceDeliveryStatus$: Observable<ServiceDeliveryCallStatus> =
    this.store$.pipe(select(selectServiceDeliveryStatus))

  getDate(input) {
    return DateTime.fromISO(new Date(input).toISOString()).toFormat('hh:mm a')
  }

  constructor(private store$: Store<AAAStore>) {}
}
