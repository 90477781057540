<aaa-input
  #inputElement
  mask="{{mask}}"
  variant="secondary"
  name="zipCode"
  i18n-label
  id="{{id}}"
  type="{{type}}"
  label="Home Postal Code"
  [ngrxFormControlState]="controls.zipCode"
  aria-required="true"
  autocomplete="postal-code"
  (aaaFocus)="showAction()"
  (aaaBlur)="hideAction()"
  required>

  <ion-button *ngIf="displayAction" size="small" (click)="switchKeyboard()" rightAction fill="clear" slot="end" class="change-keyboard">
    <span class="keyboard" *ngIf="type === 'tel' else numpad">
      <aaa-icon name="keyboard" color="medium"></aaa-icon>
    </span>
    <ng-template #numpad>
      <span class="numpad">
        <aaa-icon name="keypad-outline" size="small" color="medium"></aaa-icon>
      </span>
    </ng-template>
  </ion-button>

  <app-form-helper helper
                   i18n
                   [control]="controls?.zipCode"
                   name='zipCode'
                   type='pattern'> Postal Code is invalid.
  </app-form-helper>
  <app-form-helper helper
                   i18n
                   [control]="controls.zipCode"
                   name='zipCode'
                   type='required'> Postal Code is required.
  </app-form-helper>
</aaa-input>
