import { VehicleColor, Make } from './vehicle.types'

export const CommonMakes: Make[] = [
  {
    label: 'Ford',
    icon: 'Ford@2x.png'
  },
  {
    label: 'Toyota',
    icon: 'Toyota@2x.png'
  },
  {
    label: 'Chevrolet',
    icon: 'Chevrolet@2x.png'
  },
  {
    label: 'Honda',
    icon: 'Honda@2x.png'
  },
  {
    label: 'Nissan',
    icon: 'Nissan@2x.png'
  },
  {
    label: 'Jeep',
    icon: 'Jeep@2x.png'
  },
  {
    label: 'Ram',
    icon: 'Ram@2x.png'
  },
  {
    label: 'Hyundai',
    icon: 'Hyundai@2x.png'
  },
  {
    label: 'Subaru',
    icon: 'Subaru@2x.png'
  },
  {
    label: 'Kia',
    icon: 'Kia@2x.png'
  },
  {
    label: 'GMC',
    icon: 'GMC@2x.png'
  },
  {
    label: 'Mercedes-Benz',
    icon: 'Mercedes-Benz@2x.png'
  },
  {
    label: 'Volkswagen',
    icon: 'Volkswagen@2x.png'
  },
  {
    label: 'Tesla',
    icon: 'Tesla@2x.png'
  },
  {
    label: 'BMW',
    icon: 'Bmw@2x.png'
  },
]

export const ExtraMakes: Make[] = [
  {
    label: 'Volvo',
    icon: 'Volvo@2x.png'
  },
  {
    label: 'Renault',
    icon: 'Renault@2x.png'
  },
  {
    label: 'Citroen',
    icon: 'Citroen@2x.png'
  },
  {
    label: 'Fiat',
    icon: 'Fiat@2x.png'
  },
  {
    label: 'Suzuki',
    icon: 'Suzuki@2x.png'
  },
  {
    label: 'Peugeot',
    icon: 'Peugeot@2x.png'
  },
  {
    label: 'Ferrari',
    icon: 'Ferrari@2x.png'
  },
  {
    label: 'Land Rover',
    icon: 'LandRover@2x.png'
  },
  {
    label: 'Audi',
    icon: 'Audi@2x.png'
  },
  {
    label: 'Saab',
    icon: 'Saab@2x.png'
  },
  {
    label: 'Mini',
    icon: 'Mini@2x.png'
  },
  {
    label: 'Mazda',
    icon: 'Mazda@2x.png'
  },
  {
    label: 'Jaguar',
    icon: 'Jaguar@2x.png'
  },
  {
    label: 'Mitsubishi',
    icon: 'Mitsubishi@2x.png'
  },
  {
    label: 'Dodge',
    icon: 'Dodge@2x.png'
  },
  {
    label: 'Lamborghini',
    icon: 'Lamborghini@2x.png'
  },
  {
    label: 'Lexus',
    icon: 'Lexus@2x.png'
  },
  {
    label: 'Porsche',
    icon: 'Porsche@2x.png'
  },
  {
    label: 'Rolls-Royce',
    icon: 'RollsRoyce@2x.png'
  },
  {
    label: 'Bentley',
    icon: 'Bentley@2x.png'
  },
  {
    label: 'Alfa Romeo',
    icon: 'AlfaRomeo@2x.png'
  },
  {
    label: 'Smart',
    icon: 'Smart@2x.png'
  },
  {
    label: 'Chery',
    icon: 'Chery@2x.png'
  },
  {
    label: 'Bugatti',
    icon: 'Bugatti@2x.png'
  },
  {
    label: 'Maserati',
    icon: 'Maserati@2x.png'
  },
  {
    label: 'Mercury',
    icon: 'Mercury@2x.png'
  },
  {
    label: 'Cadillac',
    icon: 'Cadillac@2x.png'
  },
  {
    label: 'Acura',
    icon: 'Acura@2x.png'
  },

  {
    label: 'Plymouth',
    icon: 'Plymouth@2x.png'
  },
  {
    label: 'Auburn',
    icon: 'Auburn@2x.png'
  },
  {
    label: 'Pontiac',
    icon: 'Pontiac@2x.png'
  },
  {
    label: 'Cleveland',
    icon: 'Cleveland@2x.png'
  },
  {
    label: 'Cole',
    icon: 'Cole@2x.png'
  },
  {
    label: 'Davis',
    icon: 'Davis@2x.png'
  },
  {
    label: 'Du pont',
    icon: 'Dupont@2x.png'
  },
  {
    label: 'Elcar',
    icon: 'Elcar@2x.png'
  },
  {
    label: 'Lotus',
    icon: 'Lotus@2x.png'
  },
  {
    label: 'Saturn',
    icon: 'Saturn@2x.png'
  },
  {
    label: 'Essex',
    icon: 'Essex@2x.png'
  },
  {
    label: 'Franklin',
    icon: 'Franklin@2x.png'
  },
  {
    label: 'Gardner',
    icon: 'Gardner@2x.png'
  },
  {
    label: 'Haynes',
    icon: 'Haynes@2x.png'
  },
  {
    label: 'Hcs',
    icon: 'Hcs@2x.png'
  },
  {
    label: 'Biddle',
    icon: 'Biddle@2x.png'
  },
  {
    label: 'Buick',
    icon: 'Buick@2x.png'
  },
  {
    label: 'Hispano-Suiza',
    icon: 'HispanoSuiza@2x.png'
  },
  {
    label: 'Hupmobile',
    icon: 'Hupmobile@2x.png'
  },
  {
    label: 'Jordan',
    icon: 'Jordan@2x.png'
  },
  {
    label: 'Lincoln',
    icon: 'Lincoln@2x.png'
  },
  {
    label: 'Locomobile',
    icon: 'Locomobile@2x.png'
  },
  {
    label: 'Marmon',
    icon: 'Marmon@2x.png'
  },
  {
    label: 'Maxwell',
    icon: 'Maxwell@2x.png'
  },
  {
    label: 'Moon',
    icon: 'Moon@2x.png'
  },
  {
    label: 'Geo',
    icon: 'Geo@2x.png'
  },
  {
    label: 'Eagle',
    icon: 'Eagle@2x.png'
  },
  {
    label: 'Infinity',
    icon: 'Infinity@2x.png'
  },
  {
    label: 'Isuzu',
    icon: 'Isuzu@2x.png'
  },
  {
    label: 'Scion',
    icon: 'Scion@2x.png'
  },
  {
    label: 'Spyker',
    icon: 'Spyker@2x.png'
  },
  {
    label: 'Mayback',
    icon: 'Mayback@2x.png'
  },
]

export const ALL_MAKES: Make[] = [
  ...CommonMakes,
  ...ExtraMakes,
]

export const OTHER_COLOR_NAME = 'Other'
export const OTHER_COLOR = {
  name: OTHER_COLOR_NAME,
  hex: 'linear-gradient(90deg,rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%,rgba(95, 21, 242, 1) 70%,rgba(186, 12, 248, 1) 80%,rgba(251, 7, 217, 1) 90%,rgba(255, 0, 0, 1) 100%)',
  shade: '#ffffff',
  shadeAlpha: 0.15,
}

export const VEHICLE_COLORS: VehicleColor[] = [
  {
    name: 'Black',
    hex: '#000000',
    shade: '#ffffff',
    shadeAlpha: 0.1,
  },
  {
    name: 'Gray',
    hex: '#7f7d7d',
    shade: '#ffffff',
    shadeAlpha: 0.25,
  },
  {
    name: 'White',
    hex: '#f8f8f8',
    shade: '#ffffff',
    shadeAlpha: 1.0,
  },
  {
    name: 'Blue',
    hex: '#15284c',
    shade: '#0000ff',
    shadeAlpha: 0.25,
  },
  {
    name: 'Red',
    hex: '#b61600',
    shade: '#ff0000',
    shadeAlpha: 0.45,
  },
  {
    name: 'Brown',
    hex: '#4d2e2e',
    shade: '#ffffff',
    shadeAlpha: 0.1,
  },
  {
    name: 'Purple',
    hex: '#750066',
    shade: '#ffffff',
    shadeAlpha: 0.1,
  },
  {
    name: 'Maroon',
    hex: '#700800',
    shade: '#ff0000',
    shadeAlpha: 0.15,
  },
  {
    name: 'Green',
    hex: '#215143',
    shade: '#00ff00',
    shadeAlpha: 0.25,
  },
  {
    name: 'Yellow',
    hex: '#ddbe2a',
    shade: '#ffd440',
    shadeAlpha: 0.25,
  },
  {
    name: 'Orange',
    hex: '#e06625',
    shade: '#ffffff',
    shadeAlpha: 0.15,
  },
  {
    name: 'Gold',
    hex: 'linear-gradient(315deg,#7d5518,#d4bf95 69.02%,#4b2b00)',
    shade: '#ffffff',
    shadeAlpha: 0.35,
  },
  {
    name: 'Silver',
    hex: 'linear-gradient(315deg,#525252,#f8f8f8 69.02%,#525252)',
    shade: '#ffffff',
    shadeAlpha: 0.35,
  },
  {
    name: 'Other',
    hex: 'transparent',
    shade: '#ffffff',
    shadeAlpha: 0.15,
  },
]

export const findColorByName = (color: string | undefined) =>
  VEHICLE_COLORS.find((value) => value.name.toLowerCase() === color?.toLowerCase())

export const VEHICLE_MODEL_NAME_MAX_LENGTH = 36
