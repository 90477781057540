import { Subject } from 'rxjs'

declare let google: any

function Marker(latlng, map, layout, args) {
  this.latlng = latlng
  this.args = args
  this.data = args.data
  this.layout = layout
  if (this.setMap) {
    this.setMap(map)
  }
}

/**
 * Handle mouse move and touche move events
 *
 * @param element html marker element
 * @param self main reference
 * @param touch flag to difference between touch or mouse
 */
const moveHandler = (element, self, touch) => {
  const origin = self.get('origin')

  // set values according to event source
  const originClientX = touch ? origin.touches[0].clientX : origin.clientX
  const originClientY = touch ? origin.touches[0].clientY : origin.clientY
  const elementClientX = touch
    ? element.changedTouches[0].clientX
    : element.clientX
  const elementClientY = touch
    ? element.changedTouches[0].clientY
    : element.clientY

  // reference position in canvas
  const left = originClientX - elementClientX
  const top = originClientY - elementClientY

  // current marker position
  const pos = self.getProjection().fromLatLngToDivPixel(self.latlng)

  // new coords
  const _x = pos.x - left
  const _y = pos.y - top
  const latLng = self
    .getProjection()
    .fromDivPixelToLatLng(new google.maps.Point(_x, _y))

  self.set('origin', element)
  self.set('latlng', latLng)
  // redraw marker in the new position
  self.draw()
}

/**
 * Enable draggable marker properties
 *
 * @param container html marker container
 * @param self main reference
 */
const setDragMarker = (container, self) => {
  container.style.position = 'absolute'
  container.draggable = true

  const _enterEvents = [
    { event: 'mouseleave', leave: 'mouseup' },
    { event: 'touchleave', leave: 'touchend' },
  ]

  const _moveEvents = [
    {
      event: 'mousedown',
      move: 'mousemove',
      handler: 'moveHandler',
      touch: false,
      end: 'mouseup',
    },
    {
      event: 'touchstart',
      move: 'touchmove',
      handler: 'touchHandler',
      touch: true,
      end: 'touchend',
    },
  ]

  _enterEvents.forEach((e) => {
    google.maps.event.addDomListener(self.get('map').getDiv(), e.event, () => {
      google.maps.event.trigger(container, e.leave)
    })
  })

  _moveEvents.forEach((item) => {
    const endHandlerFactory = (end) => ($event) => {
      if (self && self.map && $event && $event.type === end) {
        self.map.set('draggable', true)
        container.style.cursor = 'default'
        google.maps.event.removeListener(self[item.handler])
        google.maps.event.removeListener(self['map-leave'])

        const coords = {
          lat: self.latlng.lat(),
          lng: self.latlng.lng(),
        }
        self.args.dragEvent(coords)
      }
    }

    // init listener
    google.maps.event.addDomListener(container, item.event, (e) => {
      if (item.touch) {
        if (e.touches.length > 1) {
          return
        }

        // Prevent event from being bubbled up in a touch environment.
        e.stopPropagation()
        e.preventDefault()
      }

      container.style.cursor = 'move'
      self.map.set('draggable', false)
      self.set('origin', e)

      const mapDiv = self.get('map').getDiv()
      self['map-leave'] = google.maps.event.addDomListener(
        mapDiv,
        'mouseleave',
        endHandlerFactory('mouseleave')
      )
      self[item.handler] = google.maps.event.addDomListener(
        mapDiv,
        item.move,
        (element) => moveHandler(element, self, item.touch)
      )
    })

    // end listener
    google.maps.event.addDomListener(
      container,
      item.end,
      endHandlerFactory(item.end)
    )
  })
}

export class CustomMarker {
  constructor(coords: any, map: any, layout: any, options?: any) {
    Marker.prototype = new google.maps.OverlayView()

    Marker.prototype.draw = this.draw

    Marker.prototype.onAdd = this.onAdd

    Marker.prototype.onRemove = this.removeMarker

    Marker.prototype.getPosition = this.getPosition

    Marker.prototype.getOffset = this.getOffset

    Marker.prototype.refreshMarker = this.refreshMarker

    Marker.prototype.drawDiv = this.drawDiv

    Marker.prototype.emmiter = new Subject<any>()

    return new Marker(coords, map, layout, options || {})
  }

  /**
   * Draw custom marker
   */
  private draw() {
    const self: any = this
    const div = self.div

    const point = self.getProjection().fromLatLngToDivPixel(self.latlng)
    if (!point) {
      return
    }
    const offset = self.getOffset()

    div.style.left = point.x + offset.width + 'px'
    div.style.top = point.y + offset.height + 'px'
  }

  /**
   * onAdd is called when the map's panes are ready and the overlay has been
   * added to the map.
   */
  private onAdd() {
    this.drawDiv()
    // this.setDragMarker()
  }

  /**
   * Draw dif if do not exits
   */
  private drawDiv() {
    const self: any = this

    // add layout to main div
    const div = (self.div = self.layout)

    // Added click event when it's necessary
    if (self.args.clickEvent) {
      google.maps.event.addDomListener(div, 'click touchend', (event) => {
        event.preventDefault()
        event.stopPropagation()
        self.args.clickEvent()
      })
    }

    //  Set drag eevents  when it's necessary
    if (self.args.data.draggable) {
      // google.maps.event.addDomListener(self.div, 'mousedown',
      setDragMarker(div, self)
    }

    const panes = self.getPanes()

    if (panes) {
      panes.overlayImage.appendChild(div)
    }

    return div
  }

  /**
   * Update marker location and styles
   *
   * @param coords
   * @param styles
   */
  private refreshMarker(coords, options, map) {
    const self: any = this
    let div = self.div

    if (!self.map) {
      self.setMap(map)
    }

    if (!div) {
      div = self.drawDiv()
    }

    let style = self.args.style || {}

    if (options.style) {
      style = options.style || {}
    }

    self.latlng = coords

    if (options.className) {
      div.className = options.className || ''
    }

    const _styles = Object.keys(style)

    if (_styles.length && div) {
      _styles.forEach((key) => {
        div.style[key] = style[key]
      })
    }

    const projection = self.getProjection()

    if (!projection || !self.latlng) {
      return
    }

    const point = projection.fromLatLngToDivPixel(self.latlng)
    const offset = self.getOffset()

    div.style.left = point.x + offset.width + 'px'
    div.style.top = point.y + offset.height + 'px'
  }

  /**
   * Remove custom marker
   */
  private removeMarker() {
    const self: any = this
    const div = self.div || self.layout
    if (div) {
      div.parentNode.removeChild(div)
      self.div = null
      self.layout = null
    }
  }

  /**
   * Get marker position
   */
  private getPosition() {
    const self: any = this
    return self.latlng
  }

  /**
   * Get point offset
   */
  private getOffset() {
    const self: any = this

    let offset = new google.maps.Size(0, 0)
    if (!self.div) {
      return offset
    }
    const width = self.div.offsetWidth
    const height = self.div.offsetHeight
    offset = new google.maps.Size(width, height)

    offset.width = self.data.offsetX ? -self.data.offsetX : -(width / 2)
    offset.height = self.data.offsetY ? -self.data.offsetY : -(height / 2)

    return offset
  }
}
