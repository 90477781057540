<svg
  xmlns="http://www.w3.org/2000/svg"
  width="22"
  height="22"
  viewBox="0 0 22 22"
  id="icon-tire"
>
  <path
    [attr.fill]="color"
    fill-rule="evenodd"
    d="M0 11.036A10.96 10.96 0 0 1 10.963 0 10.964 10.964 0 0 1 22 10.963 10.964 10.964 0 0 1 11.036 22 10.962 10.962 0 0 1 0 11.036zm11.782 5.76c.27-1.184.175-2.885.913-3.404.704-.496 2.275.238 3.465.431l.226.851-3.692 2.724-.912-.602zm4.91-4.717c-1.07-.622-2.768-1.053-3.033-1.92-.254-.833.977-2.119 1.555-3.206.284.02.568.042.85.063l1.48 4.426-.851.637zM8.848 5.063h4.418l.454.682c-.902.88-1.745 2.352-2.726 2.45-.783.079-1.734-1.517-2.61-2.37l.464-.762zM4.46 11.275L5.86 6.932l.856-.163 1.768 3.388-3.397 1.735-.625-.617zm4.648 6.014l-3.592-2.56.05-.844c1.217-.253 2.86-1.093 3.538-.602.768.555.67 2.307.948 3.539l-.944.467zm9.89-6.403C18.95 6.473 15.43 3.001 11.002 3c-4.522 0-8.05 3.576-8.001 8.112.048 4.414 3.567 7.885 7.995 7.886 4.52.002 8.054-3.58 8.003-8.112zM11 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
  />
</svg>
