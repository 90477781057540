<div
  class="agent-login"
  [ngClass]="{
    entry: !(isSecure$ | async),
    placeholder: isSecure$ | async
  }"
>
  <form
    class="agent-login_form"
  >
    <ng-container>
      <label for="username">Username</label>
      <input
        name="username"
        id="username"
        type="text"
        autocomplete="username"
        placeholder="Username"
        [tabIndex]="(1 + tabIndexPosition)"
        [(ngModel)]="agentSettings.agentUsername"
      />
      <div class="agent-login_password-line">
        <label for="password">Password</label>
        <input
          name="password"
          id="password"
          type="password"
          autocomplete="current-password"
          placeholder="Password"
          [tabIndex]="(2 + tabIndexPosition)"
        />
      </div>
      <app-button
        type="submit"
        [tabIndex]="(3 + tabIndexPosition)"
        [loading]="loading"
        (buttonClick)="handleSubmit()"
      >
        Confirm
      </app-button>
    </ng-container>
  </form>
</div>
