<ng-container *ngIf="hasDeniedGpsAccess; else locationEnabled">
  <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.5 14.5L29.5 1L16.5 6" [attr.stroke]="(colorName ? (colorName | color) : color)" stroke-width="1.00151" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.5 19.5L19 29L16.0031 14.747L1.5 11.5L12 7.5" [attr.stroke]="(colorName ? (colorName | color) : color)" stroke-width="1.00151" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 0L30 23.5" [attr.stroke]="(colorName ? (colorName | color) : color)" stroke-width="1.00151" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</ng-container>

<ng-template #locationEnabled>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 7.937 7.938">
    <path d="M4.938 7.644L7.644.294.294 3l3.775.902z" fill="none"
      [attr.stroke]="(colorName ? (colorName | color) : color)" stroke-width=".265" stroke-linejoin="round" />
  </svg>
</ng-template>
