import { RouterNavigatedAction, RouterRequestAction } from '@ngrx/router-store'
import { Params } from '@angular/router'
import { RedirectParams } from '../../modules/auth/auth.types'

interface DefaultParamObjectType {
  [id: string]: string
}

export const getURLQueryParams =
  (defaultObject: DefaultParamObjectType) => (params: Params) =>
    Object.keys(params).reduce(
      (result, paramKey) => ({
        ...result,
        [paramKey.toLowerCase()]: Array.isArray(params[paramKey])
          ? params[paramKey][0]
          : params[paramKey],
      }),
      defaultObject
    )

export const getRouterActionQueryParams = (
  action: RouterRequestAction | RouterNavigatedAction
): Params => (action ? action.payload.routerState.root.queryParams : null)
export const getRouterActionQueryParam = (
  action: RouterRequestAction | RouterNavigatedAction,
  queryParam: string
): String => {
  const params = getRouterActionQueryParams(action)
  return params ? params[queryParam] : null
}

export const parseUrl = (url: string): RedirectParams => {
  const urlObj = new URL(url, 'http://mockbaseurl')
  const commands = urlObj.pathname.split('/').filter(Boolean)

  const queryParams = {};
  urlObj.searchParams.forEach((value, key) => {
    queryParams[key] = value;
  })

  return {
    commands,
    extras: {
      queryParams,
      replaceUrl: true
    }
  }
}
