<ng-container *ngIf="displayEta$ | async">
  @if (isRedesign()) {
    <ion-badge>
      <aaa-text size="footnote" color="text" weight="medium">
        <ion-grid class="ion-no-padding">
          <ion-row class="ion-align-items-center">
            <ion-col>
              <aaa-icon class="clock-icon" size="small" color="text" name="clock-hour2"></aaa-icon>
            </ion-col>
            <ion-col class="eta-col">
              {{ displayArrivalTime$ | async | timeRemaining }} <ng-container i18n>until arrival</ng-container> *
            </ion-col>
          </ion-row>
        </ion-grid>
      </aaa-text>
    </ion-badge>
  } @else {
    <div class="green-round"
         (click)="toggleTime()">
      <app-img
        src="assets/drrweb-lib/images/icons/time.svg"
        alt="Estimated Time of Arrival"
        i18n-alt
      ></app-img>
      <ng-container *ngIf="isArrivalTimeDisplayed; else etaLabel">
        <span class="arrival-time">{{ displayArrivalTime$ | async | timeRemaining }}*</span>
        <span i18n>until arrival</span>
      </ng-container>
    </div>
    <ng-template #etaLabel>
      <span class="eta" i18n>ETA</span>
      <span class="eta-time">{{ displayEta$ | async | date: 'hh:mm aa' }}*</span>
    </ng-template>
  }
</ng-container>
