import { JsonPipe, NgClass } from '@angular/common'
import { Component, computed, signal } from '@angular/core'
import { Step, StepStatus } from '../../../../modules/ui/ui.types'
import { selectIterableSteps } from '../../../../modules/ui/ui.selectors'
import { AAAStore } from '../../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { AbstractComponent } from '../../../abstract.component'

@Component({
  selector: 'app-stepper-redesign',
  standalone: true,
  imports: [NgClass, JsonPipe],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
})
export class StepperRedesignComponent extends AbstractComponent {
  steps = signal<Step[]>([])
  currentStep = computed(() =>
    this.steps().find((step) => step.status === 'EDITING')
  )

  StepStatus = StepStatus

  constructor(private store$: Store<AAAStore>) {
    super()

    this.addSubscription(
      this.store$
        .pipe(select(selectIterableSteps))
        .subscribe((steps) => this.steps.set(steps))
    )
  }
}
