<app-edit-tag
  [source]="vehicle"
  field="year"
  (selected)="editVehicleSection('years')"
  [disabled]="yearDisabled$ | async"
  [tabIndexPosition]="tabIndexPosition + 1"
></app-edit-tag>
<app-edit-tag
  [source]="vehicle"
  field="make"
  (selected)="editVehicleSection('makes')"
  [tabIndexPosition]="tabIndexPosition + 1"
></app-edit-tag>
<app-edit-tag
  alias="{{ getModel(vehicle) }}"
  [source]="vehicle"
  field="model"
  (selected)="editVehicleSection('models')"
  [tabIndexPosition]="tabIndexPosition + 1"
></app-edit-tag>
<app-edit-tag
  [source]="vehicle"
  field="color"
  (selected)="editVehicleSection('details')"
  [tabIndexPosition]="tabIndexPosition + 1"
></app-edit-tag>
