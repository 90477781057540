import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import events from '../../../tagging/events'
import { DialogData } from '../../ui.types'

@Component({
  selector: 'app-call-completed-dialog',
  templateUrl: './call-completed.component.html',
  styleUrls: ['./call-completed.component.scss'],
})
export class CallCompletedDialogComponent implements OnInit {
  pageType = events.shared.CALL_COMPLETE_CONFIRM_PAGE_TYPE

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit() {}

  onClose() {
    this.data.close()
  }
}
