import { Injectable } from '@angular/core'
import { APP_VERSION } from '../../version'

declare const VERSION

@Injectable({
  providedIn: 'root',
})
export class AppVersion {

  get = (nativeApp: boolean) => {
    return nativeApp ? APP_VERSION : VERSION
  }

}
