<div class="call-history-container">
  <h4>Call History</h4>
  <ng-container *ngIf="serviceDeliveryStatus$ | async as service">
    <div class="item-container" *ngFor="let item of service.callStatusHist">
      <div *ngIf="item.reason" class="line  top-line">
        <div class="small-text label">Timestamp:</div>
        <div class="small-text bold description">{{ getDate(item.timestamp) }}</div>
      </div>
      <div *ngIf="item.stat" class="line">
        <div class="small-text label">Status:</div>
        <div class="small-text bold description">{{ item.stat }}</div>
      </div>
      <div *ngIf="item.reason" class="line">
        <div class="small-text label">Reason:</div>
        <div class="small-text bold description">{{ item.reason }}</div>
      </div>
      <div *ngIf="item.facility" class="line">
        <div class="small-text label">Facility:</div>
        <div class="small-text bold description">{{ item.facility }}</div>
      </div>
    </div>
  </ng-container>
</div>
