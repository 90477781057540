import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bump-out-animation',
  templateUrl: './bump-out-animation.component.html',
  styleUrls: ['./bump-out-animation.component.scss']
})
export class BumpOutAnimationComponent implements OnInit {

  @Input() isBumpOut = false;

  constructor() { }

  ngOnInit(): void {

  }

}
