import { PayloadedAction } from '../../shared/types'
import { ClubSettingsGroup } from './servicing-club.types'
import { SET_SERVICING_CLUB_CONFIGS } from './servicing-club.actions'

export interface ServicingClubState {
  club: ClubSettingsGroup
}

export const initialState: ServicingClubState = {
  club: {},
}

export function reducer(
  state: ServicingClubState | undefined = initialState,
  action: PayloadedAction
): ServicingClubState {
  switch (action.type) {
    case SET_SERVICING_CLUB_CONFIGS.SUCCESS:
      return {
        ...state,
        club: {
          ...state.club,
          [action.payload.club]: action.payload.config,
        },
      }
    default:
      return state
  }
}
