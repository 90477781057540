import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class WindowObject {

  getHost = () => window.location.host

  getHref = () => window.location.href

  navigateTo = (url: string) => window.location.href = url

  reload = () => window.location.reload();

  getWindow = () => window

}
