import { getCookie } from './cookies'
import { AppId } from '../../modules/auth/auth.types'

export const isMobileApp = () => {
  const appId = getCookie('AAA_AppId')
  return Boolean(
    appId &&
      (appId === AppId.IOSMOBILE ||
        appId === AppId.ANDROIDMOBILE ||
        appId === AppId.ACEAPP_IOS ||
        appId === AppId.ACEAPP_ANDROID)
  )
}

export const isNativeIOS = () => {
  const appId = getCookie('AAA_AppId')
  return Boolean(
    appId &&
    (appId === AppId.IOSMOBILE ||
      appId === AppId.ACEAPP_IOS)
  )
}

export const isNativeAndroid = () => {
  const appId = getCookie('AAA_AppId')
  return Boolean(
    appId &&
    (appId === AppId.ANDROIDMOBILE ||
      appId === AppId.ACEAPP_ANDROID)
  )
}
