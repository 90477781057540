import { Component, Input, OnInit } from '@angular/core'
import { EvStationVolts } from '../../../../shared/types';
import { CHARGING_LEVEL_LIST } from '../../../../shared/utils/ev-station/ev-station.utils';

@Component({
  selector: 'app-charging-level',
  templateUrl: './charging-level.component.html',
  styleUrls: ['./charging-level.component.scss'],
})
export class ChargingLevelComponent implements OnInit {

  @Input() title: string
  @Input() description: string
  @Input() volts: EvStationVolts
  @Input() numberOfPorts: number
  numberColor: string

  constructor() {
  }

  ngOnInit(): void {
    this.numberColor = CHARGING_LEVEL_LIST[this.volts].backgroundColor
  }
}
