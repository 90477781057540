import { AgentIdentificationComponent } from './agent-identification/agent-identification.component'
import { DesktopHeaderAgentComponent } from './agent-identification/desktop-header-agent/desktop-header-agent.component'
import { MobileHeaderAgentComponent } from './agent-identification/mobile-header-agent/mobile-header-agent.component'
import { SignInComponent } from './sign-in/sign-in.component';

export function authComponents() {
  return [
    SignInComponent,
    AgentIdentificationComponent,
    DesktopHeaderAgentComponent,
    MobileHeaderAgentComponent,
  ]
}
