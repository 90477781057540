<aria-link-button
  *ngIf="source[field]"
  #ariaLink
  [ariaLabel]="editLabel() + ' ' + (alias || '' + source[field])"
  (clickLink)="handleSelected()"
  [class]="{ disabled: disabled }"
  [tabIndex]="tabIndexPosition"
>
  <div>
    {{ alias || '' + source[field] | uppercase }}
    <app-img
      src="assets/drrweb-lib/images/icons/edit small white.svg"
      alt="{{editLabel()}} {{ source[field] }}"
    ></app-img>
  </div>
</aria-link-button>
