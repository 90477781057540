import { createFeatureSelector, createSelector, MemoizedProjection, } from '@ngrx/store'
import { PaceSetterState } from './issue.reducer'
import {
  PACE_SETTER_SITUATION_ADDITIONAL_REQUIREMENTS_TYPES,
  PACE_SETTER_SITUATION_CODES,
  PACE_SETTER_SITUATION_TYPES,
  PaceSetterCode,
  PaceSetterDetails,
  PaceSetterGroup,
  PaceSetterList,
  PaceSetterOptions,
  PaceSetterSituation,
} from './issue.types'
import { deepCopy } from '../../shared/utils/deepCopy'
import { selectClubOverridePaceSetterCode, selectServicingClubState } from '../servicing-club/servicing-club.selectors'
import { PACE_SETTER_OPTION_TEMPLATES, } from './issue.utils'
import { selectLocationClub, selectLocationStep } from '../location/location.selectors'
import { LOCATION_STEPS } from '../location/location.types'
import { isHomeAddressComplete } from '../location/location.utils'
import { selectMemberData } from '../member/member.selectors'
import { ServicingClubState } from '../servicing-club/servicing-club.reducer'
import { ServiceConfigurationSetting, ServicingClubConfig } from '../servicing-club/servicing-club.types'

const stateKey = 'paceSetter'

export const selectPaceSetterState = createFeatureSelector<PaceSetterState>(stateKey)

export const selectActivePaceSetterSituation = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): PaceSetterSituation | null => state.activeSituation
)

export const selectActivePaceSetterSituationName = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): string | null =>
    state.activeSituation && state.activeSituation.name
)

export const selectActiveSituationTemplate = createSelector(
  selectActivePaceSetterSituationName,
  (name: string): PaceSetterOptions | null =>
    name && PACE_SETTER_OPTION_TEMPLATES()[name.toUpperCase()]
)

export const selectIssueTypeTagging = createSelector(
  selectActiveSituationTemplate,
  (paceSetterOptions: PaceSetterOptions): string | undefined =>
    paceSetterOptions?.pageLoadTagging?.issueType || paceSetterOptions?.name
)

export const selectActivePaceSetterStep = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): number => state.stepIndex
)

function findActiveDetails(state: PaceSetterState): PaceSetterDetails | null {
  if (!state.activeSituation) {
    return null
  }

  return state.details[state.activeSituation.name]
}

export const selectActivePaceSetterDetails = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): PaceSetterDetails | null => findActiveDetails(state)
)

export const selectActivePaceSetterSelection = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): PaceSetterList[] => {
    const details = findActiveDetails(state)
    if (!details || !state.stepIndex) {
      return []
    }

    return (
      (details.selectedGroups.length &&
        details.selectedGroups[state.stepIndex - 1].lists) ||
      []
    )
  }
)

export const selectPaceSetterNotes = createSelector(
  selectActivePaceSetterDetails,
  (details: PaceSetterDetails): string => (details ? details.notes || '' : '')
)

export const selectActivePaceSetterCode = createSelector(
  selectPaceSetterState,
  selectClubOverridePaceSetterCode,
  (
    state: PaceSetterState,
    getOverride: MemoizedProjection
  ): PaceSetterCode | null => {
    // FIXME check tests where state is undefined
    const override = getOverride.memoized(state?.activeCode)
    return override ? { ...state?.activeCode, ...override } : state?.activeCode
  }
)

export const selectIsActivePaceSetter = (
  code: PaceSetterCode['paceSetterCode']
) =>
  createSelector(
    selectActivePaceSetterCode,
    (activeSituation: PaceSetterCode): boolean =>
      activeSituation?.paceSetterCode === code
  )

export const selectPaceSetterDetailSelections = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): PaceSetterGroup[] => {
    if (!state.activeSituation || !state.details[state.activeSituation.name]) {
      return null
    }

    return state.details[state.activeSituation.name].selectedGroups
  }
)

export const selectIsAdditionalRequirementEnabled = createSelector(
  selectActivePaceSetterCode,
  (activePaceSetterCode: PaceSetterCode): boolean => Boolean(activePaceSetterCode?.needAdditionalRequirement)
)

export const selectNeedsTow = createSelector(
  selectActivePaceSetterCode,
  (activeCode: any): boolean => {
    if (!activeCode) {
      return false
    }

    return activeCode.requiresTowing
  }
)

function flattenPaceSetterCodes(details: PaceSetterDetails): PaceSetterCode[] {
  if (!details || !details.selectedGroups) {
    return []
  }

  const flatLists: PaceSetterList[] = details.selectedGroups.reduce(
    (output, group) => output.concat(group.lists),
    []
  )

  return flatLists.reduce((output, list) => output.concat(list.members), [])
}

export const selectActiveAdditionalRequirement = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): any => {
    const details = findActiveDetails(state)

    if (!details || !state.stepIndex) {
      return null
    }

    const activeSelection =  (
      (details.selectedGroups.length &&
        details.selectedGroups[state.stepIndex - 1].lists) ||
      []
    )

    const selected = activeSelection[0]?.additional[0]

    return selected || null
  }
)

export const selectAdditionalRequirementSelected = createSelector(
  selectPaceSetterState,
  (state: PaceSetterState): Boolean => {
    const details = findActiveDetails(state)

    if (!details || !state.stepIndex) {
      return false
    }

    const activeSelection =  (
      (details.selectedGroups.length &&
        details.selectedGroups[state.stepIndex - 1].lists) ||
      []
    )

    return Boolean(activeSelection[0]?.additional?.length)
  }
)

export const selectPaceSetterNotesAndComments = createSelector(
  selectActivePaceSetterDetails,
  (details: PaceSetterDetails) => {
    if (!details) {
      return ''
    }

    const codes = flattenPaceSetterCodes(details)
    const comments = codes
      .filter((code) => !!code.comment)
      .map((code) => code.comment)
      .join('\n')

    const result = []
    if (comments) {
      result.push(comments)
    }

    if (details.notes) {
      result.push(details.notes)
    }

    return result.join('\n')
  }
)

// TODO migrate to ngrx-forms post-pilot
// TODO validate pace-setter details
export const selectIsPaceSetterValid = createSelector(
  selectActivePaceSetterCode,
  (paceSetter: PaceSetterCode): boolean =>
    Boolean(paceSetter && paceSetter.paceSetterCode)
)

export const selectLocationStepForPaceSetter = createSelector(
  selectLocationStep,
  selectActivePaceSetterCode,
  selectMemberData,
  (locationStep, activePaceSetter, memberData): LOCATION_STEPS =>
  // FIXME find faulty unit test requiring proper isolation
    (activePaceSetter?.paceSetterCode === PACE_SETTER_SITUATION_CODES.L302  &&
      activePaceSetter?.name.toLocaleLowerCase() === PACE_SETTER_SITUATION_TYPES.BATTERY_ISSUE.toLocaleLowerCase() )
    && isHomeAddressComplete(memberData)
      ? locationStep
      : LOCATION_STEPS.MAP
)

/**
 * Since we need to check for the type of additional requirments selected
 * and check the override for the club, to avoid any circular dependency
 * we will move the logic to here
 */
export const selectServicingClubLockoutPriorityCode = createSelector(
  selectServicingClubState,
  selectLocationClub,
  selectActiveAdditionalRequirement,
  (state: ServicingClubState, club: string, active): string | null => {
    if(!club || !active) {
      return null
    }

    let activeLockoutPriorityType = null;

    switch (active.name) {
      case PACE_SETTER_SITUATION_ADDITIONAL_REQUIREMENTS_TYPES.LOVED_ONE_LOCKED_IN_CAR:
        activeLockoutPriorityType = ServicingClubConfig.LOCKOUT_LOVE_PRIORITY_CODE
        break;

      case PACE_SETTER_SITUATION_ADDITIONAL_REQUIREMENTS_TYPES.MEDICATION_LOCKED_IN_CAR:
        activeLockoutPriorityType = ServicingClubConfig.LOCKOUT_MEDS_PRIORITY_CODE
        break;
    }

    if(!activeLockoutPriorityType) {
      return null
    }

    const configs = state.club[club]?.settings?.setting?.filter(
      (settings: ServiceConfigurationSetting) =>
        settings.name === activeLockoutPriorityType
    )

    return configs && configs[0]?.value || null
  }
)

