import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'icon-aar-location-pin',
  templateUrl: './aar-location-pin.icon.html',
  styleUrls: ['aar-location-pin.icon.scss']
})
export class AarLocationPinIconComponent extends AbstractIconComponent {
  @Input() index: number
  @Input() selected = false
}
