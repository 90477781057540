import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AAAStore } from '../../../store/root-reducer';
import { I18N_SUPPORTED_APP_IDS } from '../../../shared/i18n/i18n.utils';
import { ConfigService } from '../../config/config.service';
import { AppWhiteLabelId, OEMBranding } from '../auth.types';
import { RapService } from '../../rap/rap.service';
import { Observable } from 'rxjs'
import { getCookie } from '../../../shared/utils/cookies'
import { selectIsCaptchaRequired } from '../../ui/ui.selectors'
import { selectIsOdometerConfirmation, selectIsSecure } from '../../auth/auth.selectors'


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  isSecure$: Observable<boolean> = this.store$.pipe(select(selectIsSecure));
  isWhiteLabel = false;
  appId: string;
  isCaptchaRequired$: Observable<boolean> = this.store$.pipe(
    select(selectIsCaptchaRequired)
  )
  isOdometerConfirmation$: Observable<boolean> = this.store$.pipe(
    select(selectIsOdometerConfirmation)
  )
  localeSwitcherEnabled = false
  nativeAppView = false

  constructor(
    protected store$: Store<AAAStore>,
    protected rapService: RapService,
    protected configService: ConfigService,
  ) {
    this.appId = getCookie('AAA_AppId')
    if (this.appId) {
      this.isWhiteLabel = Object.keys(AppWhiteLabelId).map(key => AppWhiteLabelId[key]).includes(this.appId.toUpperCase())
    }
    this.localeSwitcherEnabled = I18N_SUPPORTED_APP_IDS.includes(this.rapService.getRapAppId() as OEMBranding)
    this.nativeAppView = this.configService.getConfig().nativeAppView
  }
}
