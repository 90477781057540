<div class="dialog">
  <h1 mat-dialog-title id="dialog-title" i18n>
    On Our Way!
  </h1>
  <div mat-dialog-content class="tall">
    <div class="paragraph-container">
      <p *ngIf="!isRapUser" i18n>
        The AAA member is required to be with the vehicle when assistance arrives.
      </p>
      <p *ngIf="isRapUser" i18n>
        Please be with the vehicle when assistance arrives.
      </p>
      <p *ngIf="!isRapUser" i18n>
        Please be prepared to show Photo ID.
      </p>
    </div>
    <ng-container *ngIf="(hasBreakdownLocationPostalCode$ | async) && !(hasBatteryQuotes$ | async) && !(isBatteryQuoteNotified$ | async) && !(isRapUser)">
      <div class="paragraph-container">
        <p>
          <b i18n>Think you may need a new battery ?</b>
        </p>
        <p i18n>As AAA member you <b>SAVE</b> on all battery purchases!</p>
      </div>
    </ng-container>
    <div class="options-buttons">
      <div *ngIf="!isRapUser">
        <app-button
          *ngIf="(hasBreakdownLocationPostalCode$ | async) && !(hasBatteryQuotes$ | async) && !(isBatteryQuoteNotified$ | async); else noQuotes"
          class="yes-button"
          (buttonClick)="showBatteryQuotes()"
          [loading]="(isLoading$ | async)"
          i18n
        >
          GET AAA BATTERY QUOTE
        </app-button>
        <ng-template #noQuotes>
          <div class="paragraph-container" @flyInOut>
            <p class="paragraph-container" i18n>
              Unfortunately, we couldn't find any battery quotes for your vehicle.
            </p>
          </div>
        </ng-template>
      </div>
      <app-button
        class="no-button"
        inverted="true"
        aria-label="dismiss this message"
        (buttonClick)="close()"
        i18n
        i18n-aria-label
      >
        CLOSE
      </app-button>
    </div>
  </div>
  <button
    class="mat-dialog-close"
    aria-label="dismiss this message"
    (click)="close()"
    i18n-aria-label
  >
    <icon-close></icon-close>
  </button>
</div>

