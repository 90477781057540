<div class="input-label-container">
  <ng-content select="label"></ng-content>
</div>
<div
  class="description-note-container"
  #container
  [ngClass]="{ expanded: expanded }"
>
  <textarea
    class="note"
    #noteInput
    [value]="inputValue || ''"
    (change)="inputValueChange.emit($event.target.value)"
    [placeholder]="placeholder || ''"
    cols="30"
    rows="1"
    (focus)="onTextAreaFocus()"
    [id]="fieldId"
    [tabIndex]="tabIndex"
    aria-required="false"
  ></textarea>

  <ng-container *ngIf="showToggle">
    <div class="icon-container" (click)="toggle()">
      <button type="button" class="toggleButton" aria-label="expand-toggle" [tabIndex]="tabIndex"></button>
    </div>
  </ng-container>
</div>
