import { Component, Input, EventEmitter, Output } from '@angular/core'
import { AARData } from '../aar/aar.types'
import { AAAStore } from '../../../store/root-reducer'
import { Store } from '@ngrx/store'
import { openPromptDialog } from '../../ui/ui.actions';
import { PromptDialogTypes } from '../../ui/ui.types';

@Component({
  selector: 'app-location-summary',
  templateUrl: './tow-location-summary.component.html',
  styleUrls: ['./tow-location-summary.component.scss'],
})
export class TowLocationSummaryComponent {
  @Input() location: AARData
  @Input() index: number
  @Output() summaryClick: EventEmitter<AARData> = new EventEmitter<AARData>()
  @Input() tabIndex = 0

  constructor(private store$: Store<AAAStore>) {}

  handleSummaryClick($event) {
    this.summaryClick.emit(this.location)
  }

  showDetails() {
    this.store$.dispatch(
      openPromptDialog({
        payload: {
          type: PromptDialogTypes.AAR_DETAILS,
          params: { id: this.location.id }
        }
      })
    )
  }
}
