import { IndexedCollection } from '../../shared/types'

export function generateCallId(callId: string, callDate: string) {
  return `${callId}-${callDate}`
}

export function indexCalls<T>(data: Array<T>): IndexedCollection<T> {
  const filteredData = data.filter((activeCall: any) => activeCall?.callId && activeCall?.callDate)
  if (!filteredData || filteredData.length === 0) {
    return {}
  }
  return filteredData.reduce(
    (indexedObj: IndexedCollection<T>, item: T) => ({
      ...indexedObj,
      [generateCallId(String(item['callId']), String(item['callDate']))]: item,
    }),
    {}
  )
}
