<ion-row>
  <ion-col>
    <div #searchContainer>
      <ion-searchbar
        id="location-searchbar"
        type="text"
        color="light"
        [ngClass]="{ 'use-shadow': useShadow }"
        [placeholder]="placeholder"
        [mode]="ionicMode"
        (ionInput)="searchbarInput($event)"
        (ionClear)="clearSearch()"
        [value]="selectedAddress"
        appCtaClickEvent="Enter Address"
        #search
      >
      </ion-searchbar>
    </div>
    <ion-list
      [ngClass]="{ 'has-back-button': hasBackButton }"
      [style.maxHeight.px]="listMaxHeight"
      class="overflow-scroll"
      [mode]="ionicMode"
      [inset]="true"
      *ngIf="showOptions">
      <ion-item [mode]="ionicMode" *ngIf="suggestedDestination">
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col size="12">
              <aaa-text size="footnote" color="medium" class="margin-around">Facility near you:</aaa-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <app-shop-card
                class="no-vertical-padding suggested-shop"
                [destination]="suggestedDestination"
                (onCardClick)="suggestedDestinationClicked()"
                [borderShadow]="false"
                [rap]="rap"
                size="reduced"
              ></app-shop-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <ion-item
        [mode]="ionicMode"
        *ngFor="let item of filteredItems;"
        (click)="locationSelect(item)"
        button
        detail>
        <app-location-address
          [address]="item"
          [matcherHighlight]="searchTerms"
        />
      </ion-item>
      <ion-item [mode]="ionicMode" *ngIf="searchFailed">
        <ion-label>
          <ion-text color="danger" i18n>Sorry, suggestions could not be loaded.</ion-text>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-col>
</ion-row>
