import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input() classNames: string[] = []
  @Input() tabIndex = 0
  @Input() animated: false
}
