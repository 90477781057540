<div class="vehicle-confirm">
  <div class="title">
    Please select your vehicle:
  </div>
  <div class="select-vehicle">
    <app-vehicle-tile *ngFor="let vehicle of (vehiclesByPlate$ | async);"
                      [vehicle]="vehicle"
                      (vehicleSelected)="handleSelectVehicle($event)">
    </app-vehicle-tile>
  </div>
  <app-clickable-link (clickLink)="handleVehicleNotListed()" class="bottom-link">
    My vehicle is not listed above
  </app-clickable-link>
</div>

