import { Injectable } from '@angular/core'
import axios from 'axios'
import { Store, select, Action, ActionCreator, Selector } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { Observable, from, combineLatest } from 'rxjs'
import { ErrorReportingMeta } from '../types'
import { mergeMap } from 'rxjs/operators'
import { dispatchErrorAction } from '../utils'
import { ConfigService } from '../../modules/config/config.service'
import {
  selectAuthClub,
  selectIsSecure,
} from '../../modules/auth/auth.selectors'

interface MapStateValues {
  [key: string]: Selector<AAAStore, any>
}

@Injectable({
  providedIn: 'root',
})
export class ErrorReportingService {
  isSecure$: Observable<boolean> = this.store$.select(selectIsSecure)
  isSecure: boolean
  club$: Observable<string | number> = this.store$.select(selectAuthClub)
  club: string | number

  constructor(
    private store$: Store<AAAStore>,
    private configService: ConfigService
  ) {
    this.isSecure$.subscribe((isSecure) => (this.isSecure = isSecure))
    this.club$.subscribe((club) => (this.club = club))
  }

  notifyErrorObservable(
    error: Error,
    actions: Array<Action> | ActionCreator,
    stateValues: MapStateValues = {}
  ): Observable<Action> {
    console.error('Notifying error: ', error)

    const metaKeys = Object.keys(stateValues)
    const metaSelectors = Object.values(stateValues)

    if (metaKeys.length === 0) {
      return from(this.notifyError(error)).pipe(
        mergeMap(() =>
          Array.isArray(actions)
            ? (actions as Array<Action>)
            : [dispatchErrorAction(actions as ActionCreator, error)]
        )
      )
    } else {
      return combineLatest(
        metaSelectors.map((selector) => this.store$.select(selector))
      ).pipe(
        mergeMap((metaValues) => {
          const meta = metaKeys.reduce(
            (metaMap, metaKey, i) => ({
              ...metaMap,
              [metaKey]: metaValues[i],
            }),
            {}
          )
          return from(this.notifyError(error, meta)).pipe(
            mergeMap(() =>
              Array.isArray(actions)
                ? (actions as Array<Action>)
                : [dispatchErrorAction(actions as ActionCreator, error)]
            )
          )
        })
      )
    }
  }

  async notifyError(error: Error, meta: ErrorReportingMeta = {}) {
    const {
      errorReportingID,
      errorReportingURL,
      errorReportingToken,
      errorReportingSource,
    } = this.configService.getConfig()
    try {
      const response = await axios.request({
        baseURL: errorReportingURL,
        responseType: 'json',
        url: `/api/v1/events`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Api-token ${errorReportingToken}`,
        },
        data: {
          eventType: 'CUSTOM_INFO',
          source: errorReportingSource,
          description: error?.message || (error as any)?.response?.data?.message || error,
          attachRules: {
            entityIds: [errorReportingID],
            tagRule: [],
          },
          customRules: {
            secure: this.isSecure,
            club: this.club,
            ...(meta.callId && { callId: String(meta.callId) }),
          },
        },
      })
      return response
    } catch (_error) {
      console.error('Unable to report error: ', _error)
    }
  }
}
