import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() width = '140'
  @Input() height = '140'
  @Input() inverted: Boolean = false
  constructor() {}
}
