<ion-row>
  <ion-col *ngFor="let fill of starArray" class="star-container">
    <svg viewBox="0 0 24 24" class="star">
      <defs>
        <clipPath [attr.id]="'clip' + fill">
          <rect [attr.width]="fill + '%'" height="24" />
        </clipPath>
      </defs>
      <path
        d="M12 .587l3.668 7.568L24 9.423l-6 5.847L19.334 24 12 20.027 4.666 24 6 15.27 0 9.423l8.332-1.268z"
        fill="none"
        [attr.stroke]="color"/>
      <path
        d="M12 .587l3.668 7.568L24 9.423l-6 5.847L19.334 24 12 20.027 4.666 24 6 15.27 0 9.423l8.332-1.268z"
        [attr.fill]="color"
        [attr.clip-path]="'url(#clip' + fill + ')'"/>
    </svg>
  </ion-col>
</ion-row>
