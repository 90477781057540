<div class="dialog">

  <ng-container *ngIf="data?.title">
    <h1 mat-dialog-title>
      {{ data?.title }}
    </h1>
  </ng-container>

  <ng-container *ngIf="data?.params?.url as url">
    <div mat-dialog-content class="tall">
      <iframe id="iframeDialog"
        [attr.title]="data?.title || data?.params?.ariaLabel"
        [attr.aria-label]="data?.params?.ariaLabel"
        width="100%"
        height="100%"
        [attr.src]="url | safe"
        marginheight="0"
        marginwidth="0"
        frameborder="0">
      </iframe>
      <ng-container *ngIf="showAbTestSwitcher()">
        <app-ab-test-switcher></app-ab-test-switcher>
      </ng-container>
    </div>
  </ng-container>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message" i18n-aria-label>
    <icon-close></icon-close>
  </button>
</div>
