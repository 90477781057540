import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
} from '@angular/core'

enum ButtonColors {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  CANCEL = 'cancel',
  SECONDARY = 'secondary',
  SECONDARY_NO_UPPERCASE = 'secondary-no-uppercase',
  PRIMARY_LIGHTER = 'primary-lighter',
  PRIMARY_YELLOW = 'primary-yellow',
  PRIMARY_NO_UPPERCASE = 'primary-no-uppercase'
}

enum ButtonTypes {
  BUTTON = 'button',
  SUBMIT = 'submit',
}

enum ButtonSizes {
  CONDENSED = 'condensed',
  COZY = 'cozy',
  MEDIUM = 'medium',
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() active: Boolean = false
  @Input() inverted: Boolean = false
  @Input() disabled: Boolean = false
  @Input() noBorder: Boolean = false
  // if we want to use some custom class names
  @Input() classNames: string[] = []
  @Input() loading: Boolean = false
  @Input() type: ButtonTypes = ButtonTypes.BUTTON
  @Input() preventClickOnLoading: Boolean = true
  @Input() tabIndex: Number = 0
  @Input() color: ButtonColors = ButtonColors.PRIMARY
  @Input() size: ButtonSizes = ButtonSizes.COZY
  @Input () hasShadow: Boolean = false

  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>()

  // Trigger button click action
  onBtnClick(event) {
    // fallback validation in case some crafty user
    // want to remove the "disabled" property
    if (this.disabled || (this.preventClickOnLoading && this.loading)) {
      event.preventDefault()
      event.stopPropagation()
      return false
    }

    this.buttonClick.next()
  }
}
