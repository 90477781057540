import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogData, MessageDialogTypes, PromptDialogTypes } from '../../ui.types'
import { ConfigService } from '../../../config/config.service'
import { capitalize } from '../../../../shared/utils'
import { cancelRequest } from '../../../../shared/actions/shared.actions'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../../store/root-reducer'
import events from '../../../tagging/events'
import { AdobeEventTypes } from '../../../tagging/tagging.types'
import { Observable } from 'rxjs'
import { selectHasAvailableCall } from '../../../dashboard/calls-statuses/call-status.selectors'
import { AbstractResponsiveComponent } from '../../../../shared/abstract-responsive.component'
import { RapService } from '../../../rap/rap.service'
import { AdobeEventService } from '../../../tagging/adobe/event-adobe.service'
import { TaggingService } from '../../../tagging/tagging.service'
import { openMessageDialog, openPromptDialog } from '../../ui.actions'
import { Router } from '@angular/router'
import { configureKeyboard } from '../../../../shared/utils/capacitorAccessoryBar'

@Component({
  selector: 'app-close-request-dialog-component',
  templateUrl: './close-request-dialog.component.html',
  styleUrls: ['../shared-dialog.component.scss'],
})
export class CloseRequestDialogComponent extends AbstractResponsiveComponent implements OnInit {
  hasAvailableCall = false
  hasAvailableCall$: Observable<boolean> = this.store$.pipe(select(selectHasAvailableCall))
  isNativeAppView = this.configService.getConfig().nativeAppView
  action = this.isNativeAppView ? (() => $localize`close`)() : (() => $localize`restart`)()

  get dialogTitle() {
    return capitalize((() => $localize`${this.action} Roadside Request?`)())
  }
  get dialogText() {
    return (() => this.isNativeAppView ?
      $localize`Don't worry, any information you've entered will be saved.` :
      $localize`If you restart any information you've entered will be lost.`
    )()
  }

  constructor(
    private configService: ConfigService,
    private store$: Store<AAAStore>,
    private tagging: TaggingService,
    private rapService: RapService,
    private adobeEventService: AdobeEventService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    super()
  }
  ngOnInit(): void {
    this.subscriptions.push(
      this.hasAvailableCall$.subscribe((hasCall) => {
        this.hasAvailableCall = hasCall
      })
    )
  }

  onSubmit() {
    this.sendAnalyticsEvent()
    this.openSurveyModal()
    this.onClose()
    configureKeyboard(false)
  }

  sendAnalyticsEvent() {
    this.tagging.setClickEvent(
      this.hasAvailableCall ?
        events.dashboard.CALL_CANCEL_REQUEST :
        events.dashboard.PARTIAL_CALL_CANCELED,
      events.dashboard.CALL_STATUS_PAGE_TYPE
    )
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: this.hasAvailableCall ?
        events.dashboard.CANCEL_CALL_REQUEST :
        events.dashboard.PARTIAL_CALL_CANCELED
    })
  }

  openSurveyModal() {
    if (this.rapService.isRapUser()) {
      this.store$.dispatch(openMessageDialog({
        payload: {
          type: MessageDialogTypes.PARTIAL_CALL_CANCELED
        }
      }))
      this.store$.dispatch(cancelRequest())
    } else {
      if (this.isNativeAppView) {
        this.router.navigate([this.configService.getConfig().homeBackButtonRedirectTo], { replaceUrl: true })
      } else {
        this.store$.dispatch(cancelRequest())
        this.store$.dispatch(openPromptDialog({
          payload: {
            type: PromptDialogTypes.CANCEL_FEEDBACK_PROMPT,
            params: { hasAvailableCall: this.hasAvailableCall }
          }
        }))
      }
    }
  }

  onClose() {
    this.data.close()
  }
}
