import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-comment',
  templateUrl: './comment.icon.html',
})
export class CommentIconComponent extends AbstractIconComponent {
  static icon = 'comment'
}
