<ion-item button (click)="vehicleClick()" lines="none" >
  <app-vehicle-make-logo slot="start" [make]="buildMake(vehicle.make)"></app-vehicle-make-logo>
  <ion-label class="ion-no-margin">
    <aaa-text size="headline" display="block" weight="bold" color="dark">{{ vehicle.model }}</aaa-text>
    <aaa-text size="caption" display="block" color="medium">
      {{ vehicle.year }}
      {{ vehicle.color ? ' | ' + ( vehicle.color | titlecase ) : '' }}
      {{ vehicle.wheelDrive ? ' | ' + vehicle.wheelDrive : '' }}
    </aaa-text>
  </ion-label>
</ion-item>

<div class="edit-container" *ngIf="!isEditing && (allowEditVehicle$ | async)">
  <aaa-button variant="ghost" size="small" (click)="toggleVehicleEditor()">
    <aaa-text i18n>Edit</aaa-text>
    <aaa-icon name="pencil-outline" size="small"></aaa-icon>
  </aaa-button>
  <aaa-button variant="ghost" size="small" (click)="deleteVehicle()" class="delete-vehicle-button">
    <aaa-text i18n color="danger">Delete</aaa-text>
    <aaa-icon color="danger" name="trash" size="small"></aaa-icon>
  </aaa-button>
</div>

<app-edit-vehicle-options
  *ngIf="isEditing"
  [vehicle]="vehicle"
  [isActive]="isActive"
  [tabIndexPosition]="tabIndexPosition"
  (clickCancel)="toggleVehicleEditor()"
></app-edit-vehicle-options>
