<ng-container #shopCard *ngIf="destination">
  <app-destination-card-template
    [title]="destination.name"
    [distance]="destination.distanceTo"
    [status]="status"
    [borderShadow]="borderShadow"
    [closeable]="close.observers.length > 0"
    (close)="onClose()"
  >
    <div content>
      @if (!rap) {
        <ion-row class="destination-type">
          <app-destination-type [destination]="destination"></app-destination-type>
        </ion-row>
      }
      <ion-row class="destination-address ion-align-items-center" [ngClass]="{'ion-margin-bottom': !isReducedSize()}">
        <ion-col>
          <aaa-text class="line1">{{addressLine1}}</aaa-text>
          <aaa-text class="line2">{{addressLine2}}</aaa-text>
        </ion-col>
        @if (isReducedSize()) {
          <ion-col size="auto">
            <aaa-button variant="disclosure" size="small" class="select-suggested-btn" expand="block" (click)="handleCardClick()" i18n>
              Select Facility
            </aaa-button>
          </ion-col>
        }
      </ion-row>
      <ng-container *ngIf="!isReducedSize()">
        <ng-container *ngTemplateOutlet="evStationDetails ? evStation : phoneStatus"></ng-container>
      </ng-container>
    </div>
    <div footer>
      <ng-content select="[footer]"></ng-content>
    </div>
  </app-destination-card-template>
</ng-container>

<ng-template #evStation>
  <ion-row *ngIf="evStationDetails?.connectorTypes as connectorTypes" class="ion-justify-content-end ion-align-items-end">
    <ion-col>
      <ng-container>
        <aaa-text class="subtitle" i18n>Connectors</aaa-text>
        <app-ev-station-connector-type-list [connectorTypes]="connectorTypes">
        </app-ev-station-connector-type-list>
      </ng-container>
    </ion-col>
    @if (showSelectButton) {
      <ion-col size="auto">
        <aaa-button variant="disclosure" size="small" class="select-facility-btn" expand="block" (click)="handleCardClick()" i18n>
          Select Facility
        </aaa-button>
      </ion-col>
    }
  </ion-row>
</ng-template>

<ng-template #phoneStatus>
  <ion-row class="ion-align-items-center" [ngClass]="{'ion-margin-bottom': close.observers.length > 0}">
    @if (!showSelectButton) {
      <ion-col>
        <app-phone-number-link *ngIf="phone?.value as phone" [phoneNumber]="phone"></app-phone-number-link>
      </ion-col>
    }
    <ion-col class="destination-open-status" [ngClass]="{'justifyEnd': !showSelectButton}" *ngIf="operatingDays?.length">
      <aaa-text class="destination-status" size="caption" display="inline" color="valid" weight="semibold">
        <app-facility-status class="facility-status" [operatingDays]="operatingDays"></app-facility-status>
      </aaa-text>
      <aaa-text size="caption2" display="inline" color="faint" weight="semibold">
        <app-facility-next-date class="facility-next-date" [operatingDays]="operatingDays"></app-facility-next-date>
      </aaa-text>
    </ion-col>
    @if (showSelectButton) {
      <ion-col size="auto">
        <aaa-button variant="disclosure" size="small" class="select-facility-btn" expand="block" (click)="handleCardClick()" i18n>
          Select Facility
        </aaa-button>
      </ion-col>
    }
  </ion-row>
</ng-template>
