<svg
  xmlns="http://www.w3.org/2000/svg"
  width="18"
  height="18"
  viewBox="0 0 18 18"
  id="icon-electricity"
>
  <path
    [attr.fill]="color"
    fill-rule="evenodd"
    d="M15 6v9.5c0 .55-.45 1-1 1s-1-.45-1-1V11c0-1.1-.9-2-2-2h-1V2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2v16h10v-7.5h1.5v5a2.5 2.5 0 0 0 5 0V6H15zm2-2h.001a1 1 0 1 1 0 2H14.5A1 1 0 1 1 14 4.134V2h1v2h1V2h1v2zM8 7H2V2h6v5zM4.333 17v-3.808H3L5.667 8v3.808H7L4.333 17z"
  />
</svg>
