@if (isRedesign) {
  <ion-buttons>
    <ion-button (click)="onClose()">
      <aaa-icon slot="icon-only" name="close-outline" color="dark"></aaa-icon>
    </ion-button>
  </ion-buttons>
} @else {
  <div>
    <button class="close-button" (click)="onClose()">
      <aaa-icon name="close-outline" color="primary"></aaa-icon>
    </button>
  </div>
}
