import { Component, Input } from '@angular/core'
import { ConnectorTypes } from '../../aar/aar.types';

@Component({
  selector: 'app-ev-station-connector-type-list',
  templateUrl: './connector-type-list.component.html',
  styleUrls: ['./connector-type-list.component.scss'],
})
export class EvStationConnectorTypeListComponent {

  @Input() connectorTypes: ConnectorTypes[]
}
