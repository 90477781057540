import { Component } from '@angular/core'
import { resetState } from '../../../shared/actions/shared.actions';
import { Store } from '@ngrx/store';
import { AAAStore } from '../../../store/root-reducer';

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent {

  constructor(private store$: Store<AAAStore>) {
    this.store$.dispatch(resetState())
  }
}
