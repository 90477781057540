import { Pipe, PipeTransform } from '@angular/core'
import { FormStyle, getLocaleDayNames, TranslationWidth } from '@angular/common'
import { getCurrentLocale } from '../i18n/i18n.utils'
import { capitalize } from '../utils'

@Pipe({ name: 'weekdayI18n' })
export class WeekdayI18nPipe implements PipeTransform {
  transform(weekday: string) {
    const enWeekdays = getLocaleDayNames('en-US', FormStyle.Format, TranslationWidth.Wide)
    const index = enWeekdays.findIndex((day, _index) => day.toLowerCase() === weekday.toLowerCase())
    return capitalize(getLocaleDayNames(getCurrentLocale().locale, FormStyle.Format, TranslationWidth.Wide)[index])
  }
}
