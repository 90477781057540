<!-- Dialog Content -->
<div class="dialog">
  <h1 mat-dialog-title i18n>
    Help us find you!
  </h1>
  <div mat-dialog-content class="modal-form">
    <p i18n>Provide us with additional info to help the driver reach you.</p>

    <form
      name="form"
      [ngrxFormState]="form"
    >
      <label for="breakdownComment" i18n>Nearby building or street: </label>
      <textarea
        class="note"
        name="breakdownComment"
        id="breakdownComment"
        [ngrxFormControlState]="form.controls.breakdownComment"
        aria-required="true"
        required
      ></textarea>
      <app-form-error
        [control]="form.controls.breakdownComment"
        name='breakdownComment'
        type='required'
        i18n
      >
        Please, add a comment to help us find you.
      </app-form-error>
   </form>
  </div>

  <div mat-dialog-actions class="buttons-row">
    <app-button type="submit"
      (buttonClick)="handleCommentForm(form)"
      i18n
    >
      Confirm
    </app-button>
  </div>
  <button
    class="mat-dialog-close"
    aria-label="dismiss this message"
    i18n-aria-label
    (click)="onClose()"
  >
    <icon-close></icon-close>
  </button>
</div>

