import * as fromRouter from '@ngrx/router-store'
import { createFeatureSelector } from '@ngrx/store'
import { Section, Step } from '../modules/ui/ui.types'
import { AAAStore } from './root-reducer'

export const selectRouter = createFeatureSelector<
  AAAStore,
  fromRouter.RouterReducerState<any>
>('router')

export const {
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = fromRouter.getRouterSelectors(selectRouter)

export const selectRouterStep = selectQueryParam('step') as (
  state: AAAStore
) => Step['url']
export const selectRouterSection = selectQueryParam('section') as (
  state: AAAStore
) => Section
export const selectRouterIndex = selectQueryParam('index')
