import { array, object, string } from 'yup'
import { Advisories, Advisory } from './advisories.types'

export const AdvisorySchema = object<Advisory>().shape({
  msg: string().required(),
  terminationTime: string(),
})

export const AdvisoriesSchema = object<Advisories>().shape({
  advisories: array().of(AdvisorySchema),
})
