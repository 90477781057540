import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { selectDistanceUnit } from '../../modules/ui/ui.selectors'
import { DISTANCE_UNIT, I18nDistanceUnit, I18nDistanceUnits } from '../i18n/i18n.types'
import { AbstractComponent } from '../abstract.component'
import { convertMilesToKm } from '../utils/distanceConverter'
import { getCurrentLocale } from '../i18n/i18n.utils'


@Component({
  selector: 'app-distance-display',
  template: `{{ distanceToDisplay | number:'1.0-1' }} {{ unitLabel }}`
})
export class DistanceDisplayComponent extends AbstractComponent implements OnInit {

  @Input() get distance(): number {
    return this.distanceValue
  }
  set distance(newValue) {
    this.distanceValue = newValue
    this.updateDistanceToDisplay()
  }

  @Input() short = false

  distanceValue: number
  unitLabel: string
  unitOptions: I18nDistanceUnit
  distanceToDisplay: number

  constructor(private store$: Store<AAAStore>) {
    super()
  }

  distanceUnit$ = this.store$.select(selectDistanceUnit)

  ngOnInit(): void {
    this.subscriptions.push(this.distanceUnit$.subscribe((distanceUnit: DISTANCE_UNIT) => {
      this.unitOptions = I18nDistanceUnits().find((unit) =>
        unit.unit === (distanceUnit ? distanceUnit : getCurrentLocale().defaultDistanceUnit)
      )
      this.unitLabel = this.short ? this.unitOptions.abbreviation : this.unitOptions.label
      this.updateDistanceToDisplay()
    }))
  }

  updateDistanceToDisplay() {
    this.distanceToDisplay = this.unitOptions?.unit === DISTANCE_UNIT.KILOMETERS ? convertMilesToKm(this.distance) : this.distance
  }
}
