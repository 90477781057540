<ng-container *ngIf="problems">
  <ion-grid>
    <ion-row *ngIf="heading">
      <ion-col>
        <aaa-text weight="bold" class="heading title4">{{ heading }}</aaa-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item *ngFor="let problem of problems; let i = index" detail="false" lines="full">
            <aaa-checkbox
              [checked]="!!selection[problem.name]"
              (aaaChange)="problemChange(problem, $event.detail.checked)"
            >
              <aaa-text color="dark" class="text-wrap">
                {{ problem.label || problem.name }}
                <div class="problem-checklist_obs" *ngIf="problem.obs"> {{ problem.obs }} </div>
              </aaa-text>
            </aaa-checkbox>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-container>
