import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-arrow-right',
  templateUrl: './arrow-right.icon.html',
})
export class ArrowRightIconComponent extends AbstractIconComponent {
  static icon = 'arrow-right'
  color = '#15284C'
}
