import { CANCEL_EDITING_REQUEST } from '../../shared/actions/shared.actions'
import { PayloadedAction } from '../../shared/types'
import { StepTypes } from '../ui/ui.types'
import {
  RESET_EDITION_STEP_LOCATION,
  SET_EDITION_STEP_LOCATION,
} from './wizard.actions'

export interface WizardState {
  editionStepLocation: string | null
  step: StepTypes | null
}

export const initialState: WizardState = {
  editionStepLocation: null,
  step: null,
}

export function reducer(
  state: WizardState | undefined = initialState,
  action: PayloadedAction
): WizardState {
  switch (action.type) {
    case SET_EDITION_STEP_LOCATION: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CANCEL_EDITING_REQUEST:
    case RESET_EDITION_STEP_LOCATION: {
      return {
        ...state,
        editionStepLocation: null,
        step: null,
      }
    }
    default:
      return state
  }
}
