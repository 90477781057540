<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2185_7085)">
    <rect width="20" height="20" rx="10" fill="#525252"/>
    <path d="M14.1666 6.67263L13.3274 5.83334L9.99998 9.16072L6.6726 5.83334L5.83331 6.67263L9.16069 10L5.83331 13.3274L6.6726 14.1667L9.99998 10.8393L13.3274 14.1667L14.1666 13.3274L10.8393 10L14.1666 6.67263Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_2185_7085">
      <rect width="20" height="20" rx="10" fill="white"/>
    </clipPath>
  </defs>
</svg>
