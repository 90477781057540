import { Injectable } from '@angular/core'
import { selectActiveCall } from '../dashboard/calls-statuses/call-status.selectors'
import { Store } from '@ngrx/store'
import { share, takeUntil } from 'rxjs/operators'
import { Observable, Subject } from 'rxjs'
import { ActiveCall } from '../dashboard/calls.types'
import { AAAStore } from '../../store/root-reducer'
import { CallStatusService } from '../dashboard/calls-statuses/call-status.service'

@Injectable({
  providedIn: 'root',
})
export class ServiceTrackingService {
  private readonly activeCallTracker$: Observable<ActiveCall | null>
  private subscriptionCount = 0
  private destroy$ = new Subject<void>()

  constructor(
    private callStatusService: CallStatusService,
    private store$: Store<AAAStore>
  ) {
    this.activeCallTracker$ = new Observable<ActiveCall>((subscriber) => {
      if (this.subscriptionCount === 0) {
        this.callStatusService.startCallStatusesUpdater({ retry: true })
      }
      this.subscriptionCount++

      const activeCallStatusSubscription = this.store$
        .select(selectActiveCall)
        .pipe(takeUntil(this.destroy$))
        .subscribe((callStatus) => {
          subscriber.next(callStatus)
        })

      return () => {
        activeCallStatusSubscription.unsubscribe()
        this.subscriptionCount--

        if (this.subscriptionCount === 0) {
          subscriber.next(undefined)
          this.callStatusService.stopCallStatusesUpdater()
        }
      }
    }).pipe(share())
  }

  getActiveCall(): Observable<ActiveCall> {
    return this.activeCallTracker$
  }

  ngOnDestroy() {
    this.destroy$.next(null)
    this.destroy$.complete()
  }
}
