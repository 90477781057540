import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'

import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap, } from 'rxjs/operators'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import { GooglePlacesService } from './google-places/google-places.service'
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap'
import { isMobileApp } from '../../../shared/utils/app-detect'
import { isMobileBrowser } from '../../../shared/utils/browser-detect'
import { SearchViews, USE_CURRENT_LOCATION } from '../../location/location.types'
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component'

import { isEmpty, remove } from 'lodash'
import { Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'

// adaptation of https://ng-bootstrap.github.io/#/components/typeahead/examples

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  providers: [GooglePlacesService],
  styleUrls: ['./auto-complete.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AutocompleteComponent extends AbstractResponsiveComponent implements OnInit, AfterViewInit {
  @Output() addressSelected = new EventEmitter()
  @Output() isValidChange = new EventEmitter()
  @Output() cancelSearch = new Subject()
  @Input() model = ''
  @Input() placeHolder = ''
  @Input() readonly = false
  @Input() name = ''
  @Input() inverted = false
  @Input() isLoading = false
  @Input() location
  @Input() tabIndex = 0

  @Input() customElement = false
  @Input() hasUseCurrentLocationOption = false
  @Input() editing = false
  @Input() lastSearchLocation = {}
  @Input() enableAutofocus = false
  @Input() view: SearchViews = SearchViews.BREAKDOWN_LOCATION
  @Input() topTemplate: TemplateRef<any>;

  clearName = ''
  isValid: boolean
  searchFailed = false

  private openCloseSubject = new BehaviorSubject<{ isOpen: boolean }>({
    isOpen: false,
  })
  onOpenClose$ = this.openCloseSubject.pipe(
    distinctUntilChanged((prev, cur) => {
      if (cur.isOpen && (isMobileApp() || isMobileBrowser())) {
        // fixes Safari mobile issue on the component position when the input shows suggestions
        // https://caniuse.com/?search=scrollIntoView
        setTimeout(() => {
          this.typeaheadInput.nativeElement.scrollIntoView({
            behavior: 'smooth',
          })
        }, 150)
      }
      return prev.isOpen === cur.isOpen
    })
  )

  @ViewChild('input')
  typeaheadInput: ElementRef
  private inputClassObserver: MutationObserver

  constructor(
    private _service: GooglePlacesService,
    private store$: Store<AAAStore>
  ) {
    super()
  }

  ngOnInit() {
    this.clearName = `${this.name}Clear`
  }

  ngAfterViewInit() {
    this.inputClassObserver = new MutationObserver(
      (mutations: MutationRecord[]) => {
        mutations
          .filter((mutation) => mutation.attributeName === 'class')
          .forEach((mutation) =>
            this.openCloseSubject.next({
              isOpen: (mutation.target as HTMLElement).classList.contains(
                'scroll-up'
              ),
            })
          )
      }
    )
    this.inputClassObserver.observe(this.typeaheadInput.nativeElement, {
      attributes: true,
    })
    // IBM Equal Access Accessibility Checker - complains about this attribute with the following violation:
    // "The ARIA attribute 'aria-multiline' is not valid for the element <input> with ARIA role 'combobox'"
    // https://www.w3.org/TR/html-aria/#docconformance
    this.typeaheadInput.nativeElement.removeAttribute('aria-multiline')

    if(this.enableAutofocus) {
      this.typeaheadInput.nativeElement.focus()
      this.typeaheadInput.nativeElement.setAttribute('autofocus', '')
    }
  }

  selectedItem(event: NgbTypeaheadSelectItemEvent) {
    this.addressSelected.emit(event.item)

    // Don't want e.g. mobile keyboard showing
    this.typeaheadInput.nativeElement.blur()
    this.typeaheadInput.nativeElement.classList.remove('scroll-up')
  }

  setIsValid(isValid: boolean, type = null) {
    this.isValid = isValid
    this.isValidChange.emit(type ? type : isValid)
  }

  onClearInput(input: HTMLInputElement) {
    input.value = ''
    input.focus()
    this.model = ''
    this.setIsValid(false, 'reset')
    this.addressSelected.emit({})
  }

  formatter = (value: any) => value.description

  handleInput(event) {
    this.setIsValid(true)
    if (event.target.value?.length < 3) {
      this.setIsValid(false)
    }
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) =>
        this._service.search(term, this.location).pipe(
          tap(() => (this.searchFailed = false)),
          map((res: any[]) => {
            // If last search exists, the last search element will be pushed into results
            if (!isEmpty(this.lastSearchLocation) && this.customElement) {
              res.push({
                ...this.lastSearchLocation,
                last_searched: true,
              })
            }

            if (this.customElement && res.length) {
              return this.addCustomElement(res)
            }
            return res
          }),
          catchError(() => {
            this.searchFailed = true
            return of([])
          })
        )
      )
    )

  focus() {
    if (this.typeaheadInput) {
      this.typeaheadInput.nativeElement.focus()
    }
  }

  addCustomElement(data = []) {
    const defaultCustom = this.hasUseCurrentLocationOption ? {
      main_text: $localize`Use current position`.toUpperCase(),
      secondary_text: $localize`Sharing your location helps us finding you`,
      custom_result: true,
      custom_value: USE_CURRENT_LOCATION,
    } : {}

    const lastSearchedIndex = data.findIndex((e) => e.last_searched)

    const lastSearched = data[lastSearchedIndex] || {}
    // check if a last search exists and remove it from main list
    if (lastSearchedIndex !== -1) {
      remove(data, (e, i) => i === lastSearchedIndex)
    }

    const topTemplate = this.topTemplate ? {
      top_template: Boolean(this.topTemplate)
    } : {}

    // create new results array and clean empty elements
    return [topTemplate, defaultCustom, lastSearched, ...data].filter(
      (e) => !isEmpty(e)
    )
  }

  onCancelSearch() {
    this.cancelSearch.next()
  }
}
