import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-phone',
  templateUrl: './phone.icon.html',
})
export class PhoneIconComponent extends AbstractIconComponent {
  static icon = 'phone'
}
