import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VehicleData } from '../../../modules/member/member.types'
import { Vehicle } from '../../../modules/vehicle/vehicle.types'

@Component({
  selector: 'app-vehicle-tile',
  templateUrl: './vehicle-tile.component.html',
  styleUrls: ['./vehicle-tile.component.scss']
})
export class VehicleTileComponent {
  @Input() vehicle: Vehicle & VehicleData
  @Output() vehicleSelected: EventEmitter<VehicleData> = new EventEmitter<VehicleData>()

  constructor(
  ) {
  }

  selectVehicle() {
    this.vehicleSelected.emit(this.vehicle)
  }
}
