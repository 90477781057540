import { Component, Input, ViewChild } from '@angular/core';
import { TaggingService } from '../../../modules/tagging/tagging.service';
import auth from '../../../modules/tagging/events/auth';
import events from '../../../modules/tagging/events';
import { InputComponent } from '@aaa-mobile/shared-components';

@Component({
  selector: 'app-input-postal-code',
  templateUrl: './input-postal-code.component.html',
  styleUrls: ['./input-postal-code.component.scss']
})
export class InputPostalCodeComponent {

  @ViewChild('inputElement') input: InputComponent
  @Input() focusInputHelper: InputComponent
  @Input() controls
  @Input() id: string
  type = 'tel'
  mask = 'us_zip_code'
  displayAction = false
  focusTimeout: ReturnType<typeof setTimeout>;

  constructor(private taggingService: TaggingService) {
  }

  handleFocus = () => {
    setTimeout(async () => {
      if (this.focusInputHelper) {
        await this.focusInputHelper.ionInputElem.setFocus()
      }
      await this.input.ionInputElem.setFocus()
    })
  }

  switchKeyboard() {
    this.type = this.type === 'tel' ? 'text' : 'tel'
    this.mask = this.type === 'text'
      ? '[/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/]'
      : 'us_zip_code'
    this.taggingService.setClickEvent(`${auth.CHANGE_TYPE_ZIP_CODE} ${this.type}`, events.auth.PAGE_TYPE)

    this.handleFocus()
  }

  showAction() {
    clearTimeout(this.focusTimeout)
    this.displayAction = true
  }

  hideAction() {
    this.focusTimeout = setTimeout(() => {
      this.displayAction = false
    }, 500)
  }
}
