import { Component, Input, OnInit } from '@angular/core'
import { OperatingDay } from '../types'
import { FacilityUtils } from '../../modules/location/aar/aar.utils';

@Component({
  selector: 'app-facility-status',
  templateUrl: './facility-status.component.html',
  styleUrls: ['./facility-status.component.scss'],
})
export class FacilityStatusComponent implements OnInit {
  @Input() operatingDays: OperatingDay[]
  open = false

  constructor(
    private facilityUtils: FacilityUtils,
  ) {
  }

  ngOnInit(): void {
    this.open = this.facilityUtils.isOpen(this.operatingDays)
  }
}
