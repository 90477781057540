import { IndexedCollection, OperatingDay } from '../../../shared/types'
import { hourToDate } from '../../../shared/utils/datetime'
import { PaceSetterCode } from '../../issue/issue.types'
import { AARData } from './aar.types'
import { Injectable } from '@angular/core';

const FLAT_TIRE_NO_SPARE_CODE = 'T180'
const ACCIDENT_CODE = 'T906'
const BODY_SHOP = 'Body Shop'
const TIRE_WHEEL_SERVICE = 'Tire/Wheel Service'

@Injectable({
  providedIn: 'root',
})
export class FacilityUtils {

  constructor() {
  }

  isOpen = (operatingDays: OperatingDay[]) => isFacilityOpen(operatingDays)

}

export function isAAROpen(aar: AARData) {
  return isFacilityOpen(aar.operatingDays)
}

export function isFacilityOpen(operatingDays: OperatingDay[]) {
  let result

  try {
    const now = new Date()
    const today = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(
      now
    )
    const operatingDay = operatingDays.find(
      (day) => day.day.toUpperCase() === today.toUpperCase()
    )
    const from = hourToDate(operatingDay.open)
    const to = hourToDate(operatingDay.close)

    result = from <= now && to >= now
  } catch (error) {
    // If e.g. the open/close times are malformatted, we want to avoid falling apart.
    result = false
  }

  return result
}

export function filterAarByServiceOffers(
  activePaceCode: PaceSetterCode,
  res: IndexedCollection<AARData>,
  isRapUser: boolean,
): IndexedCollection<AARData> {
  // If isRapUser we don't want to apply this filter
  // When a member selects Flat Tire and no spare (code T180),
  // only AAR facilities that have 'Tire/Wheel Service' listed as a service should appear in the results.
  if (!isRapUser && activePaceCode?.paceSetterCode === FLAT_TIRE_NO_SPARE_CODE) {
    Object.keys(res).forEach((id) => {
      if (
        !res[id] ||
        !res[id].serviceOffers ||
        res[id].serviceOffers.length === 0 ||
        res[id].serviceOffers.indexOf(TIRE_WHEEL_SERVICE) === -1
      ) {
        delete res[id]
      }
    })
  }

  return res
}

export function objectToArray(data) {
  return Array.isArray(data) ? data : [data]
}
function normalizeAddress(address: string): string {
  const commonAbbreviations: Record<string, string> = {
    aly: 'alley',
    anx: 'annex',
    arc: 'arcade',
    ave: 'avenue',
    byu: 'bayou',
    bch: 'beach',
    bnd: 'bend',
    blf: 'bluff',
    btm: 'bottom',
    blvd: 'boulevard',
    br: 'branch',
    brg: 'bridge',
    brk: 'brook',
    bg: 'burg',
    byp: 'bypass',
    cp: 'camp',
    cyn: 'canyon',
    cpe: 'cape',
    cswy: 'causeway',
    ctr: 'center',
    clf: 'cliff',
    clb: 'club',
    cmns: 'commons',
    cnr: 'corner',
    cors: 'corners',
    crse: 'course',
    ct: 'court',
    cv: 'cove',
    crk: 'creek',
    cres: 'crescent',
    xing: 'crossing',
    dl: 'dale',
    dr: 'drive',
    est: 'estate',
    expy: 'expressway',
    fls: 'falls',
    fld: 'field',
    flts: 'flats',
    frd: 'ford',
    frg: 'forge',
    fwy: 'freeway',
    gtwy: 'gateway',
    gln: 'glen',
    grv: 'grove',
    hvn: 'haven',
    hts: 'heights',
    hwy: 'highway',
    holw: 'hollow',
    jct: 'junction',
    ln: 'lane',
    mtwy: 'motorway',
    opas: 'overpass',
    park: 'park',
    pkwy: 'parkway',
    pl: 'place',
    plz: 'plaza',
    pt: 'point',
    rd: 'road',
    rdg: 'ridge',
    rte: 'route',
    skwy: 'skyway',
    sq: 'square',
    sta: 'station',
    st: 'street',
    ter: 'terrace',
    trl: 'trail',
    way: 'way'
  }
  return address.toLowerCase().replace(/\b\w+\b/g, match => commonAbbreviations[match] || match);
}

export function areAddressesEqual(address1: string, address2: string): boolean {
  const normalizedAddress1 = normalizeAddress(address1);
  const normalizedAddress2 = normalizeAddress(address2);

  return normalizedAddress1 === normalizedAddress2;
}
