import { Directive, ElementRef, HostListener, Input } from '@angular/core'
import { AdobeEventService } from '../../modules/tagging/adobe/event-adobe.service'
import { AdobeEventData, AdobeEventTypes } from '../../modules/tagging/tagging.types'
import { capitalize } from '../utils'

const DIRECTIVES_TO_CHECK = [
  'appClickEvent',
  'appCtaClickEvent',
  'appSystemTriggeredEvent'
]

const EVENT_NAMES = {
  appClickEvent: AdobeEventTypes.CLICK,
  appCtaClickEvent: AdobeEventTypes.CTA,
  appSystemTriggeredEvent: AdobeEventTypes.SYSTEM
}

@Directive({
  selector: `
  [appClickEvent],
  [appCtaClickEvent],
  [appSystemTriggeredEvent]`,
})
export class AdobeEventDirective {
  @Input('appClickEvent') click_value = ''
  @Input('appCtaClickEvent') cta_click_value = ''
  @Input('appSystemTriggeredEvent') system_triggered_value = ''
  @Input() issueType = ''
  @Input() directAttributes = {}

  constructor(
    private el: ElementRef<HTMLElement | HTMLAnchorElement>,
    private adobeEventService: AdobeEventService
  ) { }

  @HostListener('click', ['$event'])
  @HostListener('clickLink', ['$event'])
  onClick() {
    const eventData = this.getEventData()
    const directAttributes = this.getDirectAttributes()

    this.adobeEventService.sendEvent(
      eventData,
      this.issueType,
      directAttributes
    )
  }

  checkEventName(): AdobeEventTypes {
    let eventName: AdobeEventTypes | null = null

    DIRECTIVES_TO_CHECK.forEach(directive => {
      if (this.el.nativeElement.hasAttribute(directive)) {
        eventName = EVENT_NAMES[directive]
      }
    })
    return eventName
  }

  getDirectAttributes(): {} {
    if ('href' in this.el.nativeElement) {
      return {
        ...this.directAttributes
      }
    }
    return this.directAttributes
  }

  getEventData(): AdobeEventData {
    return {
      eventName: this.checkEventName() ?? AdobeEventTypes.CTA,
      eventValue: capitalize(
        this.cta_click_value
        || this.click_value
        || this.system_triggered_value
        || this.el.nativeElement.textContent
        || this.el.nativeElement.tagName)
    }
  }
}
