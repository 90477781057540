<svg
  xmlns="http://www.w3.org/2000/svg"
  width="22"
  height="22"
  viewBox="0 0 22 22"
  id="icon-key-turn"
>
  <g fill="none" fill-rule="evenodd" [attr.stroke]="color" stroke-width="2">
    <circle cx="11" cy="11" r="10" />
    <path d="M10 6h2v10h-2z" />
  </g>
</svg>
