<div class="dialog">
  <h1 mat-dialog-title i18n>Alert</h1>

  <div class="dialog-content">
    <p class="text-message">{{data.content}}</p>
    <div class="dialog-actions">
      <app-call-aaa-button
        [clickEventPage]="pageType"
        (buttonClick)="onCallAAAClick()">
      </app-call-aaa-button>

      <app-link-button
        class="dismiss-button"
        (clickLink)="onClose()"
        [ariaLabel]="'dismiss this message'"
        i18n
      >
        Dismiss
      </app-link-button>
    </div>
  </div>

  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message" i18n-aria-label>
    <icon-close></icon-close>
  </button>
</div>
