import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-license-plate',
  templateUrl: './license-plate.icon.html',
})
export class LicensePlateIconComponent extends AbstractIconComponent {
  static icon = 'license-plate'
}
