import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  AdditionalLocationInfoFormState,
  CancelFeedbackOthersFormState,
  CarRentalAuthFormState,
  FormsState,
  MembershipNumberAuthFormState,
  NameAuthFormState,
  OdometerConfirmationFormState,
  PhoneNumberFormState,
  PlateToVinFormState,
  SubscriberNumberAuthFormState,
  VinMileageAuthFormState,
} from './forms.reducer'
import { FormGroupState } from 'ngrx-forms'

const stateKey = 'forms'

export const selectForms = createFeatureSelector<FormsState>(stateKey)

export const selectAuthForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<MembershipNumberAuthFormState> =>
    forms.auth
)

export const selectNameAuthForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<NameAuthFormState> => forms.nameAuth
)

export const selectPhoneNumberAuth = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<PhoneNumberFormState> => forms.phoneNumberAuth
)

export const selectAdditionalLocationInfoForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<AdditionalLocationInfoFormState> =>
    forms.additionalLocationInfo
)

export const selectVinMileageAuthForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<VinMileageAuthFormState> => forms.vinMileageAuth
)

export const selectOdometerConfirmationForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<OdometerConfirmationFormState> => forms.odometerConfirmation
)

export const selectCarRentalAuthForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<CarRentalAuthFormState> => forms.carRentalAuth
)

export const selectSubscriberNumberAuthForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<SubscriberNumberAuthFormState> => forms.subscriberNumberAuth
)

export const selectCancelFeedbackOthersForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<CancelFeedbackOthersFormState> => forms.cancelFeedbackOthers
)

export const selectPlateToVinForm = createSelector(
  selectForms,
  (forms: FormsState): FormGroupState<PlateToVinFormState> => forms.plateToVin
)
