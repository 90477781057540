import { DialogData } from '../ui.types'

export class ClosableDialog {
  hideCloseButton = false

  constructor(public data: DialogData) {
    this.hideCloseButton = Boolean(this.data.params?.hideCloseButton)
  }

  onClose() {
    if (!this.hideCloseButton) {
      this.data.close()
    }
  }

}
