import { Component, OnInit } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { Store, select } from '@ngrx/store'
import { setVehicleDetails } from '../vehicle.actions'
import { selectEditOrAddVehicleDesc, selectWorkingVehicle } from '../vehicle.selectors'
import { AbstractComponent } from '../../../shared/abstract.component'
import { TaggingService } from '../../tagging/tagging.service'
import { addPartialCallRequest } from '../../dashboard/calls.actions'
import events from '../../tagging/events'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { AdobeEventTypes } from '../../tagging/tagging.types'

@Component({
  selector: 'app-vehicle-details-step',
  templateUrl: './vehicle-details-step.component.html',
  styleUrls: ['./vehicle-details-step.component.scss'],
})
export class VehicleDetailsStepComponent
  extends AbstractComponent
  implements OnInit {

  // TODO Consider using a ngrx-form for the entire vehicle creation
  isPristine = true
  color: string
  details = {
    notes: '',
  }

  details$ = this.store$.pipe(select(selectWorkingVehicle))

  editOrAddVehicleDesc$ = this.store$.pipe(select(selectEditOrAddVehicleDesc))
  editOrAddVehicleDesc: string

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  valueOrEmpty(source, field) {
    if (source[field]) {
      return [{ name: source[field] }]
    }

    return []
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.details$.subscribe((details) => {
        this.color = details.color
        this.details = {
          notes: details.notes || '',
        }
      }),
      this.editOrAddVehicleDesc$.subscribe(
        editOrAdd => this.editOrAddVehicleDesc = editOrAdd
      )
    )
  }

  nameOrEmpty(values) {
    if (!values || !values.length || !values[0].name) {
      return ''
    }

    return values[0].name
  }

  onNext() {
    this.isPristine = false
    if (!this.color) {
      return null
    }

    this.store$.dispatch(
      setVehicleDetails({
        payload: {
          color: this.color,
          notes: this.details.notes,
        },
      })
    )

    this.taggingService.setClickEvent(
      events.vehicle.VEHICLE_COMPLETE,
      events.vehicle.VEHICLE_SUSPICIOUS_PAGE_TYPE
    )
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: `${this.editOrAddVehicleDesc} ${events.vehicle.VEHICLE_COMPLETE}`
    })

    this.store$.dispatch(addPartialCallRequest())
  }
}
