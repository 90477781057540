import { Component, Input, Output, EventEmitter } from '@angular/core'
import { VehicleEditState } from '../vehicle.reducer'
import { AAAStore } from '../../../store/root-reducer'
import { Store, select } from '@ngrx/store'
import { editVehicle, setVehicleStep } from '../vehicle.actions'
import { VehicleData } from '../../member/member.types'
import { Observable } from 'rxjs/internal/Observable'
import { selectIsVehicleYearEditing } from '../vehicle.selectors'
import { clearActiveVehicle } from '../../member/member.actions'
import { getModelDescription } from '../vehicle.utils'

@Component({
  selector: 'app-edit-vehicle-options',
  templateUrl: './edit-vehicle-options.component.html',
  styleUrls: ['./edit-vehicle-options.component.scss'],
})
export class EditVehicleOptionsComponent {
  @Input() vehicle: VehicleEditState = {}
  @Input() isActive = false
  @Input() tabIndexPosition = 0

  @Output() clickCancel: EventEmitter<any> = new EventEmitter<any>()

  yearDisabled$: Observable<boolean> = this.store$.pipe(
    select(selectIsVehicleYearEditing)
  )

  constructor(private store$: Store<AAAStore>) {}

  editVehicleSection(section: string) {
    this.store$.dispatch(clearActiveVehicle())
    this.store$.dispatch(setVehicleStep({
      payload: {
        step: section
      }
    }))
    this.store$.dispatch(
      editVehicle({
        payload: {
          vehicle: this.vehicle as VehicleData,
          isActive: this.isActive,
          section,
        },
      })
    )

    //handle canceling button
    if (!section) {
      this.clickCancel.emit()
    }
  }

  getModel(vehicle: VehicleEditState) {
    return getModelDescription(vehicle)
  }

  cancelEditVehicle() {
    this.store$.dispatch(setVehicleStep({
      payload: {
        step: null
      }
    }))
    this.clickCancel.emit()
  }

}
