<div class="app-stepper" #stepperContainer>
  <ng-container *ngFor="let step of steps; let i = index; let last = last">
    <app-step
      class="app_step {{
        (step?.status || 'PENDING') +
          ' next-' +
          (steps[i + 1]?.status || 'PENDING') | lowercase
      }} "
      [ngClass]="{ 'last-step': last, 'small-steps': steps?.length > 4 }"
      [stepId]="i + 1"
      [stepTitle]="step?.title"
      [stepStatus]="step?.status || 'PENDING'"
      [stepUrl]="step?.url"
      [tabIndex]="10 + i"
    >
    </app-step>
  </ng-container>
</div>
