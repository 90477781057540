<ng-container *ngIf="display">
  <div class="collapse service-history-collapse container" @flyInOut>
    <a (click)="handleDisplay()" class="service-history-close red-highlight">
      CLOSE
    </a>
    <h4 class="service-history-title">Service History</h4>
    <ng-container *ngIf="(serviceHistory$ | async) as serviceHistory">
      <div *ngFor="let i of serviceHistory, let k = index" class="service-history-item" >
        <app-service-history-item [item]="i" ></app-service-history-item>
      </div>
    </ng-container>
  </div>
  <div (click)="handleDisplay()" class="background-overlay"></div>
</ng-container>
