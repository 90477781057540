<svg
  xmlns="http://www.w3.org/2000/svg"
  width="22"
  height="27"
  viewBox="0 0 22 27"
  id="icon-battery-jump"
>
  <g [attr.fill]="color" fill-rule="evenodd">
    <path
      d="M10.596 21.606l1.76.292c-.197 1.148-.19 2.28-.817 3.269a3.741 3.741 0 0 1-4.2 1.613c-1.582-.447-2.718-1.944-2.721-3.62-.008-3.176 0-6.352-.003-9.528-.002-1.631-.69-2.323-2.297-2.323H0V9.47c1.435.066 2.92-.297 4.31.37 1.432.688 2.108 1.907 2.11 3.51.005 3.219-.012 6.439.01 9.659.01 1.656 1.623 2.6 2.968 1.77.584-.36.843-.922.944-1.583.079-.515.164-1.029.254-1.59M19.32 21.003c-.615.021-.938-.3-1.024-.884-.35-2.391-.7-4.783-1.034-7.177-.13-.931.13-1.26 1.027-1.388.407-.058.816-.102 1.22-.17.843-.141 1.226.105 1.36.953.2 1.28.369 2.567.551 3.85.155 1.088.304 2.176.466 3.263.117.787-.112 1.138-.901 1.288-.552.105-1.11.178-1.665.265"
    />
    <path
      d="M12.654 20.966c-.544-.085-1.172-.15-1.783-.288-.558-.128-.747-.552-.668-1.11.242-1.722.484-3.443.728-5.164.102-.719.204-1.438.32-2.154.109-.684.459-.955 1.124-.875.537.064 1.076.13 1.609.224.68.12.955.522.857 1.224-.327 2.331-.656 4.663-.992 6.993-.13.896-.42 1.182-1.195 1.15M15.57 7.807c.218-.314.455-.507.434-.663-.024-.172-.287-.428-.444-.428-.152 0-.402.27-.423.446-.019.155.217.342.432.645m-1.337 2.833l-1.82-.281c.143-1.076.273-2.116.422-3.153.286-1.982.58-3.963.877-5.943.123-.824.899-1.327 1.665-1.058.108.038.235.327.193.437-.096.256-.274.488-.448.705-.445.554-.363 1.118.234 1.506.637.414.65.677.057 1.161-.58.473-.566 1.167.045 1.593.25.174.532.3.779.477.787.566.769 1.608-.029 2.156-.072.05-.148.092-.222.137-1.302.78-1.302.78-1.753 2.263"
    />
    <path
      d="M16.622 5.696c.635-.73.606-1.087-.14-1.718-.396-.334-.37-.73.068-1.015.832-.54.871-.952.224-1.738-.187-.227-.269-.543-.412-.847 1.403-.279 1.837-.032 2.013 1.143.42 2.806.813 5.616 1.213 8.425.015.107.002.218.002.41l-1.795.3c-.162-.843-.412-1.607-1.25-1.959.988-1.257.995-1.816.077-3.001"
    />
  </g>
</svg>
