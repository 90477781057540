import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-car-tilt',
  templateUrl: './car-tilt.icon.html',
})
export class CarTiltIconComponent extends AbstractIconComponent {
  static icon = 'car-tilt'
}
