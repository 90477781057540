import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'icon-magnifier',
  templateUrl: './magnifier.icon.html',
})
export class MagnifierIconComponent extends AbstractIconComponent {
  static icon = 'magnifier'
  @Input() scale = 1
}
