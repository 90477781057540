import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import {
  StoreRouterConnectingModule,
  FullRouterStateSerializer,
} from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'

export const routes: Routes = [
  {
    path: 'healthz',
    loadChildren: () =>
      import('@aaa-mobile/drrweb-lib').then((m) => m.HealthModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('@aaa-mobile/drrweb-lib').then((m) => m.DrrWebMainModule),
  },
]

@NgModule({
  imports: [
    StoreModule,
    RouterModule.forRoot(routes, {}),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
      stateKey: 'router',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
