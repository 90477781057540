import { isPlatform } from '@ionic/angular'

export const configureKeyboard = async (isVisible: boolean) => {
  if (isPlatform('capacitor')) {
    try {
      // @ts-ignore
      const { Capacitor } = await import('@capacitor/core')
      if (Capacitor.isPluginAvailable('Keyboard')) {
        // @ts-ignore
        const { Keyboard } = await import('@capacitor/keyboard')
        Keyboard.setAccessoryBarVisible({ isVisible })
      }
    } catch (error) {
      console.error(error)
    }
  }
}
