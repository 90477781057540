import { createFeatureSelector, createSelector } from '@ngrx/store'
import { LoadingState } from './loading.reducer'

const stateKey = 'loading'

export const selectLoading = createFeatureSelector<LoadingState>(stateKey)

export const selectIsLoading = (feature: string) =>
  createSelector(
    selectLoading,
    (loading: object): boolean => Boolean(loading) && Boolean(loading[feature])
  )
export const selectIsAnyRequestLoading = createSelector(
  selectLoading,
  (loadingState): boolean =>
    Boolean(loadingState && Object.values(loadingState).some(Boolean))
)
