<div class="vin-mileage-credentials">
    <label i18n id="last-name-label" for="mileage">{{mileageLabel$ | async}}</label>
    <input
        i18n-placeholder
        name="mileage"
        id="mileage"
        #mileage
        type="number"
        placeholder="Mileage"
        autocomplete="on"
        [ngrxFormControlState]="controls.mileage"
        [tabIndex]="3" />
    <app-form-error i18n [control]="controls?.mileage" name='mileage' type='required'>
        Mileage is required.
    </app-form-error>
    <app-form-error i18n [control]="controls?.mileage" name='mileage' type='greaterThan'>
        Mileage must be greater than 0.
    </app-form-error>
    <app-form-error i18n [control]="controls?.mileage" name='mileage' type='lessThan'>
        Mileage number is too long.
    </app-form-error>
</div>

