import { createFeatureSelector, createSelector } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'

import {
  BreakdownLocation,
  BreakdownLocationDetails,
  DIRECTIONAL_NAMES,
  GenericCoordinates,
  GoogleLocationMarker,
  LastSearchLocation,
  LOCATION_STEPS,
  LocationCard,
  LocationMapTemplate
} from './location.types'
import { concatAddress } from '../../shared/utils/concatAddress'
import { BreakdownLocationParams } from '../dashboard/calls.types'
import { AdditionalLocationInfoFormState } from '../ui/forms/forms.reducer'
import { selectAdditionalLocationInfoForm } from '../ui/forms/forms.selectors'
import { selectUserDefaultCoords, selectUserDefaultCoordsLoading } from '../ui/ui.selectors'
import { LOCATION_TYPE, SET_BREAKDOWN_LOCATION } from './location.actions'
import { LocationState } from './location.reducer'
import { BREAKDOWN_LOCATION_OPTIONS } from './breakdown-location/breakdown-location.constants'
import { AuthMethods } from '../auth/auth.types';
import { isHomeAddressComplete } from './location.utils';
import { selectIsLoading } from '../ui/loading/loading.selectors';
import { selectAuthMethod } from '../auth/auth.selectors';
import { selectMemberData } from '../member/member.selectors';
import { GoogleCoordinates } from './google-geocode/types';
import { INITIAL_COORDS } from './location.constants';
import { LocationAddress } from '../breakdown-location/components/breakdown-location.types'
import { MarkerTypes } from '../ui/ui.types'

const stateKey = 'location'

export const selectLocationState = createFeatureSelector<LocationState>(stateKey)

export const selectLocationServicesAvailable = createSelector(
  selectLocationState,
  (state: LocationState) => state.servicesAvailable
)

export const selectGpsLocationDenied = createSelector(
  selectLocationState,
  (state: LocationState): boolean => state.gpsLocationDenied
)

export const selectBreakdownLocation = createSelector(
  selectLocationState,
  // TODO remove unnecessary null check, improve affected unit tests instead.
  (state: LocationState): BreakdownLocation => state && state.breakdownLocation
)

export const selectBreakdownLocationAddress = createSelector(
  selectLocationState,
  (state: LocationState): string => state.address
)

export const selectBreakdownLocationCoordinates = createSelector(
  selectLocationState,
  (state: LocationState): GenericCoordinates => ({
    latitude: state.breakdownLocation?.latitude,
    longitude: state.breakdownLocation?.longitude,
  })
)

export const selectSpecialAssistance = createSelector(
  selectLocationState,
  (state: LocationState) => state.specialAssistance
)

export const selectIsBreakdownLocationHome = createSelector(
  selectBreakdownLocation,
  (breakdownLocation: BreakdownLocation): boolean =>
    breakdownLocation.locationType === LOCATION_TYPE.HOME ||
    breakdownLocation.locationType === LOCATION_TYPE.HOME_LINK
)

export const selectLocationStep = createSelector(
  selectLocationState,
  (state: LocationState): LOCATION_STEPS => state.step
)

export const selectCurrentGeoLocation = createSelector(
  selectLocationState,
  (state: LocationState): GenericCoordinates => state?.geoLocationCoordinates
)

export const selectBreakdownLocationDetails = createSelector(
  selectLocationState,
  selectAdditionalLocationInfoForm,
  (
    state: LocationState,
    additionalInfoForm: FormGroupState<AdditionalLocationInfoFormState>
  ): BreakdownLocationDetails => ({
    // FIXME: Momentary hack due to Angular Template form + ngrx-forms mix in app.
    // Remove when using ngrx-forms in BreakdownLocationDetailsComponent.
    // It should reuse the same AdditionalLocationInfoFormState. (LocationDetailsFormState rename adviced).
    ...state.breakdownLocationDetails,
    notes:
      state.breakdownLocationDetails.notes ||
      additionalInfoForm.controls.breakdownComment.value,
  })
)

export const selectBreakdownLocationOptionsNames = createSelector(
  selectBreakdownLocationDetails,
  (state: BreakdownLocationDetails) => state && (state.options || []).map(option => option.name)
)

export const selectBreakdownLocationInterstateSelected = createSelector(
  selectBreakdownLocationDetails,
  (state: BreakdownLocationDetails): Boolean => {
    const selectedOptions = (state.options || []).map(option => option.name)
    const interstateSelected = selectedOptions.findIndex((el => el === BREAKDOWN_LOCATION_OPTIONS().ON_INTERSTATE_HIGHWAY))
    return interstateSelected !== -1
  }
)

export const selectBreakdownMarker = createSelector(
  selectLocationState,
  (locationState: LocationState): GoogleLocationMarker => {
    if (!locationState.address) {
      return null
    }

    const {
      location,
      latitude,
      longitude,
      postalCode,
      state,
      city,
      streetName,
      streetNumber,
      accuracy,
      locationType,
    } = locationState.breakdownLocation

    return {
      lat: Number(latitude),
      lng: Number(longitude),
      accuracy,
      locationType,
      address: concatAddress(
        {
          postalCode,
          state,
          city,
          streetName,
          streetNumber: String(streetNumber),
        },
        location
      ),
      notes: locationState.breakdownLocationDetails.notes,
    }
  }
)

export const selectIsBreakdownLocationValid = createSelector(
  selectBreakdownLocationCoordinates,
  selectBreakdownLocationAddress,
  (coordinates: GenericCoordinates, address: string): boolean =>
    Boolean(address) &&
    Boolean(coordinates.latitude) &&
    Boolean(coordinates.longitude)
)

export const selectHasBreakdownLocationPostalCode = createSelector(
  selectBreakdownLocation,
  (breakdownLocation: BreakdownLocation): boolean =>
    (breakdownLocation?.postalCode || breakdownLocation?.zip || '') !== ''
)

export const selectLandMarkLocationName = createSelector(
  selectLocationState,
  (state: LocationState): string => state.landmark
)

export const displayLandmarkAddress = createSelector(
  selectLocationState,
  (state: LocationState): boolean => state.displayLandmark
)

export const selectLandMarkAndAddress = createSelector(
  selectLocationState,
  (state: LocationState): string => {
    const landmark = state.landmark || ''
    const landmarkAndAddress = state.address && state.displayLandmark ?
      `${landmark}, ${state.address.replace(landmark, '')}` :
      ''
    return (state.displayLandmark && landmark ? landmarkAndAddress : state.address).trim()
  }
)
export const selectLandMarkAndStreet = createSelector(
  selectLocationState,
  selectBreakdownLocation,
  (state: LocationState, blState: BreakdownLocation): string => {
    let address = `${blState?.streetNumber ? blState.streetNumber : ''} ${blState?.streetName ? blState.streetName : ''}`
    const landmark = state.landmark || ''
    if (landmark && state.displayLandmark) {
      address = `${state.landmark}, ${address.replace(state.landmark, '')}`
    }
    return address.trim()
  }
)

export const selectCityStateAndZip = createSelector(
  selectBreakdownLocation,
  (state: BreakdownLocation): string =>
    state && state.city && state.state ?
      `${state.city}, ${state.state} ${state.postalCode || state.zip || ''}` : ''
)

export const selectLocationClub = createSelector(
  selectLocationState,
  (state: LocationState): string =>
  // FIXME: find tests with undefined state
    state?.servicingClub || state?.tempServicingClub
)

export const selectLocationClubRequested = createSelector(
  selectLocationState,
  (state: LocationState): boolean =>
    state.servicingClubRequested
)

export const selectCountryClub = createSelector(
  selectLocationState,
  (state: LocationState): string => state.country
)

export const selectIsHighway = createSelector(
  selectLocationState,
  (state: LocationState): boolean => state.highway
)

export const selectHighwayExit = createSelector(
  selectLocationState,
  (state: LocationState): string | null => {
    let description = null

    const highwayExits: any = state.highwayExits
    const exitAhead = highwayExits?.exitAhead || null
    const exitBehind = highwayExits?.exitBehind || null
    const direction = exitAhead?.direction
      ? DIRECTIONAL_NAMES[exitAhead['direction']]
      : null

    const ahead = cleanUpString(`${exitAhead?.exitNumber || exitAhead?.exitName || ''}`)
    const behind = cleanUpString(`${exitBehind?.exitNumber || exitBehind?.exitName || ''}`)

    if (direction && ahead && behind) {
      description = $localize`${direction} between exits ${ahead} and ${behind}`
    }

    if (direction && ahead && !behind) {
      description = $localize`${direction} near exit ${ahead}`
    }

    if (direction && !ahead && !behind) {
      description = direction
    }

    return description
  }
)

export const selectBreakdownLocationParams = createSelector(
  selectBreakdownLocation,
  selectSpecialAssistance,
  selectCountryClub,
  (breakdownLocation, specialAssistance, countryClub): BreakdownLocationParams =>
    breakdownLocation && {
      latitude: Number(breakdownLocation.latitude),
      longitude: Number(breakdownLocation.longitude),
      location: breakdownLocation.location,
      streetNumber: breakdownLocation.streetNumber,
      street: breakdownLocation.streetName,
      city: breakdownLocation.city,
      state: breakdownLocation.state,
      zip: breakdownLocation.postalCode,
      landmark: breakdownLocation.landmark,
      driverDirections: specialAssistance,
      ...( countryClub ? { country: countryClub } : {} )
    }
)

export const selectHasGPSAccess = createSelector(
  selectLocationServicesAvailable,
  selectUserDefaultCoords,
  (areLocationServicesAvailable, userDefaultCoords): boolean =>
    areLocationServicesAvailable || (Boolean(userDefaultCoords && userDefaultCoords.lat && userDefaultCoords.lng))
)

export const selectLocationCard = createSelector(
  selectIsBreakdownLocationValid,
  selectIsLoading(SET_BREAKDOWN_LOCATION.ACTION),
  selectAuthMethod,
  selectMemberData,
  selectUserDefaultCoordsLoading,
  (isValidLocation, isLoadingLocation, authMethod, member, userDefaultCoordsLoading): LocationCard => ({
    display: !isValidLocation,
    displayHomeItem: isHomeAddressComplete(member) && !(authMethod === AuthMethods.MEMBER_NAME),
    loading: isLoadingLocation,
    userDefaultCoordsLoading,
    member,
  })
)

export const selectLocationMapTemplate = createSelector(
  selectIsBreakdownLocationValid,
  (isValidLocation): LocationMapTemplate => ({
    static: !isValidLocation,
    ...(isValidLocation ? { centerMarker: { type: MarkerTypes.CAR } } : {})
  })
)

export const selectBreakdownCoordinates = createSelector(
  selectLocationState,
  (state): GoogleCoordinates => state.breakdownLocation
    ? {
      lat: Number(state.breakdownLocation?.latitude),
      lng: Number(state.breakdownLocation?.longitude),
    }
    : INITIAL_COORDS
)

function cleanUpString(str: string) {
  return str.replace(/\/<wbr\/>/g, '')
}

export const selectLastSearchLocation = createSelector(
  selectLocationState,
  (state: LocationState): LastSearchLocation => state.lastSearchLocation
)

export const selectAdjustLocation = createSelector(
  selectLocationState,
  (state: LocationState): boolean => state.adjustLocation
)

export const selectAdjustLocationAddress = (() => {
  let previousLastLocation: LocationAddress;

  return createSelector(
    selectLastSearchLocation,
    selectLandMarkAndStreet,
    selectCityStateAndZip,
    (lastLocation, landMarkAndStreet, cityStateAndZip): LocationAddress => {
      if (previousLastLocation !== lastLocation && lastLocation) {
        previousLastLocation = lastLocation;
        return lastLocation;
      } else {
        return {
          main_text: landMarkAndStreet,
          secondary_text: cityStateAndZip,
        }
      }
    }
  )
})()
