import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core'
import { PaceSetterCode } from '../issue.types'
import { defer } from 'lodash'
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-problem-checklist',
  templateUrl: './problem-checklist.component.html',
  styleUrls: ['./problem-checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProblemChecklistComponent implements OnChanges, OnDestroy {
  @Input() heading: string
  @Input() name: string
  @Input() problems: PaceSetterCode[]
  @Input() multiSelect = false
  @Input() allowCollapse = true
  @Input() required = false
  @Input() selectedItems: PaceSetterCode[] = []
  @Input() open = true
  @Input() tabIndexPosition = 0

  @Output() selectionChanged: EventEmitter<PaceSetterCode[]> = new EventEmitter()

  selection: Record<string, PaceSetterCode> | {} = {}

  private selectionChangeSubject = new Subject<any>()

  constructor() {
    const RIPPLE_DURATION = 225
    this.selectionChangeSubject
      .pipe(debounceTime(RIPPLE_DURATION))
      .subscribe((value) => {
        this.selectionChanged.emit(value)
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedItems']) {
      this.updateSelection()
    }
  }

  ngOnDestroy() {
    this.selectionChangeSubject.complete();
  }

  ngAfterViewInit() {
    defer(() => this.bootstrapSelection())
  }

  bootstrapSelection() {
    if (!this.selectedItems || !this.selectedItems.length) {
      return
    }

    // if multi select check if is selected
    if (this.multiSelect) {
      this.selectedItems.forEach((_problem) => {
        const _selected = this.isSelected(_problem)
        this.problemChange(_problem, _selected)
      })
      return
    }

    // if not multi - only take first element
    const [problem] = this.selectedItems
    const selected = this.isSelected(problem)
    this.problemChange(problem, selected)
  }

  isSelected(problem: PaceSetterCode) {
    return !!this.selection[problem.name]
  }

  problemChange(
    problem: PaceSetterCode,
    checked: boolean,
  ) {
    if (this.multiSelect) {
      this.problemChangeMultiSelect(problem, checked)
    } else {
      this.problemChangeSingleSelect(problem, checked)
    }
  }

  problemChangeSingleSelect(
    problem: PaceSetterCode,
    checked: boolean,
  ) {
    this.selection = { [problem.name]: checked ? problem : null }
    this.emitSelection()
  }

  problemChangeMultiSelect(
    problem: PaceSetterCode,
    checked: boolean,
  ) {
    this.selection[problem.name] = checked ? problem : null
    this.emitSelection()
  }

  private updateSelection() {
    this.selection = this.selectedItems.reduce((acc, item) => {
      acc[item.name] = item
      return acc
    }, {})
  }

  private emitSelection() {
    const _emitValue = Object.values(this.selection).filter(Boolean)
    this.selectionChangeSubject.next(_emitValue)
  }
}
