<svg
  width="16px"
  height="24px"
  viewBox="0 0 16 24"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  id="icon-pin-map"
>
  <g
    id="Service-Tracker---Flow"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      id="iPhone-6---AAA-RSO---Request-En-Route---Scroll"
      transform="translate(-31.000000, -87.000000)"
      fill="#003893"
    >
      <g id="Tabs" transform="translate(10.000000, 74.000000)">
        <g id="content">
          <g id="Tabs" transform="translate(17.000000, -5.000000)">
            <g id="Tab-Map" transform="translate(4.000000, 18.000000)">
              <path
                d="M1.44202821,12.312547 C0.534322233,11.0366869 0,9.47301629 0,7.78364211 C0,3.48485528 3.4597392,0 7.72754379,0 C11.9953484,0 15.4550876,3.48485528 15.4550876,7.78364211 C15.4550876,9.27885243 15.0365291,10.6755935 14.310993,11.8614746 L8.07283409,24 L3.19846236,15.5880335 L1.44202821,12.312547 Z M7.72754379,13.0428598 C10.6111955,13.0428598 12.9488572,10.6882278 12.9488572,7.78364211 C12.9488572,4.87905641 10.6111955,2.52442447 7.72754379,2.52442447 C4.84389204,2.52442447 2.50623042,4.87905641 2.50623042,7.78364211 C2.50623042,10.6882278 4.84389204,13.0428598 7.72754379,13.0428598 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
