import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { SharedModule } from '../../shared'
import { GoogleGeocodeService } from './google-geocode/google-geocode.service'
import { TowLocationEffects } from './tow-location/tow-location.effects'
import { GoogleMap } from '@angular/google-maps';
import { GoogleMapsConfig } from '../../google-maps-config';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([
      TowLocationEffects,
    ]),
    GoogleMap,
  ],
  providers: [
    GoogleMapsConfig,
    GoogleGeocodeService,
  ]
})
export class LocationModule { }
