import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'icon-tow-location-pin',
  templateUrl: './tow-location-pin.icon.html',
  styleUrls: ['tow-location-pin.icon.scss']
})
export class TowLocationPinIconComponent extends AbstractIconComponent {
  @Input() isSelected = true
  @Input() small = false
}
