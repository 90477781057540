import { NgModule } from '@angular/core'

import { SharedModule } from '../shared'
import { IsAuthorizedRouteGuard } from '../shared/route-guard/is-authorized.route-guard'
import { IsNotAuthorizedRouteGuard } from '../shared/route-guard/is-not-authorized.route-guard'
import { RouteGuardService } from '../shared/route-guard/route-guard.service'

@NgModule({
  imports: [SharedModule],
  providers: [
    RouteGuardService,
    IsAuthorizedRouteGuard,
    IsNotAuthorizedRouteGuard,
  ],
})
export class MainModule {}
