import { NgrxValueConverter } from 'ngrx-forms'

export class NumericOnlyValueConverter
implements NgrxValueConverter<string, string> {
  convertViewToStateValue(value: string): string {
    return (value || '').replace(/\D/g, '')
  }

  convertStateToViewValue(value: string): string {
    return value
  }
}
