import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogData } from '../../ui.types'
import { TaggingService } from '../../../tagging/tagging.service'
import events from '../../../tagging/events'

@Component({
  selector: 'app-not-a-member-dialog',
  templateUrl: './not-a-member-dialog.component.html',
  styleUrls: [
    '../shared-dialog.component.scss',
    './not-a-member-dialog.component.scss',
  ],
})
export class NotAMemberDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private taggingService: TaggingService
  ) {}

  onClose() {
    this.taggingService.setClickEvent(events.shared.CALL_AAA_CLICK, events.shared.NOT_A_MEMBER_PAGE_TYPE)
    this.data.close()
  }
}
