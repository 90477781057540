import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-battery',
  templateUrl: './battery.icon.html',
})
export class BatteryIconComponent extends AbstractIconComponent {
  static icon = 'battery'
}
