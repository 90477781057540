export const getAllCookies = (): Object =>
  document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .reduce((accumulator, cookie) => {
      const [key, value] = cookie.split('=')
      accumulator[key] = value
      return accumulator
    }, {})

export const getCookie = (cookieName: string): string => {
  const fromStorage = localStorage.getItem(cookieName)
  if (fromStorage) {
    return fromStorage
  } else {
    const sanitizedCookieName = cookieName.replace(/-/g, '\\-')
    const matches = new RegExp(`${sanitizedCookieName}=[\\w-]+`, 'g').exec(
      document.cookie
    )
    if (!matches) {
      return ''
    }

    return matches[0].split('=')[1]
  }
}

export const setCookie = (cookieName: string, cookieValue: string): void => {
  localStorage.setItem(cookieName, cookieValue)
  document.cookie = `${cookieName}=${cookieValue}; path=/;`
}

export const getAppId = (): string => getCookie('AAA_AppId')

const REDESIGN_FLAG = 'redesign'
const REDESIGN_ON = 'on'
const REDESIGN_OFF = 'off'

export const isRedesign = (): boolean => true
export const turnOnRedesign = (): void => setCookie(REDESIGN_FLAG, REDESIGN_ON)
export const turnOffRedesign = (): void => setCookie(REDESIGN_FLAG, REDESIGN_OFF)
