import { AdobeEventService } from '../../../modules/tagging/adobe/event-adobe.service';
import { AdobeClickEvent, AdobeEventData, AdobeEventTypes } from '../../../modules/tagging/tagging.types';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'

enum ButtonSizes {
  CONDENSED = 'condensed',
  COZY = 'cozy',
  FIT_CONTENT = 'fit-content',
}

@Component({
  selector: 'app-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LinkButtonComponent {
  @Output() clickLink: EventEmitter<any> = new EventEmitter<any>()

  @Input() href = ''
  @Input() inverted = false
  @Input() target = ''
  @Input() tabIndex = 0
  @Input() size: ButtonSizes = ButtonSizes.COZY
  @Input() ariaLabel = null
  @Input() elementId = null
  @Input() role = null
  @Input() fontSize = null
  @Input() analyticData: null | AdobeEventData = null

  constructor(private adobeEventService: AdobeEventService) {}

  handleClick($event: Event) {
    if (this.clickLink) {
      if (this.href === '' || this.href === undefined) {
        $event.preventDefault()
        $event.stopPropagation()
      } else {
        $event['href'] = this.href
      }

      this.clickLink.emit($event)
    }

    if(this.analyticData) {
      this.adobeEventService.sendEvent({
        eventName: this.analyticData.eventName,
        eventValue: this.analyticData.eventValue
      })
    }
  }

}
