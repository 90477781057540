<ng-container *ngIf="destination.evStationDetails?.chargeLevel as chargerLevel; else shop">
  <app-charging-level-list-v2
    [dcFast]="chargerLevel.dcFast"
    [level1]="chargerLevel.level1"
    [level2]="chargerLevel.level2"
  ></app-charging-level-list-v2>
</ng-container>
<ng-template #shop>
  <ion-grid class="ion-no-padding">
    <ion-row class="info-section">
      <ion-col class="icon-column" size="auto">
        <ng-container *ngIf="isOwnedShop(); else approvedShop">
          <icon-aaa-logo class="logo" />
        </ng-container>
        <ng-template #approvedShop>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="4" fill="#64748B"/>
            <path d="M20.9607 14.0052H17.9948V11.0393L21.455 7.57913C20.3481 7.05048 19.1045 6.878 17.8956 7.08543C16.6866 7.29286 15.5716 7.87 14.7042 8.73737C13.8369 9.60475 13.2597 10.7197 13.0523 11.9287C12.8449 13.1377 13.0174 14.3812 13.546 15.4881L7.61425 21.4199C7.22095 21.8132 7 22.3466 7 22.9028C7 23.459 7.22095 23.9924 7.61425 24.3857C8.00755 24.779 8.54098 25 9.09719 25C9.6534 25 10.1868 24.779 10.5801 24.3857L16.5119 18.454C17.6188 18.9826 18.8623 19.1551 20.0713 18.9477C21.2803 18.7403 22.3953 18.1631 23.2626 17.2958C24.13 16.4284 24.7071 15.3134 24.9146 14.1044C25.122 12.8955 24.9495 11.6519 24.4209 10.545L20.9607 14.0052Z" fill="#F1F5F9"/>
          </svg>
        </ng-template>
      </ion-col>
      <ion-col class="info-column">
        <ion-row>
          <aaa-text class="title">{{getTitle()}}</aaa-text>
        </ion-row>
        <ion-row>
          <aaa-text class="subtitle description" display="flex" align="left" i18n>Member Discount Available</aaa-text>
        </ion-row>
      </ion-col>
      <ion-col class="info-column" size="auto" *ngIf="destination.ratingSummary?.reviewCount > 0 && destination.ratingSummary?.ratingAvg >= 3.5">
        <ion-row class="title ratings">
          <app-rating-v2 class="margin-right-4" [rate]="destination.ratingSummary.ratingAvg"></app-rating-v2>
          <aaa-text i18n>{{ destination.ratingSummary.ratingAvg | number:'1.0-1' }}</aaa-text>
        </ion-row>
        <ion-row class="ion-justify-content-end">
          <app-reviews-count-v2 [numberReviews]="destination.ratingSummary.reviewCount"></app-reviews-count-v2>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>
