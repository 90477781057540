import { createFeatureSelector, createSelector } from '@ngrx/store'
import { RouteDistance, RouteDistancesState } from './route-distance.reducer'

const stateKey = 'routesDistance'

export const selectRoutesDistanceState = createFeatureSelector<RouteDistancesState>(stateKey)

export const selectRoutesDistance = createSelector(
  selectRoutesDistanceState,
  (state: RouteDistancesState): RouteDistance[] => state.routesDistance
)
