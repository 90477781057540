import {
  createFormGroupState,
  formGroupReducer,
  FormGroupState,
  ResetAction,
  updateGroup,
  validate,
  ValidationErrors,
} from 'ngrx-forms'
import { greaterThan, lessThan, pattern, required } from 'ngrx-forms/validation'
import { createAction } from '@ngrx/store'
import { ACCEPT_VIN_8_FORM, AUTH_RESET } from '../../auth/auth.actions'
import { CANCEL_EDITING_REQUEST } from '../../../shared/actions/shared.actions'
import { PayloadedAction } from '../../../shared/types';
import { MAX_INTEGER } from '../../constants/shared.constants';
import { validateMembershipNumber } from '../../../shared/utils/validateMembershipNumber'

export interface AuthFormsState {
  auth: FormGroupState<MembershipNumberAuthFormState>
  nameAuth: FormGroupState<NameAuthFormState>
  vinMileageAuth: FormGroupState<VinMileageAuthFormState>
  odometerConfirmation: FormGroupState<OdometerConfirmationFormState>
  vinMileageAuthConfig: VinMileageAuthConfig
  carRentalAuth: FormGroupState<CarRentalAuthFormState>
  subscriberNumberAuth: FormGroupState<SubscriberNumberAuthFormState>
  phoneNumberAuth: FormGroupState<PhoneNumberFormState>
  cancelFeedbackOthers: FormGroupState<CancelFeedbackOthersFormState>
}
export interface CallRequestFormsState {
  additionalLocationInfo: FormGroupState<AdditionalLocationInfoFormState>
}
export interface PlateToVinFormsState {
  plateToVin: FormGroupState<PlateToVinFormState>
}
export type FormsState = AuthFormsState &
  CallRequestFormsState &
  PlateToVinFormsState

export type CancelFeedbackOthersFormInitialState = Pick<
  AuthFormsState,
  'cancelFeedbackOthers'
>

export const AUTH_FORM = 'form.auth'
export const NAME_AUTH_FORM = 'form.nameauth'
export const ADDITIONAL_LOCATION_INFO_FORM = 'form.additionalLocationInfo'
// TODO: ngrx-forms' main benefit is that they are part of the state.
// ngrx-forms handles state.forms automatically, you'll find the above 3 forms there.
// Somehow, these forms are hacked within state.submit. Must be moved here with the rest.
export const USER_CONTACT_INFO_FORM = 'form.contactInfo'
export const PASSENGER_INFO_FORM = 'form.passengers'
export const VIN_MILEAGE_FORM = 'form.vinMileage'
export const ODOMETER_CONFIRMATION_FORM = 'form.odometerConfirmation'
export const CAR_RENTAL_FORM = 'form.carRental'
export const SUBSCRIBER_NUMBER_FORM = 'form.subscriberNumber'
export const PHONE_NUMBER_AUTH_FORM = 'form.phoneNumberAuth'
export const CANCEL_FEEDBACK_OTHERS_FORM = 'form.cancelFeedbackOthers'
export const RESET_CANCEL_FEEDBACK_OTHERS_FORM =
  'RESET_CANCEL_FEEDBACK_OTHERS_FORM'
export const PLATE_TO_VIN_FORM = 'form.plateToVin'
export const RESET_PLATE_TO_VIN_FORM = 'RESET_PLATE_TO_VIN_FORM'

export const MEMBERSHIP_NUMBER_LENGTH = 16

export const resetOthersForm = () =>
  new ResetAction(CANCEL_FEEDBACK_OTHERS_FORM)
export const setResetCancelFeedbackOthersForm = createAction(
  RESET_CANCEL_FEEDBACK_OTHERS_FORM
)
export const setResetPlateToVinForm = createAction(RESET_PLATE_TO_VIN_FORM)

export interface MembershipNumberAuthFormState {
  membershipNumber: string
  zipCode: string
}

export interface NameAuthFormState {
  firstName: string
  lastName: string
  zipCode: string
}

export interface PhoneNumberFormState {
  phoneNumber: string
}

export interface CancelFeedbackOthersFormState {
  reason: string
}

export interface VinMileageAuthFormState {
  vinNumber: string
  mileage: number
}

export interface OdometerConfirmationFormState {
  mileage: number
}

export interface CarRentalAuthFormState {
  refNumber: number
}

export interface SubscriberNumberAuthFormState {
  subscriber: number
}

export interface AdditionalLocationInfoFormState {
  breakdownComment: string
}

export interface PlateToVinFormState {
  licensePlate: string
  state: string
  color: string
}

const checkNumberErrorMessage = () => $localize`Invalid Membership Number. Please try again.`

const checkNumberValidator = (membershipNumber: string): ValidationErrors | null => {
  if(membershipNumber.replace(/\s/g, '').length !== MEMBERSHIP_NUMBER_LENGTH) {
    return null
  }
  const isValid = validateMembershipNumber(membershipNumber)
  return isValid ? null : { checkNumber: checkNumberErrorMessage() };
}

const validateAuthForm = updateGroup<MembershipNumberAuthFormState>({
  membershipNumber: validate(
    required,
    pattern(/^\d{3}\s?\d{3}\s?\d{9}\s?\d{1}$/),
    checkNumberValidator
  ),
  zipCode: validate(required, pattern(/^(\d{5}|[A-Z]\d[A-Z]?\d[A-Z]\d)$/i)),
})

const validateNameAuthForm = updateGroup<NameAuthFormState>({
  firstName: validate(required),
  lastName: validate(required),
  zipCode: validate(required, pattern(/^[A-Z0-9]{5}[A-Z0-9]?\s?$/i)),
})

export interface VinMileageAuthConfig {
  acceptVin8Char: boolean
}

const validatePhoneNumberAuthForm = updateGroup<PhoneNumberFormState>({
  phoneNumber: validate(required, pattern(/^\d{10}$/)),
})

const validateVinMileageAuthForm = updateGroup<VinMileageAuthFormState>({
  vinNumber: validate(required, pattern(/^[a-zA-Z0-9]{17}$/)),
  mileage: validate(required, greaterThan(0), lessThan(MAX_INTEGER)),
})

const validateOdometerConfirmationForm = updateGroup<OdometerConfirmationFormState>({
  mileage: validate(required, greaterThan(0), lessThan(MAX_INTEGER)),
})

const validateVin8MileageAuthForm = updateGroup<VinMileageAuthFormState>({
  vinNumber: validate(required, pattern(/^([a-zA-Z0-9]{8}|[a-zA-Z0-9]{17})$/)),
  mileage: validate(required, greaterThan(0), lessThan(MAX_INTEGER)),
})

const validateAdditionalLocationInfoForm =
  updateGroup<AdditionalLocationInfoFormState>({
    breakdownComment: validate(required),
  })

const validateCarRentalAuthForm = updateGroup<CarRentalAuthFormState>({
  refNumber: validate(required),
})

const validateSubscriberNumberAuthForm =
  updateGroup<SubscriberNumberAuthFormState>({
    subscriber: validate(required),
  })

const validateCancelFeedbackOthersForm =
  updateGroup<CancelFeedbackOthersFormState>({
    reason: validate(required),
  })

const validatePlateToVinForm = updateGroup<PlateToVinFormState>({
  licensePlate: validate(required, pattern(/^[a-zA-Z0-9]{6,7}$/)),
  state: validate(required),
  color: validate(required),
})

export function createAuthFormInitialState(): AuthFormsState {
  return {
    auth: createFormGroupState<MembershipNumberAuthFormState>(AUTH_FORM, {
      membershipNumber: '',
      zipCode: '',
    }),
    nameAuth: createFormGroupState<NameAuthFormState>(NAME_AUTH_FORM, {
      firstName: '',
      lastName: '',
      zipCode: '',
    }),
    vinMileageAuth: createFormGroupState<VinMileageAuthFormState>(VIN_MILEAGE_FORM, {
      vinNumber: '',
      mileage: 0,
    }),
    odometerConfirmation: createFormGroupState<OdometerConfirmationFormState>(ODOMETER_CONFIRMATION_FORM, {
      mileage: 0,
    }),
    vinMileageAuthConfig: {
      acceptVin8Char: false
    },
    carRentalAuth: createFormGroupState<CarRentalAuthFormState>(CAR_RENTAL_FORM, {
      refNumber: null,
    }),
    subscriberNumberAuth: createFormGroupState<SubscriberNumberAuthFormState>(SUBSCRIBER_NUMBER_FORM, {
      subscriber: null,
    }),
    phoneNumberAuth: createFormGroupState<PhoneNumberFormState>(PHONE_NUMBER_AUTH_FORM, {
      phoneNumber: ''
    }),
    cancelFeedbackOthers: createFormGroupState<CancelFeedbackOthersFormState>(CANCEL_FEEDBACK_OTHERS_FORM, {
      reason: ''
    }),
  }
}

export function createPlateToVinFormInitialState(): PlateToVinFormsState {
  return {
    plateToVin: createFormGroupState<PlateToVinFormState>(PLATE_TO_VIN_FORM, {
      licensePlate: '',
      state: '',
      color: '',
    }),
  }
}
export function createCallFormInitialState(): CallRequestFormsState {
  return {
    additionalLocationInfo:
      createFormGroupState<AdditionalLocationInfoFormState>(
        ADDITIONAL_LOCATION_INFO_FORM,
        {
          breakdownComment: '',
        }
      ),
  }
}

export function createOthersFormInitialState(): CancelFeedbackOthersFormInitialState {
  return {
    cancelFeedbackOthers: createFormGroupState<CancelFeedbackOthersFormState>(
      CANCEL_FEEDBACK_OTHERS_FORM,
      { reason: '' }
    ),
  }
}

export function createInitialState(): FormsState {
  return {
    ...createAuthFormInitialState(),
    ...createCallFormInitialState(),
    ...createPlateToVinFormInitialState(),
  }
}

export function reducer(
  state: FormsState | undefined = createInitialState(),
  action: PayloadedAction
): FormsState {
  if (action.type === AUTH_RESET) {
    state = {
      ...state,
      ...createAuthFormInitialState(),
    }
  }

  if (action.type === CANCEL_EDITING_REQUEST) {
    state = {
      ...state,
      ...createCallFormInitialState(),
    }
  }

  if (action.type === ACCEPT_VIN_8_FORM) {
    state = {
      ...state,
      vinMileageAuthConfig: {
        ...state.vinMileageAuthConfig,
        acceptVin8Char: action.payload,
      },
    }
  }

  const auth = validateAuthForm(formGroupReducer(state.auth, action))
  if (auth !== state.auth) {
    state = {
      ...state,
      auth,
    }
  }

  const nameAuth = validateNameAuthForm(
    formGroupReducer(state.nameAuth, action)
  )
  if (nameAuth !== state.nameAuth) {
    state = {
      ...state,
      nameAuth,
    }
  }

  const phoneNumberAuth = validatePhoneNumberAuthForm(
    formGroupReducer(state.phoneNumberAuth, action)
  )
  if (phoneNumberAuth !== state.phoneNumberAuth) {
    state = {
      ...state,
      phoneNumberAuth,
    }
  }

  const vinMileageAuth = state.vinMileageAuthConfig.acceptVin8Char
    ? validateVin8MileageAuthForm(formGroupReducer(state.vinMileageAuth, action))
    : validateVinMileageAuthForm(formGroupReducer(state.vinMileageAuth, action))
  if (vinMileageAuth !== state.vinMileageAuth) {
    state = {
      ...state,
      vinMileageAuth,
    }
  }

  const odometerConfirmation = validateOdometerConfirmationForm(formGroupReducer(state.odometerConfirmation, action))
  if (odometerConfirmation !== state.odometerConfirmation) {
    state = {
      ...state,
      odometerConfirmation,
    }
  }

  const carRentalAuth = validateCarRentalAuthForm(formGroupReducer(state.carRentalAuth, action))
  if (carRentalAuth !== state.carRentalAuth) {
    state = {
      ...state,
      carRentalAuth,
    }
  }

  const subscriberNumberAuth = validateSubscriberNumberAuthForm(
    formGroupReducer(state.subscriberNumberAuth, action)
  )
  if (subscriberNumberAuth !== state.subscriberNumberAuth) {
    state = {
      ...state,
      subscriberNumberAuth,
    }
  }

  const additionalLocationInfo = validateAdditionalLocationInfoForm(
    formGroupReducer(state.additionalLocationInfo, action)
  )
  if (additionalLocationInfo !== state.additionalLocationInfo) {
    state = {
      ...state,
      additionalLocationInfo,
    }
  }

  const plateToVinForm = validatePlateToVinForm(
    formGroupReducer(state.plateToVin, action)
  )
  if (plateToVinForm !== state.plateToVin) {
    state = {
      ...state,
      plateToVin: plateToVinForm,
    }
  }

  const cancelFeedbackOthers = validateCancelFeedbackOthersForm(
    formGroupReducer(state.cancelFeedbackOthers, action)
  )
  if (cancelFeedbackOthers !== state.cancelFeedbackOthers) {
    state = {
      ...state,
      cancelFeedbackOthers,
    }
  }
  if (action.type === RESET_CANCEL_FEEDBACK_OTHERS_FORM) {
    state = {
      ...state,
      ...createOthersFormInitialState(),
    }
  }
  if (action.type === RESET_PLATE_TO_VIN_FORM) {
    state = {
      ...state,
      ...createPlateToVinFormInitialState(),
    }
  }

  return state
}
