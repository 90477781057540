import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-charging-level-list',
  templateUrl: './charging-level-list.component.html',
  styleUrls: ['./charging-level-list.component.scss'],
})
export class ChargingLevelListComponent {

  @Input() dcFast = 0
  @Input() level1 = 0
  @Input() level2 = 0

  constructor() {
  }

}
