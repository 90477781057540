<a
  [href]="href || '#'"
  [tabIndex]="tabIndex"
  class="app-clickable-link"
  (click)="handleClick($event)"
  [title]="content.innerText"
  [target]="target || '_blank'"
  triggerable-element
>
  <span #content>
    <ng-content></ng-content>
  </span>
</a>
