import { Pipe, PipeTransform } from '@angular/core';

function formatDigit(digit: number): string {
  return digit.toString().padStart(2, '0');
}
@Pipe({ name: 'timeRemaining' })
export class TimeRemainingPipe implements PipeTransform {

  transform(milliseconds: number): string {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));

    if (hours === 0) {
      return `${formatDigit(minutes)}min`;
    }

    if (minutes < 0) {
      return `00min`;
    }

    return `${formatDigit(hours)}h ${formatDigit(minutes)}min`;
  }

}
