<svg
  xmlns="http://www.w3.org/2000/svg"
  width="17"
  height="18"
  viewBox="0 0 17 18"
  id="icon-gasoline"
>
  <path
    [attr.fill]="color"
    fill-rule="evenodd"
    d="M15.77 4.23l.01-.01L12.06.5 11 1.56l2.11 2.11c-.94.36-1.61 1.26-1.61 2.33A2.5 2.5 0 0 0 14 8.5c.36 0 .69-.08 1-.21v7.21c0 .55-.45 1-1 1s-1-.45-1-1V11c0-1.1-.9-2-2-2h-1V2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2v16h10v-7.5h1.5v5a2.5 2.5 0 0 0 5 0V6c0-.69-.28-1.32-.73-1.77zM8 7H2V2h6v5zm6 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-6.588 6.1v2.34a3.066 3.066 0 0 1-.938.474c-.332.1-.687.15-1.066.15-.472 0-.9-.073-1.282-.22a2.87 2.87 0 0 1-.98-.612 2.681 2.681 0 0 1-.626-.936 3.14 3.14 0 0 1-.22-1.188c0-.435.07-.833.212-1.196.141-.363.342-.675.602-.936.26-.261.575-.464.946-.608.37-.144.787-.216 1.248-.216.235 0 .454.019.658.056.204.037.393.089.568.154.175.065.334.145.478.238.144.093.276.196.396.308l-.308.488a.294.294 0 0 1-.188.142c-.077.017-.161-.002-.252-.058l-.264-.152a1.703 1.703 0 0 0-.294-.132 2.413 2.413 0 0 0-.83-.128c-.285 0-.543.048-.774.144-.23.096-.427.233-.59.412a1.857 1.857 0 0 0-.376.644c-.088.25-.132.53-.132.84 0 .33.047.626.142.886.095.26.228.48.4.66.172.18.38.317.622.412.243.095.513.142.812.142a2.2 2.2 0 0 0 .572-.068c.168-.045.332-.107.492-.184v-1.048h-.728a.219.219 0 0 1-.162-.058.193.193 0 0 1-.058-.142V13.1h1.92z"
  />
</svg>
