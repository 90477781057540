<div class="input-state-container"
     [ngClass]="{'error-border': error || (form$ | async).controls.state.isTouched && (form$ | async).controls.state.errors?.required }">
  <icon-location class="icon"
    [colorName]="'red'">
  </icon-location>
  <app-select
    i18n-placeholder
    [elementId]="'States'"
    placeholder="State"
    [items$]="usStatesOptions$"
    [searchable]="false"
    [dropdownPosition]="'auto'"
    [selection]="selection"
    (selectionMade)="handleChange($event)">
  </app-select>
</div>
