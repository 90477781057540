import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  Input,
} from '@angular/core'
import { NgSelectComponent } from '@ng-select/ng-select'
import { CHARACTER_LIMIT_VEHICLE_COLOR } from '../../../modules/location/location.constants'
import { Color, COLORS, VehicleUtils } from '../../../modules/vehicle/vehicle.utils'
import { Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'

@Component({
  selector: 'app-input-vehicle-color',
  templateUrl: './input-vehicle-color.component.html',
  styleUrls: ['./input-vehicle-color.component.scss'],
})
export class InputVehicleColorComponent {
  private color
  availableColors = COLORS()
  maxCharacters = CHARACTER_LIMIT_VEHICLE_COLOR

  get selectedColor() {
    return this.color
  }

  @Input()
  set selectedColor(color) {
    this.color = typeof color === 'string' ? color : color?.code || color?.label
  }

  @Input() error = false
  @Output() selectedColorChange: EventEmitter<string> = new EventEmitter<string>()
  @ViewChild('ngSelect') ngSelect: NgSelectComponent

  constructor(
    private store$: Store<AAAStore>,
    private vehicleUtils: VehicleUtils
  ) { }

  handleChange() {
    this.store$.dispatch({
      controlId: 'form.plateToVin.color',
      value: this.selectedColor,
      type: 'ngrx/forms/SET_VALUE'
    })
    this.selectedColorChange.emit(this.selectedColor)
    this.ngSelect.blur()
  }

  isColorAvailable() {
    return this.vehicleUtils.isColorAvailable(this.selectedColor)
  }

  getColor = (): Color => this.vehicleUtils.getColor(this.selectedColor)

}
