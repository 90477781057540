import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { selectIsSecure } from '../../../modules/auth/auth.selectors';
import { OdometerConfirmationFormState } from '../../../modules/ui/forms/forms.reducer';
import { selectOdometerConfirmationForm } from '../../../modules/ui/forms/forms.selectors';
import { AAAStore } from '../../../store/root-reducer';
import { ValidationFormComponent } from '../../types';
import { selectMileageLabel } from '../../../modules/ui/ui.selectors'

@Component({
  selector: 'app-odometer-confirmation',
  templateUrl: './odometer-confirmation.component.html',
  styleUrls: ['./odometer-confirmation.component.scss']
})
export class OdometerConfirmationComponent implements ValidationFormComponent, OnInit {

  isSecure$ = this.store$.pipe(select(selectIsSecure))

  mileageLabel$ = this.store$.select(selectMileageLabel)

  constructor(private store$: Store<AAAStore>) {
  }

  odometerConfirmationForm: FormGroupState<OdometerConfirmationFormState>;
  odometerConfirmationForm$: Observable<FormGroupState<OdometerConfirmationFormState>> = this.store$.pipe(
    select(selectOdometerConfirmationForm)
  )

  get formState(): FormGroupState<any> {
    return this.odometerConfirmationForm
  }

  get controls(): any {
    return this.formState && this.formState.controls
  }

  ngOnInit(): void {
    this.odometerConfirmationForm$.subscribe((odometerConfirmation) => {
      this.odometerConfirmationForm = odometerConfirmation;
    })
  }

}
