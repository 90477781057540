import { Component, Input, OnInit } from '@angular/core'
import { ChargingLevelStyle, EvStationVolts } from '../types';
import { CHARGING_LEVEL_LIST } from '../utils/ev-station/ev-station.utils';

@Component({
  selector: 'icon-charging-level',
  templateUrl: './charging-level.icon.html',
})
export class ChargingLevelIconComponent implements OnInit {
  @Input() volts: EvStationVolts
  @Input() size = 69
  chargingLevel: ChargingLevelStyle

  constructor() {
  }

  ngOnInit(): void {
    this.chargingLevel = CHARGING_LEVEL_LIST[this.volts]
  }
}
