import auth from './auth'
import dashboard from './dashboard'
import issue from './issue'
import location from './location'
import passengers from './passengers'
import shared from './shared'
import submit from './submit'
import towTo from './tow-to'
import vehicle from './vehicle'

export default {
  auth,
  dashboard,
  issue,
  location,
  passengers,
  shared,
  submit,
  towTo,
  vehicle,
}
