<ion-card class="ion-no-margin">
  <ion-list class="ion-no-padding">
    <app-request-details-item
      i18n-title
      icon="map-pin"
      title="Location"
      [clickable]="clickable()"
      [caption]="[(selectBreakdownLocationLandmark$ | async), (selectBreakdownLocationAddress$ | async), (highWayExit$ | async)]"
      (itemClick)="editService.doEdit('location')">
    </app-request-details-item>
    <app-request-details-item
      i18n-title
      icon="warning-outline"
      title="Issue"
      [clickable]="clickable() && (isIssueChangeAllowed$ | async)"
      [caption]="paceSetterCode$ | async"
      (itemClick)="editService.doEdit('issue', '')">
    </app-request-details-item>
    <app-request-details-item
      *ngIf="(activeVehicle$ | async) as vehicle"
      i18n-title
      icon="car"
      title="Vehicle"
      [caption]="vehicle"
      [clickable]="clickable() && (isVehicleChangeAllowed$ | async)"
      (itemClick)="editService.doEdit('vehicle')">
    </app-request-details-item>
    <app-request-details-item
      *ngIf="(towLocation$ | async) as towTo"
      i18n-title
      icon="map-search"
      title="Tow To"
      [clickable]="clickable()"
      [caption]="[towTo.landmark, towTo.addressLine1, towTo.addressLine2]"
      (itemClick)="editService.doEdit('tow-location')">
    </app-request-details-item>
    <app-request-details-item
      *ngIf="clickable() || (specialAssistance$ | async)"
      i18n-title
      icon="ear-off"
      title="Special Assistance"
      [clickable]="clickable()"
      [caption]="(specialAssistance$ | async) || '—'"
      (itemClick)="handleSpecialAssistanceEdit()">
    </app-request-details-item>
    <app-request-details-item
      *ngIf="(passengersQty$ | async) as passengersQty"
      i18n-title
      icon="users-group"
      title="Passengers"
      [clickable]="clickable()"
      [caption]="passengersQty"
      (itemClick)="goToPassengers()">
    </app-request-details-item>
  </ion-list>
</ion-card>
