import { IndexedCollection } from '../types'

function arrayToIndexedObject<
  T extends { [key: string]: any },
  I extends keyof T
>(array: Array<T>, index: I) {
  return array.reduce((indexedObj: IndexedCollection<T>, item: T) => {
    if (!item[index]) {
      throw new Error('indexCollection: index id path not found.')
    }
    return {
      ...indexedObj,
      [item[index]]: item,
    }
  }, {})
}

export function indexCollection<T, I extends keyof T>(
  data: Array<any>,
  // TODO investigate a way to spread explicit TS array types
  idPath: I | [string, I]
): IndexedCollection<T> {
  if (!data || data.length === 0) {
    return {}
  }

  if (typeof idPath === 'string') {
    return arrayToIndexedObject<T, I>(data, idPath)
  } else if (Array.isArray(idPath)) {
    const [nestedAttribute, index] = idPath

    return data.reduce(
      (indexedCollection: IndexedCollection<T>, item: T) => ({
        ...indexedCollection,
        ...arrayToIndexedObject<any, any>(item[nestedAttribute], index),
      }),
      {}
    )
  } else {
    throw new Error(
      'indexCollection: an index id is required as string or array of strings.'
    )
  }
}
