import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-key-lost',
  templateUrl: './key-lost.icon.html',
})
export class KeyLostIconComponent extends AbstractIconComponent {
  static icon = 'key-lost'
}
