import { ActionReducer } from '@ngrx/store'

import { AAAStore } from '../root-reducer'
import { CHECK_SESSION_TIMEOUT } from '../../modules/session/session.actions'
import { DIALOG_OPEN } from '../../modules/ui/ui.actions'
import { PayloadedAction } from '../../shared/types'
import { MessageDialogTypes } from '../../modules/ui/ui.types'

export function lastInteractionMiddleware(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function newReducer(state: AAAStore, action: PayloadedAction) {
    if (
      state &&
      !Boolean(
        action.type === CHECK_SESSION_TIMEOUT ||
          (action.type === DIALOG_OPEN.MESSAGE &&
            action.payload &&
            action.payload.type === MessageDialogTypes.SESSION_TIMEOUT)
      )
    ) {
      const nextState = {
        ...state,
        session: {
          ...state.session,
          lastInteraction: new Date().getTime(),
        },
      }
      return reducer(nextState, action)
    }

    return reducer(state, action)
  }
}
