<div class="input-license-plate">
  <icon-license-plate class="icon-plate"
    color="#FA1223">
  </icon-license-plate>
  <input
    i18n-placeholder
    id="licensePlate"
    name="licensePlate"
    [ngrxFormControlState]="(form$ | async).controls.licensePlate"
    [ngClass]="{'error-border': error || ((form$ | async).controls.licensePlate.errors?.pattern || (form$ | async).controls.licensePlate.errors?.required) && (form$ | async).controls.licensePlate.isTouched }"
    type="text"
    placeholder="License Plate"
    [tabIndex]="1"
    (blur)="handleBlurText($event)"
    mask="A{7}"
  />
</div>
