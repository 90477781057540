import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-out-of-fuel',
  templateUrl: './out-of-fuel.icon.html',
})
export class OutOfFuelIconComponent extends AbstractIconComponent {
  static icon = 'out-of-fuel'
}
