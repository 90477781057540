import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'


@Component({
  selector: 'icon-hand',
  templateUrl: './hand.icon.html',
  styleUrls: ['./hand.icon.scss'],
})
export class HandIconComponent extends AbstractIconComponent {

}
