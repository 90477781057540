<ng-container *ngIf="!small; else small_pin">
  <svg class="tow-location-pin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       width="82px" height="109px" viewBox="0 0 82 109" [ngClass]="{ 'pin-selected': isSelected }">
    <defs>
      <filter id="a" width="171.7%" height="291.2%" x="-35.8%" y="-95.6%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="1.912"/>
      </filter>
      <path id="c"
            d="M0 20.5c0 15.375 20.611 38.071 20.611 38.071S41.222 35.875 41.222 20.5c0-11.304-9.245-20.5-20.61-20.5C9.245 0 0 9.196 0 20.5z"/>
      <filter id="b" width="136.4%" height="126.4%" x="-18.2%" y="-12.8%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter1"/>
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(20 5)">
        <ellipse cx="21" cy="63" fill="#4A4A4A" filter="url(#a)" opacity=".395" rx="8" ry="3"/>
        <path stroke="currentColor" stroke-linecap="square" stroke-width="2" d="M20.683 42.857V63.43"/>
        <use fill="#000" filter="url(#b)" xlink:href="#c"/>
        <use fill="currentColor" xlink:href="#c"/>
        <g transform="translate(5.745 5.714)">
          <ellipse cx="14.938" cy="14.857" fill="#FFF" rx="14.938" ry="14.857"/>
          <path fill="currentColor"
                d="M21.829 7.934c-.2-.585-.757-1-1.414-1H9.46c-.658 0-1.205.415-1.415 1l-2.07 5.933v7.923c0 .545.447.991.995.991h.996a.996.996 0 0 0 .996-.99V20.8h11.95v.99c0 .545.448.991.996.991h.995a.996.996 0 0 0 .996-.99v-7.924L21.83 7.934zM9.46 17.829a1.488 1.488 0 0 1-1.494-1.486c0-.822.667-1.486 1.494-1.486.826 0 1.493.664 1.493 1.486s-.667 1.486-1.493 1.486zm10.954 0a1.488 1.488 0 0 1-1.494-1.486c0-.822.667-1.486 1.494-1.486.826 0 1.494.664 1.494 1.486s-.668 1.486-1.494 1.486zM7.967 12.876L9.46 8.42h10.954l1.494 4.457H7.967z"/>
        </g>
      </g>
    </g>
  </svg>
</ng-container>
<ng-template #small_pin>
  <svg class="tow-location-pin" width="35" height="44" viewBox="0 0 35 44" fill="none" xmlns="http://www.w3.org/2000/svg" [ngClass]="{ 'pin-selected': isSelected }">
    <g filter="url(#filter0_d_2828_52)">
      <path
        d="M16.0937 35.2624C16.7266 36.2459 18.2031 36.2459 18.8359 35.2624C29.1016 20.5112 31 18.9659 31 13.4868C31 6.04098 24.9531 0 17.5 0C9.97656 0 4 6.04098 4 13.4868C4 18.9659 5.82812 20.5112 16.0937 35.2624Z"
        fill="currentColor"/>
      <rect x="7" y="3" width="21" height="21" rx="10.5" fill="white"/>
      <path
        d="M22.7207 11.1562H21.4531L21.0879 10.2754C20.7227 9.35156 19.8418 8.75 18.8535 8.75H16.125C15.1367 8.75 14.2559 9.35156 13.8906 10.2754L13.5254 11.1562H12.2578C12.0859 11.1562 11.957 11.3281 12 11.4785L12.1289 11.9941C12.1504 12.123 12.2578 12.1875 12.3867 12.1875H12.8164C12.5156 12.4453 12.3438 12.8105 12.3438 13.2188V14.25C12.3438 14.6152 12.4727 14.916 12.6875 15.1523V16.3125C12.6875 16.6992 12.9883 17 13.375 17H14.0625C14.4277 17 14.75 16.6992 14.75 16.3125V15.625H20.25V16.3125C20.25 16.6992 20.5508 17 20.9375 17H21.625C21.9902 17 22.3125 16.6992 22.3125 16.3125V15.1523C22.5059 14.916 22.6562 14.6152 22.6562 14.25V13.2188C22.6562 12.8105 22.4629 12.4453 22.1621 12.1875H22.6133C22.7207 12.1875 22.8281 12.123 22.8496 11.9941L22.9785 11.4785C23.0215 11.3281 22.8926 11.1562 22.7207 11.1562ZM15.1582 10.791C15.3301 10.3828 15.6953 10.125 16.125 10.125H18.8535C19.2832 10.125 19.6484 10.3828 19.8203 10.791L20.25 11.8438H14.75L15.1582 10.791ZM14.0625 14.25C13.6328 14.25 13.375 13.9922 13.375 13.5625C13.375 13.1543 13.6328 12.875 14.0625 12.875C14.4707 12.875 15.0938 13.498 15.0938 13.9062C15.0938 14.3145 14.4707 14.25 14.0625 14.25ZM20.9375 14.25C20.5078 14.25 19.9062 14.3145 19.9062 13.9062C19.9062 13.498 20.5078 12.875 20.9375 12.875C21.3457 12.875 21.625 13.1543 21.625 13.5625C21.625 13.9922 21.3457 14.25 20.9375 14.25Z"
        fill="currentColor"/>
    </g>
    <defs>
      <filter id="filter0_d_2828_52" x="0" y="0" width="35" height="44" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2828_52"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2828_52" result="shape"/>
      </filter>
    </defs>
  </svg>
</ng-template>
